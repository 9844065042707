import styled from 'styled-components';

import { useSignUpContext } from './context';

const layout = {
  vertical: '@media (max-width: 1129px)',
  horizontal: '@media (min-width: 1130px)',
};

const stepperLayout = {
  small: '@media (max-width: 939px)',
  large: '@media (min-width: 940px)',
};

const Wrapper = styled.div`
  ${stepperLayout.large} {
    grid-column: 1;
    padding: 32px 0 48px 0;
  }
  ${stepperLayout.small} {
    grid-row: 1;
    padding: 0 0 32px 0;
  }
`;

const Container = styled.nav`
  display: flex;
  ${layout.vertical} {
    flex-direction: column;
  }
`;

const StepNumber = styled.span`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: block;
  color: #ffffff;
  margin-right: 8px;
  transition: background-color .2s;
`;

const StepLabel = styled.span`
  line-height: 32px;
`;

const Step = styled.button<{
  selected: boolean;
  filled: boolean;
}>`
  -webkit-tap-highlight-color: transparent;
  border: none;
  padding: 0;
  background: transparent;
  cursor: ${p => p.onClick ? 'pointer' : 'auto'};
  font-size: 17px;
  color: ${p => p.selected ? p.theme.palette.text.high: p.theme.palette.text.low};
  display: flex;
  transition: color .2s;
  ${StepNumber} {
    background: ${p => p.filled ? p.theme.palette.primary.main : p.theme.palette.gray[400]};
  }

  &:hover:not(:disabled) {
    ${StepNumber} {
      background: ${p => p.filled ? p.theme.palette.primary.dark : p.theme.palette.gray[600]};
    }
  }
`;

const Interline = styled.span`
  display: block;

  ${layout.vertical} {
    width: 18px;
    height: 18px;
    border-right: 2px dotted ${p => p.theme.palette.gray[400]};
    margin: 8px 0;
  }

  ${layout.horizontal} {
    width: 64px;
    height: 18px;
    border-bottom: 2px dotted ${p => p.theme.palette.gray[400]};
    margin: 0 8px;
  }
`;

const Stepper: React.FC = () => {
  const [{
    currentStep,
    paymentMethodProvided,
    paymentMethodRequired,
    status,
  }, dispatch] = useSignUpContext();

  return (
    <Wrapper>
      <Container>
        <Step
          selected={currentStep === 'userDetailsAndConsents'}
          filled={true}
          onClick={() => dispatch({ type: 'setStep', targetStep: 'userDetailsAndConsents' })}
        >
          <StepNumber>1</StepNumber>
          <StepLabel>Dane konta</StepLabel>
        </Step>
        <Interline />
        {paymentMethodRequired && (
          <>
            <Step
              selected={currentStep === 'paymentMethod'}
              filled={currentStep === 'paymentMethod' || paymentMethodProvided}
              onClick={() => dispatch({ type: 'setStep', targetStep: 'paymentMethod' })}
            >
              <StepNumber>2</StepNumber>
              <StepLabel>Dane płatności</StepLabel>
            </Step>
            <Interline />
          </>
        )}
        <Step
          selected={status === 'signUpPending' || status === 'signUpSuccess'}
          filled={status === 'signUpPending' || status === 'signUpSuccess'}
          disabled
        >
          <StepNumber>{paymentMethodRequired ? '3' : '2'}</StepNumber>
          <StepLabel>Finalizacja</StepLabel>
        </Step>
      </Container>
    </Wrapper>
  )
};

export default Stepper;
