import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Carousel } from 'src/components';
import { useUserState } from 'src/contexts/UserContext';
import { CarouselItemId } from 'src/models/Carousel';
import CarouselService from 'src/services/CarouselService';

export const CarouselPreview: React.FC<{
  id: CarouselItemId;
}> = ({
  id,
}) => {
  const history = useHistory();
  const { data: item } = useQuery(
    ['carouselConfig', id],
    () => CarouselService.getSingleCarousel(id),
  );
  if (useUserState().isRegularUser) {
    history.replace('/');
  }

  if (!item) {
    return null;
  }

  return <Carousel page="homepage" previewItem={item} />
};
