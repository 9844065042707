import { Wrapper, Count, Separator } from './styles';

export const Header: React.FC<{
  count?: number;
}> = ({
  children,
  count = 0,
}) => (
  <>
  <Separator />
  <Wrapper>
    {children}
    {count > 0 && <Count>{count}</Count>}
  </Wrapper>
  </>
);
