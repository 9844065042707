import { useMediaQuery } from '@mui/material';
import { useUserInfo } from 'src/hooks';
import { DesktopNavbar } from './DesktopNavbar';
import { MobileNavbar } from './MobileNavbar';

export const Navbar: React.FC = () => {
  const isDesktopVariant = useMediaQuery('(min-width: 1280px)');
  const { user } = useUserInfo();
  return isDesktopVariant
    ? <DesktopNavbar adminMode={user.hasAdminAccess} />
    : <MobileNavbar adminMode={user.hasAdminAccess} />;
};
