export type VideoId = Branded<string, 'videoId'>;
export type VimeoId = Branded<string, 'vimeoId'>;
export type ThumbnailId = Branded<string, 'thumbnailId'>;

interface VideoThumbnail {
  id: ThumbnailId;
  width: number;
  height: number;
  link: string;
  uploadLink: string;
}

export interface CreateVideoDto {
  duration: number;
  size: string;
  name: string;
}

export interface VideoDto {
  id: VideoId;
  vimeoId: VimeoId;
  name: string;
  size: string;
  duration: number | null;
  createdDate: string;
  thumbnail: VideoThumbnail[];
  uploadLink: string;
  viewLink: string;
}

export interface Video {
  id: VideoId;
  vimeoId: VimeoId;
  name: string;
  size: number | null;
  duration: number | null;
  createdDate: Date;
  thumbnail: VideoThumbnail[];
  uploadLink: string;
  viewLink: string;
}

/**
 * 
 * @param vimeoLink e.g. `https://vimeo.com/610725041/890ddf876c`
 * @returns e.g. https://player.vimeo.com/video/610725041?h=890ddf876c
 */
const composeEmbedLink = (vimeoLink: string) => {
  const [key, videoId] = vimeoLink.split('/').reverse();
  return `https://player.vimeo.com/video/${videoId}?h=${key}`;
}

export const transformVideoDto = (dto: VideoDto): Video => ({
  ...dto,
  size: Number(dto.size) || null,
  createdDate: new Date(dto.createdDate),
  viewLink: composeEmbedLink(dto.viewLink),
});
