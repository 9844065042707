import { useEffect, useRef } from 'react';

import { useSignUpContext } from '../context';
import { GTMEvent } from 'src/models/GoogleTagManager';
import { SubscriptionProduct } from '../../../models/SubscriptionProduct';
import { User } from '../../../models/User';
import GTMService from 'src/services/GTMService';
import { md5 } from 'src/utils/md5';


interface ItemInfo {
  product: SubscriptionProduct;
  user?: User;
  discount_info?: string;
}

export const sendEcommerceEvent = (eventType: GTMEvent, itemInfo: ItemInfo) => {
  // Google recommends clearing ecommerce object prior to pushing any ecommerce event to the data layer
  GTMService.sendEvent({ ecommerce: null });
  GTMService.sendEvent({
    event: eventType,
    ecommerce: {
      items: [{
        product_id: itemInfo.product.id,
        product_name: itemInfo.product.name,
      }],
      transactionId: itemInfo.user && `reg-${itemInfo.user.id}`,
      md5: itemInfo.user && md5(itemInfo.user.email),
      value: itemInfo.product.price / 100,
      currency: 'PLN',
      discount_info: itemInfo.discount_info,
    }
  })
}

export const useTrackSignUpEvent = () => {
  const [{
    product,
    userDetails: { firstName, lastName, email },
    consents,
    paymentMethodProvided,
  }] = useSignUpContext();
  const shouldSendAddToCartEvent = useRef(true);
  const shouldSendBeginCheckoutEvent = useRef(true);

  useEffect(() => {
    if (product && shouldSendAddToCartEvent.current) {
      const userDetailsFilled = [firstName, lastName, email].every(Boolean);
      const allConsentsGranted = Object.values(consents).every(Boolean);
      if (userDetailsFilled && allConsentsGranted) {
        sendEcommerceEvent(GTMEvent.Registration, { product });
        shouldSendAddToCartEvent.current = false;
      }
    }
  }, [product, firstName, lastName, email, consents]);

  useEffect(() => {
    if (product && paymentMethodProvided && shouldSendBeginCheckoutEvent.current) {
      sendEcommerceEvent(GTMEvent.AddCardInfo, { product });
      shouldSendBeginCheckoutEvent.current = false;
    }
  }, [product, paymentMethodProvided]);
}
