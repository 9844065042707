import React, { useContext, useReducer } from 'react';

import { AuthTokenService } from 'src/services';

import { Dispatch, State} from './types';
import { initialState, userReducer } from './reducer';

const UserStateContext = React.createContext<
  [State, Dispatch] | undefined
>(undefined);

const UserProvider: React.FC = ({ children }) => {
  const authStatus = AuthTokenService.get() ? 'pending' : 'idle';
  const value = useReducer(userReducer, { ...initialState, authStatus });
  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export {
  UserProvider,
  useUserContext,
};
