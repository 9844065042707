import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as Svg } from './icon.svg';

const pulse = keyframes`
  \ 0%   { opacity: 0 }
  \ 30%  { opacity: 0.8 }
  \ 60%  { opacity: 0.0 }
  \ 100% { opacity: 0.0 }
`;

const Icon = styled.div.attrs({
  children: <Svg />
})`
  width: 64px;
  height: 64px;
  color: ${p => p.theme.palette.primary.main};
  & path {
    fill: currentColor;
    opacity: 0;
    animation: ${pulse} 2.4s infinite ease-in-out;
  }
  & .shape1 { animation-delay: 0s }
  & .shape2 { animation-delay: .2s }
  & .shape3 { animation-delay: .4s }
  & .shape4 { animation-delay: .6s }
  & .shape5 { animation-delay: .8s }
`;

export const Loader: React.FC<{
  delay?: number;
  className?: string;
}> = ({
  delay = 0,
  className
}) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const ref = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(ref);
  }, [delay]);

  return isVisible
    ? <Icon className={className} />
    : null;
};
