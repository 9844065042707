import { ChallengeSessionId } from 'src/models/Challenge';
import { Session } from 'src/components';

import { useChallengeSession } from './hooks';

const ChallengeSession: React.FC<{
  id: ChallengeSessionId;
  isCompleted: boolean;
  onToggleCompleted: () => void;
}> = ({
  id,
  isCompleted,
  onToggleCompleted,
}) => {
  const { data: session } = useChallengeSession(id);

  if (!session) return null;

  return (
    <Session
      contentType={session.contentType}
      title={session.title}
      underline={session.underline}
      difficulty={session.difficulty}
      primaryDescription={session.primaryDescription}
      secondaryDescription={session.secondaryDescription}
      coach={session.coach}
      style={session.style as any}
      type={session.type as any}
      tag={session.tag as any}
      equipment={session.equipment as any}
      video={session.video}
      horizontalThumbnail={session.horizontalThumbnail}
      attachments={session.extras}
      isCompleted={isCompleted}
      toggleCompleted={onToggleCompleted}
      duration={session.video?.duration}
    />
  );
};

export default ChallengeSession;
