import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';

import { CarouselPage, useCarousel } from 'src/hooks';
import { CarouselItem } from 'src/models/Carousel';

import { CarouselItem as HeroCarouselItem, ItemType } from './HeroCarousel/types';
import HeroCarousel from './HeroCarousel';

const pageTypes = {
  homepage: CarouselPage.Homepage,
  sessions: CarouselPage.Sessions,
  challenges: CarouselPage.Challenges,
  meditations: CarouselPage.Meditations,
  pilates: CarouselPage.Pilates,
  teachers: CarouselPage.Teachers,
};

export const Carousel: React.FC<{
  page: keyof typeof pageTypes;
  previewItem?: CarouselItem;
}> = ({
  page,
  previewItem,
}) => {
  const pageSpecificlItem = useCarousel(pageTypes[page]);
  const item = previewItem || pageSpecificlItem;

  const [visible, setVisible] = useState(false);
  useEffect(() => setVisible(!!item), [item]);

  if (!item) {
    return null;
  }

  const carouselItem: HeroCarouselItem = {
    ...item,
    ctaUrl: item.ctaLink,
    imageUrl: item.image ?? '',
    focalPoint: [0.5, 0.5],
    type: ItemType.ImageLeft,
  };


  return (
    <Collapse in={visible}>
      <HeroCarousel items={[carouselItem]} />
    </Collapse>
  )
};
