import React, { useRef } from 'react';
import {
  useScroll,
  useDragToScroll,
  useVisibleElements,
} from 'react-snaplist-carousel';

import {
  Wrapper,
  StyledSnapList,
  StyledSnapItem,
  Arrow,
} from './styles';

export const ScrollbarCarousel: React.FC<{
  gutter?: number;
  arrowSize?: 'small' | 'large';
  className?: string;
}> = ({
  gutter = 0,
  children,
  arrowSize = 'large',
  className,
}) => {
  const itemCount = React.Children.count(children);

  const snapList = useRef(null);
  const goToSnapItem = useScroll({ ref: snapList });
  useDragToScroll({ ref: snapList });

  const position = useVisibleElements(
    { ref: snapList, debounce: 10 },
    (items) => ({
      isStart: items.includes(0),
      isEnd: items.includes(itemCount - 1),
      prevItem: Math.min(...items) - 1,
      nextItem: Math.max(...items) + 1,
    }),
  );
  const goBack = () => goToSnapItem(position.prevItem);
  const goForward = () => goToSnapItem(position.nextItem);

  return (
    <Wrapper className={className}>
      <Arrow
        direction="prev"
        onClick={goBack}
        disabled={position.isStart}
        size={arrowSize}
      />
      <StyledSnapList
        direction="horizontal"
        ref={snapList}
        hideScrollbar={false}
      >
        {
          React.Children.map(children, (item, index) => (
            <StyledSnapItem
              key={index}
              snapAlign="center"
              margin={{
                left: `${index === 0 ? 0 : gutter / 2}px`,
                right: `${index === itemCount - 1 ? 0 : gutter / 2}px`,
              }}
            >
              {item}
            </StyledSnapItem>
          ))
        }
      </StyledSnapList>
      <Arrow
        direction="next"
        onClick={goForward}
        disabled={position.isEnd}
        size={arrowSize}
      />
    </Wrapper>
  );
};
