import styled from 'styled-components';
import { Dialog as MuiDialog } from '@mui/material';

export const Dialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(8px);
      
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(8px);
    }
  }
  .MuiDialog-paper {
    box-shadow: none;
    ${p => p.theme.shadows[4]};
    border-radius: 8px;
    border: ${p => p.theme.border};
  }
`;

export const Wrapper = styled.div``;

export const Title = styled.h2`
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3px;
  padding: 16px;
  border-bottom: ${p => p.theme.border};
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 16px;
  border-bottom: ${p => p.theme.border};

  & > p:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  & > * {
    margin: 0 0 0 16px;
  }
`;
