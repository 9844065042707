import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../icons/times-circle.svg';

export const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  user-select: none;
`;

export const ChipWrapper = styled.li`
  display: block;
  background: ${p => p.theme.palette.primary.main};
  color: #ffffff;
  height: 40px;
  padding: 0 8px 0 16px;
  margin: 8px 8px 0 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const ChipLabel = styled.span`
  white-space: pre;
`;

export const ChipDelete = styled.button.attrs({
  children: <DeleteIcon />,
})`
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  fill: #ffffff;
  &:hover {
    fill: ${p => p.theme.palette.secondary.main};
  }
  & svg {
    width: 20px;
    height: 20px;
    transition: fill .2s;
  }
`;

export const ClearButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 8px;
`;
