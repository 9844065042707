import { useEffect, useState } from 'react';

import { Button, InfoBox, Modal } from 'src/components';
import { useUserActions, useUserState } from 'src/contexts/UserContext';

export const InactiveSubscriptionInfo: React.FC = () => {
  const {
    showInactiveSubscriptionInfo,
    manualPaymentStatus,
  } = useUserState();

  const {
    toggleInactiveSubscriptionInfo,
    triggerManualPayment,
  } = useUserActions();

  // If the user's current plan is marked as hidden and is no longer, we don't want them
  // to restore the same plan, but to select a new one that is not hidden.
  const canRestoreCurrentSubscriptionProduct = false; // user.subscription?.subscriptionProduct?.hidden === false;

  const [requestPaymentConfirmation, setRequestPaymentConfirmation] = useState(false);
  useEffect(() => {
    if (!showInactiveSubscriptionInfo) {
      setRequestPaymentConfirmation(false);
    }
  }, [showInactiveSubscriptionInfo]);
  
  const dismiss = () => toggleInactiveSubscriptionInfo(false);

  return (
    <>
      <Modal open={showInactiveSubscriptionInfo}>
        <Modal.Title>Twoja subskrypcja wygasła</Modal.Title>
        <Modal.Content>
          Twoje konto jest nieaktywne. Wybierz odpowiedni plan i dokonaj płatności.
        </Modal.Content>
        <Modal.Actions>
          {canRestoreCurrentSubscriptionProduct && (
            <>
              <Button
                label="Zmień dane płatności"
                onClick={dismiss}
                variant="outlined"
                color="default"
              />
              <Button
                label="Użyj aktywnej karty"
                pending={manualPaymentStatus === 'pending'}
                onClick={() => setRequestPaymentConfirmation(true)}
              />
            </>
          )}
          {!canRestoreCurrentSubscriptionProduct && (
            <Button
              label="Wybierz plan"
              onClick={dismiss}
            />
          )}
        </Modal.Actions>
      </Modal>

      <Modal open={requestPaymentConfirmation}>
        <Modal.Title>Potwierdzenie płatności</Modal.Title>
        <Modal.Content>
          <p>Czy na pewno chcesz ponownie aktywować swoją subskrypcję? Potwierdzenie spowoduje automatyczne odnowienie subskrypcji i pobranie środków z Twojej karty.</p>
          {manualPaymentStatus === 'error' && (
            <InfoBox
              mode="error"
              message="Nie udało się dokonać płatności."
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Anuluj"
            onClick={() => setRequestPaymentConfirmation(false)}
            variant="outlined"
            color="default"
            disabled={manualPaymentStatus === 'pending'}
          />
          <Button
            label="Tak, aktywuj ponownie"
            pending={manualPaymentStatus === 'pending'}
            onClick={() => {
              triggerManualPayment();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
};
