import { BackLink, FullPageLoader } from 'src/components';

import useToken from './useToken';
import RequestEmail from './RequestEmail';
import SetNewPassword from './SetNewPassword';

import { BoxContainer } from './styles';

const ResetPassword: React.FC = () => {
  const {
    token,
    tokenValidationPending,
    isValid,
  } = useToken();

  if (tokenValidationPending) {
    return <FullPageLoader />;
  }

  if (!token || (!tokenValidationPending && isValid === false)) {
    return (
      <>
        <BackLink label="Logowanie" to="/login" />
        <BoxContainer>
          <RequestEmail tokenWasInvalid={isValid === false} />
        </BoxContainer>
      </>
    );
  }

  return (
    <>
      <BackLink label="Logowanie" to="/login" />
      <BoxContainer>
        <SetNewPassword token={token} />
      </BoxContainer>
    </>
  );
};

export default ResetPassword;
