import { Button, Modal } from 'src/components';
import { useUserActions, useUserState } from 'src/contexts/UserContext';
import { composeStripeCardErrorMessage } from 'src/utils';

export const CardErrorInfo: React.FC = () => {
  const {
    cardError,
    cardErrorCode,
    cardErrorDeclineCode,
    cardErrorDismissed,
  } = useUserState();
  const { dismissCardError } = useUserActions();

  const detailedCardErrorMessage = cardErrorCode && composeStripeCardErrorMessage(cardErrorCode, cardErrorDeclineCode);

  return (
    <Modal open={!!cardError && !cardErrorDismissed}>
      <Modal.Title>Błąd</Modal.Title>
      <Modal.Content>
        <p>Wystąpił błąd przetwarzania płatności</p>
        {detailedCardErrorMessage && <p>{detailedCardErrorMessage}</p>}
      </Modal.Content>
      <Modal.Actions>
        <Button
          label="Wróć"
          onClick={dismissCardError}
        />
      </Modal.Actions>
    </Modal>
  );
};
