import { useQuery, useQueryClient } from 'react-query';

import { Session } from 'src/models/Session';
import { Challenge } from 'src/models/Challenge';
import { arrayOfNumbers } from 'src/utils/array';
import { Header, SessionCard } from 'src/components';

import {
  List,
  Item,
  EmptyListInfo,
} from './styles';
import { BackNavigationInfo } from 'src/models/BackNavigationInfo';

export const ActivityList: React.FC<{
  title: string;
  link: string;
  maxItemCount: number;
  fetchItems: () => Promise<Array<Session | Challenge>>;
  className?: string;
  backLink: BackNavigationInfo;
}> = ({
  title,
  link,
  fetchItems,
  maxItemCount,
  className,
  backLink,
}) => {
  const queryClient = useQueryClient();
  const {
    data,
    isFetching,
  } = useQuery(
    ['shortActivityList', title],
    fetchItems,
    { refetchOnWindowFocus: false },
  );

  const itemCount = isFetching
    ? maxItemCount
    : data?.length ?? maxItemCount;
  const noData = !isFetching && !data?.length;

  return (
    <div className={className}>
      <Header
        title={title}
        as="h2"
        linkLabel="Pokaż wszystko"
        link={link}
      />
      {noData ? (
        <EmptyListInfo
          mode="info"
          message="Nic tu jeszcze nie ma."
        />
      ) : (
        <List>
          {arrayOfNumbers(itemCount).map(index => (
            <Item key={index}>
              <SessionCard
                mode="horizontal"
                order={index}
                session={data?.[index]}
                backNavigationInfo={backLink}
                onLikeToggleSuccess={() => queryClient.invalidateQueries(['shortActivityList'])}
              />
            </Item>
          ))
          }
        </List>
      )}
      
    </div>
  );
};
