import { FilterTrait } from 'src/models/SessionFilters';

export type CoachId = Branded<string, 'coachId'>;

export interface Coach {
  id: CoachId;
  name: string;
  thumbnail: string | null;
  profileUrl: string | null;
  hidden?: boolean;
  trait: FilterTrait.Coach;
}

export interface CoachDto {
  id: CoachId;
  name: string;
  thumbnail: string;
  profileUrl: string;
  hidden?: boolean;
}

export const transformCoachDto = (dto: CoachDto): Coach => ({
  id: dto.id,
  name: dto.name,
  thumbnail: dto.thumbnail,
  profileUrl: dto.profileUrl,
  hidden: dto.hidden,
  trait: FilterTrait.Coach,
});
