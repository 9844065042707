import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  maxContentWidth: 1280,
  spacing: factor => factor * 8,
  border: '1px solid rgb(0, 0, 0, 0.12)',
  palette: {
    common: {
      white: '#ffffff',
      black: '#000000',
    },
    primary: {
      main: '#84D5DC',
      light: '#B7FFFF',
      dark: '#51A3AA',
    },
    secondary: {
      main: '#F82551',
      light: '#FF667D',
      dark: '#BE0029',
    },
    error: '#B00020',
    text: {
      low: 'rgba(0, 0, 0, 0.4)',
      medium: 'rgba(0, 0, 0, 0.75)',
      high: 'rgba(0, 0, 0, 0.86)',
    },
    gray: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },
  shadows: {
    1: 'filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));',
    2: 'filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));',
    3: 'filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));',
    4: 'filter: drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));',
  },
};

export default theme;
