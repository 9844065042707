import Cookies from 'js-cookie';
export const AUTH_TOKEN_KEY = 'PY_ACCESS_TOKEN';

export const CookieTokenService = {
  get(): string | null {
    return Cookies.get(AUTH_TOKEN_KEY) ?? null;
  },

  set(token: string) {
    Cookies.remove(AUTH_TOKEN_KEY);
    Cookies.set(AUTH_TOKEN_KEY, token);
  },

  clear() {
    Cookies.remove(AUTH_TOKEN_KEY);
  },
}

const AuthTokenService = {
  get(): string | null {
    return localStorage.getItem(AUTH_TOKEN_KEY) ?? null;
  },

  set(token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  },

  clear() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  },
};

export default AuthTokenService;
