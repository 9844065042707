import {
  Box,
  Brand,
  Title,
} from './styles';

export const AuthBox: React.FC<{
  title: string;
  className?: string;
}> = ({
  title,
  children,
  className,
}) => (
  <Box className={className}>
    <Brand />
    <Title>{title}</Title>
    {children}
  </Box>
);
