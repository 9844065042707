export enum FilterTrait {
  Coach = 'coach',
  Equipment = 'equipment',
  Style = 'style',
  Tag = 'tag',
  Type = 'type',
  Difficulty = 'difficulty',
  Duration = 'duration',
  Phrase = 'phrase',
};
