import { useMemo } from 'react';

type ValidationRule = {
  label: string;
  test: (candidate: string) => boolean;
};

const rules: ValidationRule[] = [
  {
    label: 'Min. 8 i max. 20 znaki',
    test: (candidate) => candidate.length >= 8 && candidate.length <= 20,
  },
  {
    label: 'Min. 1 wielka i mała litera',
    test: (candidate) => !(!(/\p{Ll}/u.test(candidate)) || !(/\p{Lu}/u.test(candidate))),
  },
  {
    label: 'Min. 1 cyfra',
    test: (candidate) => /[0-9]/.test(candidate),
  },
  {
    label: 'Min. 1 znak specjalny',
    test: (candidate) => /[^\w\s]/.test(candidate),
  },
  {
    label: 'Brak spacji',
    test: (candidate) => /^\S*$/.test(candidate),
  },
];

const usePasswordValidation = (value: string) => useMemo(
  () => ({
    isValid: rules.every(rule => rule.test(value)),
    rules: rules.map(rule => ({
      label: rule.label,
      isOk: rule.test(value),
    })),
  }),
  [value],
);

export default usePasswordValidation;
