import styled from 'styled-components';
import { Skeleton } from '@mui/material';

export {
  Layout,
} from './main';

export const Card = styled.div``;

export const Image = styled(Skeleton).attrs({
  variant: 'rectangular',
  animation: 'wave',
})`
  grid-area: image;
  min-height: 95.625px;
`;

export const Overline = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  grid-area: overline;
  font-size: 11px;
  line-height: 14px;
  width: 40%;
`;

export const Title = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  grid-area: title;
  font-size: 16px;
  line-height: 23px;
  margin: 6px 0 6px 0 !important;
  width: 90%;
`;

export const Underline = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  grid-area: underline;
  align-self: flex-start;
  font-size: 13px;
  line-height: 148%;
  width: 60%;
`;

export const Difficulty = styled(Skeleton).attrs({
  variant: 'rectangular',
  animation: 'wave',
  width: 76,
  height: 14,
})`
  grid-area: difficulty;
  align-self: center;
  margin-bottom: 3px;
`;


export const Duration = styled(Skeleton).attrs({
  variant: 'rectangular',
  animation: 'wave',
  width: 50,
  height: 14,
})`
  grid-area: duration;
  align-self: center;
  margin-bottom: 3px;
`;
