import { useQuery } from 'react-query';
import { TagsService } from 'src/services';

export const useTags = () => {
  const coaches = useQuery(
    'tags',
    TagsService.getAll,
    {
      staleTime: Infinity
    }
  );
  return coaches.data ?? null;
};
