import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserState } from 'src/contexts/UserContext';

import { useSignUpContext } from '../context';

export const useRedirectIfLoggedIn = () => {
  const history = useHistory();
  const { authStatus } = useUserState();
  const [{ status: signUpStatus }] = useSignUpContext();

  useEffect(() => {
    // This means an existing and authenticated user tries to view the registration form;
    // redirect to user profile instead:
    if (authStatus === 'loggedIn' && signUpStatus !== 'signUpSuccess') {
      history.replace('/me/subscription');
    }
  }, [authStatus, signUpStatus, history]);
}
