import { useEffect, useState } from 'react';

import {
  CardNumber,
  CardExpiry,
  CardCvc,
} from 'src/features/StripeCardForm';

import { useSignUpContext } from '../context';
import Form from '../Form';
import { SignUpError } from '../context/types';
import Branding from '../Branding';
import { WidgetLayout } from 'src/components';

export const PaymentMethod: React.FC = () => {
  const [{
    status,
    error,
  }, dispatch] = useSignUpContext();

  const signUpPending = status === 'signUpPending';
  const showErrorIfEmpty = error === SignUpError.InvalidPaymentMethod;

  const [cardNumberReady, setCardNumberReady] = useState(false);
  const [cardExpiryReady, setCardExpiryReady] = useState(false);
  const [cardCvcReady, setCardCvcReady] = useState(false);

  const [cardDetailsTouched, setCardDetailsTouched] = useState(false);

  useEffect(
    () => dispatch({
      type: 'togglePaymentMethodProvided',
      provided: cardNumberReady && cardExpiryReady && cardCvcReady,
    }),
    [cardNumberReady, cardExpiryReady, cardCvcReady, dispatch]
  );

  useEffect(
    () => dispatch({
      type: 'togglePaymentMethodTouched',
      touched: cardDetailsTouched,
    }),
    [cardDetailsTouched, dispatch]
  );

  return (
    <WidgetLayout.ContentWithMargin>
      <Form.Layout slideFrom="right">

        <Form.Heading>Dane płatności</Form.Heading>

        <Form.FullWidth>
          <CardNumber
            errorIfEmpty={showErrorIfEmpty}
            onReadyStateChange={setCardNumberReady}
            onTouchedStateChange={setCardDetailsTouched}
            disabled={signUpPending}
          />
        </Form.FullWidth>

        <Form.LeftColumn>
          <CardExpiry
            errorIfEmpty={showErrorIfEmpty}
            onReadyStateChange={setCardExpiryReady}
            onTouchedStateChange={setCardDetailsTouched}
            disabled={signUpPending}
          />
        </Form.LeftColumn>

        <Form.RightColumn>
          <CardCvc
            errorIfEmpty={showErrorIfEmpty}
            onReadyStateChange={setCardCvcReady}
            onTouchedStateChange={setCardDetailsTouched}
            disabled={signUpPending}
          />
        </Form.RightColumn>

        <Form.FullWidth>
          <Branding />
        </Form.FullWidth>

      </Form.Layout>
    </WidgetLayout.ContentWithMargin>

  );
};
