import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { SnapList, SnapItem } from 'react-snaplist-carousel';

import { ReactComponent as ArrowIcon } from './arrow.svg';

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledSnapList = styled(SnapList)`
  transform: rotateX(180deg); // move scrollbar to top
  ::-webkit-scrollbar {
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background: ${p => transparentize(0.75, p.theme.palette.primary.main)};
  }
  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.palette.primary.dark};
  }
`;

export const StyledSnapItem = styled(SnapItem)`
  // With rotateX(180deg) the result was extremely blurry in Safari.
  transform: scale(1, -1);
  user-select: none;
`;

const arrowSize = {
  small: 32,
  large: 64,
};

export const Arrow = styled.button.attrs(p => ({
  children: <ArrowIcon />,
}))<{direction: 'prev' | 'next'; size: 'small' | 'large';}>`
  @media only screen and (max-width: 800px) {
    display: none;
  }

  position: absolute;
  z-index: 10;
  width: ${p => arrowSize[p.size]}px;
  height: ${p => arrowSize[p.size]}px;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  transition: opacity 0.2s;

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }

  padding: 0;
  top: 50%;
  ${p => p.direction === 'prev' && css`
    left: 0;
    transform: rotate(180deg);
  `}
  ${p => p.direction === 'next' && css`
    right: 0;
    transform: translate(0, -50%);
  `}

  border: ${p => p.theme.border};
  background: ${p => p.theme.palette.common.white};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
              0px 2px 6px 0px rgba(0, 0, 0, 0.04),
              0px 10px 20px 0px rgba(0, 0, 0, 0.04);
  ${p => p.direction === 'prev' && css`
    left: 0;
    transform: rotate(180deg) translate(50%, 50%);
  `}
  ${p => p.direction === 'next' && css`
    right: 0;
    transform: translate(50%, -50%);
  `}


  & svg {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: fill 0.2s;
    fill: ${p => p.theme.palette.primary.dark};
  }
  &:hover svg {
    fill: ${p => p.theme.palette.primary.main};
  }
`;
