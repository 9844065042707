import styled from 'styled-components';
import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel } from '@mui/material';


const FormControlLabel = styled(MuiFormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 13px;
    line-height: 19px;
    color: ${p => p.theme.palette.text.low};
    transition: color .2s;
    margin-top: 2px;
    strong {
      color: ${p => p.theme.palette.text.medium};
    }
  }
  & .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;
    color: ${p => p.theme.palette.gray[400]};
    border-width: 1px;
    &.Mui-checked {
      color: ${p => p.theme.palette.primary.main};
      & + .MuiFormControlLabel-label {
        color: ${p => p.theme.palette.text.high};
      }
    }
  }
  && {
    align-items: flex-start;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const Checkbox: React.FC<{
  name?: string;
  disabled?: boolean;
  label: React.ComponentProps<typeof MuiFormControlLabel>['label'];
  checked: React.ComponentProps<typeof MuiFormControlLabel>['checked'];
  onChange: React.ComponentProps<typeof MuiFormControlLabel>['onChange'];
}> = ({
  name,
  disabled,
  label,
  checked,
  onChange,
}) => (
  <FormControlLabel
    control={<MuiCheckbox color="primary" />}
    name={name}
    label={label}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
  />
);

export default Checkbox;
