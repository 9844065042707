import { SubscriptionIsCanceled, InvoiceNonPayable } from 'src/errors';
import { Coupon, CouponCode, CouponDto } from 'src/models/Coupon';
import { SubscriptionProductId } from 'src/models/SubscriptionProduct';
import { transformUserDto, UserDto } from 'src/models/User';
import { HttpService } from '.';

const getMe = () => HttpService
  .get<UserDto>('/public/users/me?join=subscription&join=subscription.subscriptionProduct&join=subscription.nextSubscriptionProduct&join=coupon&join=coupon.appliesTo')
  .then(transformUserDto);

type ChangeCardResult = {
  requires3DS: boolean;
  clientSecret: string;
  cardSuffix: string | null;
};

const changeCard = (paymentMethodId: string) => HttpService
  .patch<ChangeCardResult>('/public/users/me/card', { paymentMethodId });

const cancelSubscription = () => HttpService
  .delete('/public/users/me/subscription/cancel')
  .then(getMe);

const undoCancelSubscription = () => HttpService
  .patch('/public/users/me/subscription/reactivate')
  .then(getMe);

const changeSubscription = (id: SubscriptionProductId) => HttpService
  .patch(`/public/users/me/subscription/product/${id}`)
  .then(getMe);

const triggerManualPayment = (subscriptionProductId: SubscriptionProductId) => HttpService
  .patch('/public/payments/latest-invoice')
  .catch(error => {
    if (error instanceof SubscriptionIsCanceled || error instanceof InvoiceNonPayable) {
      return HttpService.post('/public/payments/product', { subscriptionProductId });
    }
    throw error;
  })
  .then(getMe);

const paymentIntent = (subscriptionProductId: SubscriptionProductId) => HttpService
  .post<{ id: string, clientSecret: string }>('/public/payments/payment-intent', { subscriptionProductId });

const confirmPaymentIntent = (paymentIntentId: string) => HttpService
  .post<boolean>('/public/payments/payment-intent-confirm', { paymentIntentId });

const lookupCoupon = (couponCode: string) => HttpService
  .get<CouponDto>(`/public/coupon/${couponCode}`)
  .then(Coupon.fromDto);

const couponEligibility = () => HttpService
  .get<boolean>(`/public/users/me/redeem`);

const redeemCoupon = (couponCode: CouponCode) => HttpService
  .put(`/public/users/me/redeem/${couponCode}`);

const UsersService = {
  getMe,
  changeCard,
  cancelSubscription,
  undoCancelSubscription,
  changeSubscription,
  triggerManualPayment,
  paymentIntent,
  confirmPaymentIntent,
  lookupCoupon,
  couponEligibility,
  redeemCoupon,
};

export default UsersService;
