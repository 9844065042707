import React from 'react';
import { useCmsContent } from 'src/hooks';
import styled from 'styled-components';

import { Carousel, Header } from 'src/components';
import { HomepageSection, StandardHomepageSectionType } from 'src/models/HomepageSection';
import { isNativePlatform } from '../../config';

import SessionsCarousel from './SessionsCarousel';
import CoachesCarousel from './CoachesCarousel';
import ChallengesCarousel from './ChallengesCarousel';
import ActivityCarousel from './ActivityCarousel';
import SessionSearchbox from 'src/features/SessionSearchbox';

const Container = styled.div``;

const Spacer = styled.div`
  height: 48px;
`;

const CarouselSpacer = styled.div`
  height: 24px;
  @media only screen and (max-width: 799px) {
    display: none;
  }
`;

const Home: React.FC = () => {
  const sections = useCmsContent().homepageSections;
  return (
    <Container>
      {
        !isNativePlatform &&
          <Carousel page="homepage" />
      }
      <CarouselSpacer/>
      <Header
        title="Znajdź sesję"
        underline="Wpisz dowolną frazę i użyj naszych filtrów by znaleźć sesję idealnie dopasowaną do Twojej praktyki."
        as="h2"
      />
      <Spacer/>
      <SessionSearchbox pathBase="sessions" />
      <Spacer />
      <ActivityCarousel />
      {
        sections
          .map(renderSectionItem)
          .map(prependComponent(<Spacer/>))
      }
    </Container>
  )
};

const renderSectionItem = (section: HomepageSection, index: number) => {
  if (section.isCustom) {
    return (
      <SessionsCarousel
        title={section.title}
        underline={section.underline}
        filters={section.filters}
        viewAllLabel="Zobacz kolekcję"
      />
    );
  }
  if (section.type === StandardHomepageSectionType.LatestPractices) {
    return (
      <SessionsCarousel
        title="Nowości"
        underline="Sesje niedawno dodane do portalu"
        viewAllLabel="Wszystkie sesje"
        passMetadataToSessionsLibrary={false}
        key={index}
      />
    );
  }
  if (section.type === StandardHomepageSectionType.Teachers) {
    return (
      <CoachesCarousel key={`${index}-coaches`} />
    );
  }
  if (section.type === StandardHomepageSectionType.Challenges) {
    return (
      <ChallengesCarousel key={`${index}-challenges`} />
    );
  }
  return null;
};

const prependComponent = (elementToPrepend: JSX.Element) =>
  (existingElement: JSX.Element | null, index: number) =>
    existingElement === null ? null : (
      <React.Fragment key={index}>
        {elementToPrepend}
        {existingElement}
      </React.Fragment>
    );

export default Home;
