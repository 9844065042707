import {EquipmentBadgeProps} from './Equipment.types';
import classnames from 'classnames';
import styles from './EquipmentBadge.module.scss';
import EquipmentIcon from './EquipmentIcon.component';

const EquipmentBadge: React.FC<EquipmentBadgeProps> = ({
  className,
  disabled = false,
  icon,
  onClick,
  text,
}) =>
  <button
    className={classnames(styles.Container, className)}
    disabled={disabled}
    onClick={onClick}
  >
    {icon && <EquipmentIcon icon={icon}/>}
    <span>{text}</span>
  </button>;

export default EquipmentBadge;
