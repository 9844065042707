import styled, { css } from 'styled-components';
import { Tooltip } from '@mui/material';

import { prettifyPrice } from 'src/utils';

import InvoiceButton from './InvoiceButton';
import { screenSize } from '../../styles';

const text = css`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.high};
  ${screenSize.small} {
    padding-bottom: 0;
  }
`;

const Date = styled.div`
  ${text}
  padding: 16px;
  border-top: ${p => p.theme.border};
  ${screenSize.small} {
    padding-bottom: 0;
  }
`;

const ProductName = styled.div`
  ${text}
  padding: 16px 0;
  border-top: ${p => p.theme.border};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${screenSize.small} {
    grid-column: 2 / span 2;
    padding-bottom: 0;
  }
`;

const Price = styled.div`
  ${text}
  padding: 16px;
  border-top: ${p => p.theme.border};
  white-space: pre;
  strong {
    color: ${p => p.theme.palette.primary.dark};
  }
`;

const Invoice = styled.div`
  ${text}
  border-top: ${p => p.theme.border};
  ${screenSize.small} {
    border: none;
    grid-column: 1 / span 4;
  }
`;

const PaymentItem: React.FC<{
  date: Date,
  productName: string;
  amount: number;
  subtotal: number;
  couponCode: string | null;
  invoiceUrl: string | null;
}> = ({
  date,
  productName,
  amount,
  subtotal,
  couponCode,
  invoiceUrl,
}) => (
  <>
    <Date>
      {date.toLocaleDateString()}
    </Date>
    <ProductName>
      {productName}
    </ProductName>
    {subtotal > amount ? (
        <Price>
          <Tooltip title={couponCode ? `Z kodem „${couponCode}”` : ''}>
            <span>
              <del>{prettifyPrice(subtotal, 'zł')}</del> <strong>{prettifyPrice(amount, 'zł')}</strong>
            </span>
          </Tooltip>
        </Price>
    ) : (
      <Price>{prettifyPrice(amount, 'zł')}</Price>
    )}
    <Invoice>
      {invoiceUrl && <InvoiceButton url={invoiceUrl} />}
    </Invoice>
  </>
);

export default PaymentItem;
