import { SCondition } from '@nestjsx/crud-request';

import { ChallengeFilters } from 'src/models/Challenge';
import { cleanString } from 'src/utils';

// 'steps.challengeSession.style.id': string[];
// 'steps.challengeSession.type.id': string[];
// const minWordLength = 5;
// const searchForIndividualPhraseWords = false;

export const composeQuerySearch = (
  filters: ChallengeFilters,
): SCondition => {
  const conditions: SCondition[] = [{ 'published': { '$eq': true } }];

  if (filters.coach) {
    conditions.push({
      'coach.id': { '$in': filters.coach }
    });
  }

  if (filters.difficulty) {
    conditions.push({
      'difficulty': { '$in': filters.difficulty }
    });
  }

  if (filters.duration) {
    const durationRanges = filters.duration.map(v => {
      const [min, max] = v.split(',') as [string, string];
      return { 'duration': max === '' ? { '$gte': min } : { '$between': [min, max] } };
    });

    conditions.push({ '$or': durationRanges });
  }

  if (filters.tag) {
    conditions.push({
      'tag.id': { '$in': filters.tag }
    });
  }

  if (filters.phrase) {
    conditions.push({
      'normalizedTitle': { '$contL': cleanString(filters.phrase[0]!) }
    });
  }

  return { '$and': conditions };
}
