import { SessionCardlet } from 'src/components';
import { SessionId } from 'src/models/Session';
import { TypeId } from 'src/models/Type';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { arrayOfNumbers } from 'src/utils/array';

import { useSimilarSessions } from './hooks';

const Header = styled.h2`
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.3px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 24px 0 0 0;
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const SimilarSessions: React.FC<{
  sessionId: SessionId,
  typeId: TypeId[],
  limit: number,
}> = ({
  sessionId,
  typeId,
  limit,
}) => {
  const queryClient = useQueryClient();
  const { data, isFetching } = useSimilarSessions({
    limit,
    exclude: sessionId,
    type: typeId,
  });
  const sessions = data ?? [];

  const actualItemCount = isFetching ? null : sessions.length;
  const displayItemCount = actualItemCount ?? limit;

  return (
    <>
    <Header>Podobne sesje</Header>
      <List>
        {arrayOfNumbers(displayItemCount).map(index => (
          <Item key={index}>
            <SessionCardlet
              session={sessions[index]}
              order={index}
              onLikeToggleSuccess={() => queryClient.invalidateQueries(['similarSessions'])}
            />
          </Item>
        ))}
      </List>
    </>
  );
}

export default SimilarSessions;
