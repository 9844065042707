import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding: 36px;
  background-color: #F5F5F5;
`;

const Header = styled.span`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #FF667D;
`;

const Label = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.86);
  margin-bottom: 8px;
`;

const Instruction = styled.span`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.86);
`;

interface ErrorBoundaryProps {
  label?: string;
  instruction?: string;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
  > {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() { }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Header>:(</Header>
          <Label>{this.props.label || 'Coś poszło nie tak'}</Label>
          {this.props.instruction && (
            <Instruction>{this.props.instruction}</Instruction>
          )}
        </Container>
      );
    }
    return this.props.children;
  }
}