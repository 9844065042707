import { isNativePlatform } from 'src/config';
import { useUserState } from 'src/contexts/UserContext';

import {
  Container,
  UserInfo,
  UserName,
  UserEmail,
  Link,
} from './styles';

export const Menu: React.FC = () => {
  const { user } = useUserState();
  return (
    <Container>
      <UserInfo>
        <UserName>{user.name}</UserName>
        <UserEmail>{user.email}</UserEmail>
      </UserInfo>
      <Link to="/me/summary">Twoja praktyka</Link>
      {!isNativePlatform && (
        <>
          <Link to="/me/subscription">Zarządzaj subskrypcją</Link>
          <Link to="/me/payments">Historia płatności</Link>
        </>
      )}
    </Container>
  );
}
