const removeDashes = (str: string) => str.replace(/(-|–|—)/g, ' ');

const cleanString = (str: string, { noDashes = false } = {}) =>
  (noDashes ? removeDashes(str) : str)
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Note: this doesn't catch all accented letters, e.g. "ł"
    .toLocaleLowerCase()
    .replace(/ł/g, 'l')
    .replace(/\s+/g, ' '); // multiple whitespaces -> single space

export default cleanString;
