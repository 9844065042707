import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useUserState } from '../../../../contexts/UserContext';
import { ProductService } from '../../../../services';

export const useSubscriptionProducts = () => {
  const result = useQuery(
    'subcriptionProducts',
    ProductService.getAll,
    { refetchOnWindowFocus: false }
  );
  const { user } = useUserState();
  return useMemo(() => {
    const productEndBehaviour = user.subscription?.subscriptionProduct?.endBehaviour;
    if (result.data == null || productEndBehaviour === 'one_time') {
      return result.data;
    }
    return result.data.filter(p => p.endBehaviour !== 'one_time');
  }, [result, user]);
};
