import { InfoBox } from 'src/components';
import { useUserState } from 'src/contexts/UserContext';

export const AdminModeNotification: React.FC<{
  className?: string;
}> = ({
  className,
}) => {
  const { isRegularUser } = useUserState();

  if (!isRegularUser) {
    return (
      <InfoBox
        mode="info"
        message="Jako administrator nie masz aktywnej subskrypcji. W związku z tym funkcje zarządzania subskrypcją mają charakter poglądowy i nie spowodują żadnych zmian."
        delay={1000}
        className={className}
      />
    );
  }

  return null;
};
