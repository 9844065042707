import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Portal } from 'src/components';
import { isNativePlatform } from '../../../config';

import hamburgerIcon from './images/hamburger.svg';
import hamburgerIconMobile from './images/hamburger_mobile.svg';
import closeIcon from './images/close.svg';
import closeIconMobile from './images/close_mobile.svg';

import UserMenu from '../UserMenu';
import BrandLogo from '../BrandLogo';
import { ContainedWidth } from '../Layout';
import { useMenuItems } from '../hooks';
import { isExternalLink } from './utils';

const Spacer = styled.div`
  height: 64px;
`;

const Wrapper = styled.div`
  border-top: 4px solid ${p => p.theme.palette.primary.main};
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 64px;
  z-index: 1001;
  background: ${p => p.theme.palette.common.white};
  ${p => p.theme.shadows[1]}
`;

const Content = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled(BrandLogo)`
  width: 32px;
  height: 32px;
  margin: ${(60-32)/2}px;
  margin-left: 0;
`;

const Hamburger = styled.button<{open: boolean, mobile?: boolean}>`
  width: 50px;
  height: 60px;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  -webkit-tap-highlight-color: transparent;
  background-image: url(${
    ({open, mobile}) =>
            open ?
                    mobile ? closeIconMobile : closeIcon :
                    mobile ? hamburgerIconMobile : hamburgerIcon
    });
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateX(8px);
`;

const Slideable = styled.div<{open: boolean}>`
  position: fixed;
  z-index: 1000;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${p => p.theme.palette.common.white};
  transition: transform 300ms;
  transform: translateY(${p => p.open ? 0 : -100}%);
  overflow: scroll;
`;

const UserMenuWrapper = styled.div`
  display: block;
  margin: 24px 16px 0 16px;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Link = styled(NavLink).attrs({ className: 'nav-item' })`
  display: block;
  line-height: 40px;
  font-size: 16px;
  margin: 16px 16px 0 16px;
  padding: 0 8px;
  border-radius: 4px;
  && {
    color: ${p => p.theme.palette.common.black};
    text-decoration: none;
  }
  &.active {
    background: #f3fbfc;
  }
`;


export const MobileNavbar: React.FC<{
  adminMode?: boolean;
}> = ({
  adminMode,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const close = () => setOpen(false);
  return (
    <>
      <Spacer />
      <Wrapper>
        <ContainedWidth>
          <Content>
            <Logo onClick={() => setOpen(false)} />
            <Hamburger open={open} onClick={toggle} mobile={isNativePlatform} />
          </Content>
        </ContainedWidth>
      </Wrapper>
      <Portal>
        <Slideable open={open}>
          <Menu>
            <UserMenuWrapper>
              <UserMenu onNavigate={close} />
            </UserMenuWrapper>
            {useMenuItems().map(({ path, label }) => (
              <Link
                {...(isExternalLink(path)
                  ? ({
                    as: 'a',
                    href: path,
                    target: '_blank'
                  })
                  : ({
                    to: path,
                    exact: true
                  })
                )}
                key={label}
                onClick={close}
              >
                {label}
              </Link>
            ))}
            {/* To avoid CSS conflicts, it's safer to use a regular <a> instead of router link */}
            {adminMode && <Link as="a" href="/admin">Admin</Link>}
          </Menu>
        </Slideable>
      </Portal>
    </>
  );
};
