import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { ErrorReportingService, HttpService, TagsService } from 'src/services';
import { SessionDto, transformSessionDto, Session, SessionId } from 'src/models/Session';
import { SessionQuery } from 'src/models/SessionQuery';
import { GetManyResponse } from 'src/types';

import { composeRequestQueryBuilderSearch } from './SessionsService.helpers';
import { Tag } from 'src/models/Tag';
import { TypeId } from 'src/models/Type';
import { Comment, CommentDto, CommentId } from 'src/models/Comment';

/**
 * Ensures that all the data we need is joined to the base entity.
 */
const createBaseQueryBuilder = () =>
  new RequestQueryBuilder()
    .setJoin({ field: 'coach' })
    .setJoin({ field: 'equipment' })
    .setJoin({ field: 'style' })
    .setJoin({ field: 'tag' })
    .setJoin({ field: 'type' })
    .setJoin({ field: 'extras' })
    .setJoin({ field: 'video' })
    .setJoin({ field: 'video.thumbnail' })
    .setJoin({ field: 'horizontalThumbnail' });

/**
 * Applies sorting and any relevant filters.
 */
const composeQuery = (tags: Tag[], query?: SessionQuery) => {
  const qb = createBaseQueryBuilder()
    .sortBy({ field: 'updatedDate', order: 'DESC' })
    .setFilter({ field: 'published', operator: '$eq', value: true });

  if (query?.limit) {
    qb.setLimit(query.limit);
  }
  if (query?.page) {
    qb.setPage(query.page);
  }

  const searchConditions = composeRequestQueryBuilderSearch(tags, query?.filters ?? [], query?.exclude);
  if (searchConditions) {
    qb.search(searchConditions);
  }

  return qb.query()
};

const SessionsService = {

  getMany: (query?: SessionQuery): Promise<GetManyResponse<Session>> =>
    TagsService.getAll()
      .then(tags => HttpService.get<GetManyResponse<SessionDto>>(`/public/sessions?${composeQuery(tags, query)}`))
      .then(response => ({
        ...response,
        data: response.data.map(transformSessionDto)
      })),

  getSimilar: ({
    type,
    exclude,
    limit,
  }: {
    type: TypeId[];
    exclude: SessionId;
    limit: number;
  }): Promise<Session[]> => {
    const query = createBaseQueryBuilder()
      .setFilter({ field: 'type.id', operator: '$in', value: type })
      .setFilter({ field: 'id', operator: '$ne', value: exclude })
      .setFilter({ field: 'published', operator: '$eq', value: true })
      .sortBy({ field: 'updatedDate', order: 'DESC' })
      .setLimit(limit)
      .query();
    return HttpService.get<GetManyResponse<SessionDto>>(`/public/sessions?${query}`)
      .then(response => response.data)
      .then(sessions => sessions.map(transformSessionDto));
  },

  getById: (id: string): Promise<Session> => HttpService
    .get<SessionDto>(`/public/sessions/${id}?${createBaseQueryBuilder().query()}`)
    .then(transformSessionDto),

  toggleLiked: (id: SessionId, status = true) => HttpService
    .patch(`/public/sessions/${id}/activity/like?status=${status}`),

  toggleCompleted: (id: SessionId, status = true) => HttpService
    .patch(`/public/sessions/${id}/activity/view?status=${status}`),

  markVisited: (id: SessionId) => HttpService
    .patch(`/public/sessions/${id}/activity/visit?status=true`)
    .catch(() => ErrorReportingService.report(`Could not mark session as visited; session id: ${id}`)),

  getComments: async (id: SessionId, page: number, limit: number): Promise<GetManyResponse<Comment>> => {
    const query = new RequestQueryBuilder()
      .setFilter({ field: 'session.id', operator: '$eq', value: id })
      .setPage(page)
      .setLimit(limit)
      .sortBy({ field: 'createdDate', order: 'DESC' })
      .query();
    const response = await HttpService
      .get<GetManyResponse<CommentDto>>(`/public/comment?${query}`);
    return {
      ...response,
      data: response.data.map(Comment.fromDto)
    };
  },

  postComment: (sessionId: SessionId, content: string) => HttpService
    .post<CommentDto>('/public/comment', { sessionId, content })
    .then(Comment.fromDto),

  removeComment: (commentId: CommentId) => HttpService
    .delete(`/public/comment/${commentId}`),

  editComment: (commentId: CommentId, content: string) => HttpService
    .patch<CommentDto>(`/public/comment/${commentId}`, { content })
    .then(Comment.fromDto),

  hideComment: (commentId: CommentId) => HttpService
    .patch<CommentDto>(`/comment/${commentId}`, { hidden: true })
    .then(Comment.fromDto),

  unhideComment: (commentId: CommentId) => HttpService
    .patch<CommentDto>(`/comment/${commentId}`, { hidden: false })
    .then(Comment.fromDto),
};

export default SessionsService;
