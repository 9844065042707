import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { ProductService } from 'src/services';
import { useSignUpContext } from '../context';

export const useRetrieveSubscriptionProduct = () => {
  const [, dispatch] = useSignUpContext();
  const { subscriptionProductId } = useParams<{ subscriptionProductId: string }>();

  const {
    data: product,
    status,
  } = useQuery(
    ['subscriptionProduct', subscriptionProductId],
    () => ProductService.getById(subscriptionProductId!),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    switch (status) {
      case 'success':
        if (!product) {
          return dispatch({ type: 'setSubscriptionProductError' });
        }
        return dispatch({ type: 'setSubscriptionProduct', product });
      case 'error': return dispatch({ type: 'setSubscriptionProductError' });
    }
  }, [status, product, dispatch]);
};
