import { Container, Item } from './styles';

export const DifficultyIndicators: React.FC<{
  difficulty: number;
  maxDifficulty?: number;
  className?: string;
}> = ({
  difficulty,
  maxDifficulty: length = 5,
  className,
}) =>  (
  <Container className={className}>
    {Array.from({ length}, (_, i) => i ).map(i => (
      <Item
        filled={i < difficulty}
        key={i}
      />
    ))}
  </Container>
);
