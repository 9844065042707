export interface UserActivitySummary {
  totalDuration: number;
  completedSessions: number;
  completedChallenges: number;
}

export interface UserActivitySummaryDto {
  totalDuration: number;
  completedSessions: number;
  completedChallenges: number;
}

export class UserActivitySummary {
  static fromDto = (dto: UserActivitySummaryDto): UserActivitySummary => ({
    totalDuration: dto.totalDuration,
    completedSessions: dto.completedSessions,
    completedChallenges: dto.completedChallenges,
  });
}
