import { Session } from 'src/models/Session';
import { Session as SessionComponent } from 'src/components';
import { SessionsService } from 'src/services';

const SessionDetails: React.FC<Session & {
  toggleCompleted: () => void;
}> = (
  {
    toggleCompleted,
    ...session
  },
) => (
  <SessionComponent
    contentType={session.contentType}
    title={session.title}
    underline={session.underline}
    difficulty={session.difficulty}
    primaryDescription={session.primaryDescription}
    secondaryDescription={session.secondaryDescription}
    coach={session.coach}
    style={session.style as any}
    type={session.type as any}
    tag={session.tag as any}
    equipment={session.equipment as any}
    video={session.video}
    horizontalThumbnail={session.horizontalThumbnail}
    attachments={session.extras}
    duration={session.video?.duration}
    isLiked={session.isLiked}
    toggleLike={liked => SessionsService.toggleLiked(session.id, liked)}
    isCompleted={session.isCompleted}
    toggleCompleted={toggleCompleted}
  />
);

export default SessionDetails;
