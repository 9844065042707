import { SubscriptionPayment } from 'src/models/SubscriptionPayment';
import styled from 'styled-components';

import PaymentItem from './PaymentItem';

const Wrapper = styled.div`
  border: ${p => p.theme.border};
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto auto;
`;

const Header = styled.header`
  grid-column: 1 / span 4;
  background: ${p => p.theme.palette.gray[100]};
  border-radius: 8px 8px 0 0;
  padding: 16px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const PaymentList: React.FC<{
  payments: SubscriptionPayment[];
}> = ({
  payments,
}) => (
  <Wrapper>
    <Header>Twoje płatności</Header>
    {payments.map(payment => (
      <PaymentItem
        key={payment.id}
        date={payment.date}
        productName={payment.subscription.name}
        amount={payment.amount}
        subtotal={payment.subtotal}
        couponCode={payment.couponCode}
        invoiceUrl={payment.invoiceUrl}
      />
    ))}
  </Wrapper>
);

export default PaymentList;
