import styled from 'styled-components';

import { ReactComponent as Logo } from 'src/assets/logo.svg';

export const BrandLogo = styled.h1.attrs(({
  children: <Logo />,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
