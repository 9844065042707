import { BackLink, Carousel, CoachCard, FullPageLoader, Header } from 'src/components'
import { useCoaches, useScrollToTop } from 'src/hooks';
import styled from 'styled-components';
import { isNativePlatform } from '../../config';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -22.5px;
`;

const Card = styled(CoachCard)`
  margin: 24px 22.5px;
  flex-grow: 1;
`;

const DummyCard = styled.div`
  width: 112px;
  height: 10px;
  height: 0;
  margin: 0px 22.5px;
  flex-grow: 1;
`;

export const Coaches: React.FC = () => {
  useScrollToTop();
  const coaches = useCoaches();

  if (!coaches) {
    return <FullPageLoader />
  }

  return (
    <>
      <BackLink
        to="/"
        label="Strona główna"
      />
      {
        !isNativePlatform &&
          <Carousel page="teachers" />
      }
      <Header
        title="Nauczyciele"
        as="h1"
      />
      <Grid>
        {coaches.map(coach => <Card {...coach} key={coach.id} scrollInToView />)}
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
        <DummyCard />
      </Grid>
    </>
  );
};
