import styled from 'styled-components';
import { Skeleton } from '@mui/material';

export {
  Layout,
  Header,
} from './main';

export const Card = styled.div``;

export const Image = styled(Skeleton).attrs({
  variant: 'rectangular',
  animation: 'wave',
})<{mode: 'horizontal' | 'vertical'}>`
  grid-area: image;
  padding-top: ${p => p.mode === 'vertical' ? 133 : 56.25}%;
`;

export const Overline = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  font-size: 11px;
  line-height: 21px;
  width: 40%;
`;

export const Title = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  font-size: 19px;
  line-height: 120%;
  margin: 8px 0 4px 0 !important;
  width: 90%;
`;

export const Underline = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  font-size: 13px;
  line-height: 148%;
  width: 70%;
`;

export const Avatar = styled(Skeleton).attrs({
  variant: 'circular',
  animation: 'wave',
  width: 22,
  height: 22,
})``;

export const Coach = styled.div`
  grid-area: coach;
  margin: 16px 0 34px 0;
  display: flex;
  width: 40%;
`;

export const CoachLabel = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
})`
  margin-left: 8px;
  font-size: 13px;
  line-height: 148%;
  flex-grow: 1;
`;

export const Difficulty = styled.div`
  grid-area: difficulty;
  align-self: flex-end;
`;

export const DifficultyLabel = styled(Skeleton).attrs({
  variant: 'text',
  animation: 'wave',
  width: 50,
})`
  font-size: 13px;
  line-height: 148%;
`;

export const DifficultyIndicators = styled(Skeleton).attrs({
  variant: 'rectangular',
  animation: 'wave',
  width: 100,
  height: 12,
})`
  margin-top: 8px;
`;

export const DurationWrapper = styled.div`
  grid-area: duration;
  align-self: flex-end;
  justify-self: flex-end;
`;

export const Duration = styled(Skeleton).attrs({
  variant: 'rectangular',
  animation: 'wave',
  width: 80,
  height: 39,
})``;
