import { Avatar as MuiAvatar } from '@mui/material';

import { stringAvatar } from './helpers';

export const Avatar: React.FC<{
  name: string;
  className?: string;
}> = ({
  name,
  className,
}) => (
  <MuiAvatar
    {...stringAvatar(name)}
    className={className}
  />
);
