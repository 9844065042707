import styled, { css } from 'styled-components';

export const Container = styled.div<{ size: "small" | "medium" | "large" }>`
  box-sizing: border-box;
  border-radius: 100%;
  & > img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.12);
  }

  ${(p) =>
    p.size === "small" &&
    css`
      width: 22px;
      height: 22px;
      border: none;
      padding: 0;
    `}

  ${(p) =>
    p.size === "medium" &&
    css`
      width: 40px;
      height: 40px;
    `}

  ${(p) =>
    p.size === "large" &&
    css`
      width: 56px;
      height: 56px;
    `}
`;

/**
 
@import 'src/styles/global';

.Container {
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border: $border;
  border-radius: 100%;
  padding: $spacing-4;
  & > img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.large {
  width: 56px;
  height: 56px;
}

.medium {
  width: 40px;
  height: 40px;
}

.small {
  width: 22px;
  height: 22px;
  border: none;
  padding: 0;
}


 */
