import React from 'react'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import { useTheme, alpha } from '@mui/material'

type Props = InputBaseComponentProps & {
  component: React.FunctionComponent<InputBaseComponentProps>;
  options: Record<string, unknown>;
};

export const StripeInput = React.forwardRef((props: Props, ref) : JSX.Element => {
  const theme = useTheme();
  const { component: Component, options, ...other } = props;
  const [mountNode, setMountNode] = React.useState<HTMLInputElement | null>(
    null
  );

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        if (mountNode === null) {
          return;
        }

        mountNode.focus();
      },
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: `16px`,
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: alpha(theme.palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...other}
    />
  );
});
