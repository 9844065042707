import styled from 'styled-components';
import { ScrollbarCarousel } from 'src/components';

export const Wrapper = styled.div``;

export const Carousel = styled(ScrollbarCarousel).attrs({
  gutter: 24,
})`
  margin-top: 16px;
`;

export const Item = styled.div<{isFirst: boolean; isLast: boolean;}>`
  width: calc(100vw - 2 * 24px);
  @media (min-width: 500px) {
    max-width: calc((1280px - 24px * 3) / 4);
  }
  margin-top: 24px;
`;
