import { useMemo } from 'react';
import styled from 'styled-components';

import { SubscriptionProduct } from 'src/models/SubscriptionProduct';
import { SubscriptionProductPriceDetails } from 'src/models/SubscriptionProductPriceDetails';
import { Coupon } from 'src/models/Coupon';

const Container = styled.div``;

const Overline = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: ${p => p.theme.palette.text.medium};
`;

const Title = styled.p`
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3px;
  color: ${p => p.theme.palette.text.high};
  margin: 4px 0;
`;

const Price = styled.span`
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.palette.text.medium};
  del {
    color: ${p => p.theme.palette.text.low};
  }
  strong {
    color: ${p => p.theme.palette.primary.dark};
  }
`;

const SubscriptionBox: React.FC<{
  subscription: SubscriptionProduct;
  coupon: Coupon | null;
  isEligibleForCoupons: boolean;
}> = ({
  subscription,
  coupon,
  isEligibleForCoupons,
}) => {
  const {
    name,
    description,
    isRenewable,
  } = subscription;

  const price = useMemo(() => SubscriptionProductPriceDetails.compose(subscription, coupon), [coupon, subscription]);

  const hasOneTimeDiscount = !!price.discount?.once && !isEligibleForCoupons;
  const hasAllTimeDiscount = !!price.discount?.forever;
  const hasRegularPrice = !hasOneTimeDiscount && !hasAllTimeDiscount;

  return (
    <Container>
      <Overline>{description}</Overline>
      <Title>{name}</Title>
      {hasRegularPrice && (
        <Price>
          {price.basePrice}
          {isRenewable && ` / ${price.interval}`}
        </Price>
      )}
      {hasAllTimeDiscount && (
        <Price>
          <del>{price.basePrice}</del>
          <strong>{price.discount!.discountedPrice}</strong>
          {isRenewable && ` / ${price.interval}`}
        </Price>
      )}
      {hasOneTimeDiscount && (
        <Price>
          najbliższa płatność: <strong>{price.discount!.discountedPrice}</strong>
          {isRenewable && `(kolejne: ${price.basePrice} / ${price.interval})`}
        </Price>
      )}
    </Container>
  );
}

export default SubscriptionBox;
