import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';
import { CommentId } from 'src/models/Comment';
import { ChallengeId, ChallengeSessionId, ChallengeStepId } from 'src/models/Challenge';
import { SessionId } from 'src/models/Session';
import { UserId } from 'src/models/User';

export enum CommentType {
  session = 1,
  challenge = 2,
  challengeStep = 3,
}

export enum CommentStatus {
  default = 0,
  hidden = 16,
  deletedByModerator = 32,
  deletedByUser = 64,
}

export interface CommentDto {
  id: CommentId;
  content: string;
  hidden: boolean;
  createdDate: string;
  updatedDate: string;
  session?: { id: string };
  challenge?: { id: string };
  challengeSession?: { id: string };
  sessionId?: SessionId;
  challengeId?: ChallengeId;
  challengeSessionId?: ChallengeSessionId;
  path?: string[];
  type?: CommentType;
  status?: CommentStatus;
  replyCount?: number;
  replies?: CommentDto[];
  thread?: CommentDto;
  parent?: CommentDto;
  user: {
    id: UserId,
    name: string,
  };
}

export interface CommentsByIdRequest {
  sessionId?: SessionId;
  challengeId?: ChallengeId;
  challengeStepId?: ChallengeStepId;
  challengeSessionId?: ChallengeSessionId;
  replyCommentId?: CommentId;
  path?: string;
}

export const CommentsClient = {

  getComments: async (
    id: CommentsByIdRequest,
    offset: number,
    limit: number,
    sortOrder: 'DESC' | 'ASC'
  ): Promise<GetManyResponse<CommentDto>> => {
    const builder = new URLSearchParams();
    if (id.challengeSessionId) {
      builder.set('key', 'challengeSessionId');
      builder.set('value', id.challengeSessionId);
    } else if (id.challengeId) {
      builder.set('key', 'challengeId');
      builder.set('value', id.challengeId);
    } else if (id.sessionId) {
      builder.set('key', 'sessionId');
      builder.set('value', id.sessionId);
    }
    if (id.path) {
      builder.set('path',  id.path);
    }
    builder.set('skip', offset.toString());
    builder.set('take', limit.toString());
    builder.set('sort', 'createdDate');
    builder.set('sortOrder', sortOrder);

    const query = builder.toString();
    const response = await HttpService
      .get<GetManyResponse<CommentDto>>(`/public/comment/search?${query}`);
    return response;
  },

  getComments2: async (
    id: CommentsByIdRequest,
    offset: number,
    limit: number,
  ): Promise<GetManyResponse<CommentDto>> => {
    const builder = new RequestQueryBuilder();
    if (id.sessionId) {
      builder.setFilter({ field: 'session.id', operator: '$eq', value: id.sessionId });
    } else if (id.challengeId) {
      builder.setFilter({ field: 'challenge.id', operator: '$eq', value: id.challengeId });
    } else if (id.challengeSessionId) {
      builder.setFilter({ field: 'challengeSession.id', operator: '$eq', value: id.challengeSessionId });
    }
    const query = builder
      .setOffset(offset)
      .setLimit(limit)
      .sortBy({ field: 'createdDate', order: 'DESC' })
      .query();
    const response = await HttpService
      .get<GetManyResponse<CommentDto>>(`/public/comment?${query}`);
    return response;
  },

  postComment: async (id: CommentsByIdRequest, content: string) => {
    const dto = await HttpService
      .post<CommentDto>('/public/comment', { ...id, content });
    return dto;
  },

  removeComment: (commentId: CommentId) => HttpService
    .delete(`/public/comment/${commentId}`),

  editComment: (commentId: CommentId, content: string) => HttpService
    .patch<CommentDto>(`/public/comment/${commentId}`, { content }),

  hideComment: (commentId: CommentId) => HttpService
    .patch<CommentDto>(`/comment/${commentId}`, { hidden: true }),

  unhideComment: (commentId: CommentId) => HttpService
    .patch<CommentDto>(`/comment/${commentId}`, { hidden: false }),
};
export default CommentsClient;
