import { useUserState } from 'src/contexts/UserContext';
import { useHistory } from 'react-router-dom';

import { ActivityService } from 'src/services';

import { ActivitySummary, ActivityList } from '../features';
import { Divider, Spacer } from '../styles';

const itemsPerList = 3;

const fetchLikes = () => ActivityService
  .getLikedSessionsAndChallenges(0, itemsPerList)
  .then(r => r.data);

const fetchVisits = () => ActivityService
  .getVisitedSessionsAndChallenges(0, itemsPerList)
  .then(r => r.data);

export const Summary: React.FC = () => {
  const currentPathname = useHistory().location.pathname;
  const { user } = useUserState();

  return (
    <>
      <ActivitySummary
        username={user.name}
        fetchSummary={ActivityService.getActivitySummary}
      />
      <Spacer />
      <Divider />
      <Spacer />
      <ActivityList
        title="Twoja aktywność"
        link="/activity"
        fetchItems={fetchVisits}
        maxItemCount={3}
        backLink={{
          link: currentPathname,
          linkLabel: 'Twoja praktyka',
        }}
      />
      <Spacer />
      <Divider />
      <Spacer />
      <ActivityList
        title="Ulubione"
        link="/likes"
        fetchItems={fetchLikes}
        maxItemCount={3}
        backLink={{
          link: currentPathname,
          linkLabel: 'Twoja praktyka',
        }}
      />
    </>
  );
};
