import styled from 'styled-components';
import { FullImageCarouselItem } from '../types';

import {
  Wrapper,
  Overline,
  Title,
  Text,
  Cta,
} from './styles';

const ImageBackground = styled.div<{src: string; focalPoint: [number, number];}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:
    top ${p => p.focalPoint[0] * 100}%
    left ${p => p.focalPoint[1] * 100}%;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0,0,0,0.3); */
  }
`;

const Item = styled.section`
  width: 100%;
  position: relative;
  padding: 32px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${Text}, ${Title} {
    /* color: ${p => p.theme.palette.common.white}; */
    color: ${p => p.theme.palette.primary.dark};
  }
`;

export const FullImageItem: React.FC<FullImageCarouselItem> = ({
  overline,
  title,
  copy,
  ctaLabel,
  ctaUrl,
  imageUrl,
  focalPoint,
}) => (
  <Wrapper>
    <ImageBackground src={imageUrl} focalPoint={focalPoint} />
    <Item>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Overline>{overline}</Overline>
        <Title>{title}</Title>
      </div>
      <Text dangerouslySetInnerHTML={{ __html: copy ?? '' }} />
      {!!ctaLabel && !!ctaUrl && <Cta href={ctaUrl}>{ctaLabel}</Cta>}
    </Item>
  </Wrapper>
);
