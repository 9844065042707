import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as Arrow } from './arrow.svg';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div.attrs({
  children: <Arrow />
})`
  & svg { fill: currentColor; }
  width: 16px;
  height: 16px;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  ${Link}:hover > & {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const Label = styled.span<{ size: 'small' | 'regular' }>`
  display: block;
  margin-left: 8px;
  font-size: ${p => p.size === 'regular' ? '16px' : '13px'};
  line-height: 24px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.high};
`;
