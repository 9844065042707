import { Collapse } from '@mui/material';
import { SearchResultsSummary } from 'src/components';
import { SessionFilters } from 'src/models/SessionFilters';

import { isNativePlatform } from '../../config';
import AllSelectedFilters from './AllSelectedFilters';
import Multiselect from './Multiselect';
import MultiselectWithCounter from './MultiselectWithCounter';
import {
  Wrapper,
  Field,
  BasicFields,
  AdvancedFields,
  ButtonArea,
  ModeToggle,
  ModeIcon,
  ModeLabel,
  ClearButton,
  ClearIcon,
  ClearLabel,
  SearchResultsButton,
  SearchResultsIcon,
  SearchResultsLabel,
  SearchResultsExpand,
  SimplifiedSearchArea,
  SimplifiedSearchInput,
} from './styles/searchboxStyles';

import useSessionSearch from './useSessionSearch';

const SessionSearchbox: React.FC<{
  pathBase: string;
  showFilterSummary?: boolean;
  showResultsSummary?: boolean;
  results?: number;
  searchPending?: boolean;
  liveSearch?: boolean;
  presetFilters?: SessionFilters,
}> = ({
  pathBase,
  showFilterSummary = false,
  showResultsSummary = false,
  results = NaN,
  searchPending = false,
  liveSearch,
  presetFilters = [],
}) => {

  const {
    ready,
    filters,
    committedFilters,
    searchLink,
    advancedMode,
    toggleAdvancedMode,
    options,
    aggregatedOptions,
    values,
    aggregatedValues,
    onChange,
    onAddForeignValue,
    onPhraseConfirm,
    onClearFilter,
    onClearAllFilters,
    displayFilters,
    toggleDisplayFilters
  } = useSessionSearch({
    pathBase,
    liveSearch,
    queryTitle: 'Znajdź sesję',
    queryUnderline: 'Wpisz dowolną frazę i użyj naszych filtrów, by znaleźć sesję idealnie dopasowaną do Twojej praktyki.',
  });

  const hideStyleAndType = pathBase === 'pilates' || pathBase === 'meditations';

  return (
    <>
      <Wrapper>
        <Collapse in={isNativePlatform ? displayFilters : true}>
        <BasicFields>
          <Field area="tag">
            <Multiselect
              label="Wyszukaj frazę"
              id="tags-and-phrase-select"
              aggregatedOptions={aggregatedOptions}
              aggregatedValues={aggregatedValues}
              tagValues={values.tags}
              onTagsChange={onChange.tags}
              onAddForeignValue={onAddForeignValue}
              phrase={values.phrase}
              onPhraseChange={onChange.phrase}
              onPhraseConfirm={onPhraseConfirm}
            />
          </Field>
          <Field area="duration">
            <MultiselectWithCounter
              label="Czas trwania"
              id="duration-select"
              options={options.durationRanges}
              values={values.durationRanges}
              onChange={onChange.durationRanges}
              noTextInput
            />
          </Field>
          <Field area="difficulty">
            <MultiselectWithCounter
              label="Poziom trudności"
              id="difficulty-select"
              options={options.difficulties}
              values={values.difficulties}
              onChange={onChange.difficulties}
              noTextInput
            />
          </Field>
        </BasicFields>
        </Collapse>
        <Collapse in={isNativePlatform ? displayFilters && advancedMode : advancedMode}>
          <AdvancedFields>
            <Field area="coach">
              <MultiselectWithCounter
                label="Nauczyciel"
                id="coach-select"
                options={options.coaches}
                values={values.coaches}
                onChange={onChange.coaches}
              />
            </Field>
            {!hideStyleAndType && (
              <Field area="style">
                <MultiselectWithCounter
                  label="Styl jogi"
                  id="style-select"
                  options={options.styles}
                  values={values.styles}
                  onChange={onChange.styles}
                />
              </Field>
            )}
            {!hideStyleAndType && (
              <Field area="type">
                <MultiselectWithCounter
                  label="Rodzaj jogi"
                  id="type-select"
                  options={options.types}
                  values={values.types}
                  onChange={onChange.types}
                />
              </Field>
            )}
          </AdvancedFields>
        </Collapse>
        {
          isNativePlatform ?
          displayFilters ?
            <ButtonArea>
              <ModeToggle onClick={toggleAdvancedMode} advancedMode={advancedMode}>
                <>
                  <ModeIcon/>
                  <ModeLabel>{advancedMode ? 'Pokaż mniej' : 'Pokaż więcej'}</ModeLabel>
                </>
              </ModeToggle>
              <SearchResultsButton to={searchLink} mobile={isNativePlatform}>
                <SearchResultsLabel>Pokaż wyniki</SearchResultsLabel>
                <SearchResultsIcon/>
              </SearchResultsButton>
            </ButtonArea>
            :
            <SimplifiedSearchArea>
              <SimplifiedSearchInput>
                <Field area="tag">
                  <Multiselect
                    label="Wyszukaj frazę"
                    id="tags-and-phrase-select"
                    aggregatedOptions={aggregatedOptions}
                    aggregatedValues={aggregatedValues}
                    tagValues={values.tags}
                    onTagsChange={onChange.tags}
                    onAddForeignValue={onAddForeignValue}
                    phrase={values.phrase}
                    onPhraseChange={onChange.phrase}
                    onPhraseConfirm={onPhraseConfirm}
                  />
                </Field>
              </SimplifiedSearchInput>
              <SearchResultsExpand onClick={toggleDisplayFilters} mobile={isNativePlatform}>
                <SearchResultsLabel>Pokaż filtry</SearchResultsLabel>
              </SearchResultsExpand>
            </ SimplifiedSearchArea>
            :
            <ButtonArea>
              <ModeToggle onClick={toggleAdvancedMode} advancedMode={advancedMode}>
                <ModeIcon />
                <ModeLabel>{advancedMode ? 'Pokaż mniej' : 'Pokaż więcej'}</ModeLabel>
              </ModeToggle>
              <SearchResultsButton to={searchLink}>
                <SearchResultsLabel>Pokaż wyniki</SearchResultsLabel>
                <SearchResultsIcon />
              </SearchResultsButton>
            </ButtonArea>
        }
      </Wrapper>
    {showResultsSummary && (
      <SearchResultsSummary
        results={results}
        pending={searchPending}
        showIf={ready && Math.min(filters.length, committedFilters.length) > 0 /*&& committedFilters.length > 0*/}
      />
    )}
    {ready && showFilterSummary && (
      <AllSelectedFilters
        filters={values.all}
        onClearFilter={onClearFilter}
        clearAllButton={(
          <ClearButton onClick={onClearAllFilters}>
            <ClearIcon />
            <ClearLabel>Wyczyść wszystko</ClearLabel>
          </ClearButton>
        )}
      />
    )}
    </>
  );
};

export default SessionSearchbox;
