import { Collapse, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import meditationIcon from '../../assets/icons/meditation_nav_icon.png';
import planIcon from '../../assets/icons/plan_nav_icon.png';
import profileIcon from '../../assets/icons/profile_nav_icon.png';
import sessionsIcon from '../../assets/icons/sessions_nav_icon.png';
import { isNativePlatform } from '../../config';
import { useCmsContent } from '../../contexts/CmsContentContext';
import Footer from './Footer';
import { useBottomNavigation } from './hooks';
import Navbar from './Navbar';
import NavbarEmpty from './NavbarEmpty';

const Wrapper = styled.div`
  flex-grow: 1; // take whole vertical space, at least to fill the screen
  display: flex;
  flex-direction: column;
`;

const SideMargins = styled.div<{isLoginPage?: boolean}>`
  flex-grow: 1; 
  display: grid;
  grid-template-columns: minmax(48px, auto) minmax(0, 1280px) minmax(48px, auto);
  @media (max-width: 767px) {
    grid-template-columns: minmax(24px, auto) minmax(0, 1fr) minmax(24px, auto);
  }
  // views with little content should still occupy at least the remaining height
  // of the screen (minus topbar & footer); makes centering easy
  grid-template-rows: minmax(100%, auto);
  padding-bottom: ${p => p.isLoginPage && isNativePlatform ? '60px' : '0'};
`;

export const ContainedWidth: React.FC<{className?: string; isLoginPage?: boolean}> =
  ({ children, className , isLoginPage}) => {
  return <SideMargins isLoginPage={isLoginPage}>
    <div style={{ gridColumn: '2' }} className={className}>
      {children}
    </div>
  </SideMargins>
}

const BottomNavButton = styled(BottomNavigationAction)`
  padding: 6px 1px 14px 1px;
  max-width: 80px;
  .MuiBottomNavigationAction-label {
    font-size: 0.75rem;
    white-space: nowrap;
  }
  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.75rem;
    white-space: nowrap;
  }
`;

const BottomNavButtonFirst = styled(BottomNavigationAction)`
  padding: 6px 1px 14px 1px;
  max-width: 45px;
  min-width: 45px;
  .MuiBottomNavigationAction-wrapper {
    max-width: 40px;
  }
  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.75rem;
  }
`;

const Content = styled.div`
  padding: 48px 0;
  height: 100%;
`;

const NavIcon = styled('img')`
  width: 32px;
  height: 32px;
`;

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 100,
    height: '80px',
    padding: '0 5px',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
});

const Layout: React.FC<{
  navbar?: boolean;
  emptyNavbar?: boolean;
}> = ({
  children,
  navbar,
  emptyNavbar,
}) => {
  const practicePlanLink = useCmsContent().links.practicePlan;
  const history = useHistory();
  const {location} = history;
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const {bottomNavigationChangeRoute, tabIndex} = useBottomNavigation({practicePlanLink});
  const showBottomNav =
    isNativePlatform &&
    !location.pathname.includes('/login');

  const classes = useStyles();

  useEffect(() => {
    switch (location.pathname) {
      case '/login':
        setIsFooterVisible(false);
        break;
      default:
        setIsFooterVisible(true);
        break;
    }
  }, [location.pathname]);

  return <Wrapper data-testid="layout-wrapper">
    <Collapse in={navbar}>
      {navbar && (
        <div>
          <Navbar />
        </div>
      )}
    </Collapse>
    <Collapse in={emptyNavbar}>
      {emptyNavbar && (
        <div>
          <NavbarEmpty />
        </div>
      )}
    </Collapse>
    <ContainedWidth isLoginPage={isFooterVisible}>
      <Content>
        {children}
      </Content>
    </ContainedWidth>
    {
      isNativePlatform ?
        isFooterVisible ?
          <Footer /> : <></>
        : <Footer />
    }
    {
      showBottomNav &&
        <BottomNavigation
            value={tabIndex}
            onChange={(event, newValue) => {
              bottomNavigationChangeRoute(newValue)
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavButtonFirst label="joga" icon={<NavIcon src={sessionsIcon} />} />
            <BottomNavButton label="medytacje" icon={<NavIcon src={meditationIcon} />} />
            <BottomNavButton label="plan praktyki" icon={<NavIcon src={planIcon} />} />
            <BottomNavButton label="moje konto" icon={<NavIcon src={profileIcon} />} />
        </BottomNavigation>
    }
  </Wrapper>
}

export default Layout;
