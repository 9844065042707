import { ChallengeId, ChallengeSessionId, ChallengeStepId } from 'src/models/Challenge';
import { BackLink, Loader, WidgetLayout } from 'src/components';
import styled from 'styled-components';

import {
  useChallenge,
  useToggleStepCompleted,
  useReturnLink,
  useMarkVisited,
} from './hooks';
import StepList from './StepList';
import ChallengeDetails from './ChallengeDetails';
import ChallengeSession from './ChallengeSession';
import { useScrollToTop } from 'src/hooks';
import Comments from 'src/features/Comments';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom-v5-compat';
import { useQueryClient } from 'react-query';

const LoaderArea = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Challenge = () => {
  const { id, stepId } = useParams() as { id: ChallengeId, stepId?: ChallengeStepId };
  const { data: challenge, isLoading } = useChallenge(id);
  useScrollToTop([id, stepId]);
  useMarkVisited(id, stepId);
  const returnLink = useReturnLink();
  const step = challenge?.steps
    .flatMap(group => group.steps)
    .find(step => step.id === stepId);
  const sessionId = step?.session?.id;
  const isStepCompleted = !!step?.isCompleted;
  
  const commentsId = { challengeId: id, challengeStepId: step?.id, challengeSessionId: sessionId };

  const toggleStepCompleted = useToggleStepCompleted();

  const nextStep = challenge?.steps
    .flatMap(group => group.steps)
    .find(() => true); // TODO: find first not finished step

  const steps = challenge?.steps
    .flatMap(group => group.steps) ?? [];
  const totalCount = steps.length;
  const completedCount = steps.filter(s => s.isCompleted).length;
  const progress = completedCount / totalCount || 0;

  return (
    <WidgetLayout>
      <WidgetLayout.MainContentArea>
        {(isLoading || !challenge) && (
          <LoaderArea>
            <Loader />
          </LoaderArea>
        )}
        {(!isLoading && !!challenge) && (
          <>
            {
              sessionId
                ? <BackLink label="Szczegóły wyzwania" to={`/challenges/${id}`} />
                : <BackLink label={returnLink.label} to={returnLink.to} />
            }
            {
              sessionId && stepId
                ? (
                  <ChallengeSession
                    id={sessionId}
                    isCompleted={isStepCompleted}
                    onToggleCompleted={() => toggleStepCompleted(id, stepId, !isStepCompleted)}
                  />
                )
                : (
                  <ChallengeDetails {...challenge} />
                )
            }
          </>
        )}
      </WidgetLayout.MainContentArea>

      {(!isLoading && !!challenge && !stepId) && (
        <WidgetLayout.SecondaryContentArea>
          <Comments id={commentsId} />
        </WidgetLayout.SecondaryContentArea>
      )}

      {(!isLoading && !!challenge && sessionId && stepId) && (
        <WidgetLayout.SecondaryContentArea>
          <Comments id={commentsId} />
        </WidgetLayout.SecondaryContentArea>
      )}

      <WidgetLayout.WidgetArea>
        { challenge && (
          <StepList
            groups={challenge.steps}
            composeStepUrl={subpath => `/challenges/${id}/${subpath}`}
            progress={progress}
            currentStep={stepId}
            nextStep={nextStep}
            coach={challenge.coach}
            equipment={challenge.equipment}
            duration={challenge.duration ?? undefined}
            difficulty={challenge.difficulty}
            showBackLink={sessionId && !!stepId}
            challengeUrl={`/challenges/${id}`}
          />
        )}
      </WidgetLayout.WidgetArea>
    </WidgetLayout>
  );
};

export function ChallengeBySession() {
  const { challengeSessionId } = useParams<{challengeSessionId: ChallengeSessionId}>();
  const queryClient = useQueryClient();
  const { data } = useChallenge(undefined, challengeSessionId);
  if (data != null) {
    queryClient.setQueryData(['challenges', data.id], data);
    const stepId = data.steps.flatMap(s => s.steps).find(s => s.session!.id === challengeSessionId)?.id;
    return (
      <Navigate to={`/challenges/${data.id}/${stepId}`} replace />
    );
  }
  return (
    <div>Loading...</div>
  );
}

export default Challenge;
