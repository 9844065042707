import { ApiError } from 'src/errors';
import { AuthRequestDto, AuthSuccessDto, RegisterDto, RegisterResultDto, UnfinishedRegisterDto } from 'src/models/Auth';
import { ErrorReportingService, HttpService } from '.';
import AuthTokenService from './AuthTokenService';

const login = (body: AuthRequestDto) => HttpService
  .post<AuthSuccessDto>('/auth/login', body);

const register = (body: RegisterDto) => HttpService
  .post<RegisterResultDto>('/auth/register', body);

const registerV2 = (body: RegisterDto) => HttpService
  .post<RegisterResultDto>('/auth/registerV2', body);

const sendUnfinishedSignUpData = (body: UnfinishedRegisterDto) => HttpService
  .put<AuthSuccessDto>('/public/users/checkout/partial', body)
  .catch(ErrorReportingService.report);

const requestPasswordReset = (email: string): Promise<boolean> => HttpService
  .post('/auth/reset-password/email', { email })
  .then(() => true)
  .catch(e => {
    if (e instanceof ApiError && e.responseStatus === 404) {
      return false;
    }
    throw e;
  });

const validatePasswordResetToken = (token: string) => {
  AuthTokenService.set(token);
  return HttpService
    .get('/auth/reset-password/validate')
    .then(() => true)
    .catch(() => false)
    .finally(() => AuthTokenService.clear());
};

const resetPassword = (password: string, token: string) => {
  AuthTokenService.set(token);
  return HttpService
    .patch('/auth/reset-password', { password })
    .finally(() => AuthTokenService.clear());
};

const isLegacyPlatformUser = (email: string): Promise<boolean> =>
  HttpService
    .post<boolean>('/auth/is-non-migrated-user', { email })
    .catch(() => false);

const AuthService = {
  login,
  register,
  registerV2,
  sendUnfinishedSignUpData,
  requestPasswordReset,
  validatePasswordResetToken,
  resetPassword,
  isLegacyPlatformUser,
};

export default AuthService;
