import { InfoBox } from 'src/components';
import { useUserState } from 'src/contexts/UserContext';
import { useLastDifferentValue } from 'src/hooks';

export const ManualPaymentSuccess: React.FC<{
  className?: string;
}> = ({
  className,
}) => {
  const { manualPaymentStatus: currPaymentStatus } = useUserState();
  const prevPaymentStatus = useLastDifferentValue(currPaymentStatus);
  const hasSuccessfullyPaid = prevPaymentStatus === 'pending' && currPaymentStatus === 'idle';

  if (hasSuccessfullyPaid) {
    return (
      <InfoBox
        mode="success"
        message="Płatność zakończona pomyślnie"
        delay={1000}
        className={className}
      />
    );
  }

  return null;
};
