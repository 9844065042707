import { QueryClient, QueryClientProvider } from 'react-query';
import { CmsContentProvider } from 'src/contexts';
import { WelcomeViewContextProvider } from 'src/contexts/WelcomeViewContext';
import { ThemeProvider } from 'src/styles';

import Layout from './Layout';

const queryClient = new QueryClient();

export const AppWrapper: React.FC<{
  navbar?: boolean;
  emptyNavbar?: boolean;
}> = ({
  children,
  navbar,
  emptyNavbar,
}) => (
  <QueryClientProvider client={queryClient}>
    <CmsContentProvider>
      <WelcomeViewContextProvider>
        <ThemeProvider>
          <Layout navbar={navbar} emptyNavbar={emptyNavbar}>
            {children}
          </Layout>
        </ThemeProvider>
      </WelcomeViewContextProvider>
    </CmsContentProvider>
  </QueryClientProvider>
);
