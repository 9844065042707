import { useEffect, useState } from 'react';
import { CarouselItem } from './types';
import Item from './Item';
import {
  Carousel,
  Item as ItemWrapper,
  Arrow,
  Navigation,
  NavigationItem
} from './HeroCarousel.styles';

export const HeroCarousel: React.FC<{
  items: CarouselItem[];
  autoplayInterval?: number;
}> = ({
  items,
  autoplayInterval = 10_000,
}) => {
  const [activeItem, setActiveItem] = useState(0);
  const goBack = () => setActiveItem(i => i === 0 ? items.length - 1 : i - 1);
  const goForward = () => setActiveItem(i => i === items.length - 1 ? 0 : i + 1);
  const multiItems = items.length < 1;

  useEffect(() => {
    const timeout = setTimeout(
      () => setActiveItem(i => i === items.length - 1 ? 0 : i + 1),
      autoplayInterval,
    );
    return () => clearTimeout(timeout);
  }, [activeItem, setActiveItem, items.length, autoplayInterval]);

  return (
    <Carousel>
      {multiItems && <Arrow direction="prev" onClick={goBack} />}
      {items
        .map((item) => (<Item {...item} />))
        .map((item, index) => (
          <ItemWrapper key={index} active={index === activeItem}>
            {item}
          </ItemWrapper>
        ))
      }
      {multiItems && <Arrow direction="next" onClick={goForward} />}
      {multiItems && (
        <Navigation>
          {items.map((_, index) => (
            <NavigationItem
              onClick={() => setActiveItem(index)}
              active={index === activeItem}
              key={index} />
          ))}
        </Navigation>
      )}
    </Carousel>
  );
};
