import { VideoDto, Video, transformVideoDto } from './video';
import { ExtraFile, ExtraFileDto, transformExtraFileDto } from './ExtraFile';
import { Coach, CoachDto, transformCoachDto } from './Coach';
import { Equipment, EquipmentDto } from './Equipment';
import { containsCompletedEvent, containsLikedEvent, SessionEvent } from './SessionEvent';

export type SessionId = Branded<string, 'sessionId'>;

export interface SessionItem {
  name: string;
  id: string;
}

export interface Session {
  id: SessionId;
  contentType: 'session';
  title: string;
  underline: string;
  difficulty: number;
  primaryDescription: string;
  secondaryDescription: string;
  published: boolean;
  createdDate: Date;
  updatedDate: Date;
  isCompleted: boolean;
  isLiked: boolean;
  coach: Coach | null;
  style: SessionItem | null;
  type: SessionItem[];
  tag: SessionItem[];
  equipment: Equipment[];
  video: Video | null;
  horizontalThumbnail: string | null;
  extras: Array<ExtraFile>;
}

export interface BaseSessionDto {
  id: SessionId;
  title: string | null;
  underline: string | null;
  difficulty: number;
  primaryDescription: string | null;
  secondaryDescription: string | null;
  published: boolean;
  createdDate: string;
  updatedDate: string;
  userActivity: SessionEvent[];
  horizontalThumbnail?: { url: string | null; };
}

export interface SessionDto extends BaseSessionDto {
  coach: Array<CoachDto>;
  style: Array<SessionItem>;
  type: Array<SessionItem>;
  tag: Array<SessionItem>;
  equipment: EquipmentDto[];
  video?: VideoDto;
  extras?: Array<ExtraFileDto>;
}

export interface SessionUpdateDto {
  title?: string | null;
  underline?: string | null;
  difficulty?: number;
  primaryDescription?: string | null;
  secondaryDescription?: string | null;
  published?: boolean;
  coach?: Array<{id: string;}>;
  style?: Array<{id: string;}>;
  type?: Array<{id: string;}>;
  tag?: Array<{id: string;}>;
  equipment?: Array<{id: string;}>;
  duration?: number;
}

export const transformSessionDto = (dto: SessionDto): Session => ({
  id: dto.id,
  contentType: 'session',
  title: dto.title ?? '',
  underline: dto.underline ?? '',
  difficulty: dto.difficulty,
  primaryDescription: dto.primaryDescription ?? '',
  secondaryDescription: dto.secondaryDescription ?? '',
  published: dto.published,
  coach: dto.coach?.[0] ? transformCoachDto(dto.coach?.[0]) : null,
  style: dto.style?.[0] ?? null,
  type: (dto.type ?? []),
  tag: dto.tag ?? [],
  equipment: (dto.equipment ?? []).map(Equipment.fromDto),
  video: dto.video ? transformVideoDto(dto.video) : null,
  createdDate: new Date(dto.createdDate),
  updatedDate: new Date(dto.updatedDate),
  isCompleted: containsCompletedEvent(dto.userActivity),
  isLiked: containsLikedEvent(dto.userActivity),
  horizontalThumbnail: dto.horizontalThumbnail?.url ?? null,
  extras: (dto.extras ?? [])
    .map(transformExtraFileDto)
    .sort((a, b) => a.createdDate.getTime() - b.createdDate.getTime()),
});
