import styled from 'styled-components';

export const Container = styled.ul`
  width: auto;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li<{filled: boolean}>`
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border: 1px solid ${p => p.theme.palette.primary.dark};
    border-radius: 100%;
    margin: 0;

    &:not(:first-child) {
      margin: 0 0 0 4px;
    }
    &:first-child {
      margin: 0;
    }

    background: ${p => p.filled ? p.theme.palette.primary.dark : 'transparent'};
`;
