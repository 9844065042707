import { Coupon } from 'src/models/Coupon';
import { SubscriptionProduct } from 'src/models/SubscriptionProduct';

export enum SignUpError {
  InvalidUserDetails = 'InvalidUserDetails',
  TermsAndConditionsNotAccepted = 'TermsAndConditionsNotAccepted',
  EmailExists = 'EmailExists',
  InvalidPaymentMethod = 'InvalidPaymentMethod',
  SpecificPaymentFailure = 'SpecificPaymentFailure',
  GenericPaymentFailure = 'GenericPaymentFailure',
  UnknownError = 'UnknownError',
}

export type SignUpStatus =
  'retrievingSubscriptionProduct' |
  'subscriptionProductNotFound' |
  'idle' |
  'signUpPending' |
  'signUpError' |
  'signUpSuccess';

export type SignUpState = {
  status: SignUpStatus;
  product: SubscriptionProduct;
  coupon: Coupon | null;
  couponCode: string;
  couponLookupStatus: 'idle' | 'pending' | 'invalid';
  currentStep: 'userDetailsAndConsents' | 'paymentMethod';
  error: SignUpError | null;
  cardErrorCode: string | null;
  cardErrorDeclineCode: string | null;
  errorDismissed: boolean;
  intent: {
    clientSecret: string,
  } | null,
  consents: {
    termsAndConditions: boolean;
    marketing: boolean;
    profiling: boolean;
  };
  userDetails: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };
  validUserDetailsProvided: boolean;
  paymentMethodRequired: boolean;
  paymentMethodProvided: boolean;
  paymentMethodTouched: boolean;
};

export type SignUpAction =
  | {
    type: 'setSubscriptionProduct';
    product: SubscriptionProduct;
  }
  | {
    type: 'setSubscriptionProductError';
  }
  | {
    type: 'setCouponCode';
    couponCode: string;
  }
  | {
    type: 'clearCoupon';
  }
  | {
    type: 'lookUpCoupon';
  }
  | {
    type: 'lookupCouponSuccess';
    coupon: Coupon;
  }
  | {
    type: 'lookupCouponError'
  }
  | {
    type: 'setUserDetails';
    userDetails: SignUpState['userDetails'];
  }
  | {
    type: 'setConsents';
    consents: SignUpState['consents'];
  }
  | {
    type: 'toggleValidUserDetailsProvided';
    provided: boolean;
  }
  | {
    type: 'togglePaymentMethodProvided';
    provided: boolean;
  }
  | {
    type: 'togglePaymentMethodTouched';
    touched: boolean;
  }
  | {
    type: 'setError';
    error: SignUpError;
  }
  | {
    type: 'dismissError';
  }
  | {
    type: 'setStep';
    targetStep: SignUpState['currentStep'];
  }
  | {
    type: 'setIntent',
    intent: { clientSecret: string }
  }
  | {
    type: 'signUpStarted';
  }
  | {
    type: 'signUpSuccess';
  }
  | {
    type: 'signUpError';
    error: any;
  }

export type SignUpDispatch = (action: SignUpAction) => void;
export type SignUpReducer = (state: SignUpState, action: SignUpAction) => SignUpState;
