export enum SessionEventType {
  Completed = 'completed',
  Liked = 'liked',
};

export interface SessionEvent {
  type: SessionEventType;
  status: boolean;
}

export const containsCompletedEvent = (events: SessionEvent[]) =>
  events.some(event => event.type === SessionEventType.Completed && event.status);

export const containsLikedEvent = (events: SessionEvent[]) =>
  events.some(event => event.type === SessionEventType.Liked && event.status);
