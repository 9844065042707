import { Button, Modal } from 'src/components';

import {
  CodeInput,
  LookupButton,
  ResetButton,
} from './styles';
import { useCouponLookup } from '../hooks/useCouponLookup';
import { useSignUpContext } from '../context';

export const CouponSection: React.FC = () => {
  const [{
    status,
    couponCode,
    coupon,
    couponLookupStatus,
  }, dispatch] = useSignUpContext();

  const lookUp = useCouponLookup();
  const reset = () => dispatch({ type: 'clearCoupon'});

  const signUpPending = status === 'signUpPending';

  const lookupButton = (
    <LookupButton
      label="Zastosuj"
      disabled={!couponCode || signUpPending}
      onClick={lookUp}
    />
  );

  const resetButton = (
    <ResetButton
      label="Usuń"
      disabled={signUpPending}
      onClick={() => dispatch({ type: 'clearCoupon'})}
    />
  );

  return (
    <div>
      <CodeInput
        label="Kupon zniżkowy"
        name="coupon"
        value={couponCode}
        disabled={!!coupon || signUpPending}
        onChange={e => dispatch({ type: 'setCouponCode', couponCode: e.target.value })}
        onKeyUp={e => e.code === 'Enter' && lookUp()}
        onKeyPress={e => e.code === 'Enter' && e.preventDefault()}
        InputProps={{
          endAdornment: coupon ? resetButton : lookupButton
        }}
      />

      <Modal open={couponLookupStatus === 'invalid'}>
        <Modal.Title>Błąd</Modal.Title>
        <Modal.Content>
          <p>Podany kod jest nieprawidłowy.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Wróć"
            onClick={reset}
          />
        </Modal.Actions>
      </Modal>
    </div>
  )
};
