import { useCallback } from 'react';

import { UsersService } from 'src/services';

import { useSignUpContext } from '../context';

export const useCouponLookup = () => {
  const [{
    couponCode,
    product,
  }, dispatch] = useSignUpContext();

  return useCallback(() => {
    if (!couponCode) {
      return;
    }
    dispatch({ type: 'lookUpCoupon' });
    UsersService.lookupCoupon(couponCode)
      .then(coupon => {
        if (!product || coupon.appliesTo.includes(product.id)) {
          return coupon;
        }
        throw new Error('Code does not apply to current product');
      })
      .then(coupon => dispatch({ type: 'lookupCouponSuccess', coupon }))
      .catch(() => dispatch({ type: 'lookupCouponError' }))
  }, [couponCode, product, dispatch]);
};
