import React from 'react';
import { InputAdornment } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from '../icons/chevron-down.svg';
import { ReactComponent as ClearIcon } from '../icons/x.svg';

export const EndAdornment = styled(InputAdornment).attrs({
  position: 'end'
})`
  && { margin: 5px 0 5px 4px }
  &:last-of-type { margin-right: 4px; }
`;

export const DropdownIndicator = styled.div.attrs({
  children: <ArrowIcon />,
})<{isOpen?: boolean;}>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 60%;
    height: 60%;
    fill: rgba(0,0,0,0.8);
    transition: transform .2s;
    transform: rotate(${p => p.isOpen ? 180 : 0}deg);
  }
`;

export const ClearOptions = styled.div.attrs({
  children: <ClearIcon />,
})`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background .2s;
  &:hover {
    background: rgba(0,0,0,0.03);
  }
  & svg {
    width: 60%;
    height: 60%;
    fill: rgba(0,0,0,0.8);
  }
`;

export const Listbox = styled.ul`
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.03);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0,0,0,0.1);
  }
`;

export const Paper = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 4%),
              0 2px 6px rgb(0 0 0 / 4%),
              0 0 1px rgb(0 0 0 / 4%);
`;
