import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import { StripeTextField } from './StripeTextField';

type Props = {
  errorIfEmpty?: boolean;
  disabled?: boolean;
  onReadyStateChange: (isReady: boolean) => void;
  onTouchedStateChange?: (isTouched: boolean) => void;
};

export const CardNumber: React.FC<Props> = ({
  errorIfEmpty = false,
  disabled,
  onReadyStateChange,
  onTouchedStateChange,
}) => (
  <StripeTextField
    label="Numer karty płatniczej"
    placeholder="1234 1234 1234 1234"
    stripeElement={CardNumberElement}
    variant="outlined"
    size="small"
    errorIfEmpty={errorIfEmpty}
    onReadyStateChange={onReadyStateChange}
    onTouchedStateChange={onTouchedStateChange}
    disabled={disabled}
  />
);

export const CardExpiry: React.FC<Props> = ({
  errorIfEmpty = false,
  disabled,
  onReadyStateChange,
  onTouchedStateChange,
}) => (
  <StripeTextField
    label="Data ważności"
    placeholder="MM / RR"
    stripeElement={CardExpiryElement}
    variant="outlined"
    size="small"
    errorIfEmpty={errorIfEmpty}
    onReadyStateChange={onReadyStateChange}
    onTouchedStateChange={onTouchedStateChange}
    disabled={disabled}
  />
);

export const CardCvc: React.FC<Props> = ({
  errorIfEmpty = false,
  disabled,
  onReadyStateChange,
  onTouchedStateChange,
}) => (
  <StripeTextField
    label="CVC"
    placeholder="Kod CVC"
    stripeElement={CardCvcElement}
    variant="outlined"
    size="small"
    errorIfEmpty={errorIfEmpty}
    onReadyStateChange={onReadyStateChange}
    onTouchedStateChange={onTouchedStateChange}
    disabled={disabled}
  />
);
