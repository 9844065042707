import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useUserState } from 'src/contexts/UserContext';

const useRedirect = () => {
  const history = useHistory();
  const { state } = useLocation() as { state: { from?: Location } };
  const { authStatus } = useUserState();

  useEffect(() => {
    if (authStatus === 'loggedIn') {
      history.replace(state?.from ?? '/');
    }
  }, [history, state, authStatus]);
};

export default useRedirect;
