import { useEffect, useState } from 'react';

import { legacyPlatformUrl } from 'src/config';
import { Button, Modal } from 'src/components';

import useLogin from './useLogin';

const redirectDelay = 5000;

const LegacyUserInfo: React.FC = () => {
  const { isLegacyPlatformUser } = useLogin();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (shouldRedirect) {
      window.location.replace(legacyPlatformUrl);
    }
  }, [shouldRedirect])

  useEffect(() => {
    if (!isLegacyPlatformUser) return;
    const timeout = setTimeout(
      () => setShouldRedirect(true),
      redirectDelay,
    );
    return () => clearTimeout(timeout);
  }, [isLegacyPlatformUser]);

  if (!isLegacyPlatformUser) return null;
  return (
    <Modal open={isLegacyPlatformUser}>
      <Modal.Title>Już chwileczkę…</Modal.Title>
      <Modal.Content>
        <p>Zabieram Cię na stronę do zalogowania.</p>
        <p>Usprawniamy naszą platformę. Dziękujemy za wyrozumiałość!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          label="OK"
          onClick={() => setShouldRedirect(true)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default LegacyUserInfo;
