import styled from 'styled-components';
import UnstyledInfoBox from 'src/components/InfoBox';

export const BoxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
`;

export const Form = styled.form`
  display: grid;
  width: 100%;
  row-gap: 24px;
`;

export const InfoBox = styled(UnstyledInfoBox)`
  width: 100%;
  margin-bottom: 24px;
`;
