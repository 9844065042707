import mixpanel from 'mixpanel-browser';
import { mixpanelKey } from 'src/config';

const init = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  mixpanel.init(
    mixpanelKey,
    { api_host: "https://api-eu.mixpanel.com" },
    '',
  );
}

const track = (...args: Parameters<typeof mixpanel.track>) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  try {
    mixpanel.track(...args)
  } catch (e) {
    console.error(e);
  }
};

const MixpanelService = {
  init,
  track,
};

export default MixpanelService;
