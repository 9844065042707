import { WidgetLayout } from 'src/components';
import styled from 'styled-components';

const Container = styled.section`
  color: ${p => p.theme.palette.text.low};
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  & > p:not(:first-child) {
    margin-top: 13px;
  }
`;

const Separator = styled.div`
  ${WidgetLayout.mediaQueries.horizontal} {
    border-top: ${p => p.theme.border};
    /* margin-top: 36px; */
    padding-top: 36px;
  }
  ${WidgetLayout.mediaQueries.vertical} {
    margin-top: 16px;
  }
`;

const content = {
  userDetailsAndConsents: <>Administratorem Twoich danych osobowych jest PortalYogi PSA z siedzibą w Warszawie, Zabłocińska 7/10, 01-627 Warszawa, zarejestrowana przez Sąd Rejonowy dla m.st. Warszawy XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000771462, email: <a href="mailto:pomoc@portalyogi.pl">pomoc@portalyogi.pl</a>. Twoje dane osobowe mogą być wykorzystywane w celu: Wykonania umowy lub do podjęcia działań przed jej zawarciem, odrębnie w celu realizacji zakupów - składania zamówień oraz realizacji zgłoszonych reklamacji; Marketingowym Administratora, a także w celach marketingowych partnerów Administratora, w tym w celach analitycznych i profilowania; Przeprowadzania badania opinii Klienta, na podstawie udzielonych odpowiedzi w ankiecie; Kontaktu - udzielenia informacji oraz opieki nad Klientem; Dochodzenia roszczeń związanych z zawartą umową, gdzie uzasadnionym interesem Administratora jest możliwość dochodzenia i ochrony przed ewentualnymi roszczeniami; Zautomatyzowanego przetwarzania danych osobowych, które ma na celu przygotowanie oferty uwzględniającej specjalne rabaty i promocje; Wypełniania obowiązków wynikających z przepisów prawa, w tym przepisów o rachunkowości oraz obowiązków podatkowych. Przysługuje Ci prawo dostępu do treści swoich danych, sprostowania, usunięcia, przenoszenia danych lub ograniczenia ich przetwarzania oraz zgłoszenia sprzeciwu.</>,
  paymentMethod: <>Zlecenie realizacji płatności: Zlecenie wykonuje Stripe Payments; Dane odbiorcy, tytuł oraz kwota płatności dostarczane są Stripe Payments przez odbiorcę; Zlecenie jest przekazywane do realizacji po otrzymaniu przez Stripe Payments Państwa wpłaty. Płatność udostępniana jest odbiorcy w ciągu automatycznie; Stripe Payments nie pobiera opłaty od realizacji usługi.</>,
};

export const Disclaimer: React.FC<{
  step: 'userDetailsAndConsents' | 'paymentMethod' 
}> = ({
  step,
}) => (
  <>
    <WidgetLayout.ContentWithoutMargin>
      <Separator />
    </WidgetLayout.ContentWithoutMargin>
    <WidgetLayout.ContentWithMargin>
      <Container>
        {content[step]}
      </Container>
    </WidgetLayout.ContentWithMargin>
  </>
);
