import { gtmId } from 'src/config';
import { CustomEnvironmentParams, GtmPurchaseEvent, SnippetParams } from 'src/models/GoogleTagManager';

export const DEFAULT_DOMAIN = 'https://www.googletagmanager.com'

const getDataLayerSnippet = () => 'window.dataLayer = window.dataLayer || [];'

const getIframeSnippet = (
  id: SnippetParams['id'],
  environment?: CustomEnvironmentParams
) => {
  let params = '';
  if (environment) {
    const { gtm_auth, gtm_preview } = environment
    params = `&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x`
  }
  return `<iframe src="${DEFAULT_DOMAIN}/ns.html?id=${id}${params}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`
}

const getGTMScript = (
  id: SnippetParams['id'],
  environment?: CustomEnvironmentParams
) => {
  let params = ''
  if (environment) {
    const { gtm_auth, gtm_preview } = environment;
    params = `+"&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x"`
  }
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '${DEFAULT_DOMAIN}/gtm.js?id='+i+dl${params};f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');
  `
}

const setupGTM = (params: SnippetParams) => {
  const getDataLayerScript = (): HTMLElement => {
    const dataLayerScript = document.createElement('script');
    dataLayerScript.innerHTML = getDataLayerSnippet();
    return dataLayerScript;
  }

  const getNoScript = (): HTMLElement => {
    const noScript = document.createElement('noscript');
    noScript.innerHTML = getIframeSnippet(params.id, params.environment);
    return noScript;
  }

  const getScript = (): HTMLElement => {
    const script = document.createElement('script');
    script.innerHTML = getGTMScript(params.id, params.environment);
    return script;
  }

  return {
    getDataLayerScript,
    getNoScript,
    getScript
  }
}

const init = (environment?: CustomEnvironmentParams) => {
  if (gtmId) {
    const gtm = setupGTM({ id: gtmId, environment });

    const dataLayerScript = gtm.getDataLayerScript();
    const script = gtm.getScript();
    const noScript = gtm.getNoScript();

    document.head.insertBefore(dataLayerScript, document.head.childNodes[0]!);
    document.head.insertBefore(script, document.head.childNodes[1]!);
    document.body.insertBefore(noScript, document.body.childNodes[0]!);
  }
}

const sendEvent = (data: GtmPurchaseEvent | { ecommerce: null }) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  } else {
    console.warn(`[GTMService] dataLayer does not exist.`);
  }
}

const GTMService = {
  init,
  sendEvent
}

export default GTMService;
