function stringToColor(value: string) {
  let hash = 0;
  let i = 0;

  for (i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i++) {
    const num = (hash >> (i * 8)) & 0xff;
    color += num.toString(16).padStart(2, '0');
  }

  return color;
}

export function stringAvatar(name: string) {
  const [a, b] = name.split(/ +/, 2) as [string, string?];
  return {
    style: {
      backgroundColor: stringToColor(name),
    },
    children: `${a[0]}${b != null ? b[0] : a[1]}`,
  };
}
