import { isNativePlatform } from 'src/config';
import { Switch, Redirect, useHistory, Route } from 'react-router-dom';

import { useScrollToTop } from 'src/hooks';
import Menu from './Menu';
import {
  Summary,
  ProfileDetails,
  Subscription,
  Payments,
} from './pages';
import {
  Layout,
  MenuArea,
  ContentArea,
} from './styles';

export const Profile: React.FC = () => {
  const {location} = useHistory();
  useScrollToTop([location.pathname]);
  return (
    <Layout>
      <MenuArea>
        <Menu />
      </MenuArea>
      <ContentArea>
        <Switch>
          <Route path="/me/summary" component={Summary} />
          <Route path="/me/profile" component={ProfileDetails} />
          {!isNativePlatform && (
            <>
              <Route path="/me/subscription" component={Subscription} />
              <Route path="/me/payments" component={Payments} />
            </>
          )}
          <Route path="*" render={() => <Redirect to="/me/summary" /> } />
        </Switch>
      </ContentArea>
    </Layout>
  );
};
