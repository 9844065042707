import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { DurationIndicator } from 'src/components';

export const Card = styled(Link)`
  display: block;
  width: 100%;
`;

export const Layout = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-areas: "image image"
                       "header header"
                       "coach coach"
                       "separator separator"
                       "difficulty duration";
`;

export const Label = styled.span``;

export const Image = styled.div<{mode: 'horizontal' | 'vertical'}>`
  grid-area: image;
  background: ${p => p.theme.palette.gray[100]};
  position: relative;
  padding-top: ${p => p.mode === 'vertical' ? 133 : 56.25}%;

  &>img {
    position: absolute;
    top: 0;
    display: block;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
    &[src=""] {
      display: none;
    }
  }
`;

export const Header = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const Overline = styled.span<{highlight?: boolean;}>`
  display: block;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${p => p.theme.palette.text.low};
  align-self: flex-start;
  ${p => p.highlight && css`
    background: ${p => transparentize(0.7, p.theme.palette.primary.main)};
    color: ${p => p.theme.palette.text.high};
    padding: 0 5px;
  `}
`;

export const Title = styled.h3`
  display: block;
  font-weight: bold;
  font-size: 19px;
  line-height: 120%;
  letter-spacing: 0.3px;  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${p => p.theme.palette.text.high};
  margin: 8px 0 4px 0;
`;

export const Underline = styled.span`
  display: block;
  font-weight: normal;
  font-size: 13px;
  line-height: 148%;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Coach = styled.div`
  grid-area: coach;
  display: flex;
  align-items: center;
  margin: 16px 0;

  & ${Label} {
    display: block;
    margin-left: 8px;
    font-weight: normal;
    font-size: 13px;
    line-height: 148%;
    letter-spacing: 0.17px;
    color: ${p => p.theme.palette.text.medium};
  }
`;

export const Separator = styled.div`
  grid-area: separator;
  border-bottom: ${p => p.theme.border};
  margin-bottom: 16px;
`;

export const Difficulty = styled.div`
  & ${Label} {
    display: block;
    font-size: 13px;
    line-height: 148%;
    letter-spacing: 0.17px;
    color: ${p => p.theme.palette.text.low};
    margin: 0 0 8px 0;
  }
`;

export const Duration = styled(props => <DurationIndicator size="big" {...props}/>)`
  justify-self: flex-end;
  padding: 8px;
  border-radius: 8px;
  background: ${p => transparentize(0.9, p.theme.palette.primary.main)};
`;
