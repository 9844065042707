export type ExtraFileId = Branded<string, 'extrasId'>;

export interface ExtraFileDto {
  id: ExtraFileId;
  createdDate: string;
  name: string;
  fileExtension: string;
  url: string | null;
}

export interface ExtraFile {
  id: ExtraFileId;
  createdDate: Date;
  name: string;
  fileExtension: string;
  url: string | null;
}

export const transformExtraFileDto = (dto: ExtraFileDto): ExtraFile => ({
  id: dto.id,
  createdDate: new Date(dto.createdDate),
  name: dto.name,
  fileExtension: dto.fileExtension,
  url: dto.url,
});
