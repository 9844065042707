import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useWelcomeViewContext } from 'src/contexts/WelcomeViewContext';

export const useRedirectIfVisited = () => {
  const { showWelcomeView, toggleWelcomeView } = useWelcomeViewContext();
  const history = useHistory();

  useEffect(() => {
    if (!showWelcomeView) {
      history.replace('/home');
    }
    toggleWelcomeView(false);
    // Deps array left empty on purpose:
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
