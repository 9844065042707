import { useQuery } from 'react-query';
import { StylesService } from 'src/services';

export const useStyles = () => {
  const styles = useQuery(
    'styles',
    StylesService.getAll,
    {
      staleTime: Infinity
    }
  );
  return styles.data ?? null;
};
