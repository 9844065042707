import styled, { css } from 'styled-components';
import { ReactComponent as ArrowIcon } from './arrow.svg';

export const Carousel = styled.div`
  max-width: ${p => p.theme.maxContentWidth}px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;
// TODO: restore
// @media only screen and (max-width: 799px) {
//   display: none;
//   margin: 0;
// }

export const Item = styled.div.attrs<{active: boolean}>(p => ({
  'aria-hidden': !p.active,
}))<{active: boolean}>`
  grid-row-start: 1;
  grid-column-start: 1;
  pointer-events: ${p => p.active ? 'auto' : 'none'};
  opacity: ${p => p.active ? 1 : 0};
  transition: opacity 1s;
`;

export const Navigation = styled.nav`
  height: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  top: -32px;
  margin-bottom: -32px;
`;

export const NavigationItem = styled.button<{active: boolean}>`
  background: ${p => p.active
    ? p.theme.palette.primary.dark
    : p.theme.palette.primary.main};
  transition: background 1s;
  opacity: ${p => p.active ? 1 : 0.6};
  border: none;
  outline: none;
  display: block;
  width: 48px;
  height: 4px;
  margin: 0 4px;
`;

export const Arrow = styled.button.attrs(p => ({
  children: <ArrowIcon/>,
}))<{direction: 'prev' | 'next'}>`
  position: absolute;
  z-index: 10;
  width: 64px;
  height: 64px;
  cursor: pointer;
  outline: none;
  border-radius: 50%;

  padding: 0;
  top: 50%;
  ${p => p.direction === 'prev' && css`
    left: 0;
    transform: rotate(180deg) translate(0, 50%);
  `}
  ${p => p.direction === 'next' && css`
    right: 0;
    transform: translate(0, -50%);
  `}

  border: none;
  background: none;

  @media only screen and (min-width: 800px) {
    border: ${p => p.theme.border};
    background: ${p => p.theme.palette.common.white};
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
                0px 2px 6px 0px rgba(0, 0, 0, 0.04),
                0px 10px 20px 0px rgba(0, 0, 0, 0.04);
    ${p => p.direction === 'prev' && css`
      left: 0;
      transform: rotate(180deg) translate(50%, 50%);
    `}
    ${p => p.direction === 'next' && css`
      right: 0;
      transform: translate(50%, -50%);
    `}
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    transition: fill 0.2s;
    fill: ${p => p.theme.palette.primary.dark};
  }
  &:hover svg {
    fill: ${p => p.theme.palette.primary.main};
  }
`;
