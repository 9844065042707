import { useInfiniteQuery } from 'react-query';
import { Waypoint } from 'react-waypoint';

import { ActivityService } from 'src/services';
import { BackLink, Header, SessionCard } from 'src/components';
import { arrayOfNumbers } from 'src/utils/array';

import { List, Item } from './styles';
import { useScrollToTop } from 'src/hooks';

const itemsPerPage = 6;

export const Visits: React.FC = () => {
  useScrollToTop();
  const query = useInfiniteQuery(
    'visited',
    fetchVisits,
    {
      getNextPageParam: ({page, pageCount}) => page < pageCount ? page : false,
      refetchOnWindowFocus: false,
    },
  );

  const items = query.data?.pages.flatMap(page => page.data) ?? [];
  const fetchNextPage = query.hasNextPage && !query.isFetchingNextPage
    ? () => query.fetchNextPage()
    : () => {};
  const placeholderCount = (query.isFetching || query.hasNextPage) ? itemsPerPage : 0; 
  const cardsCount = items.length + placeholderCount;

  return (
    <>
      <BackLink label="Twoja praktyka" to="/me/summary" />
      <Header
        title="Twoja aktywność"
        as="h1"
      />
      <List>
        {arrayOfNumbers(cardsCount).map(index => (
          <Item key={index}>
            {
              index > cardsCount - (itemsPerPage * 2) &&
              <Waypoint onEnter={fetchNextPage} />
            }
            <SessionCard
              mode="horizontal"
              session={items[index]}
              order={index % itemsPerPage}
              backNavigationInfo={{
                link: '/activity',
                linkLabel: 'Twoja aktywność',
              }}
            />
          </Item>
        ))}
      </List>
    </>
  );
};

const fetchVisits = ({ pageParam = 0 }) =>
  ActivityService.getVisitedSessionsAndChallenges(pageParam + 1, itemsPerPage);
