import { Avatar, DifficultyIndicators } from 'src/components';

import {
  Card,
  Layout,
  Label,
  Image,
  Header,
  Overline,
  Title,
  Underline,
  Coach,
  Separator,
  Difficulty,
  Duration,
} from './styles/main';

import LikeButton from './LikeButton';

export const SessionCardActual: React.FC<{
  contentType: 'session' | 'challenge' | 'challengeSession';
  link: string;
  title: string;
  underline: string;
  image?: string;
  styleName?: string;
  type?: string;
  coach: string;
  coachImage: string;
  difficulty: number;
  duration?: number;
  mode: 'horizontal' | 'vertical';
  isLiked: boolean;
  onToggleLike?: () => void;
}> = ({
  contentType,
  link,
  title,
  underline,
  image = '',
  styleName = '',
  coach,
  coachImage,
  difficulty,
  duration,
  mode,
  isLiked,
  onToggleLike,
}) => (
  <Card to={link}>
    <Layout>
      <LikeButton liked={isLiked} onToggle={onToggleLike} />
      <Image mode={mode}><img src={image} alt=""/></Image>
      <Header>
        <Overline highlight={contentType === 'challenge'}>
          {contentType === 'challenge' ? 'Wyzwanie' : styleName}
        </Overline>
        <Title>{title}</Title>
        <Underline>{underline}</Underline>
      </Header>
      <Coach>
        <Avatar size="small" imageSrc={coachImage} />
        <Label>{coach}</Label>
      </Coach>
      <Separator />
      <Difficulty>
        <Label>Poziom</Label>
        <DifficultyIndicators difficulty={difficulty}/>
      </Difficulty>
      <Duration duration={duration} />
    </Layout>
  </Card>
);
