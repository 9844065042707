import { SubscriptionProductId } from 'src/models/SubscriptionProduct';

export type CouponCode = Branded<string, 'couponCode'>;
export type CouponId = Branded<string, 'couponId'>;

export enum CouponDuration {
  Once = 'once',
  Forever = 'forever',
};

export interface CouponDto {
  code: CouponCode;
  duration: CouponDuration;
  amountOff: number | null;
  percentOff: number | null;
  appliesTo: Array<{ id: SubscriptionProductId }>
}

export interface Coupon {
  code: CouponCode;
  duration: CouponDuration;
  amountOff: number | null;
  percentOff: number | null;
  appliesTo: SubscriptionProductId[];
}

export class Coupon {
  static fromDto = (dto: CouponDto): Coupon => ({
    code: dto.code,
    duration: dto.duration,
    amountOff: dto.amountOff,
    percentOff: dto.percentOff,
    appliesTo: dto.appliesTo.map(product => product.id),
  });
}
