import { useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

export const PwaConfig = {
  onSuccess: (registration: ServiceWorkerRegistration) => {},
  onUpdate: (registration: ServiceWorkerRegistration) => {},
};

type AppNotifyState = {
  open: boolean;
  message?: string;
  type?: 'updateReady' | 'updateSuccess';
};

export function AppNotify() {
  const [ state, setState ] = useState<AppNotifyState>({ open: false });
  const { open, message } = state;

  PwaConfig.onSuccess = () => {
    setState({ message: 'Masz najnowszą wersję aplikacji', type: 'updateSuccess', open: true });
  };

  PwaConfig.onUpdate = () => {
    setState({ message: 'Dostępna jest nowa wersja aplikacji', type: 'updateReady', open: true });
  };

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ open: false });
  };

  const handleUpdate = () => {
    if (state.type === 'updateReady') {
      document.location.reload();
    }
    setState({ open: false });
  }

  const action = (
    <>
      <Button color='secondary' size='small' onClick={handleUpdate}>
        OK
      </Button>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      message={message}
      action={action}
    />
  );
}
