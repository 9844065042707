import { SessionId } from 'src/models/Session';

export type ChallengeId = SessionId;
export type ChallengeStepId = Branded<string, 'challengeStepId'>;
export type ChallengeStepContentId = Branded<string, 'challengeStepContentId'>;

export enum ChallengeStepType {
  Session = 'session',
  Page = 'page',
  Lecture = 'lecture',
}

export * from './Challenge';
export * from './ChallengeSession';
export * from './ChallengeStep';
export * from './ChallengeSteps';
export * from './ChallengeQuery';
