import { CouponCode } from './Coupon';

export interface SubscriptionPaymentDto {
  id: string;
  amount: number;
  subtotal: number | null;
  couponCode: CouponCode | null;
  invoiceId: string;
  invoiceUrl: string;
  updatedDate: string;
  subscriptionSnapshot: {
    subscriptionProduct: {
      name: string;
      description: string;
    };
  };
}

export interface SubscriptionPayment {
  id: string;
  amount: number;
  subtotal: number;
  couponCode: CouponCode | null;
  invoiceId: string | null;
  invoiceUrl: string | null;
  date: Date;
  subscription: {
    name: string;
    description: string;
  };
}

export class SubscriptionPayment {
  static fromDto = (dto: SubscriptionPaymentDto): SubscriptionPayment => ({
    id: dto.id,
    amount: dto.amount,
    subtotal: dto.subtotal === null ? dto.amount : dto.subtotal,
    couponCode: dto.couponCode,
    invoiceId: dto.invoiceId || null,
    invoiceUrl: dto.invoiceUrl || null,
    date: new Date(dto.updatedDate),
    subscription: {
      name: dto.subscriptionSnapshot.subscriptionProduct.name,
      description: dto.subscriptionSnapshot.subscriptionProduct.description,
    },
  })
}
