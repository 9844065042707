import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DurationIndicator } from 'src/components';

export const Card = styled(Link)`
  display: block;
  width: 100%;
`;

export const Layout = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 170px 12px auto 16px 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: "image . overline overline overline"
                       "image . title title title"
                       "image . underline underline underline"
                       "image . difficulty separator duration";
`;


export const Image = styled.div`
  grid-area: image;
  background: ${p => p.theme.palette.gray[100]};
  position: relative;
  padding-top: 56.25%;

  &>img {
    position: absolute;
    top: 0;
    display: block;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
    &[src=""] {
      display: none;
    }
  }
`;

export const Overline = styled.span`
  grid-area: overline;
  display: block;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${p => p.theme.palette.text.low};
  align-self: flex-start;
`;

export const Title = styled.h3`
  grid-area: title;
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.3px;  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${p => p.theme.palette.text.high};
  margin: 6px 0 6px 0;
`;

export const Underline = styled.span`
  grid-area: underline;
  display: block;
  font-weight: normal;
  font-size: 13px;
  line-height: 148%;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.low};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Separator = styled.div`
  grid-area: separator;
  border-right: ${p => p.theme.border};
  justify-self: center;
`;

export const Difficulty = styled.div`
  grid-area: difficulty;
  align-self: center;
`;

export const Duration = styled(props => <DurationIndicator size="small" {...props}/>)`
  grid-area: duration;
`;
