import { Capacitor } from '@capacitor/core';

import { StyleId } from 'src/models/Style';

export const apiUrl = process.env.REACT_APP_API_URL ?? '';
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '';
export const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY ?? '';
export const sentryRelease = process.env.REACT_APP_SENTRY_RELEASE ?? '';
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN ?? '';
export const environment = process.env.REACT_APP_ENVIRONMENT ?? 'unknown';
export const legacyPlatformUrl = process.env.REACT_APP_LEGACY_PLATFORM_URL ?? '';
export const meditationStyleId = process.env.REACT_APP_MEDITATION_STYLE_ID ?? '' as StyleId;
export const pilatesTypeId = process.env.REACT_APP_PILATES_TYPE_ID ?? '' as StyleId;
export const gtmId = process.env.REACT_APP_GTM_ID ?? '';
export const pinterestPixelTagId = environment === 'production' ? '2613108337804' : '';

export const isNativePlatform = Capacitor.isNativePlatform();
export const isIOS = Capacitor.getPlatform() === 'ios';
export const isAndroid = Capacitor.getPlatform() === 'android';
