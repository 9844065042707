import styled from 'styled-components';
import { Collapse } from '@mui/material';

import { ChallengeStep, ChallengeStepId } from 'src/models/Challenge';
import { ToggleArrow } from 'src/components';

import { Step } from './Step';

export const Group: React.FC<{
  title: string;
  steps: ChallengeStep[];
  currentStep?: ChallengeStepId;
  composeStepUrl: (subpath: string) => string;
  onToggle: () => void;
  isOpen: boolean;
}> = ({
  title,
  steps,
  currentStep,
  composeStepUrl,
  onToggle,
  isOpen,
}) => (
  <Container>
    <TitleWrapper onClick={onToggle}>
      <Title>{title}</Title>
      <ToggleArrow open={isOpen}/>
    </TitleWrapper>
    <Collapse in={isOpen}>
      <Contents>
        {steps.map(step => (
          <Step
            key={step.id}
            title={step.title}
            duration={step.session?.duration}
            link={composeStepUrl(step.id)}
            active={step.id === currentStep}
            completed={step.isCompleted}
          />
        ))}
      </Contents>
    </Collapse>
  </Container>
);

const Container = styled.div`
  border-top: ${p => p.theme.border};
`;

const TitleWrapper = styled.h3`
  cursor: pointer;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const Contents = styled.div``;
