import ReactPaginate from 'react-paginate';

import styles from './Pagination.module.scss';

export const Pagination: React.FC<{
  page: number;
  pageCount: number;
  onPageChange: (selectedPage: number) => void;
}> = ({
  page,
  pageCount,
  onPageChange
}) => pageCount > 1 ? (
  <ReactPaginate
    forcePage={page - 1}
    initialPage={page - 1}
    disableInitialCallback={true}
    previousLabel=''
    nextLabel=''
    breakLabel='...'
    breakClassName={styles.PaginateBreak}
    pageCount={pageCount}
    marginPagesDisplayed={1}
    pageRangeDisplayed={2}
    onPageChange={({ selected }) => onPageChange(selected)}
    containerClassName={styles.PaginateContainer}
    previousClassName={styles.PaginatePrevious}
    nextClassName={styles.PaginateNext}
    pageLinkClassName={styles.PaginatePage}
    activeClassName={styles.PaginateActive}
    disabledClassName={styles.PaginateDisabled}
  />
) : null;
