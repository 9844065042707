import { SessionFilters } from './SessionFilters';
// import { CoachId } from './Coach';
// import { StyleId } from './Style';
// import { TagId } from './Tag';
// import { TypeId } from './Type';

/**
 * All available filters that are relevant for homepage sections along
 * with their names. Used in admin panel (homepage section editor).
 */
// export type FilterOptions = {
//   [FilterTrait.Coach]: {name: string; value: CoachId}[],
//   [FilterTrait.Difficulty]: {name: string; value: number}[],
//   [FilterTrait.Style]: {name: string; value: StyleId}[],
//   [FilterTrait.Tag]: {name: string; value: TagId}[],
//   [FilterTrait.Type]: {name: string; value: TypeId}[],
// };

export enum StandardHomepageSectionType {
  Teachers = 'teachers',
  Challenges = 'challenges',
  LatestPractices = 'latest-practices',
};

/**
 * Non-configurable section. It's order can be changed relative to
 * all other sections, but it not customizable otherwise.
 */
export type StandardHomepageSection = {
  isCustom: false;
  type: StandardHomepageSectionType;
};

/**
 * Section with a custom filter that is used for pulling specific
 * type of sessions.
 */
export type CustomHomepageSection = {
  isCustom: true;
  title: string;
  underline: string;
  // filterType: keyof FilterOptions;
  // filterValues: FilterOptions[keyof FilterOptions][number]['value'][];
  filters: SessionFilters;
};

export type HomepageSection = CustomHomepageSection | StandardHomepageSection;
