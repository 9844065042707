import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CoachesService } from 'src/services';

export const useCoaches = ({ withHidden = false } = {} as { withHidden: boolean }) => {
  const coaches = useQuery(
    'coaches',
    CoachesService.getAll,
    {
      staleTime: Infinity
    }
  );
  return useMemo(() => {
    if (coaches.data != null && withHidden === false) {
      return coaches.data.filter(c => c.hidden !== true)
    }
    return coaches.data ?? null;
  }, [coaches.data, withHidden]);
};
