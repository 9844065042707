import { Difficulty } from 'src/models/Difficulty';
import { FilterTrait } from 'src/models/SessionFilters';

const difficulties: Difficulty[] = [
  {
    value: '1',
    name: 'Dla początkujących',
    trait: FilterTrait.Difficulty,
  },
  {
    value: '2',
    name: '',
    trait: FilterTrait.Difficulty,
  },
  {
    value: '3',
    name: '',
    trait: FilterTrait.Difficulty,
  },
  {
    value: '4',
    name: '',
    trait: FilterTrait.Difficulty,
  },
  {
    value: '5',
    name: 'Dla zaawansowanych',
    trait: FilterTrait.Difficulty,
  },
];

export const useDifficulties = () => difficulties;
