import { FilterCond } from './FilterCond';
import { FilterTrait } from './FilterTrait';
import { SessionFiltersByTrait } from './SessionFiltersByTrait';

/**
 * An ordered collection of filters relevant for querying session-like entities
 * (sessions, challenges).
 * 
 * Note that the ordering is not relevant for querying itself,
 * but it's important for the UX: we can present all conditions in the same order
 * as the user specified them.
 */
export interface SessionFilters extends Array<FilterCond> {};

export interface SessionExcludeFilters {
  [FilterTrait.Style]: string;
  [FilterTrait.Type]: string;
}

export class SessionFilters {

  static groupByTrait(filters: SessionFilters): SessionFiltersByTrait {
    const empty: SessionFiltersByTrait = {
      [FilterTrait.Coach]: [],
      [FilterTrait.Equipment]:  [],
      [FilterTrait.Style]: [],
      [FilterTrait.Tag]: [],
      [FilterTrait.Type]: [],
      [FilterTrait.Difficulty]: [],
      [FilterTrait.Duration]: [],
      [FilterTrait.Phrase]: [],
    };
    return filters.reduce((grouped, cond) => {
      return {
        ...grouped,
        [cond.trait]: [
          ...grouped[cond.trait],
          cond.value,
        ],
      }
    }, empty);
  }

  static addFilter(filters: SessionFilters, cond: FilterCond): SessionFilters {
    return filters;
  }

  static removeFilter(filters: SessionFilters, cond: FilterCond): SessionFilters {
    return filters;
  }

}

