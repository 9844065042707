import { useQuery, useQueryClient } from 'react-query';

import { SessionsService } from 'src/services';
import { SessionFilters } from 'src/models/SessionFilters';
import { SessionQuery } from 'src/models/SessionQuery';
import { arrayOfNumbers } from 'src/utils/array';
import { Header, SessionCard } from 'src/components';

import {
  Wrapper,
  Carousel,
  Item,
} from './styles';

export const SessionsCarousel: React.FC<{
  title: string;
  underline: string;
  passMetadataToSessionsLibrary?: boolean;
  filters?: SessionFilters;
  maxItems?: number;
  viewAllLabel?: string;
}> = ({
  title,
  underline,
  passMetadataToSessionsLibrary = true,
  filters = [],
  maxItems = 9,
  viewAllLabel = 'Wszystkie',
}) => {
  const queryClient = useQueryClient();
  const { data: sessions = [], isSuccess } = useQuery(
    ['SessionsCarousel', maxItems, ...filters],
    () => SessionsService.getMany({
      filters,
      limit: maxItems,
    }).then(result => result.data),
    {
      staleTime: 0,
    }
  );

  // Unless data is ready, show <maxItems> placeholders
  const actualCount = isSuccess ? sessions.length : null;
  const displayCount = actualCount ?? maxItems;

  const params = SessionQuery.serializeToUrlParams({
    filters,
    ...passMetadataToSessionsLibrary ? { title, underline, } : {},
  });
  const link = `/sessions${params ? `?${params}` : ''}`;

  const backInfo = {
    link,
    linkLabel: filters.length ? 'Wyniki wyszukiwania' : 'Wszystkie sesje',
  };

  return (
    <Wrapper>
      <Header
        title={title}
        underline={underline}
        link={`/sessions${params ? `?${params}` : ''}`}
        linkLabel={viewAllLabel}
        as="h2"
      />
      <Carousel>
        {arrayOfNumbers(displayCount).map(index => (
          <Item
            isFirst={index === 0}
            isLast={index === sessions.length - 1}
            key={index}
          >
            <SessionCard
              mode="horizontal"
              session={sessions[index]}
              backNavigationInfo={backInfo}
              onLikeToggleSuccess={() => {
                queryClient.invalidateQueries(['SessionsCarousel']);
                queryClient.invalidateQueries(['visited', 'forHomepage']);
              }}
            />
          </Item>
        ))}
      </Carousel>
    </Wrapper>
  );
};
