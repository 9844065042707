import { Redirect } from 'react-router-dom';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { FullPageLoader } from '../../components';
import { StripeProvider } from '../../features/StripeCardForm';
import { useSignUpContext } from './context';
import { SignUpProvider } from './context';
import { useRetrieveSubscriptionProduct } from './hooks/useRetrieveSubscriptionProduct';
import SignUp from './SignUp';
import { OnePaymentSignUp } from './OneTimePayment';

function SignUpWithStripe() {
  useRetrieveSubscriptionProduct();
  const [{ product, status, intent }] = useSignUpContext();

  if (status === 'retrievingSubscriptionProduct') {
    return <FullPageLoader />
  }

  if (status === 'subscriptionProductNotFound' || !product) {
    return <Redirect to="/" />
  }

  const options: StripeElementsOptions = {
    clientSecret: intent?.clientSecret
  };

  return (
    <StripeProvider options={options} key={intent?.clientSecret}>
      {product.endBehaviour === 'one_time' ? (<OnePaymentSignUp />) : (<SignUp />)}
    </StripeProvider>
  );
}

const SignUpWithContext: React.FC = () => (
  <SignUpProvider>
    <SignUpWithStripe />
  </SignUpProvider>
);

export default SignUpWithContext;
