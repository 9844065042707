import { useQuery, useQueryClient } from 'react-query';

import { ActivityService } from 'src/services';
import { arrayOfNumbers } from 'src/utils/array';
import { Header, SessionCard } from 'src/components';

import {
  Wrapper,
  Carousel,
  Item,
} from './styles';

export const ActivityCarousel: React.FC<{
  maxItems?: number;
}> = ({
  maxItems = 9,
}) => {
  const queryClient = useQueryClient();
  const { data: items = [], isSuccess } = useQuery(
    ['visited', 'forHomepage', maxItems],
    () => ActivityService.getVisitedSessionsAndChallenges(0, maxItems).then(response => response.data),
    {
      staleTime: 0,
    }
  );

  // Unless data is ready, show <maxItems> placeholders
  const actualCount = isSuccess ? items.length : null;
  const displayCount = actualCount ?? maxItems;

  return (
    <Wrapper>
      <Header
        title="Twoja aktywność"
        link={'/activity'}
        linkLabel="Pokaż wszystko"
        as="h2"
      />
      <Carousel>
        {arrayOfNumbers(displayCount).map(index => (
          <Item
            isFirst={index === 0}
            isLast={index === items.length - 1}
            key={index}
          >
            <SessionCard
              mode="horizontal"
              session={items[index]}
              backNavigationInfo={{
                link: '/activity',
                linkLabel: 'Twoja aktywność',
              }}
              onLikeToggleSuccess={() => {
                queryClient.invalidateQueries(['SessionsCarousel']);
                queryClient.invalidateQueries(['ChallengesCarousel']);
                queryClient.invalidateQueries(['visited', 'forHomepage']);
              }}
            />
          </Item>
        ))}
      </Carousel>
    </Wrapper>
  );
};
