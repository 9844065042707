import styled from 'styled-components';

export const OnboardingSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  `

export const OnboardingImageContainer = styled.div<{img: string}>`
  height: 447px;
  width: 100%;
  background-image: url("${props => props.img}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  `

export const OnboardingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin-top: 40px;
`

export const OnboardingTitle = styled.p`
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  
  margin-bottom: 20px;
`

export const OnboardingSubtitle = styled.p`
  max-width: 173px;
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  
  margin-bottom: 20px;
`
