import { useQuery } from 'react-query';
import { TypesService } from 'src/services';

export const useTypes = () => {
  const types = useQuery(
    'types',
    TypesService.getAll,
    {
      staleTime: Infinity
    }
  );
  return types.data ?? null;
};
