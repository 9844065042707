import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SwiperSlide} from 'swiper/react';

export const OnboardingSwipeSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 400px;
  background-color: #649693;
`

export const OnboardingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 550px;
  background-color: #649693;
`;

export const OnboardingButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 100;
`;

export const OnboardingButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OnboardingSlideBulletsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 13px;
`;

export const OnboardingSlideBulletElement = styled('span')<{isActive: boolean}>`
  width: 11px;
  height: 11px;
  background-color: ${props => props.isActive ? '#FFFFFF' : 'rgba(255, 255, 255, 0.25)'};
  border-radius: 50%;
`;

export const OnboardingButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 48px;
  background: #000000;
  
  border: none;
  border-radius: 25px;

  margin: 32px 0 52px 0;
  
  &:active {
    background: rgba(0, 0, 0, 0.75);
  }
`

export const OnboardingButtonText = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #FFFFFF;
`;

