import styled from 'styled-components';

import { ReactComponent as Svg } from './completedIcon.svg';

export const Icon = styled(Svg)`
  fill: currentColor;
  width: 100%;
  height: 100%;
`;

export const Button = styled.button<{ completed?: boolean }>`
  display: block;
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 4px;
  border: ${p => p.theme.border};
  outline: none;
  cursor: pointer;
  background: transparent;

  transition: color .2s;
  color: ${p => p.completed ? p.theme.palette.primary.dark : p.theme.palette.text.medium};

  ${Icon} {
    position: absolute;
    top: 9px;
    left: 9px;
    width: 18px;
    height: 18px;
    transition: fill .2s, opacity .2s;
  }

  &:hover ${Icon} {
    fill: ${p => p.completed ? p.theme.palette.primary.main : p.theme.palette.primary.dark};
  }
`;

const CompletedButton: React.FC<{
  completed: boolean;
  onToggle: () => void;
}> = ({
  completed,
  onToggle,
}) => {
  return (
    <Button
      completed={completed}
      onClick={onToggle}
    >
      <Icon />
    </Button>
  );
}

export default CompletedButton;
