
import { transformCoachDto } from 'src/models/Coach';
import { transformExtraFileDto } from 'src/models/ExtraFile';
import { BaseSessionDto, Session, SessionDto } from 'src/models/Session';
import { transformVideoDto } from 'src/models/video';
import { ChallengeSteps, ChallengeStepsDto } from './ChallengeSteps'
import { containsCompletedEvent, containsLikedEvent } from '../SessionEvent';

// export interface ChallengeEntity {
//   id: string;
//   version: number;
//   createdDate: Date;
//   updatedDate: Date;
//   title: string;
//   normalizedTitle: string;
//   underline: string;
//   difficulty: number;
//   primaryDescription: string;
//   secondaryDescription: string;
//   published: boolean;
//   duration: number;

//   // equipment: EquipmentEntity[];
//   // video: VideoEntity;
//   // extras: ExtrasEntity[];
//   // horizontalThumbnail: FileEntity;
//   steps: ChallengeStepEntity[];
//   // verticalThumbnail: FileEntity;
//   // userActivity: UserActivityEntity[];
//   // coach: CoachEntity[];
//   // tag: TagEntity[];
//   // user: UserEntity;
// }

// export interface ChallengeStepEntity {
//   id: string;
//   version: number;
//   createdDate: Date;
//   updatedDate: Date;
//   // user: UserEntity;
//   // type: ChallengeStepType;
//   order: number;
//   // challenge: ChallengeEntity;
//   group: string;
//   challengeSession: ChallengeSessionEntity;
//   // userActivity: UserActivityEntity[];
// }

// export interface ChallengeSessionEntity {
//   id: string;
//   version: number;
//   createdDate: Date;
//   updatedDate: Date;
//   user: UserEntity;
//   title: string;
//   normalizedTitle: string;
//   underline: string;
//   difficulty: number;
//   primaryDescription: string;
//   secondaryDescription: string;
//   published: boolean;
//   coach: CoachEntity[];
//   tag: TagEntity[];
//   equipment: EquipmentEntity[];
//   video: VideoEntity;
//   extras: ExtrasEntity[];
//   horizontalThumbnail: FileEntity;
//   style: StyleEntity[];
//   type: TypeEntity[];
//   // challengeStep: ChallengeStepEntity;
//   // userActivity: UserActivityEntity[];
// }

export interface Challenge extends Omit<
  Session, 'contentType' | 'style' | 'type'
> {
  contentType: 'challenge';
  steps: ChallengeSteps;
  horizontalThumbnail: string | null;
  verticalThumbnail: string | null;
  duration: number | null;
}

export interface BaseChallengeDto extends BaseSessionDto {
  steps: ChallengeStepsDto;
}

export interface ChallengeDto extends SessionDto {
  steps?: ChallengeStepsDto;
  horizontalThumbnail?: { url: string | null; };
  verticalThumbnail?: { url: string | null; };
  duration?: number;
}

export class Challenge {
  static fromDto = (dto: ChallengeDto, { excludeUnpublishedSteps = false } = {}): Challenge => ({
    id: dto.id,
    contentType: 'challenge',
    title: dto.title ?? '',
    underline: dto.underline ?? '',
    difficulty: dto.difficulty,
    primaryDescription: dto.primaryDescription ?? '',
    secondaryDescription: dto.secondaryDescription ?? '',
    published: dto.published,
    coach: dto.coach?.[0] ? transformCoachDto(dto.coach?.[0]) : null,
    // style: dto.style?.[0] ?? null,
    // type: (dto.type?.[0] ?? null),
    tag: dto.tag ?? [],
    equipment: dto.equipment ?? [],
    video: dto.video ? transformVideoDto(dto.video) : null,
    createdDate: new Date(dto.createdDate),
    updatedDate: new Date(dto.updatedDate),
    isCompleted: containsCompletedEvent(dto.userActivity),
    isLiked: containsLikedEvent(dto.userActivity),
    extras: (dto.extras ?? [])
      .map(transformExtraFileDto)
      .sort((a, b) => a.createdDate.getTime() - b.createdDate.getTime()),
    steps: ChallengeSteps.fromDto(dto.steps ?? [], { excludeUnpublishedSteps }),
    duration: dto.duration ?? null,
    horizontalThumbnail: dto.horizontalThumbnail?.url ?? null,
    verticalThumbnail: dto.verticalThumbnail?.url ?? null,
  });
}
