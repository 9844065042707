import {useState, ChangeEvent, useEffect} from 'react';
import {Collapse, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';

import usePasswordValidation from './usePasswordValidation';

import {
  ValidationRules,
  Rule,
  RuleStatusIcon,
} from './styles';

const generalValidationError = 'Hasło jest zbyt słabe';

export const PasswordInput: React.FC<{
  className?: string;
  size?: React.ComponentProps<typeof FormControl>['size'];
  onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  value: string;
  label:string;
  showValidationChecks?: boolean;
  name: string;
  onReadyStateChange?: (isReady: boolean) => void;
  errorIfEmpty?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}> = ({
  className,
  size,
  onChange,
  value,
  name,
  label,
  showValidationChecks = false,
  onReadyStateChange,
  errorIfEmpty,
  disabled = false,
  errorMessage,
}) => {
  const { isValid, rules } = usePasswordValidation(value);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(show => !show);

  
  const [isFocused, setIsFocused] = useState(false);
  const isEmpty = !value.length;

  useEffect(() => {
    onReadyStateChange?.(isValid);
  }, [onReadyStateChange, isValid]);

  let errorText = '';
  if (errorMessage) {
    errorText = errorMessage;
  } else if (isEmpty && errorIfEmpty) {
    errorText = 'Pole wymagane';
  } else if (!isEmpty && !isValid && !isFocused) {
    errorText = generalValidationError;
  }

  return (
    <FormControl
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      error={
        !!errorMessage
        || (!isValid && !isEmpty && !isFocused)
        || (isEmpty && errorIfEmpty)
      }
      size={size}
      variant="outlined"
      fullWidth
      className={className}
      disabled={disabled}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        id={name}
        type={showPassword ? 'text' : 'password'}
        value={value}
        name={name}
        onChange={onChange}
        label={label}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={e => e.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        aria-describedby="password-validation-checks"
        data-testid="password-input"
      />
      {
        errorText && (
          <FormHelperText>
            {errorText}
          </FormHelperText>
        )
      }
      {showValidationChecks && (
        <Collapse in={isFocused}>
          <ValidationRules>
            {rules.map(rule => (
              <Rule key={rule.label}>
                <RuleStatusIcon isOk={rule.isOk} />
                {rule.label}
              </Rule>
            ))}
          </ValidationRules>
        </Collapse>
      )}

    </FormControl>
  )
};
