import styled from 'styled-components';

import { InfoBox } from 'src/components';

export const layout = {
  vertical: '@media (max-width: 939px)',
  horizontal: '@media (min-width: 940px)',
};

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 24px 0 0 0;
  display: grid;
  grid-gap: 24px;
  ${layout.vertical} {
    grid-template-columns: 1fr;
  }
  ${layout.horizontal} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Item = styled.li`

`;

export const EmptyListInfo = styled(InfoBox)`
  margin: 24px 0 0 0;
`;
