import { DurationRange } from 'src/models/DurationRange';
import { FilterTrait } from 'src/models/SessionFilters';

const ranges: DurationRange[] = [
  {
    name: 'do 20 min',
    value: `${0},${20 * 60}`,
    trait: FilterTrait.Duration,
  },
  {
    name: '20-30 min',
    value: `${20 * 60},${30 * 60}`,
    trait: FilterTrait.Duration,
  },
  {
    name: '30-45 min',
    value: `${30 * 60},${45 * 60}`,
    trait: FilterTrait.Duration,
  },
  {
    name: '> 45 min',
    value: `${45 * 60},`,
    trait: FilterTrait.Duration,
  },
];

export const useDurationRanges = () => ranges;
