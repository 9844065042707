import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';
import { Coach, CoachDto, transformCoachDto } from 'src/models/Coach';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

const coachQuery = new RequestQueryBuilder()
  .sortBy({ field: 'order', order: 'DESC' })
  .sortBy({ field: 'updatedDate', order: 'DESC' })
  .query()

const CoachesService = {

  getAll: (): Promise<Coach[]> => HttpService
    .get<GetManyResponse<CoachDto>>(`/public/coaches?${coachQuery}`)
    .then(response => response.data)
    .then(coaches => coaches.map(transformCoachDto)),

};

export default CoachesService;
