import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Challenge } from 'src/models/Challenge';
import { Session } from 'src/models/Session';

import { UserActivity, UserActivityDto, UserActivityType } from 'src/models/UserActivity';
import { UserActivitySummary, UserActivitySummaryDto } from 'src/models/UserActivitySummary';
import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';

abstract class ActivityService {

  static getActivitySummary = (): Promise<UserActivitySummary> => HttpService
    .get<UserActivitySummaryDto>('public/users/me/activity/summary')
    .then(UserActivitySummary.fromDto);

  static getSessionsAndChallengesByActivity = async (
    page: number,
    limit: number,
    activityType: UserActivityType.Liked | UserActivityType.Visited,
  ): Promise<GetManyResponse<Session | Challenge>> => {
    const endpoint = {
      [UserActivityType.Liked]: 'public/users/me/activity/likes',
      [UserActivityType.Visited]: 'public/users/me/activity/visits',
    }[activityType];
    const query = new RequestQueryBuilder()
      .setPage(page)
      .setLimit(limit)
      .sortBy({ field: 'updatedDate', order: 'DESC' })
      .query();
    const response = await HttpService
      .get<GetManyResponse<UserActivityDto>>(`${endpoint}?${query}`);
    return ({
      ...response,
      data: response.data
        .map(UserActivity.fromDto)
        // We don't need event metadata, just the contents:
        .map(({ session, challenge }) => session || challenge)
        .filter((item): item is Session | Challenge => !!item)
    });
  };

  static getLikedSessionsAndChallenges = async (
    page: number,
    limit: number,
  ): Promise<GetManyResponse<Session | Challenge>> =>
    ActivityService.getSessionsAndChallengesByActivity(page, limit, UserActivityType.Liked);

  static getVisitedSessionsAndChallenges = async (
    page: number,
    limit: number,
  ): Promise<GetManyResponse<Session | Challenge>> =>
    ActivityService.getSessionsAndChallengesByActivity(page, limit, UserActivityType.Visited);

}

export default ActivityService;
