import { SessionQuery } from 'src/models/SessionQuery';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useSessionQuery = (): SessionQuery => {
  const location = useLocation();
  const [query, setQuery] = useState(SessionQuery.deserializeFromUrlParams(location.search));

  useEffect(() => {
    setQuery(SessionQuery.deserializeFromUrlParams(location.search));
  }, [location.search]);

  return query;
};
