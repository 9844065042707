import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

import { StripeInput } from './StripeInput';

type StripeElement =
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

type OnChangeEvent =
  | StripeCardCvcElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardNumberElementChangeEvent;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  onChange?: React.ComponentProps<T>['onChange'];
  inputProps?: React.ComponentProps<T>;
  stripeElement: T;
  placeholder: string;
  errorIfEmpty?: boolean;
  onReadyStateChange?: (isReady: boolean) => void;
  onTouchedStateChange?: (isTouched: boolean) => void;
}

export const StripeTextField = <T extends StripeElement>(
  {
    InputLabelProps,
    stripeElement,
    InputProps = {},
    inputProps,
    placeholder,
    errorIfEmpty,
    onChange,
    onReadyStateChange,
    onTouchedStateChange,
    ...other
  }: StripeTextFieldProps<T>
) => {

  const [focused, setFocused] = useState(false);
  const [filled, setFilled] = useState(false);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  const emptyError = ((errorIfEmpty && !filled) || (touched && !filled))
    ? 'Pole wymagane'
    : '';

  const shrinkLabel = focused || filled;
  const isCardNumber = stripeElement.displayName === 'CardNumberElement';
  const showIcon = shrinkLabel && isCardNumber;
  const showPlaceholder = focused && !filled;

  return (
    <TextField
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      error={(!!error && !focused) || (!!emptyError)}
      helperText={error || emptyError}
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: focused || filled,
        style: {
          ...(!focused && isCardNumber && !filled && touched) ? { transform: 'translate(45px, 8px) scale(1)' } : {},
        }
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
          options: {
            ...showIcon ? { showIcon } : {},
            placeholder: showPlaceholder ? placeholder : '',
          }
        },
        inputComponent: StripeInput,
      }}
      onChange={(e: OnChangeEvent) => {
        setFilled(!e?.empty);
        setTouched(true);
        setError(e?.error?.message ?? '');
        onChange?.(e as any);
        onReadyStateChange?.(!!e?.complete);
        onTouchedStateChange?.(true)
      }}
      {...(other as any)}
    />
  );
};
