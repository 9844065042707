import { VideoDto } from 'src/models/video';
import { containsCompletedEvent, containsLikedEvent, SessionEvent } from '../SessionEvent';
import { ChallengeStepId, ChallengeStepType } from './';
import { ChallengeSessionId } from './ChallengeSession';

export interface ChallengeStepDto {
  id: ChallengeStepId;
  order: number;
  group: string;
  type: ChallengeStepType;
  challengeLecture: null;
  challengeSession: {
    id: ChallengeSessionId;
    title: string;
    published: boolean;
    video: VideoDto | null;
  } | null;
  challengePage: null;
  userActivity: SessionEvent[];
}

export interface ChallengeStepUpdateDto {
  id?: ChallengeStepId;
  group: string;
  type: ChallengeStepType;
  order: number;
  challengeSession?: { id: string };
  challengeLecture?: { id: string };
  challengePage?: { id: string };
}

export interface ChallengeStep {
  id: ChallengeStepId;
  title: string;
  group: string;
  type: ChallengeStepType;
  isLiked: boolean;
  isCompleted: boolean;
  session: {
    id: ChallengeSessionId;
    title: string;
    duration: number | null;
  } | null;
  lecture: null;
  page: null;
}

export class ChallengeStep {
  static fromDto = (dto: ChallengeStepDto): ChallengeStep => ({
    id: dto.id,
    title: dto.challengeSession?.title ?? '<unknown>',
    group: dto.group,
    type: dto.type,
    isCompleted: containsCompletedEvent(dto.userActivity),
    isLiked: containsLikedEvent(dto.userActivity),
    session: dto.challengeSession
      ? {
        id: dto.challengeSession.id,
        title: dto.challengeSession.title,
        duration: dto.challengeSession.video?.duration ?? null,
      } : null,
    lecture: null,
    page: null,
  });

  static toUpdateDto = (
    step: Pick<ChallengeStep, 'type' | 'group' | 'session' | 'id'>,
    order: number,
  ): ChallengeStepUpdateDto => {
    const stepDetails = {
      id: step.id,
      type: step.type,
      group: step.group,
      order,
    };

    switch (step.type) {
      case ChallengeStepType.Session: return {
        ...stepDetails,
        ...step.session ? { challengeSession: { id: step.session!.id } } : {},
      };
      default: {
        throw new Error('Could not process challenge step information');
      }
    }
  };
}
