import { FilterTrait } from 'src/models/SessionFilters';

export type EquipmentId = Branded<string, 'equipmentId'>;

export interface Equipment {
  id: EquipmentId;
  name: string;
  description: string | null;
  trait: FilterTrait.Equipment;
};

export type EquipmentDto = Equipment;

export class Equipment {
  static fromDto = (dto: EquipmentDto): Equipment => ({
    id: dto.id,
    name: dto.name,
    description: dto.description,
    trait: FilterTrait.Equipment,
  });
}
