
export const welcomeTopHeaderColored = 'Dziękujemy';
export const welcomeTopHeaderText = 'za\n zakup subskrypcji!'
export const welcomeTopSubHeader = 'Twoje konto jest już aktywne. Na podany adres email przesłaliśmy wiadomość zawierającą podstawowe informacje o Twojej subskrypcji.';
export const welcomeSecondSectionOverhead = 'Przejdź do studia';
export const welcomeSecondSectionHeader = 'Ciesz się Jogą';

export const welcomeContentSectionOneHeader = 'Rozpocznij praktykę';
export const welcomeContentSectionOneParagraph = 'Specjalnie dla nowych użytkowników przygotowaliśmy program, który pokaże jak postawić pierwsze kroki na macie.';
export const welcomeContentSectionOneButtonText = 'Dla Początkujących';

export const welcomeContentSectionTwoHeader = 'Odkrywaj samodzielnie';
export const welcomeContentSectionTwoParagraph = 'Możesz również odkrywać Studio PortalYogi samodzielnie. Znajdź sesję idealnie dopasowaną do Twoich potrzeb!';
export const welcomeContentSectionTwoButtonText = 'Przejdź do studia';

export const welcomeSubscriptionInfoHeader = 'Informacje o subskrypcji';
export const welcomeSubscriptionInfoParagraph = 'Informacje o swoim planie i historii płatności możesz znaleźć zawsze na stronie swojego konta.';
export const welcomeSubscriptionInfoButton = 'Zarządzanie Kontem';
