import styled from 'styled-components';
import { ReactComponent as Logo } from 'src/assets/logo.svg';

export const Image = styled.span.attrs(({
  children: <Logo />,
}))`
  display: block;
  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
