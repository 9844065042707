import styled from 'styled-components';

import BrandLogo from '../BrandLogo';
import { ContainedWidth } from '../Layout';

/** Pushes actual contents down by the height of the navbar. */
const Spacer = styled.div`
  height: 80px;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  border-top: 4px solid ${p => p.theme.palette.primary.main};
  background: ${p => p.theme.palette.common.white};
  ${p => p.theme.shadows[1]}
`;

const Layout = styled.div`
  height: 80px;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 10px minmax(0, 1fr) auto;
  grid-template-areas: "brand . menu profile";
`;

const Logo = styled(BrandLogo)`
  grid-area: brand;
  width: 56px;
  height: 56px;
  align-self: center;
  & svg {
    fill: ${p => p.theme.palette.common.white};
  }
  &:hover svg {
    fill: ${p => p.theme.palette.primary.dark};
  }
`;

export const DesktopNavbar: React.FC = () => (
  <>
    <Spacer />
    <Wrapper>
      <ContainedWidth>
        <Layout>
          <Logo />
        </Layout>
      </ContainedWidth>
    </Wrapper>
  </>
);
