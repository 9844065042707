import { BackLink, Header, SessionCard, Pagination, Carousel } from 'src/components';
import Searchbox from 'src/features/SessionSearchbox';
import { useScrollToTop, useSessionQuery } from 'src/hooks';
import { BackNavigationInfo } from 'src/models/BackNavigationInfo';
import { SessionExcludeFilters, SessionFilters } from 'src/models/SessionFilters';
import { SessionQuery } from 'src/models/SessionQuery';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { SessionsService } from 'src/services';
import { arrayOfNumbers } from 'src/utils/array';
import { isNativePlatform } from '../../config';

import { List, Item, SearchboxWrapper, } from './styles';

const itemsPerPage = 12;
const Y_SCROLL_OFFSET = -50;
const INITIAL_SCREEN_Y = 0;

export type SessionsListProps = {
  pathBase?: string;
  presetFilters?: SessionFilters;
  exclude?: SessionExcludeFilters;
  defaultTitle?: string;
  defaultUnderline?: string;
  carouselPage?: React.ComponentProps<typeof Carousel>['page'];
};

const SessionsList = ({
  pathBase = 'sessions',
  presetFilters = [],
  exclude,
  defaultTitle = 'Sesje jogi',
  defaultUnderline = 'Znajdź idealnie dopasowaną praktykę',
  carouselPage = 'sessions',
}: SessionsListProps) => {
  const query = useSessionQuery();
  const [scrollInToView, setScrollInToView] = useState<boolean>();
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const history = useHistory();

  const queryWithPredefinedFilters: typeof query = {
    ...query,
    // Important step: merge any filters encoded in the URL with preset filters:
    filters: presetFilters.concat(query.filters ?? []),
    exclude,
  };

  const hasFilters = query.filters?.length;
  const backInfo: BackNavigationInfo = {
    link: `${history.location.pathname}${history.location.search}`,
    linkLabel: hasFilters ? 'Wyniki wyszukiwania' : defaultTitle,
  };

  const { data, isFetching } = useQuery(
    ['SessionsList', queryWithPredefinedFilters],
    () => SessionsService.getMany({
      ...queryWithPredefinedFilters,
      limit: itemsPerPage,
      page: queryWithPredefinedFilters.page ?? 1,
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  useScrollToTop([queryWithPredefinedFilters.page]);

  // We want to keep the current page as part of the query params (so that features
  // such as as refreshing, link copying/pasting and history navigation work well).
  // However, the params can also hold information about currently applied filters.
  // Merge them with the new page number and push to history.
  const handlePageClick = (selectedPage: number) => {
    const newPage = selectedPage + 1;
    const params = SessionQuery.serializeToUrlParams({
      ...query,
      page: newPage,
    });
    history.push({ search: params });
  };

  const sessions = data?.data ?? [];
  const page = data?.page ?? 0;
  const pageCount = data?.pageCount ?? 0;
  const results = data?.total;

  const title = query.title || defaultTitle;
  const underline = query.underline || (query.title ? '' : defaultUnderline);

  const actualItemCount = isFetching ? null : sessions.length;
  const displayItemCount = actualItemCount ?? itemsPerPage;

  useEffect(() => {
    if (query.scrollInToView === 'true') {
      setScrollInToView(true);
    }
  }, [query.scrollInToView]);

  useEffect(() => {
    if (!scrollInToView) {
      return;
    }
    const timeout = setTimeout(() => {

      const y = (scrollRef?.getBoundingClientRect()?.top ?? INITIAL_SCREEN_Y) + window.pageYOffset + Y_SCROLL_OFFSET;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 0);

    return () => {
      clearTimeout(timeout);
    }
  }, [scrollRef, scrollInToView]);


  return (
    <>
      <BackLink
        label='Strona główna'
        to='/home'
      />
      {
        !isNativePlatform &&
          <Carousel page={carouselPage} />
      }
      <Header
        title={title}
        underline={underline}
        as="h1"
      />
      <SearchboxWrapper>
        <Searchbox
          searchPending={isFetching}
          results={results}
          pathBase={pathBase}
          presetFilters={presetFilters}
          showFilterSummary
          showResultsSummary
          liveSearch
        />
      </SearchboxWrapper>
      <div ref={setScrollRef} />
      <List multiplePages={pageCount > 1}>
        {arrayOfNumbers(displayItemCount).map(index => (
          <Item key={index}>
            <SessionCard
              mode="horizontal"
              pathBase={pathBase}
              session={sessions[index]}
              order={index}
              backNavigationInfo={backInfo}
            />
          </Item>
        ))}
      </List>
      <Pagination
        page={page}
        pageCount={pageCount}
        onPageChange={handlePageClick}
      />
    </>
  );
}

export default SessionsList;
