import { FilterTrait } from 'src/models/SessionFilters';

export type StyleId = Branded<string, 'styleId'>;

export interface Style {
  id: StyleId;
  name: string;
  description: string | null;
  trait: FilterTrait.Style;
};

export type StyleDto = Style;

export class Style {
  static fromDto = (dto: StyleDto): Style => ({
    id: dto.id,
    name: dto.name,
    description: dto.description,
    trait: FilterTrait.Style,
  });
}
