import styled from 'styled-components';
import { transparentize } from 'polished';

import { SubscriptionProduct } from 'src/models/SubscriptionProduct';

import SubscriptionBox from './SubscriptionBox';
import { Coupon } from 'src/models/Coupon';

const Label = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  -webkit-tap-highlight-color: transparent;
`;

const Input = styled.input.attrs({
  type: 'radio',
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  display: block;
  position: relative;
  flex-shrink: 0;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  border: ${p => p.theme.border};
  border-radius: 50%;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: background .2s;
  }

  ${Label}:hover input:not(:disabled) ~ &:after {
    background: ${p => p.theme.palette.gray[300]};
    background: ${p => transparentize(0.7, p.theme.palette.primary.main)};
  }

  input:checked ~ &&&:after {
    background: ${p => p.theme.palette.primary.main};
  }
`;

const Box = styled(SubscriptionBox)``;

const SubscriptionOption: React.FC<{
  subscription: SubscriptionProduct;
  coupon: Coupon | null;
  isEligibleForCoupons: boolean;
  onSelect: (value: SubscriptionProduct) => void;
  checked?: boolean;
  disabled?: boolean;
}> = ({
  subscription,
  coupon,
  isEligibleForCoupons,
  onSelect,
  checked,
  disabled,
}) => (
  <Label htmlFor={subscription.id}>
    <Input
      name="subscription"
      id={subscription.id}
      value={subscription.id}
      checked={checked}
      onChange={() => onSelect(subscription)}
      disabled={disabled}
    />
    <Checkmark />
    <Box
      subscription={subscription}
      coupon={coupon}
      isEligibleForCoupons={isEligibleForCoupons}
    />
  </Label>
);


export default SubscriptionOption;
