import lzstring from 'lz-string';

const serializeParam = (value: any) =>
  lzstring.compressToEncodedURIComponent(JSON.stringify(value));
  
const deserializeParam = <T>(encodedString: string): T | null => {
  if (!encodedString) {
    return null;
  }
  const decompressed = lzstring.decompressFromEncodedURIComponent(encodedString);
  return decompressed
    ? JSON.parse(decompressed)
    : null;
}

export interface BackNavigationInfo {
  linkLabel?: string;
  link?: string;
}

export class BackNavigationInfo {
  static serializeToUrlParams(info: BackNavigationInfo): string {
    const encodedInfo = serializeParam(info);
    const params = new URLSearchParams();
    params.append('b', encodedInfo);
    return params.toString();
  }

  static deserializeFromUrlParams(paramString: string): BackNavigationInfo {
    const params = Object.fromEntries(new URLSearchParams(paramString));
    return deserializeParam<BackNavigationInfo>(params.b || '{}') || {};
  }
}
