import { useStripe } from '@stripe/react-stripe-js';
import { SetupIntentResult } from '@stripe/stripe-js';

export const useConfirmCardSetup = () => {
  const stripe = useStripe();

  return async (clientSecret: string): Promise<SetupIntentResult> => {
    if (!stripe) {
      throw new Error('Could not initialize Stripe');
    }
    return stripe.confirmCardSetup(clientSecret);
  };
};
