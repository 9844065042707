import { SubscriptionProductPriceDetails } from 'src/models/SubscriptionProductPriceDetails';
import Chip from '@mui/material/Chip';

import { Item, Label, Price } from './styles';
import Image from './Image';

export const OrderItem: React.FC<{
  label: string;
  secondaryLabel?: string;
  price: SubscriptionProductPriceDetails;
  isRenewable: boolean;
}> = ({ label, secondaryLabel, price, isRenewable }) => (
  <Item>
    <Image />
    <div>
      <Label>{label}</Label>
      {secondaryLabel && <Chip label={secondaryLabel} variant='outlined' color='default' size='small' />}
      {!price.discount && (
        <Price>
          {price.basePrice}
          {isRenewable && ` / ${price.interval}`}
        </Price>
      )}
      {price.discount?.forever && (
        <Price>
          <del>{price.basePrice}</del>{" "}
          <strong>{price.discount.discountedPrice}</strong>
          {isRenewable && ` / ${price.interval}`}
        </Price>
      )}
      {price.discount?.once && (
        <>
          {isRenewable ? (
            <>
              <Price>
                pierwsza płatność:{" "}
                <strong>{price.discount.discountedPrice}</strong>
              </Price>
              <Price>
                (kolejne: {price.basePrice} / {price.interval})
              </Price>
            </>
          ) : (
            <Price>
              <del>{price.basePrice}</del>{" "}
              <strong>{price.discount.discountedPrice}</strong>
            </Price>
          )}
        </>
      )}
    </div>
  </Item>
);
