import { useCmsContent } from 'src/hooks';
import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type UseBottomNavigationPropsType = {
  practicePlanLink?: string | null;
}

type UseBottomNavigationReturnType = {
  bottomNavigationChangeRoute: (index: number) => void,
  tabIndex: number
}

type MenuItem = {
  path: string;
  label: string;
}

export const useMenuItems = () => {
  const practicePlanLink = useCmsContent().links.practicePlan;
  return useMemo(() => [
    {
      path: '/sessions',
      label: 'Sesje jogi',
    },
    {
      path: '/meditations',
      label: 'Medytacje',
    },
    {
      path: '/challenges',
      label: 'Wyzwania',
    },
    {
      path: '/pilates',
      label: 'Pilates',
    },
    {
      path: '/teachers',
      label: 'Nauczyciele',
    },
    {
      path: 'https://www.facebook.com/groups/grupa.portalyogi/',
      label: 'Grupa',
    },
    practicePlanLink ? {
      path: practicePlanLink,
      label: 'Plan praktyki',
    } : undefined,
  ].filter((item): item is MenuItem => !!item), [practicePlanLink]);
};

const userMenuItems = [
  {
    path: '/me/summary',
    label: 'Twoja praktyka',
  },
  {
    path: '/me/subscription',
    label: 'Zarządzaj subskrypcją',
  },
  {
    path: '/me/payments',
    label: 'Historia płatności',
  },
  {
    path: '/logout',
    label: 'Wyloguj się',
  },
];

export const useUserMenuItems = () => userMenuItems;

export const useBottomNavigation = ({ practicePlanLink }: UseBottomNavigationPropsType): UseBottomNavigationReturnType => {
  const history = useHistory();
  const [tabIndex, setTabIndex] = React.useState(0);

  const bottomNavigationChangeRoute = (index: number) => {
    setTabIndex(index);
    switch (index) {
      case 0:
        history.push('/sessions');
        break;
      case 1:
        history.push('/meditations');
        break;
      case 2:
        //eslint-disable-next-line
        const splitUrl = practicePlanLink?.split(/(http[s]?:\/\/)?([^\/\s]+\/)(.*)/);
        if (!splitUrl?.[3]) {
          return;
        }
        history.push(`/${splitUrl[3]}`)
        break;
      case 3:
        history.push('/me/summary');
        break;
      default:
        history.push('/home')
    }
  }

  useEffect(() => {
    if (history.location.pathname.includes('/sessions')) {
      setTabIndex(0);
      return;
    }

    if (history.location.pathname.includes('/meditations')) {
      setTabIndex(1);
      return;
    }

    if (history.location.pathname.includes('/me/summary')) {
      setTabIndex(3);
      return;
    }

    //eslint-disable-next-line
    const splitUrl = practicePlanLink?.split(/(http[s]?:\/\/)?([^\/\s]+\/)(.*)/);
    if (history.location.pathname.includes(`${splitUrl?.[3]}`)) {
      setTabIndex(2);
      return;
    }
  }, [history.location.pathname, practicePlanLink]);

  return {
    bottomNavigationChangeRoute,
    tabIndex
  }
}
