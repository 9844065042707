import { BackLink, Loader, WidgetLayout } from 'src/components';
import Comments from 'src/features/Comments';
import { useScrollToTop } from 'src/hooks';
import { SessionId } from 'src/models/Session';
import { TypeId } from 'src/models/Type';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useSession,
  useReturnLink,
  useToggleCompleted,
  useMarkVisited,
} from './hooks';
import SessionDetails from './SessionDetails';
import SimilarSessions from './SimilarSessions';
import { useMemo } from 'react';

const LoaderArea = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Session = () => {
  const { id } = useParams() as {id: SessionId};
  useScrollToTop([id]);
  useMarkVisited(id);
  const { data: session, isLoading } = useSession(id);
  const toggleCompleted = useToggleCompleted();
  const link = useReturnLink();

  const commentsId = useMemo(() => ({ sessionId: id}), [id])

  return (
    <WidgetLayout>
      <WidgetLayout.MainContentArea>
        {(isLoading || !session) && (
          <LoaderArea>
            <Loader />
          </LoaderArea>
        )}
        {(!isLoading && !!session) && (
          <>
            <BackLink
              label={link.label}
              to={link.to}
            />
            <SessionDetails
              {...session}
              toggleCompleted={() => toggleCompleted(id, !session.isCompleted)}
            />
          </>
        )}
      </WidgetLayout.MainContentArea>
      {(!isLoading && !!session) && (
        <WidgetLayout.SecondaryContentArea>
          <Comments id={commentsId} />
        </WidgetLayout.SecondaryContentArea>
      )}
      <WidgetLayout.WidgetArea>
        <SimilarSessions
          sessionId={id}
          typeId={(session?.type.map(t => t.id) ?? []) as TypeId[]}
          limit={7}
        />
      </WidgetLayout.WidgetArea>
    </WidgetLayout>
  );
}

export default Session;
