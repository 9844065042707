import styled from 'styled-components';
import { VideoCarouselItem } from '../types';

import {
  Wrapper,
  Overline,
  Title,
  Text,
  Cta,
} from './styles';

const Video = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: none;
  padding: 24px 0 24px 64px;
  @media only screen and (min-width: 800px) {
    display: flex;
  }
`;

const Item = styled.section`
  width: 100%;
  position: relative;
  padding: 32px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 800px) {
    width: 50%;
    transform: translateX(100%);
    align-items: flex-start;
    ${Text} {
      text-align: left;
    }
  }
`;

export const VideoItem: React.FC<VideoCarouselItem> = ({
  overline,
  title,
  copy,
  ctaLabel,
  ctaUrl,
  videoId,
}) => (
  <Wrapper>
    <Video>
      {/* <VimeoPlayer viewLink={videoId} /> */}
    </Video>
    <Item>
      <Overline>{overline}</Overline>
      <Title>{title}</Title>
      <Text dangerouslySetInnerHTML={{ __html: copy ?? '' }} />
      {!!ctaLabel && !!ctaUrl && <Cta href={ctaUrl}>{ctaLabel}</Cta>}
    </Item>
  </Wrapper>
);
