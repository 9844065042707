import { useRef, useState } from 'react';
import useSize from '@react-hook/size';
import {
  Wrapper,
  Arrow,
  Overlay,
  minHeightToCollapse,
  minHeightDifferenceToCollapse,
} from './styles';

export const MobileCollapsible: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [, height] = useSize(ref);
  const noCollapse = height < minHeightToCollapse + minHeightDifferenceToCollapse;

  return (
    <Wrapper
      realHeight={height}  
      collapsed={collapsed}
      noCollapse={noCollapse}
      onClick={() => setCollapsed(!collapsed)}
    > 
      <div ref={ref}>
        {children}
      </div>
      <Overlay collapsed={collapsed} noCollapse={noCollapse} />
      <Arrow collapsed={collapsed} noCollapse={noCollapse} />
    </Wrapper>
  );
};
