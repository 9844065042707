import styled from 'styled-components';

import { ReactComponent as Svg } from './session.svg';

export const SessionIcon = styled.div.attrs({
  children: <Svg />,
})`
  width: 18px;
  height: 18px;
  position: relative;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: fill .2s;
    fill: ${p => p.theme.palette.primary.main};
  }
`;
