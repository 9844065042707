import { CmsContentDto, transformCmsContentDto } from 'src/models/CmsContent';
import { HttpService } from 'src/services';

class CmsService {

  static getContent = () => HttpService
    .get<CmsContentDto>('/public/cms/config')
    .then(transformCmsContentDto);

};

export default CmsService;
