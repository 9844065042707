import { SessionExcludeFilters, SessionFilters } from 'src/models/SessionFilters';
import { ChallengeFilters, ChallengeQuery } from '../Challenge/ChallengeQuery';

export interface SessionQuery {
  title?: string;
  underline?: string;
  page?: number;
  limit?: number;
  filters?: SessionFilters;
  exclude?: SessionExcludeFilters;
  scrollInToView?: string;
}

function queryToSearchParams(query: SessionQuery | ChallengeQuery) {
  const searchParams = new URLSearchParams();
  for (const [name, value] of Object.entries(query)) {
    if (value == null) {
      continue;
    }
    switch (typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        searchParams.append(name, String(value));
    }
  }
  if (Array.isArray(query.filters)) {
    for (const item of query.filters) {
      searchParams.append(`filters.${item.trait}`, String(item.value));
    }
  } else if (query.filters != null) {
    for (const [trait, values] of Object.entries(query.filters)) {
      if (values == null) {
        continue;
      }
      for (const val of values) {
        searchParams.append(`filters.${trait}`, val);
      }
    }
  }
  return searchParams;
}

function assignSessionFilter(query: SessionQuery, trait: any, value: string) {
  // (query.filters ??= []).push({ trait, value });
  if (query.filters == null) {
    query.filters = [];
  }
  query.filters.push({ trait, value });
}

function assignChallengeFilter(query: ChallengeQuery, trait: string, value: string) {
  // ((query.filters ??= {})[trait as keyof ChallengeFilters] ??= []).push(value);
  if (query.filters == null) {
    query.filters = {};
  }
  const name = trait as keyof ChallengeFilters;
  if (query.filters[name] == null) {
    query.filters[name] = [];
  }
  query.filters[name]!.push(value);
}

function searchParamsToQuery<T>(searchParams: URLSearchParams, assignFn: (query: T, trait: string, value: string) => void) {
  const query = {} as any;
  for (const [name, value] of searchParams) {
    if (name.startsWith('filters.')) {
      assignFn(query, name.split('.')[1]!, value);
    } else {
      (query as any)[name] = value;
    }
  }
  if (query.page)
    query.page = Number(query.page);
  if (query.limit)
    query.limit = Number(query.limit);

  return query as T;
}

export class SessionQuery {
  static serializeToUrlParams(query: SessionQuery | ChallengeQuery): string {
    if (query.page === 1) {
      query = { ...query as any, page: undefined };
    }
    return queryToSearchParams(query).toString();
  };

  static deserializeFromUrlParams(paramString: string, asChallenge: true): ChallengeQuery;
  static deserializeFromUrlParams(paramString: string): SessionQuery;
  static deserializeFromUrlParams(paramString: string, asChallenge = false): SessionQuery | ChallengeQuery {
    const params = new URLSearchParams(paramString);
    return asChallenge ? searchParamsToQuery(params, assignChallengeFilter) : searchParamsToQuery(params, assignSessionFilter);
  };
}
