import { Loader } from 'src/components';
import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.section`
  background: ${p => p.theme.palette.common.white};
  ${p => p.theme.shadows[1]}
  border: ${p => p.theme.border};
  border-radius: 8px;
  padding: 16px;
`;

export const Welcome = styled.p`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.low};
`;

export const Heading = styled.h2`
  font-size: 33px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.2px;
  text-align: left;

`;

export const Items = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const Item = styled.div`
  border: ${p => p.theme.border};
  padding: 8px;
  flex: 1 1 0;
  &:first-child {
    border-radius: 8px 0 0 8px;
    border-right: none;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    border-left: none;
  }
`;

export const ItemName = styled.h3`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.17px;
  text-align: left;
  color: ${p => p.theme.palette.primary.dark};
  margin-bottom: 4px;
`;

const showCount = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const ItemCount = styled.p<{ delay: number }>`
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  animation: ${showCount} .5s ${p => p.delay}ms backwards;
  display: inline-block;
`;

export const ItemCountLoader = styled(Loader)`
  width: 28px;
  height: 28px;
`;
