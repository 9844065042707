import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { stripePublicKey } from 'src/config';

const stripe = loadStripe(
  stripePublicKey,
  {
    locale: 'pl',
    apiVersion: '2022-11-15',
  },
);

export const StripeProvider: React.FC<{options?: StripeElementsOptions}> = ({ children, options }) => (
  <Elements
    stripe={stripe}
    options={{
      ...options,
      appearance: {
        theme: 'flat',
        labels: 'floating'
      },
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Syne:wght@500;700&family=Work+Sans:ital,wght@0,300;0,600;1,400&display=swap',
        },
      ],
    }}
  >
    {children}
  </Elements>
);
