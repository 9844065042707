import { Collapse } from '@mui/material';
import {
  Wrapper,
  RegularText,
  ResultsCount,
} from './styles';

export const SearchResultsSummary: React.FC<{
  pending: boolean;
  results: number;
  showIf: boolean;
}> = ({
  pending,
  results,
  showIf: show,
}) => {


  return (
    <Collapse in={show}>
      <Wrapper>
        {pending || Number.isNaN(results)
        ? (
            <RegularText>Wyszukiwanie...</RegularText>
        ) : (
          <>
            <RegularText>Liczba wyników:</RegularText>
            <ResultsCount>{results}</ResultsCount>
          </>
        )}
      </Wrapper>
    </Collapse>
  );
};
