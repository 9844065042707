import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';
import { Type, TypeDto } from 'src/models/Type';

const TypesService = {

  getAll: (): Promise<Type[]> => HttpService
    .get<GetManyResponse<TypeDto>>('/public/types')
    .then(response => response.data)
    .then(coaches => coaches.map(Type.fromDto)),

};

export default TypesService;
