import { Container } from './styles';

export const Avatar: React.FC<{
  size: 'small' | 'medium' | 'large';
  imageSrc?: string;
  alt?: string;
}> = ({
  alt = 'avatar',
  imageSrc,
  size,
}) => (
  <Container size={size}>
    <img src={imageSrc} alt={alt}/>
  </Container>
);
