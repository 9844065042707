import styled from 'styled-components';
import { HeartFilled, HeartOutline } from 'src/icons';
import { useCallback } from 'react';

export const Button = styled.button<{ liked?: boolean; pending?: boolean; }>`
  position: absolute;
  z-index: 1;
  display: block;
  width: 32px;
  height: 32px;
  top: 16px;
  right: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(100, 100, 100, 0.2);
  backdrop-filter: blur(2px) invert(20%) brightness(120%);

  transition: color .2s;
  color: ${p => p.liked ? p.theme.palette.secondary.light : '#ffffff'};

  ${HeartOutline}, ${HeartFilled} {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
  }

  ${HeartFilled} {
    opacity: ${p => p.liked ? 1 : 0};
    transition: opacity .2s;
  }

  &:hover ${HeartFilled} {
    opacity: ${p => p.liked ? 1 : 0.5};
  }

  &:disabled {
    pointer-events: none;
  }
`;

const LikeButton: React.FC<{
  liked: boolean;
  onToggle?: () => void;
}> = ({
  liked,
  onToggle,
}) => {
  const handleClick = useCallback((event: React.MouseEvent) => {
    if (!onToggle) return;
    event.preventDefault();
    onToggle();
  }, [onToggle]);

  return (
    <Button
      liked={liked}
      onClick={handleClick}
      disabled={!onToggle}
    >
      <HeartFilled />
      <HeartOutline />
    </Button>
  );
}

export default LikeButton;
