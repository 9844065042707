const DECIMAL_SEPARATOR = ',';

export const prettifyPrice = (decimalSeparator: string) =>
  /**
   * @param amount amount in base currency unit (cents, grosz)
   * @param suffix e.g. `"$"`, `"zł"`
   * @returns prettifiedPrice e.g. `34,00 zł`
   */
  (amount: number, suffix?: string) => {
    return (amount / 100)
      .toFixed(2)
      .replace('.', decimalSeparator)
      .concat(suffix ? ` ${suffix}` : '');
  };

export default prettifyPrice(DECIMAL_SEPARATOR);
