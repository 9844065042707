import { WidgetLayout } from 'src/components';
import styled from 'styled-components';

import { useSignUpContext } from './context';
import Disclaimer from './Disclaimer';
import UserDetailsAndConsents from './UserDetailsAndConsents';

const StepContent = styled.section<{ active: boolean }>`
  display: ${p => p.active ? 'block' : 'none'};
`;

const StepManager = ({
  stepper, paymentElement
}: {stepper: React.ReactElement, paymentElement: React.ReactElement}) => {
  const [{
    currentStep,
  }] = useSignUpContext();

  return (
    <>
      <WidgetLayout.MainContentArea>
        {stepper}
        <StepContent active={currentStep === 'userDetailsAndConsents'}>
          <UserDetailsAndConsents />
        </StepContent>
        <StepContent active={currentStep === 'paymentMethod'}>
          {paymentElement}
        </StepContent>
      </WidgetLayout.MainContentArea>
      <WidgetLayout.SecondaryContentArea>
        <Disclaimer step={currentStep} />
      </WidgetLayout.SecondaryContentArea>
    </>
  );
};

export default StepManager;
