import { OnboardingSlideInterface } from './OnboardingSlide.interface';
import {
  OnboardingImageContainer,
  OnboardingInfoContainer,
  OnboardingTitle,
  OnboardingSubtitle,
  OnboardingSlide
} from './OnboardingSlide.styles';

export const OnboardingSlideComponent = ({img, title, subtitle}: OnboardingSlideInterface) => {

  return <OnboardingSlide>
      <OnboardingImageContainer img={img} />
      <OnboardingInfoContainer>
        <OnboardingTitle>
          {title}
        </OnboardingTitle>
        <OnboardingSubtitle>
          {subtitle}
        </OnboardingSubtitle>
      </OnboardingInfoContainer>
    </OnboardingSlide>;
}
