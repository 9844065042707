import React, { useContext, useReducer } from 'react';

import { SignUpDispatch, SignUpState } from './types';
import { initialSignUpState, signUpReducer } from './reducer';

const SignUpContext = React.createContext<
  [SignUpState, SignUpDispatch] | undefined
>(undefined);

const SignUpProvider: React.FC = ({ children }) => {
  const value = useReducer(signUpReducer, initialSignUpState);
  return (
    <SignUpContext.Provider value={value}>
      {children}
    </SignUpContext.Provider>
  );
};

const useSignUpContext = () => {
  const context = useContext(SignUpContext);
  if (context === undefined) {
    throw new Error('useSignUpContext must be used within a SignUpProvider');
  }
  return context;
};

export {
  SignUpProvider,
  useSignUpContext,
};
