import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthBox, Button, PasswordInput } from 'src/components';
import { AuthService } from 'src/services';

import { Form, InfoBox } from '../styles';

const REDIRECT_DELAY = 3000;

export const SetNewPassword: React.FC<{token: string}> = ({ token }) => {

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== passwordRepeat) {
      setError('Podane hasła są różne');
      return;
    }
    setIsPending(true);
    setError('');
    AuthService.resetPassword(password, token)
      .then(() => setIsSuccess(true))
      .catch(() => {
        setError('Błąd: nie udało się zresetować hasła.');
      })
      .finally(() => setIsPending(false))
  };

  useEffect(() => {
    if (!isSuccess) return;
    const timeoutRef = setTimeout(
      () => history.replace('/login'),
      REDIRECT_DELAY,
    );
    return () => clearTimeout(timeoutRef);
  }, [isSuccess, history]);

  return (
    <AuthBox title="Ustaw nowe hasło">
      {error && (
        <InfoBox
          mode="error"
          message={error}
        />
      )}
      {isSuccess && (
        <InfoBox
          mode="success"
          message="Hasło zostało zresetowane! Za chwilę wrócisz do formularza logowania..."
        />
      )}
      <Form onSubmit={onSubmit}>
        <PasswordInput
          label="Hasło"
          name="newPassword"
          value={password}
          onChange={event => {setPassword(event.target.value)}}
          onReadyStateChange={setIsPasswordValid}
          size="small"
          showValidationChecks
          disabled={isPending || isSuccess}
        />
        <PasswordInput
          label="Powtórz hasło"
          name="newPasswordRepeat"
          value={passwordRepeat}
          onChange={event => {setPasswordRepeat(event.target.value)}}
          size="small"
          disabled={isPending || isSuccess}
       />
       <Button
        submit
        pending={isPending}
        disabled={!isPasswordValid || !passwordRepeat}
        label="Ustaw hasło"
      />
      </Form>
    </AuthBox>
  );
};
