import { Coupon, CouponCode } from './Coupon';
import { SubscriptionProduct, Interval } from 'src/models/SubscriptionProduct';
import { CouponDuration } from './Coupon';

export interface SubscriptionProductPriceDetails {
  basePrice: string;
  interval: string;
  discount: Discount | null;
}

export interface Discount {
  couponCode: CouponCode;
  basePrice: string;
  discountedPrice: string;
  once: boolean;
  forever: boolean;
};

export function calculateDiscountedPrice({ price }: SubscriptionProduct, coupon: Coupon) {
  if (coupon.amountOff != null) {
    return Math.max(0, price - coupon.amountOff);
  }
  if (coupon.percentOff != null) {
    return price * (100 - coupon.percentOff) / 100;
  }
  return price;
};

const formatPrice = (price: number) => `${(price / 100).toFixed(2).replace('.', ',')} zł`;

const formatInterval = (interval: Interval, count: number) => {
  const intervalName = {
    [Interval.Day]: (count: number) => count === 1 ? 'dzień' : 'dni',
    [Interval.Week]: (count: number) => count === 1 ? 'tydzień' : 'tygodni',
    [Interval.Month]: (count: number) => count === 1 ? 'miesiąc' : 'miesięcy',
    [Interval.Year]: (count: number) => count === 1 ? 'rok' : 'lata',
  }[interval](count);
  return `${count === 1 ? '' : count} ${intervalName}`;
};

export class SubscriptionProductPriceDetails {
  static compose(product: SubscriptionProduct, coupon?: Coupon | null): SubscriptionProductPriceDetails {
    const basePrice = formatPrice(product.price);
    const interval = formatInterval(product.interval, product.intervalCount);
    const discount = coupon?.appliesTo.includes(product.id) ? {
      couponCode: coupon.code,
      basePrice,
      discountedPrice: formatPrice(calculateDiscountedPrice(product, coupon)),
      once: coupon?.duration === CouponDuration.Once,
      forever: coupon?.duration === CouponDuration.Forever,
    } : null;
    return {
      basePrice,
      interval,
      discount,
    }
  }

  static zeroPrice = formatPrice(0);
}
