import { isNativePlatform, meditationStyleId, pilatesTypeId } from 'src/config';
import { useUserState } from 'src/contexts/UserContext';
import AppWrapper from 'src/features/AppWrapper';
import { FilterTrait } from 'src/models/SessionFilters';
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {
  Home,
  Login,
  LogOut,
  Profile,
  SignUp,
  Session,
  SessionsList,
  Welcome,
  ResetPassword,
  Challenge,
  ChallengeBySession,
  ChallengeLibrary,
  Likes,
  Visits,
  Coaches,
  CarouselPreview,
} from 'src/views';
import { LoginMobile } from '../views/LoginMobile/LoginMobile';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { PaymentComplete } from '../views/SignUp/OneTimePayment/PaymentComplete';

const App: React.FC = () => {
  const { isLoggedIn } = useUserState();

  return (
    <AppWrapper
      navbar={isLoggedIn}
      emptyNavbar={!isLoggedIn}
    >
      <Switch>
        <PublicRoute
          path="/login"
          component={isNativePlatform ? LoginMobile : Login}
        />
        <ProtectedRoute
          path="/logout"
          component={LogOut}
        />
        <PublicRoute
          path="/payment/complete"
          exact
          component={PaymentComplete}
        />
        {!isNativePlatform && (
          <PublicRoute
            path="/register/:description/:subscriptionProductId/:couponCode?"
            exact
            component={SignUp}
          />
        )}
        <PublicRoute
          path="/reset-password/:token?"
          component={ResetPassword}
        />
        <ProtectedRoute
          path="/home"
          requiresSubscription
          component={Home}
        />
        <ProtectedRoute
          path="/welcome"
          requiresSubscription
          component={Welcome}
        />
        <ProtectedRoute
          path="/sessions"
          exact
          requiresSubscription
          render={() =>
            <SessionsList
              pathBase="sessions"
              exclude={{
                [FilterTrait.Style]: meditationStyleId,
                [FilterTrait.Type]: pilatesTypeId,
              }}
            />
          }
        />
        <ProtectedRoute
          path='/sessions/:id'
          requiresSubscription
          render={() => <Session />}
        />
        <ProtectedRoute
          path="/meditations"
          exact
          requiresSubscription
          render={() => (
            <SessionsList
              pathBase="meditations"
              presetFilters={[{ trait: FilterTrait.Style, value: meditationStyleId }]}
              defaultTitle="Medytacje"
              defaultUnderline="Znajdź idealnie dopasowaną praktykę"
              carouselPage="meditations"
            />
          )}
        />
        <ProtectedRoute
          path="/pilates"
          exact
          requiresSubscription
          render={() => (
            <SessionsList
              pathBase="pilates"
              presetFilters={[{ trait: FilterTrait.Type, value: pilatesTypeId }]}
              defaultTitle="Pilates"
              carouselPage="pilates"
            />
          )}
        />
        <ProtectedRoute
          path={'/meditations/:id'}
          requiresSubscription
          render={() => <Session />}
        />
        <ProtectedRoute
          path={'/pilates/:id'}
          requiresSubscription
          render={() => <Session />}
        />
        <ProtectedRoute
          path="/challenges"
          exact
          requiresSubscription
          component={ChallengeLibrary}
        />
        <ProtectedRoute
          path="/teachers"
          exact
          requiresSubscription
          component={Coaches}
        />
        <ProtectedRoute
          path="/challenges/session/:challengeSessionId"
          exact
          requiresSubscription
          component={ChallengeBySession}
        />
        <ProtectedRoute
          path="/challenges/:id/:stepId?"
          exact
          requiresSubscription
          component={Challenge}
        />
        <ProtectedRoute
          path="/likes"
          exact
          requiresSubscription
          component={Likes}
        />
        <ProtectedRoute
          path="/activity"
          exact
          requiresSubscription
          component={Visits}
        />
        <ProtectedRoute
          path="/me"
          component={Profile}
        />
        <ProtectedRoute
          path="/carousel/:id"
          exact
          requiresSubscription
          render={(p: any) => <CarouselPreview id={p.match.params.id} />}
        />
        <ProtectedRoute
          path="*"
          render={() => <Redirect to="/home" />}
        />
      </Switch>
    </AppWrapper>
  );
};

export default App;
