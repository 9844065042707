import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

export const useCreatePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();

  return async () => {
    if (!stripe || ! elements) {
      throw new Error('Could not initialize Stripe');
    }
    const card = elements.getElement(CardNumberElement);
    if (!card) {
      throw new Error('Could not retrieve Stripe CardElement');
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error || !paymentMethod) {
      throw error ?? new Error('Could not create Stripe payment method');
    }

    return paymentMethod;
  };

};
