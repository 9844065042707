import Loader from 'src/components/Loader';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { isNativePlatform } from '../../config';

type Color = 'primary' | 'secondary' | 'default' | 'dark';
type Variant =  'filled' | 'outlined' | 'minimal';

type RootComponentProps = {
  disabled: boolean;
  pending: boolean;
  fullWidth: boolean;
};

const rootElement = ({ disabled, pending, fullWidth }: RootComponentProps) => css`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;

  display: inline-block;
  ${fullWidth && `
    width: 100%;
  `}

  ${(disabled || pending) && `
    cursor: auto;
    pointer-events: none;
  `}
`;

const RootComponent = {
  Button: styled.button<RootComponentProps>`
    ${rootElement}
  `,
  Anchor: styled.a<RootComponentProps>`
    ${rootElement}
  `,
  Link: styled(Link)<RootComponentProps>`
    ${rootElement}
  `,
};

const Content = styled.div<{
  color: Color;
  variant: Variant;
  disabled?: boolean;
  pending?: boolean;
}>`
  padding: 8px 16px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  transition: background .2s, opacity .2s;
  background: ${p => p.variant === 'filled'
    ? ({
      'primary': p.theme.palette.primary.main,
      'secondary': p.theme.palette.secondary.main,
      'default': p.theme.palette.common.white,
      'dark': p.theme.palette.common.black
    }[p.color]) 
    : 'transparent'
  };
  border-color: ${p => p.variant === 'outlined'
    ? ({
      'primary': p.theme.palette.primary.dark,
      'secondary': p.theme.palette.secondary.dark,
      'default': p.theme.palette.gray[300],
      'dark': p.theme.palette.common.black
    }[p.color])
    : 'transparent'
  };

  &:hover {
    background: ${p => p.variant === 'filled'
      ? ({
        'primary': p.theme.palette.primary.dark,
        'secondary': p.theme.palette.secondary.dark,
        'default': p.theme.palette.gray[100],
        'dark': p.theme.palette.common.black
      }[p.color])
      : ({
        'primary': transparentize(0.9, p.theme.palette.primary.main),
        'secondary': transparentize(0.95, p.theme.palette.secondary.main),
        'default': transparentize(0.97, '#000000'),
        'dark': p.theme.palette.common.black
      }[p.color])
    };
  }

  ${p => p.disabled && !p.pending && `
    opacity: 0.5;
  `}
`;

const PendingOverlay = styled.div<{visible: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${p => p.visible ? 1 : 0};
`;

const PendingIcon = styled(Loader)<{
  color: Color;
  variant: Variant;
}>`
  width: 36px;
  height: 36px;
  color: ${p => p.variant === 'filled'
    ? ({
      'primary': p.theme.palette.common.white,
      'secondary': p.theme.palette.common.white,
      'default': p.theme.palette.primary.main,
      'dark': p.theme.palette.common.black
    }[p.color])
    : p.variant === 'outlined'
    ? ({
        'primary': p.theme.palette.primary.dark,
        'secondary': p.theme.palette.secondary.dark,
        'default': p.theme.palette.primary.main,
        'dark': p.theme.palette.common.black
      }[p.color])
    : ({
      'primary': p.theme.palette.primary.main,
      'secondary': p.theme.palette.secondary.main,
      'default': p.theme.palette.primary.main,
      'dark': p.theme.palette.common.black
    }[p.color])
  };
`;

export const MobileLabel = styled.span<{
  color: Color;
  variant: Variant;
  pending?: boolean;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  display: block;
  transition: opacity .2s;
  font-family: 'Work Sans';
  color: ${p => p.variant === 'filled'
  ? ({
    'primary': p.theme.palette.common.white,
    'secondary': p.theme.palette.common.white,
    'default': p.theme.palette.text.high,
    'dark': p.theme.palette.common.white
  }[p.color])
  : ({
    'primary': p.theme.palette.primary.dark,
    'secondary': p.theme.palette.secondary.dark,
    'default': p.theme.palette.text.high,
    'dark': p.theme.palette.common.white
  }[p.color])
};

  ${p => p.pending && `
    opacity: 0;
  `};
`;

const Label = styled.span<{
  color: Color;
  variant: Variant;
  pending?: boolean;
}>`
  display: block;
  transition: opacity .2s;
  color: ${p => p.variant === 'filled'
    ? ({
      'primary': p.theme.palette.common.white,
      'secondary': p.theme.palette.common.white,
      'default': p.theme.palette.text.high,
      'dark': p.theme.palette.common.white
    }[p.color])
    : ({
      'primary': p.theme.palette.primary.dark,
      'secondary': p.theme.palette.secondary.dark,
      'default': p.theme.palette.text.high,
      'dark': p.theme.palette.common.white
    }[p.color])
  };

  ${p => p.pending && `
    opacity: 0;
  `};
`;

export const Button: React.FC<{
  label?: string;
  disabled?: boolean;
  pending?: boolean;
  color?: Color;
  variant?: Variant;
  fullWidth?: boolean;
  icon?: JSX.Element;
  iconPosition?: 'start' | 'end';
  onClick?: (event: React.MouseEvent) => void;
  href?: string;
  openInNewTab?: boolean;
  routerLink?: string;
  className?: string;
  submit?: boolean;
}> = ({
  label,
  disabled = false,
  pending = false,
  color = 'primary',
  variant = 'filled',
  fullWidth = false,
  icon,
  iconPosition,
  onClick,
  href,
  openInNewTab = false,
  routerLink,
  className,
  submit = false,
}) => {
  const content = (
    <Content
      color={color}
      variant={variant}
      disabled={disabled}
      pending={pending}
    >
      {label ?
        isNativePlatform ?
            <MobileLabel
                color={color}
                variant={variant}
                pending={pending}>
              {label}
            </MobileLabel>
          :
        <Label
          color={color}
          variant={variant}
          pending={pending}
        >
          {label}
        </Label>
        :
        <></>
      }
      <PendingOverlay visible={pending}>
        <PendingIcon color={isNativePlatform ? 'primary' : color} variant={variant}/>
      </PendingOverlay>
    </Content>
  );

  if (href) {
    return (
      <RootComponent.Anchor
        onClick={onClick}
        disabled={disabled}
        pending={pending}
        fullWidth={fullWidth}
        className={className}
        href={href}
        {... openInNewTab ? { target: '_blank' } : {}}
      >
        {content}
      </RootComponent.Anchor>
    );
  }

  if (routerLink) {
    return (
      <RootComponent.Link
        onClick={onClick}
        disabled={disabled}
        pending={pending}
        fullWidth={fullWidth}
        to={routerLink}
        className={className}
      >
        {content}
      </RootComponent.Link>
    );
  }

  return (
    <RootComponent.Button
      onClick={onClick}
      disabled={disabled}
      pending={pending}
      fullWidth={fullWidth}
      className={className}
      type={submit ? 'submit' : 'button'}
      role={submit ? 'submit' : 'button'}
    >
      {content}
    </RootComponent.Button>
  )
};
