import {
  Layout,
  Header,
  Card,
  Image,
  Overline,
  Title,
  Underline,
  Avatar,
  Coach,
  CoachLabel,
  Difficulty,
  DifficultyLabel,
  DifficultyIndicators,
  DurationWrapper,
  Duration,
} from './styles/skeleton';

export const SessionCardSkeleton: React.FC<{
  className?: string;
  mode: 'horizontal' | 'vertical',
}> = ({
  className,
  mode,
}) => (
  <Card className={className}>
    <Layout>
      <Image mode={mode} />
      <Header>
        <Overline/>
        <Title/>
        <Underline/>
      </Header>
      <Coach>
        <Avatar/>
        <CoachLabel/>
      </Coach>
      <Difficulty>
        <DifficultyLabel/>
        <DifficultyIndicators/>
      </Difficulty>
      <DurationWrapper>
        <Duration/>
      </DurationWrapper>
    </Layout>
  </Card>
);
