import styled from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';

import WidgetLayout from 'src/components/WidgetLayout';

export const Container = styled.main`
`;

export const Title = styled.h1`
  grid-area: title;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.3px;
  color: ${p => p.theme.palette.text.high};
  margin-bottom: 8px;
`;

export const Underline = styled.span`
  display: block;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.5px;
  line-height: 128%;
  text-transform: uppercase;
  color: ${p => p.theme.palette.text.medium};
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h2`
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3px;
`;

export const PrimaryDescription = styled(WidgetLayout.ContentWithMargin)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`;

export const SecondaryDescription = styled(WidgetLayout.ContentWithMargin)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  padding-top: 48px;
`;

export const Separator = styled(WidgetLayout.ContentWithoutMargin)`
  border-top: ${p => p.theme.border};
  margin: 24px 0;
`;

export const EquipmentSection = styled(WidgetLayout.ContentWithMargin)``;

export const EquipmentList = styled.ul`
  padding: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

export const EquipmentItem = styled.li`
  margin: 0 8px 8px 0;
`;

export const PropertiesAndActions = styled(WidgetLayout.ContentWithMargin)`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  ${WidgetLayout.mediaQueries.vertical} {
    flex-direction: column;
  }
`;

export const Properties = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  ${WidgetLayout.mediaQueries.vertical} {
    order: 2;
  }
`;

export const Property = styled.section`
  border-left: ${p => p.theme.border};
  height: 48px;
  margin: 8px 16px 0 0;
  padding: 0 0 0 16px;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(2,1fr);
  &:first-of-type {
    padding-left: 0;
    border-left: none;
  }
`;

export const PropertyLabel = styled.h3`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.low};
`;

export const PropertyLink = styled(Link)`
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const TypeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const TypeItem = styled.li`
  display: inline;
  &:not(:last-child):after {
    content: ", ";
  }
`;

export const Actions = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 8px;
  }
  ${WidgetLayout.mediaQueries.vertical} {
    margin-bottom: 24px;
  }
`;

export const Tags = styled(WidgetLayout.ContentWithoutMargin)`
  margin: 48px 0 0 0;
  position: relative;
  border-bottom: ${p => p.theme.border};
`;

export const TagList = styled(WidgetLayout.ContentWithoutMargin)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 24px 0;
`;

export const TagItem = styled(Link)`
  display: block;
  color: ${p => p.theme.palette.text.high};
  background: ${p => transparentize(0.9, p.theme.palette.primary.main)};
  padding: 4px 8px;
  margin: 8px;
  transition: background .2s;

  &:hover, &:focus {
    background: ${p => transparentize(0.75, p.theme.palette.primary.main)};
  }
`;

export const TagToggler = styled.button`
  z-index: 100;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: content-box;
  padding: 0 12px;
  height: 32px;
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: ${p => p.theme.border};
  border-radius: 100px;
  cursor: pointer;
`;

export const CoachSection = styled(WidgetLayout.ContentWithMargin)`
  margin-top: 24px;
`;

export const CoachCard = styled(Link)`
  display: flex;
  margin-top: 24px;
  align-items: center;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  
  & > p {
    margin-left: 8px;
  }
  
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const DownloadsSection = styled(WidgetLayout.ContentWithMargin)`
margin-top: 24px;
`;
