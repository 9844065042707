import { RequestQueryBuilder } from '@nestjsx/crud-request';

import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';
import { SubscriptionPayment, SubscriptionPaymentDto } from 'src/models/SubscriptionPayment';

const SubscriptionPaymentsService = {

  getMany: ({ page = 0, limit = 12 }) => {
    const query = new RequestQueryBuilder()
      .setJoin({ field: 'subscriptionSnapshot', select: ['subscriptionProduct'] })
      .setJoin({ field: 'subscriptionSnapshot.subscriptionProduct', select: ['name', 'description'] })
      .sortBy({ field: 'createdDate', order: 'DESC' })
      .setLimit(limit)
      .setPage(page)
      .query();

    return HttpService
      .get<GetManyResponse<SubscriptionPaymentDto>>(`/public/purchase?${query}`)
      .then(r => ({ ...r, data: r.data.map(SubscriptionPayment.fromDto) }));
  },

};

export default SubscriptionPaymentsService;
