import { useUserActions } from 'src/contexts/UserContext';
import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import UserApp from './App';
import { isNativePlatform } from './config';
import { Onboarding } from './views/Onboarding/Onboarding';

const AdminApp = lazy(() => import('./Admin'));

const MainRouter: React.FC = () => {
  const { checkIfLoggedIn } = useUserActions();
  useEffect(checkIfLoggedIn, [checkIfLoggedIn]);

  return (
    <Router>
      <CompatRouter>
        <Suspense fallback={null}>
          <Switch>
            {
              isNativePlatform && <Route
                    path="/onboarding"
                    component={Onboarding}
                />
            }
            <Route path="/admin" component={AdminApp} />
            <Route component={UserApp} />
          </Switch>
        </Suspense>
      </CompatRouter>
    </Router>
  );
};

export default MainRouter;
