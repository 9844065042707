/**
 * Creates a human-readable message that explains why an attempt to charge the user's card or set up the card
 * for future use has been unsuccessful.
 * 
 * @param code see https://stripe.com/docs/error-codes
 * @param declineCode see https://stripe.com/docs/declines/codes
 */
const composeStripeCardErrorMessage = (code: string, declineCode: string | null): string | null => {
  if (code === 'incorrect_cvc') {
    return 'Podany kod CVC jest nieprawidłowy.';
  }
  if (code !== 'card_declined') {
    return null;
  }
  if (!declineCode) {
    return null;
  }
  return declinationReasons[declineCode] ?? genericDeclineReason;
};

const genericDeclineReason = 'Płatność została odrzucona z nieznanego powodu. Skontaktuj się z wystawcą lub użyj innej karty.';

const declinationReasons = {
  'generic_decline': genericDeclineReason,
  'insufficient_funds': 'Na Twojej karcie nie ma wystarczających środków.',
  'expired_card': 'Skończył się termin ważności Twojej karty.',
  'issuer_not_available': 'Nie udało się połączyć z wystawcą Twojej karty.',
  'lost_card': 'Użyta karta została zgłoszona jako zagubiona.',
  'restricted_card': 'Nie udało się zrealizować płatności (możliwe, że zgłoszono zgubienie lub kradzież Twojej karty).',
  'stolen_card': 'Twoja karta została zgłoszona jako skradziona.',
  'withdrawal_count_limit_exceeded': 'Nastąpiło przekroczenie limitu płatności dla Twojej karty.',  
} as Record<string, string>;

export default composeStripeCardErrorMessage;
