import styled from 'styled-components';

import Image from './Image';

export const Item = styled.li`
  margin: 24px 0;
  display: flex;
  column-gap: 12px;
  line-height: 1.5;

  ${Image} {
    width: 52px;
    height: 52px;
  }
`;

export const SecondaryLabel = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.palette.text.medium};
`;

export const Label = styled.span`
  display: inline;
  font-weight: bold;
  font-size: 19px;
  margin-right: 8px;
`;

export const Price = styled.span`
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: ${p => p.theme.palette.text.medium};
  del {
    color: ${p => p.theme.palette.text.low};
  }
  strong {
    color: ${p => p.theme.palette.primary.dark};
  }
`;
