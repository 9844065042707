import { useMemo } from 'react';
import { Button, WidgetLayout } from '../../../components';
import { SubscriptionProductPriceDetails } from '../../../models/SubscriptionProductPriceDetails';
import StepManager from '../StepManager';
import Stepper from '../Stepper';
import OrderSummary from '../OrderSummary';
import { OrderItem } from '../OrderItem';
import CouponSection from '../CouponSection';
import ErrorMessage from '../ErrorMessage';
import { useSignUpContext } from '../context';

import { StripePaymentElement } from './SinglePayment';
import { useCreateAccountAndPaymentIntent, useConfirmIntent } from './usePaymentIntentSecret';
import { useScrollToTop } from '../../../hooks';

export function OnePaymentSignUp() {
  const [{
    status,
    coupon,
    currentStep,
    paymentMethodRequired,
    paymentMethodProvided,
    product,
  }] = useSignUpContext();
  const register = useCreateAccountAndPaymentIntent();
  const confirmPayment = useConfirmIntent();
  useScrollToTop([currentStep]);

  const price = useMemo(() => {
    return SubscriptionProductPriceDetails.compose(product, coupon);
  }, [product, coupon]);

  const buttonLabel = !paymentMethodRequired || paymentMethodProvided || currentStep === 'paymentMethod'
    ? 'Sfinalizuj transakcję'
    : 'Przejdź do płatności';

  const buttonAction = !paymentMethodRequired || paymentMethodProvided || currentStep === 'paymentMethod'
    ? () => confirmPayment()
    : () => register();

  const isButtonActive = paymentMethodProvided || currentStep === 'userDetailsAndConsents';
  const toBeBilledToday = price.basePrice;

  return (
    <WidgetLayout>
      <StepManager stepper={<Stepper />} paymentElement={<StripePaymentElement />} />
      <WidgetLayout.WidgetArea>
        <OrderSummary
          orderItems={[
            <OrderItem
              key={product.id}
              label={product.name}
              secondaryLabel={product.description}
              price={price}
              isRenewable={product.isRenewable}
            />
          ]}
          couponSection={<CouponSection />}
          toBeBilledToday={toBeBilledToday}
          finalizeButton={(
            <Button
              submit
              onClick={buttonAction}
              label={buttonLabel}
              pending={status === 'signUpPending'}
              disabled={!isButtonActive}
            />
          )}
        />
      </WidgetLayout.WidgetArea>
      <ErrorMessage />
    </WidgetLayout>
  );
}
