export type SubscriptionProductId = Branded<string, 'subscriptionProductId'>;

export enum Interval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
};

export enum EndBehaviour {
  Cancel = 'cancel',
  Release = 'release',
  OneTime = 'one_time',
};

export interface SubscriptionProductDto {
  id: SubscriptionProductId;
  name:	string;
  legacyName: string;
  description: string;
  active:	boolean;
  hidden:	boolean;
  price: number;
  interval: Interval;
  intervalCount: number;
  trialDays: number;
  endBehaviour: 'cancel' | 'release' | 'one_time';
  extraTrackingScripts: string[] | null;
}

export interface SubscriptionProduct {
  id: SubscriptionProductId;
  name:	string;
  legacyName: string;
  description: string;
  active:	boolean;
  hidden:	boolean;
  price: number;
  interval: Interval;
  intervalCount: number;
  trialDays: number;
  endBehaviour: 'cancel' | 'release' | 'one_time';
  extraTrackingScripts: string[] | null;
  isRenewable: boolean;
}

export class SubscriptionProduct {
  static fromDto = (dto: SubscriptionProductDto): SubscriptionProduct => ({
    id: dto.id,
    name:	dto.name,
    legacyName: dto.legacyName,
    description: dto.description,
    active:	dto.active,
    hidden:	dto.hidden,
    price: dto.price,
    interval: dto.interval,
    intervalCount: dto.intervalCount,
    trialDays: dto.trialDays,
    endBehaviour: dto.endBehaviour,
    isRenewable: dto.endBehaviour === 'release',
    extraTrackingScripts: dto.extraTrackingScripts?.length ? dto.extraTrackingScripts : null,
  });
}
