import styled from 'styled-components';

import { ReactComponent as Svg } from './arrow.svg';

export const ToggleArrow = styled.div.attrs({
  children: <Svg />,
})<{open: boolean}>`
  width: 16px;
  height: 16px;
  transition: transform .2s;
  transform: rotate(${p => p.open ? 0 : 180}deg);
  position: relative;
  flex-shrink: 0;

  & > svg {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;
