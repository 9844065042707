import { UserActivitySummary } from 'src/models/UserActivitySummary';
import { useQuery } from 'react-query';

import {
  Wrapper,
  Welcome,
  Heading,
  Items,
  Item,
  ItemName,
  ItemCount,
  ItemCountLoader,
} from './styles';

export const ActivitySummary: React.FC<{
  username: string;
  fetchSummary: () => Promise<UserActivitySummary>;
}> = ({
  username,
  fetchSummary,
}) => {
  const { data: summary, isFetching } = useQuery(
    'activitySummary',
    fetchSummary,
    { refetchOnWindowFocus: false },
  );

  return (
    <Wrapper>
      <Welcome>Witaj ponownie, {username}!</Welcome>
      <Heading>Twoja praktyka</Heading>
      <Items>
        <Item>
          <ItemName>Minuty <br />na macie</ItemName>
          {(!isFetching && summary) ? <ItemCount delay={0}>{Math.round(summary.totalDuration / 60)}</ItemCount> : <ItemCountLoader delay={0} />}
        </Item>
        <Item>
          <ItemName>Zakończone <br />sesje</ItemName>
          {(!isFetching && summary) ? <ItemCount delay={500}>{summary.completedSessions}</ItemCount> : <ItemCountLoader delay={500} />}
        </Item>
        <Item>
          <ItemName>Zakończone <br />wyzwania</ItemName>
          {(!isFetching && summary) ? <ItemCount delay={1000}>{summary.completedChallenges}</ItemCount> : <ItemCountLoader delay={1000} />}
        </Item>
      </Items>
    </Wrapper>
  );
};
