import { SubscriptionProduct, SubscriptionProductDto } from './SubscriptionProduct';

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
};

export interface SubscriptionDto {
  active: boolean;
  status: SubscriptionStatus;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  cancelAt: string | null;
  trialEnd: string | null;
  currentPrice: number;
  lastInvoiceUrl: string | null;
  subscriptionProduct: SubscriptionProductDto | null;
  nextSubscriptionProduct: SubscriptionProductDto | null;
}

export interface Subscription {
  status: SubscriptionStatus;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  cancelAt: Date | null;
  trialEnd: Date | null;
  currentPrice: number;
  lastInvoiceUrl: string | null;
  subscriptionProduct: SubscriptionProduct | null;
  nextSubscriptionProduct: SubscriptionProduct | null;
}

export class Subscription {
  static fromDto = (dto: SubscriptionDto): Subscription => ({
    status: dto.status,
    currentPeriodStart: new Date(dto.currentPeriodStart),
    currentPeriodEnd: new Date(dto.currentPeriodEnd),
    cancelAt: dto.cancelAt ? new Date(dto.cancelAt) : null,
    trialEnd: dto.trialEnd ? new Date(dto.trialEnd) : null,
    currentPrice: dto.currentPrice,
    lastInvoiceUrl: dto.lastInvoiceUrl,
    subscriptionProduct:
      dto.subscriptionProduct
        ? SubscriptionProduct.fromDto(dto.subscriptionProduct)
        : null,
    nextSubscriptionProduct:
      dto.nextSubscriptionProduct
        ? SubscriptionProduct.fromDto(dto.nextSubscriptionProduct)
        : null,
  });

  static ignoreNextProductIfSame = (subscription: Subscription): Subscription =>
    subscription.nextSubscriptionProduct?.id === subscription.subscriptionProduct?.id
      ? { ...subscription, nextSubscriptionProduct: null }
      : subscription;
}
