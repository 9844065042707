import * as Sentry from '@sentry/browser';

import { sentryDSN, sentryRelease, environment } from 'src/config';

export default class ErrorReportingService {
  static init() {
    if (process.env.NODE_ENV === 'production' && sentryRelease) {
      Sentry.init({
        dsn: sentryDSN,
        release: sentryRelease,
        environment,
      });
    }
  }

  static report = (message: any) => {
    console.error('ErrorReportingService', message);
    // TODO: send to external error reporting service
  }

  static warn = (message: any) => {
    console.warn('ErrorReportingService', message);
    // TODO: send to external error reporting service
  }
}
