import styled from 'styled-components';

export const screenSize = {
  small: '@media (max-width: 499px)',
  large: '@media (min-width: 500px)',

  withoutSideMenu: '@media (max-width: 1024px)',
  withSideMenu: '@media (min-width: 1025px)',
};

export const Layout = styled.div`
  display: grid;
  margin: -48px 0;
  height: calc(100% + 2 * 48px);
  ${screenSize.withSideMenu} {
    grid-template-columns: 434px 1fr;
  }
`;

export const MenuArea = styled.div`
  grid-column: 1;
  border-right: ${p => p.theme.border};
  padding: 48px 24px 48px 0;
  ${screenSize.withoutSideMenu} {
    display: none;
  }
`;

export const ContentArea = styled.div`
  padding: 48px 0;
  ${screenSize.withSideMenu} {
    grid-column: 2;
    margin-left: 24px;
  }
  display: flex;
  flex-direction: column;
`;

export const Spacer = styled.div`
  height: 24px;
`;

export const Divider = styled.div`
  border-top: ${p => p.theme.border};
  ${screenSize.withSideMenu} {
    margin-left: -24px;
  }
`;

export const InSectionHeading = styled.h3`
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3px;
  margin-bottom: 24px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: -8px 0 0 -8px;
`;

export const ActionButton = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;;
  padding: 8px;
  position: relative;
  z-index: 1;

  color: ${p => p.theme.palette.primary.dark};
  transition: background .2s, color .2s;
  &:hover {
    z-index: 2;
    color: #ffffff;
    background-color: ${p => p.theme.palette.primary.main};
  }
`;