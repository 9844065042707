const getSessionMiniature = (
  thumbnails: Array<{ width: number; link: string;}> | undefined,
  minWidth: number
): string => {
  if (!thumbnails)
    return '';
  thumbnails.sort((a, b) => a.width < b.width ? -1 : 1);
  let chosenThumbnail = '';
  thumbnails.forEach(item => {
    if (item.width > minWidth)
      return;
    chosenThumbnail = item.link;
  })
  return chosenThumbnail;
}

export default getSessionMiniature;
