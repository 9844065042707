import { FilterTrait } from 'src/models/SessionFilters';

export type TypeId = Branded<string, 'typeId'>;

export interface Type {
  id: TypeId;
  name: string;
  description: string | null;
  trait: FilterTrait.Type;
};

export type TypeDto = Type;

export class Type {
  static fromDto = (dto: TypeDto): Type => ({
    id: dto.id,
    name: dto.name,
    description: dto.description,
    trait: FilterTrait.Type,
  });
}
