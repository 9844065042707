import { TextField } from '@mui/material';

import { AuthBox, Button, PasswordInput } from 'src/components';
import { isNativePlatform } from 'src/config';
import LegacyUserInfo from './LegacyUserInfo';
import {
  Container,
  SignUpPrompt,
  SignUpLink,
  ErrorInfo,
  Form,
  LoginIssuePrompt,
  LoginIssueLink,
  TermsPrompt,
  TermsLink,
} from './styles';

import useLogin from './useLogin';
import useRedirect from './useRedirect';

export const Login: React.FC = () => {
  useRedirect();
  const {
    email,
    setEmail,
    setEmailFocus,
    password,
    setPassword,
    isError,
    isPending,
    onSubmit,
    isLegacyPlatformUser,
  } = useLogin();

  return (
    <Container>
      <AuthBox title="Witaj ponownie">
        {!isNativePlatform && (
          <>
            <SignUpPrompt>Jesteś nowym użytkownikiem?</SignUpPrompt>
            <SignUpLink href="https://portalyogi.pl/dolacz-do-studia-portalyogi" target="_blank">Dołącz do nas</SignUpLink>
          </>
        )}
        {isError && (
          <ErrorInfo message={'Podano niepoprawne dane logowania.'} />
        )}
        <Form>
          <TextField
            label="Adres e-mail"
            size='small'
            fullWidth
            variant='outlined'
            value={email}
            name='email'
            onChange={event => {setEmail(event.target.value.toLocaleLowerCase())}}
            disabled={isPending}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            data-testid='username-input'
          />
          <PasswordInput
            size='small'
            value={password}
            label="Hasło"
            name='password'
            onChange={event => {setPassword(event.target.value)}}
            data-testid='password-input'
          />
          <Button
            label="Zaloguj się"
            fullWidth
            onClick={onSubmit}
            pending={isPending}
            disabled={isLegacyPlatformUser}
            submit
          />
        </Form>
      </AuthBox>
      <LoginIssuePrompt>
        Problem z zalogowaniem?
        <LoginIssueLink to="/reset-password">Odzyskaj hasło</LoginIssueLink>
      </LoginIssuePrompt>
      <TermsPrompt>
        {'Logując się do serwisu, akceptujesz nasz '}
        <TermsLink href="https://www.portalyogi.pl/regulamin" rel="noreferrer" target="_blank" >regulamin</TermsLink>
      </TermsPrompt>
      <LegacyUserInfo />
    </Container>
  );
};
