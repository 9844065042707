import React, { memo } from 'react';

import { cleanString } from 'src/utils';

import {
  Annotation,
  Checkmark,
  Label,
  Text,
  Wrapper,
} from './styles';

export const MultiselectOption: React.FC<{
  label: string;
  annotation?: string;
  inputValue: string;
  selected: boolean;
  checkbox?: boolean;
} & React.HTMLAttributes<HTMLLIElement>> = memo(({
  label,
  annotation,
  inputValue,
  selected,
  checkbox,
  ...liProps
}) => {
  const matchStart = cleanString(label).indexOf(cleanString(inputValue));
  const matchEnd = matchStart !== -1
    ? matchStart + inputValue.length
    : -1;

  const beforeMatch = label.slice(0, matchStart);
  const highlight = label.slice(matchStart, matchEnd);
  const afterMatch = label.slice(matchEnd, label.length);

  return (
    <Wrapper {...liProps}>
      {checkbox && <Checkmark selected={selected} />}
      <Label>
        <Text>{beforeMatch}</Text>
        <Text highlight>{highlight}</Text>
        <Text>{afterMatch}</Text>
        {annotation && <Annotation> {annotation}</Annotation>}
      </Label>
    </Wrapper>
  );
});
