import { transformCoachDto } from 'src/models/Coach';
import { transformExtraFileDto } from 'src/models/ExtraFile';
import { Session, SessionDto } from 'src/models/Session';
import { transformVideoDto } from 'src/models/video';

export type ChallengeSessionId = Branded<string, 'challengeSessionId'>;

export type ChallengeSessionDto =
  Omit<SessionDto, 'id'>
  & { id: ChallengeSessionId };

type ChallengeSession_ =
  Omit<Session, 'id' | 'contentType' | 'isCompleted' | 'isLiked'>
  & {
    id: ChallengeSessionId;
    contentType: 'challengeSession';
  };

export interface ChallengeSession extends ChallengeSession_ { }

export class ChallengeSession {
  static fromDto = (dto: ChallengeSessionDto): ChallengeSession => ({
    id: dto.id,
    contentType: 'challengeSession',
    title: dto.title ?? '',
    underline: dto.underline ?? '',
    difficulty: dto.difficulty,
    primaryDescription: dto.primaryDescription ?? '',
    secondaryDescription: dto.secondaryDescription ?? '',
    published: dto.published,
    coach: dto.coach?.[0] ? transformCoachDto(dto.coach?.[0]) : null,
    style: dto.style?.[0] ?? null,
    type: dto.type ?? [],
    tag: dto.tag ?? [],
    equipment: dto.equipment ?? [],
    video: dto.video ? transformVideoDto(dto.video) : null,
    createdDate: new Date(dto.createdDate),
    updatedDate: new Date(dto.updatedDate),
    horizontalThumbnail: dto.horizontalThumbnail?.url ?? null,
    extras: (dto.extras ?? [])
      .map(transformExtraFileDto)
      .sort((a, b) => a.createdDate.getTime() - b.createdDate.getTime()),
  });
}
