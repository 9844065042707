import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { ErrorReportingService, HttpService } from 'src/services';
import {
  Challenge,
  ChallengeDto,
  ChallengeId,
  ChallengeSession,
  ChallengeSessionDto,
  ChallengeSessionId,
  ChallengeStepId,
} from 'src/models/Challenge';
import { ChallengeQuery } from 'src/models/Challenge';
import { GetManyResponse } from 'src/types';
import { Comment, CommentDto, CommentId } from 'src/models/Comment';
import { composeQuerySearch } from './ChallengesService.helper';

const createBaseChallengeQueryBuilder = () =>
  new RequestQueryBuilder()
    .setJoin({ field: 'coach' })
    .setJoin({ field: 'equipment' })
    .setJoin({ field: 'style' })
    .setJoin({ field: 'tag' })
    .setJoin({ field: 'type' })
    .setJoin({ field: 'extras' })
    .setJoin({ field: 'video' })
    .setJoin({ field: 'video.thumbnail' })
    .setJoin({ field: 'horizontalThumbnail' })
    .setJoin({ field: 'verticalThumbnail' });

const createBaseChallengeSessionQueryBuilder = () =>
  new RequestQueryBuilder()
    .setJoin({ field: 'coach' })
    .setJoin({ field: 'equipment' })
    .setJoin({ field: 'style' })
    .setJoin({ field: 'tag' })
    .setJoin({ field: 'type' })
    .setJoin({ field: 'extras' })
    .setJoin({ field: 'video' })
    .setJoin({ field: 'video.thumbnail' })
    .setJoin({ field: 'horizontalThumbnail' });

const composeQuery = (query?: ChallengeQuery) => {
  const qb = createBaseChallengeQueryBuilder()
    .sortBy({ field: 'updatedDate', order: 'DESC' })
    .setFilter({ field: 'published', operator: '$eq', value: true });

  if (query?.limit) {
    qb.setLimit(query.limit);
  }
  if (query?.page) {
    qb.setPage(query.page);
  }

  if (query?.filters) {
    const searchConditions = composeQuerySearch(query?.filters);
    qb.search(searchConditions);
  }

  return qb.query();
};

const ChallengessService = {

  getAll: (): Promise<GetManyResponse<Challenge>> => HttpService
    .get<GetManyResponse<ChallengeDto>>(`/public/challenges?published=true&${createBaseChallengeQueryBuilder().query()}`)
    .then(response => ({
      ...response,
      data: response.data.map(c => Challenge.fromDto(c, { excludeUnpublishedSteps: true }))
    })),

  getMany: (query?: ChallengeQuery): Promise<GetManyResponse<Challenge>> => HttpService
    .get<GetManyResponse<ChallengeDto>>(`/public/challenges?${composeQuery(query)}`)
    .then(response => ({
      ...response,
      data: response.data.map(c => Challenge.fromDto(c, { excludeUnpublishedSteps: true }))
    })),

  getById: (id: ChallengeId, { excludeUnpublishedSteps = true } = {}): Promise<Challenge> => HttpService
    .get<ChallengeDto>(`/public/challenges/${id}?${createBaseChallengeQueryBuilder().query()}`)
    .then(c => Challenge.fromDto(c, { excludeUnpublishedSteps })),

  getByIdV2: (id: ChallengeId | ChallengeSessionId,
    { excludeUnpublishedSteps = true, bySession }: { excludeUnpublishedSteps?: boolean, bySession?: boolean } = {}): Promise<Challenge> => HttpService
    .get<ChallengeDto>(`/public/challenges/v2/${id}${bySession === true ? '?bySession' : ''}`)
    .then(c => Challenge.fromDto(c, { excludeUnpublishedSteps })),

  getSessionById: async (id: ChallengeSessionId) => HttpService
    .get<ChallengeSessionDto>(
      `/public/challenges/sessions/${id}?${createBaseChallengeSessionQueryBuilder().query()}`
    )
    .then(ChallengeSession.fromDto),

  toggleChallengeLiked: (id: ChallengeId, status = true) => HttpService
    .patch(`/public/challenges/${id}/activity/like?status=${status}`),

  markChallengeVisited: (id: ChallengeId) => HttpService
    .patch(`/public/challenges/${id}/activity/visit?status=true`),

  toggleChallengeStepCompleted: (id: ChallengeStepId, status: boolean) => HttpService
    .patch(`/public/challenges/step/${id}/activity/view?status=${status}`)
    .catch(() => ErrorReportingService.report(`Could not mark challenge as visited; challenge id: ${id}`)),

  getComments: async ({
    challengeId,
    challengeSessionId,
    page,
    limit,
  }: {
    challengeId?: ChallengeId,
    challengeSessionId?: ChallengeSessionId,
    page: number,
    limit: number
  }): Promise<GetManyResponse<Comment>> => {
    const builder = new RequestQueryBuilder();
    if (challengeId) {
      builder.setFilter({ field: 'challenge.id', operator: '$eq', value: challengeId });
    } else if (challengeSessionId) {
      builder.setFilter({ field: 'challengeSession.id', operator: '$eq', value: challengeSessionId });
    } else {
      throw new Error('No challege or challenge session ID was provided');
    }
    const query = builder
      .setPage(page)
      .setLimit(limit)
      .sortBy({ field: 'createdDate', order: 'DESC' })
      .query();
    const response = await HttpService
      .get<GetManyResponse<CommentDto>>(`/public/comment?${query}`);
    return {
      ...response,
      data: response.data.map(Comment.fromDto)
    };
  },

  postChallengeComment: (challengeId: ChallengeId, content: string) => HttpService
    .post<CommentDto>('/public/comment', { challengeId, content })
    .then(Comment.fromDto),

  postChallengeSessionComment: (challengeSessionId: ChallengeSessionId, content: string) => HttpService
    .post<CommentDto>('/public/comment', { challengeSessionId, content })
    .then(Comment.fromDto),

  removeComment: (commentId: CommentId) => HttpService
    .delete(`/public/comment/${commentId}`),

  editComment: (commentId: CommentId, content: string) => HttpService
    .patch<CommentDto>(`/public/comment/${commentId}`, { content })
    .then(Comment.fromDto),

  hideComment: (commentId: CommentId) => HttpService
    .patch<CommentDto>(`/comment/${commentId}`, { hidden: true })
    .then(Comment.fromDto),

  unhideComment: (commentId: CommentId) => HttpService
    .patch<CommentDto>(`/comment/${commentId}`, { hidden: false })
    .then(Comment.fromDto),
};

export default ChallengessService;
