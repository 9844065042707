import styled from 'styled-components';
import { HeartFilled, HeartOutline } from 'src/icons';
import { useCallback } from 'react';

export const Button = styled.button<{ liked?: boolean; pending?: boolean; }>`
  display: block;
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 4px;
  border: ${p => p.theme.border};
  outline: none;
  cursor: pointer;
  background: transparent;

  transition: color .2s;
  color: ${p => p.liked ? p.theme.palette.secondary.light : p.theme.palette.text.medium};

  ${HeartOutline}, ${HeartFilled} {
    position: absolute;
    top: 9px;
    left: 9px;
    width: 18px;
    height: 18px;
    transition: fill .2s, opacity .2s;
  }

  ${HeartFilled} {
    fill: ${p => p.theme.palette.secondary.light};
    opacity: ${p => p.liked ? 1 : 0};
  }

  &:hover ${HeartOutline} {
    fill: ${p => p.theme.palette.secondary.light};
  }

  &:disabled {
    pointer-events: none;
  }
`;

const LikeButton: React.FC<{
  liked: boolean;
  onToggle?: () => void;
}> = ({
  liked,
  onToggle,
}) => {
  const handleClick = useCallback((event: React.MouseEvent) => {
    if (!onToggle) return;
    event.preventDefault();
    onToggle();
  }, [onToggle]);

  return (
    <Button
      liked={liked}
      onClick={handleClick}
      disabled={!onToggle}
    >
      <HeartFilled />
      <HeartOutline />
    </Button>
  );
}

export default LikeButton;
