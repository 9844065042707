import { StripeCardError, StripeError } from 'src/errors';
import { UserId } from 'src/models/User';

import { State, Reducer, CardError } from './types';

export const initialState: State = {
  authStatus: 'idle',
  changeCardStatus: 'idle',
  changeSubscriptionStatus: 'idle',
  cancelSubscriptionStatus: 'idle',
  undoCancelSubscriptionStatus: 'idle',
  manualPaymentStatus: 'idle',
  cardError: null,
  cardErrorCode: null,
  cardErrorDeclineCode: null,
  cardErrorDismissed: false,
  showInactiveSubscriptionInfo: false,
  user: {
    id: '' as UserId,
    active: false,
    archived: false,
    createdDate: new Date(0),
    name: '',
    email: '',
    roles: [],
    cardSuffix: '',
    hasAdminAccess: false,
    subscription: null,
    coupon: null,
    isEligibleForCoupons: false,
  },
}

export const userReducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'logIn':
    case 'checkAuth': return {
      ...state,
      authStatus: 'pending',
    };

    case 'logInSuccess':
    case 'checkAuthSuccess': return {
      ...state,
      user: action.payload,
      authStatus: 'loggedIn',
    };

    case 'logInFailure': {
      if (state.authStatus === 'legacyPlatformUser') {
        return state;
      }
      return {
        ...state,
        authStatus: 'error',
      };
    }

    case 'setLegacyPlatformUser': {
      if (state.authStatus !== 'idle' && state.authStatus !== 'error') {
        return state;
      }
      return {
        ...state,
        authStatus: 'legacyPlatformUser',
      };
    }

    case 'checkAuthFailure': return (
      initialState
    );

    case 'logOut': return (
      initialState
    );

    case 'toggleInactiveSubscriptionInfo': return {
      ...state,
      showInactiveSubscriptionInfo: action.payload,
    };

    case 'openCardForm': return {
      ...state,
      changeCardStatus: 'open',
    };

    case 'changeCard': return {
      ...state,
      changeCardStatus: 'pending',
    };

    case 'changeCardSuccess': return {
      ...state,
      changeCardStatus: 'idle',
      user: {
        ...state.user,
        cardSuffix: action.payload,
      },
    };

    case 'changeCardFailure': {
      let cardError: CardError;
      if (action.payload instanceof StripeError) {
        cardError = CardError.GenericPaymentFailure;
      } else if (action.payload instanceof StripeCardError) {
        cardError = CardError.SpecificPaymentFailure;
      } else {
        cardError = CardError.UnknownError;
      }

      return {
        ...state,
        cardError,
        cardErrorDismissed: false,
        cardErrorCode: action.payload instanceof StripeCardError ? action.payload.code : null,
        cardErrorDeclineCode: action.payload instanceof StripeCardError ? action.payload.declineCode : null,
        changeCardStatus: 'error',
      };
    }

    case 'changeSubscription': return {
      ...state,
      changeSubscriptionStatus: 'pending',
    };

    case 'changeSubscriptionSuccess': return {
      ...state,
      changeSubscriptionStatus: 'idle',
      user: action.payload,
    };

    case 'changeSubscriptionFailure': {
      let cardError: CardError;
      if (action.payload instanceof StripeError) {
        cardError = CardError.GenericPaymentFailure;
      } else if (action.payload instanceof StripeCardError) {
        cardError = CardError.SpecificPaymentFailure;
      } else {
        cardError = CardError.UnknownError;
      }

      return {
        ...state,
        cardError,
        cardErrorDismissed: false,
        cardErrorCode: action.payload instanceof StripeCardError ? action.payload.code : null,
        cardErrorDeclineCode: action.payload instanceof StripeCardError ? action.payload.declineCode : null,
        changeSubscriptionStatus: 'error',
      };
    }

    case 'dismissCardError': return {
      ...state,
      cardErrorDismissed: true,
    };

    case 'cancelSubscription': return {
      ...state,
      cancelSubscriptionStatus: 'pending',
    };

    case 'cancelSubscriptionSuccess': return {
      ...state,
      cancelSubscriptionStatus: 'idle',
      user: action.payload,
    };

    case 'cancelSubscriptionFailure': return {
      ...state,
      cancelSubscriptionStatus: 'error',
    };

    case 'undoCancelSubscription': return {
      ...state,
      undoCancelSubscriptionStatus: 'pending',
    };

    case 'undoCancelSubscriptionSuccess': return {
      ...state,
      undoCancelSubscriptionStatus: 'idle',
      user: action.payload,
    };

    case 'undoCancelSubscriptionFailure': return {
      ...state,
      undoCancelSubscriptionStatus: 'error',
    };

    case 'triggerManualPayment': return {
      ...state,
      manualPaymentStatus: 'pending',
    };

    case 'triggerManualPaymentSuccess': return {
      ...state,
      manualPaymentStatus: 'idle',
      showInactiveSubscriptionInfo: false,
      user: action.payload,
    };

    case 'triggerManualPaymentFailure': {
      let cardError: CardError;
      if (action.payload instanceof StripeError) {
        cardError = CardError.GenericPaymentFailure;
      } else if (action.payload instanceof StripeCardError) {
        cardError = CardError.SpecificPaymentFailure;
      } else {
        cardError = CardError.UnknownError;
      }

      return {
        ...state,
        cardError,
        cardErrorDismissed: false,
        cardErrorCode: action.payload instanceof StripeCardError ? action.payload.code : null,
        cardErrorDeclineCode: action.payload instanceof StripeCardError ? action.payload.declineCode : null,
        manualPaymentStatus: 'error',
      };
    }

    case 'couponRedeemed': return {
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    };

    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
  }
};
