import { Coupon, CouponDto } from './Coupon';
import { Subscription, SubscriptionDto } from './Subscription';

export type UserId = Branded<string, 'userId'>;

export enum UserRole {
  User = 'user',
  Admin = 'admin',
  Superadmin = 'superadmin',
  Owner = 'owner',
  Junior = 'junior',
  Manager = 'manager',
};

export const backofficeRoles = [
  UserRole.Admin,
  UserRole.Superadmin,
  UserRole.Owner,
  UserRole.Junior,
  UserRole.Manager,
];

export interface UserDto {
  id: UserId;
  createdDate: string;
  active: boolean;
  archived: boolean;
  name: string;
  email: string;
  roles: UserRole[];
  cardSuffix: string | null;
  subscription: SubscriptionDto | null;
  coupon: CouponDto | null;
}

export interface User {
  id: UserId;
  createdDate: Date;
  active: boolean;
  archived: boolean;
  name: string;
  email: string;
  roles: UserRole[];
  cardSuffix: string | null;
  hasAdminAccess: boolean;
  subscription: Subscription | null;
  coupon: Coupon | null;
}

export const transformUserDto = (dto: UserDto): User => ({
  id: dto.id,
  createdDate: new Date(dto.createdDate),
  active: dto.active,
  archived: dto.archived,
  name: dto.name,
  email: dto.email,
  roles: dto.roles,
  cardSuffix: dto.cardSuffix,
  hasAdminAccess: dto.roles.some(role => [
    UserRole.Owner,
    UserRole.Superadmin,
    UserRole.Admin,
    UserRole.Manager,
    UserRole.Junior,
  ].includes(role)),
  subscription: dto.subscription
    ? Subscription.ignoreNextProductIfSame(Subscription.fromDto(dto.subscription))
    : null,
  coupon: dto.coupon ? Coupon.fromDto(dto.coupon) : null,
});
