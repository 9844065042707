import { HomepageSection } from './HomepageSection';

export interface CmsContentDto {
  carouselConfig: unknown;
  carouselItems: unknown;
  welcomeVideo: unknown;
  homepageSection: {
    sections: HomepageSection[];
  };
  linkConfig: Record<string, string | undefined>;
}

export interface CmsContent {
  homepageSections: HomepageSection[];
  links: {
    practicePlan: string | null;
  }
}

export const transformCmsContentDto = (dto: CmsContentDto): CmsContent => ({
  homepageSections: dto.homepageSection.sections ?? [],
  links: {
    practicePlan: dto.linkConfig.practicePlan || null,
  },
});
