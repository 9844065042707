import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Session, SessionId } from 'src/models/Session';
import { ErrorReportingService, SessionsService } from 'src/services';
import { useBackNavigationInfo } from 'src/hooks';

export const useSession = (id: SessionId) => useQuery(
  ['sessions', id],
  () => SessionsService.getById(id),
  { refetchOnWindowFocus: false },
);

export const useSimilarSessions = (params: Parameters<typeof SessionsService.getSimilar>[0]) => {
  return useQuery(
    ['similarSessions', params],
    () => params.type.length
      ? SessionsService.getSimilar(params)
      : Promise.resolve([] as Session[]),
    {
      staleTime: Infinity,
    }
  );
};

export const useReturnLink = () => {
  const backInfo = useBackNavigationInfo();
  return {
    to: backInfo.link ?? '/sessions',
    label: backInfo.linkLabel ?? 'Wszystkie sesje',
  };
};

export const useToggleCompleted = () => {
  const queryClient = useQueryClient();
  return useCallback((
    id: SessionId,
    status: boolean,
  ) => {
    SessionsService.toggleCompleted(id, status)
      .then(() => queryClient.invalidateQueries(['sessions', id]))
      .catch(ErrorReportingService.report);
  }, [queryClient]);
};

export const useMarkVisited = (id: SessionId) => useEffect(
  () => { SessionsService.markVisited(id); },
  [id]
);
