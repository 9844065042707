import { Collapse } from '@mui/material';
import { SearchResultsSummary } from 'src/components';

import { isNativePlatform } from '../../config';
import AllSelectedFilters from '../SessionSearchbox/AllSelectedFilters';
import Multiselect from '../SessionSearchbox/Multiselect';
import MultiselectWithCounter from '../SessionSearchbox/MultiselectWithCounter';
import {
  Wrapper,
  Field,
  BasicFields,
  AdvancedFields,
  ButtonArea,
  ModeToggle,
  ModeIcon,
  ModeLabel,
  ClearButton,
  ClearIcon,
  ClearLabel,
  SearchResultsButton,
  SearchResultsIcon,
  SearchResultsLabel,
  SearchResultsExpand,
  SimplifiedSearchArea,
  SimplifiedSearchInput
} from '../SessionSearchbox/styles/searchboxStyles';
import useChallengeSearch from './useChallengeSearch2';

export const ChallengeSearchbox: React.FC<{
  results?: number;
  searchPending: boolean;
}> = ({
  results = undefined,
  searchPending = false,
}) => {
  const {
    options,
    searchLink,
    aggregatedOptions,
    values,
    onChange,
    onClearFilter,
    onClearAllFilters,
    onAddForeignValue,
    onPhraseConfirm,
    advancedMode,
    toggleAdvancedMode,
    displayFilters,
    toggleDisplayFilters
  } = useChallengeSearch();

  return (
    <>
      <Wrapper>
        <Collapse in={isNativePlatform ? displayFilters : true}>
        <BasicFields>
          <Field area="tag">
            <Multiselect
              label="Wyszukaj frazę"
              id="tags-and-phrase-select"
              name="title"
              aggregatedOptions={aggregatedOptions}
              aggregatedValues={values.aggregated}
              tagValues={values.tag}
              onTagsChange={onChange.tag}
              onAddForeignValue={onAddForeignValue}
              phrase={values.phrase}
              onPhraseChange={onChange.phrase}
              onPhraseConfirm={onPhraseConfirm}
            />
          </Field>
          <Field area="duration">
            <MultiselectWithCounter
              label="Czas trwania"
              id="duration-select"
              name="duration"
              options={options.duration}
              values={values.duration}
              onChange={onChange.duration}
              noTextInput
            />
          </Field>
          <Field area="difficulty">
            <MultiselectWithCounter
              label="Poziom trudności"
              id="difficulty-select"
              name="difficulty"
              options={options.difficulty}
              values={values.difficulty}
              onChange={onChange.difficulty}
              noTextInput
            />
          </Field>
        </BasicFields>
        </Collapse>
        <Collapse in={isNativePlatform ? displayFilters && advancedMode : advancedMode}>
          <AdvancedFields>
            <Field area="coach">
              <MultiselectWithCounter
                label="Nauczyciel"
                id="coach-select"
                name="coach"
                options={options.coach}
                values={values.coach}
                onChange={onChange.coach}
              />
            </Field>
          </AdvancedFields>
        </Collapse>
        {
          isNativePlatform ?
            displayFilters ?
              <ButtonArea>
                <ModeToggle onClick={toggleAdvancedMode} advancedMode={advancedMode}>
                  <>
                    <ModeIcon/>
                    <ModeLabel>{advancedMode ? 'Pokaż mniej' : 'Pokaż więcej'}</ModeLabel>
                  </>
                </ModeToggle>
                <SearchResultsButton to={searchLink} mobile={isNativePlatform}>
                  <SearchResultsLabel>Pokaż wyniki</SearchResultsLabel>
                  <SearchResultsIcon/>
                </SearchResultsButton>
              </ButtonArea>
              :
              <SimplifiedSearchArea>
                <SimplifiedSearchInput>
                  <Field area="tag">
                    <Multiselect
                      label="Wyszukaj frazę"
                      id="tags-and-phrase-select"
                      name="title"
                      aggregatedOptions={aggregatedOptions}
                      aggregatedValues={values.aggregated}
                      tagValues={values.tag}
                      onTagsChange={onChange.tag}
                      onAddForeignValue={onAddForeignValue}
                      phrase={values.phrase}
                      onPhraseChange={onChange.phrase}
                      onPhraseConfirm={onPhraseConfirm}
                    />
                  </Field>
                </SimplifiedSearchInput>
                <SearchResultsExpand onClick={toggleDisplayFilters} mobile={isNativePlatform}>
                  <SearchResultsLabel>Pokaż filtry</SearchResultsLabel>
                </SearchResultsExpand>
              </ SimplifiedSearchArea>
            :
            <ButtonArea>
              <ModeToggle onClick={toggleAdvancedMode} advancedMode={advancedMode}>
                <ModeIcon />
                <ModeLabel>{advancedMode ? 'Pokaż mniej' : 'Pokaż więcej'}</ModeLabel>
              </ModeToggle>
              <SearchResultsButton to={searchLink}>
                <SearchResultsLabel>Pokaż wyniki</SearchResultsLabel>
                <SearchResultsIcon />
              </SearchResultsButton>
            </ButtonArea>
        }
      </Wrapper>
    <SearchResultsSummary
      results={results ?? NaN}
      pending={searchPending}
      showIf={results != null}
    />
    <AllSelectedFilters
      filters={values.all}
      onClearFilter={onClearFilter}
      clearAllButton={(
        <ClearButton onClick={onClearAllFilters}>
          <ClearIcon />
          <ClearLabel>Wyczyść wszystko</ClearLabel>
        </ClearButton>
      )}
    />
    </>
  );
};
