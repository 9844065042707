import { FilterOptionsState } from '@mui/material';
import isEqual from 'lodash/fp/isEqual';

import { cleanString } from 'src/utils';
import { FilterCond, FilterTrait } from 'src/models/SessionFilters';
import { FilterOption } from './types';

export const createFilterOptionsHandler = ({ minInputLength = 0, maxResults = -1 }) =>
  <T extends { name: string; }>(options: T[], { inputValue }: FilterOptionsState<T>) => {
    const input = cleanString(inputValue);
    if (inputValue.length < minInputLength) {
      return [];
    }
    return options
      .filter(option => cleanString(option.name).includes(input))
      .slice(0, maxResults === -1 ? undefined : maxResults);
  };

/**
 * Specifies how long a single keyword (part of user-eneterd phrase) needs to be
 * when keywords are matched against available filter names in an attempt to
 * automatically add those filters on input blur/on Enter press.
 * 
 * Eg. if it's set at `5`and the user enters phrase "something not very specific",
 * only the words "something" and "specific" will be matched.
 * 
 * Keeping this reasonably  long prevents including too many items
 * that might seem unrelated.
 */
const minWordLengthForAutomatch = 4;

export const testMatch = (phrase: string, optionName: string, { exact = true }) => {
  const against = cleanString(optionName);
  const wholePhrase = cleanString(phrase);
  const keywords = wholePhrase
    .split(' ')
    .filter(word => word.length >= minWordLengthForAutomatch);

  if (!keywords.length) {
    return false;
  }

  if (exact) {
    return (
      wholePhrase === against ||
      keywords.some(word => word === against)
    );
  }

  return (
    wholePhrase.includes(against) ||
    keywords.some(word => word.includes(against))
  );
};

export const matchFilterToOption = (filter: FilterCond, option: FilterOption) => {
  return (
    filter.trait === option.trait &&
    isEqual(getFilterValue(option), filter.value)
  );
};

export const getFilterValue = (option: FilterOption) => {
  switch (option.trait) {
    case FilterTrait.Coach: return option.id;
    case FilterTrait.Style: return option.id;
    case FilterTrait.Tag: return option.id;
    case FilterTrait.Type: return option.id;
    case FilterTrait.Duration: return option.value;
    case FilterTrait.Difficulty: return option.value;
    case FilterTrait.Phrase: return option.value;
  }
};

export const getFilterName = (option: FilterOption) => {
  switch (option.trait) {
    case FilterTrait.Coach: return option.name;
    case FilterTrait.Style: return option.name;
    case FilterTrait.Tag: return option.name;
    case FilterTrait.Type: return option.name;
    case FilterTrait.Duration: return option.name;
    case FilterTrait.Difficulty: return `Poziom: ${option.value}`;
    case FilterTrait.Phrase: return `„${option.value}”`;
  }
};
