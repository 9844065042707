import styled from 'styled-components';

export const Wrapper = styled.p`
  font-weight: bold;
  font-size: 19px;
  line-height: 120%;
  letter-spacing: 0.3px;
  margin: 24px 0 0 0;
`;

export const RegularText = styled.span`
  color: ${p => p.theme.palette.text.medium};
`;

export const ResultsCount = styled.span`
  &:before{ content: " " };
  color: ${p => p.theme.palette.primary.dark};
`;
