import mobileLogo from 'src/assets/black_logo.svg';

import { Button } from 'src/components';
import { useEffect, useState } from 'react';
import { isAndroid } from '../../config';
import LegacyUserInfo from '../Login/LegacyUserInfo';
import useLogin from '../Login/useLogin';
import useRedirect from '../Login/useRedirect';
import {
  Container,
  LoginIssueLink,
  LoginRegisterLink,
  LoginTextField,
  LoginPasswordField,
  LoginWrapper,
  LoginTitle,
  LoginLogo,
  LoginRegisterWrapper,
  LoginForm,
  LoginBottomWrapper,
  LoginInputWrapper,
  LoginButtonWrapper
} from './LoginMobile.styles';

export const LoginMobile: React.FC = () => {
  useRedirect();
  const {
    email,
    setEmail,
    setEmailFocus,
    password,
    setPassword,
    isError,
    isPending,
    onSubmit,
    isLegacyPlatformUser,
  } = useLogin();
  const [emailError, setEmailError] = useState('');
  const registerLink = isAndroid ?
    'https://portalyogi.pl/?src=[googleplay]-[]-[]&utm_source=googleplay&utm_medium=&utm_campaign='
    : 'https://portalyogi.pl/?src=[appstore]-[]-[]&utm_source=appstore&utm_medium=&utm_campaign=';

  const onEmailChange = (event: { target: { value: string; }; }) => {
    setEmail(event.target.value.toLocaleLowerCase());
    setEmailError('');
  }

  useEffect(() => {
    if (isError) {
      setEmailError('Podano niepoprawne dane logowania.')
    }
  }, [isError]);

  return (
    <Container>
      <LoginWrapper>
        <LoginLogo src={mobileLogo} />
        <LoginBottomWrapper>
          <LoginTitle>
            Zaloguj się
          </LoginTitle>
          <LoginForm>
            <LoginInputWrapper>
              <LoginTextField
                label="Adres e-mail"
                size='small'
                fullWidth
                variant='outlined'
                value={email}
                name='email'
                onChange={onEmailChange}
                disabled={isPending}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                data-testid='username-input'
                error={!!emailError}
                helperText={emailError}
              />
            </LoginInputWrapper>
            <LoginInputWrapper>
              <LoginPasswordField
                size='small'
                value={password}
                label="Hasło"
                name='password'
                onChange={event => {setPassword(event.target.value)}}
                data-testid='password-input'
              />
            </LoginInputWrapper>
            <LoginButtonWrapper>
              <Button
                label="Zaloguj się"
                fullWidth
                onClick={onSubmit}
                pending={isPending}
                disabled={isLegacyPlatformUser}
                color='dark'
                submit
              />
            </LoginButtonWrapper>
          </LoginForm>
          <LoginIssueLink to="/reset-password">Nie pamiętasz hasła?</LoginIssueLink>
          <LoginRegisterWrapper>
            Nie masz konta?
            <LoginRegisterLink href={registerLink} rel="noreferrer" target="_blank" >Zarejestruj się na stronie internetowej portalyogi.pl</LoginRegisterLink>
          </LoginRegisterWrapper>
          <LegacyUserInfo />
        </LoginBottomWrapper>
      </LoginWrapper>
    </Container>
  );
};
