import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from './arrow.svg';

export const Layout = styled.header`
  display: grid;
  grid-template-areas: "title link"
                       "underline link";
  @media (max-width: 800px) {
    grid-template-areas: "title title"
                         "underline underline"
                         "link link";
  }
`;

const HeadingElement = styled.h1``;
const PolymorphicHeading: React.FC<{ as: 'h1' | 'h2' | 'h3' }> = ({ children }) => {
  return <HeadingElement>abc {children}</HeadingElement>;
}

export const Title = styled(PolymorphicHeading)`
  grid-area: title;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.3px;
`;

export const Underline = styled.span`
  grid-area: underline;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.15px;

`;

export const Link = styled(RouterLink)`
  grid-area: link;
  justify-self: flex-end;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.palette.common.black};
  align-self: flex-end;
  @media only screen and (max-width: 800px) {
    margin-top: 16px;
  }
`;

export const LinkIcon = styled.span.attrs(p => ({
  children: <ArrowIcon/>,
}))`
  display: inline-block;
  position: relative;
  margin-left: 8px;
  transform: translateY(2px);
  width: 14px;
  height: 14px;
  transition: opacity 0.2s;

  & svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    transition: fill 0.2s;
    fill: ${p => p.theme.palette.primary.dark};
  }
  ${Link}:hover & svg {
    fill: ${p => p.theme.palette.primary.main};
  }
`;
