import { FilterTrait } from 'src/models/SessionFilters';

import { FilterOption } from '../types';
import { getFilterName } from '../utils';

import FilterChip from './FilterChip';
import { Wrapper, ClearButtonWrapper } from './styles';

const AllSelectedFilters: React.FC<{
  filters: FilterOption[];
  onClearFilter: (item: FilterOption, index: number) => void;
  clearAllButton: JSX.Element;
}> = ({
  filters,
  onClearFilter,
  clearAllButton,
}) => (
  <Wrapper>
    {filters.map((filter, index) => filter.trait !== FilterTrait.Phrase && (
      <FilterChip
        key={JSON.stringify(filter)}
        label={getFilterName(filter)}
        onRemove={() => onClearFilter(filter, index)}
      />
    ))}
    {filters.length > 0 && (
      <ClearButtonWrapper>
        {clearAllButton}
      </ClearButtonWrapper>
    )}
  </Wrapper>
);

export default AllSelectedFilters;
