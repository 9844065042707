import classnames from 'classnames';

import {ReactComponent as PdfIcon} from 'src/assets/icons/pdf_icon.svg';
import {ReactComponent as DownloadIcon} from 'src/assets/icons/download_icon.svg';
import { ExtraFile } from 'src/models/ExtraFile';

import styles from './Downloads.module.scss';

type FileWithUrl = Omit<ExtraFile, 'url'> & {url: string};

const Downloads: React.FC<{
  className?: string;
  files: ExtraFile[];
}> = ({
  className,
  files
}) => {

  const renderFileIcon = (fileType: string) => {
    switch (fileType.toLowerCase()) {
      case 'pdf':
        return (
          <div className={styles.Icon}>
            <PdfIcon/>
            <p>{fileType.toUpperCase()}</p>
          </div>
        );
      default:
        return <i/>;
    }
  };

  return (
    <div className={classnames(styles.Container, className)}>
      {
        files
          .filter((file): file is FileWithUrl => typeof file.url === 'string' && file.url !== '')
          .map(file => (
            <a className={styles.File} key={file.id} href={file.url} download>
              {
                renderFileIcon(file.fileExtension)
              }
              <div className={styles.Name}>
                  {file.name}
              </div>
              <DownloadIcon/>
            </a>
          ))
      }
    </div>
  );
};

export default Downloads;
