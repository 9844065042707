import styled from 'styled-components';
import { TextField } from '@mui/material';

import { Button } from 'src/components';

export const CodeInput = styled(TextField).attrs({
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
})`
  background: ${p => p.theme.palette.common.white};
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;

export const LookupButton = styled(Button)`
  margin: 2px;
  line-height: 20px;
`;

export const ResetButton = styled(Button).attrs({
  color: 'default',
  variant: 'outlined',
})`
  margin: 2px;
  line-height: 20px;
`;
