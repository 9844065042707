import { useUserActions, useUserState } from 'src/contexts/UserContext';
import { useEffect, useState } from 'react';

type State = {
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setEmailFocus: (isFocused: boolean) => void;
  setPassword: (password: string) => void;
  onSubmit(event: React.MouseEvent): void;
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  isLegacyPlatformUser: boolean;
}

const useLogin = (): State => {
  const { authStatus } = useUserState();
  const { logIn, checkIfIsLegacyPlatformUser } = useUserActions();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailFocus, setEmailFocus] = useState(false);

  useEffect(() => {
    if (email && !emailFocus) {
      checkIfIsLegacyPlatformUser(email);
    }
  }, [email, emailFocus, checkIfIsLegacyPlatformUser]);

  const onSubmit = (event: React.MouseEvent) => {
    event?.preventDefault();
    logIn(email.trim(), password);
  }

  return {
    email,
    setEmail,
    setEmailFocus,
    password,
    setPassword,
    onSubmit,
    isPending: authStatus === 'pending',
    isError: authStatus === 'error',
    isSuccess: authStatus === 'loggedIn',
    isLegacyPlatformUser: authStatus === 'legacyPlatformUser',
  };
};

export default useLogin;
