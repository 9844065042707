import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';

import { ChipWrapper, ChipLabel, ChipDelete } from './styles';

const FilterChip: React.FC<{
  label: string;
  onRemove: () => void;
}> = ({
  label,
  onRemove,
}) => {
  const [entered, setEntered] = useState(false);
  const [shouldRemove, scheduleRemoval] = useState(false);
  useEffect(() => setEntered(true), []);
  useEffect(() => {
    if (shouldRemove) {
      scheduleRemoval(false)
      onRemove();
    }
  }, [shouldRemove, onRemove]);

  return (
    <Collapse in={entered} onExited={() => scheduleRemoval(true)}>
      <ChipWrapper>
        <ChipLabel>{label}</ChipLabel>
        <ChipDelete onClick={() => setEntered(false)} />
      </ChipWrapper>
    </Collapse>
  )
};

export default FilterChip;
