import styled from 'styled-components';

const screen = {
  s: '@media (max-width: 540px)',
  m: '@media (min-width: 541px) and (max-width: 939px)',
  l: '@media (min-width: 940px)',
};

export const List = styled.ul<{multiplePages: boolean;}>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  column-gap: 24px;
  row-gap: 48px;
  margin-top: 24px;
  margin-bottom: ${p => p.multiplePages ? 48 : 0}px;

  ${screen.s} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  ${screen.m} {
    grid-template-columns: repeat(2, minmax(0, 1fr));    
  }
  ${screen.l} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const Item = styled.li`
  display: block;
`;

export const SearchboxWrapper = styled.div`
  margin: 24px 0 48px 0;
`;