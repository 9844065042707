import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ChallengesService } from 'src/services';
import { useChallengeQueryParams, useScrollToTop } from 'src/hooks';
import { BackLink, Carousel, Header, Pagination, SessionCard } from 'src/components';
import ChallengeSearchbox from 'src/features/ChallengeSearchbox';
import { isNativePlatform } from '../../config';

import {
  List,
  Item,
  SearchboxWrapper,
} from './styles';
import { arrayOfNumbers } from 'src/utils/array';
import { BackNavigationInfo } from 'src/models/BackNavigationInfo';
import { SessionQuery } from 'src/models/SessionQuery';

const defaultTitle = 'Wyzwania';
const defaultUnderline = '';

const itemsPerPage = 12;

const ChallengeLibrary: React.FC = () => {
  const history = useHistory();
  const query = useChallengeQueryParams();

  const backInfo: BackNavigationInfo = {
    link: `${history.location.pathname}${history.location.search}`,
    linkLabel: 'Wyzwania',
  };

  const { data, isFetching } = useQuery(
    ['ChallengeLibrary', query],
    () => ChallengesService.getMany({
      ...query,
      limit: itemsPerPage,
      page: query.page ?? 1,
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  useScrollToTop([query.page]);

  const handlePageClick = (selectedPage: number) => {
    const newPage = selectedPage + 1;
    const params = SessionQuery.serializeToUrlParams({
      ...query,
      page: newPage,
    });
    history.push({ search: params });
  };

  const challenges = data?.data ?? [];
  const page = data?.page ?? 0;
  const pageCount = data?.pageCount ?? 0;

  const title = query.title || defaultTitle;
  const underline = query.underline || (query.title ? '' : defaultUnderline);

  const actualItemCount = isFetching ? null : challenges.length;
  const displayItemCount = actualItemCount ?? itemsPerPage;

  return (
    <>
      <BackLink
        label='Strona główna'
        to="/home"
      />
      {
        !isNativePlatform &&
          <Carousel page="challenges" />
      }
      <Header
        title={title}
        underline={underline}
        as="h1"
      />
      <SearchboxWrapper>
        <ChallengeSearchbox
          searchPending={isFetching}
          results={data?.total}
        />
      </SearchboxWrapper>
      <List multiplePages={pageCount > 1}>
        {arrayOfNumbers(displayItemCount).map(index => (
          <Item key={index}>
            <SessionCard
              mode="vertical"
              session={challenges[index]}
              order={index}
              backNavigationInfo={backInfo}
            />
          </Item>
        ))}
      </List>
      <Pagination
        page={page}
        pageCount={pageCount}
        onPageChange={handlePageClick}
      />
    </>
  );
};

export default ChallengeLibrary;
