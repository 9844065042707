import { Difficulty } from 'src/models/Difficulty';
import React, { memo } from 'react';

import {
  Checkmark,
  DifficultyIndicators,
  Wrapper,
} from './styles';

export const MultiselectDifficultyOption: React.FC<{
  difficulty: Difficulty['value'];
  selected: boolean;
} & React.HTMLAttributes<HTMLLIElement>> = memo(({
  difficulty,
  selected,
  ...liProps
}) => (
  <Wrapper {...liProps}>
    <Checkmark selected={selected} />
    <DifficultyIndicators difficulty={Number(difficulty)} />
  </Wrapper>
));
