import { ChallengeStep, ChallengeStepDto, ChallengeStepUpdateDto } from './ChallengeStep';

export type ChallengeStepsDto = ChallengeStepDto[];

export type ChallengeStepsUpdateDto = ChallengeStepUpdateDto[];

export interface ChallengeSteps extends Array<{
  title: string;
  steps: ChallengeStep[];
}> {}

export class ChallengeSteps {
  static fromDto = (dto: ChallengeStepsDto, { excludeUnpublishedSteps = false } = {}): ChallengeSteps => {
    const stepsByGroup = dto
    .sort((a, b) => a.order - b.order)
    .filter(step => excludeUnpublishedSteps ? step.challengeSession?.published : true)
    .reduce((steps, step) => ({
      ...steps,
      [step.group]: [
        ...steps[step.group] ?? [],
        ChallengeStep.fromDto(step),
      ]
    }), {} as Record<string, ChallengeStep[]>);
    
    return Object.entries(stepsByGroup)
      .map(([group, steps]) => ({
        title: group,
        steps,
      }));
  }

  static toUpdateDto = (steps: ChallengeSteps): ChallengeStepsUpdateDto => {
    return steps
      .flatMap(({ steps }) => steps)
      .map(ChallengeStep.toUpdateDto);
  };
}
