import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  Container,
  Message,
  ExclamationCircle,
  ExclamationTriangle,
  CheckCircle,
  PendingOverlay,
  PendingLoader,
} from './styles';

const Icon = {
  info: ExclamationCircle,
  success: CheckCircle,
  warning: ExclamationTriangle,
  error: ExclamationCircle,
};

export const InfoBox: React.FC<{
  message: React.ReactNode;
  mode: 'info' | 'warning' | 'success' | 'error';
  noAnimation?: boolean;
  delay?: number;
  className?: string;
  onClick?: () => void;
  pending?: boolean;
}> = ({
  message,
  mode,
  noAnimation = false,
  delay = 0,
  className,
  onClick,
  pending = false,
}) => {
  const ModeIcon = Icon[mode];
  const [isVisible, setIsVisible] = useState(noAnimation);
  useEffect(() => {
    if (noAnimation) {
      return;
    }
    const ref = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(ref);
  }, [delay, noAnimation]);
  return (
    <Collapse in={isVisible} className={className}>
      <Container mode={mode} onClick={pending ? undefined : onClick}>
        <ModeIcon />
        <Message>{message}</Message>
        {pending && (
          <PendingOverlay mode={mode}>
            <PendingLoader />
          </PendingOverlay>
        )}
      </Container>
    </Collapse>
  );
};
