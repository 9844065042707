import { TextField } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { InfoBox, PasswordInput } from 'src/components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginWrapper = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  min-height: 600px;
  max-width: 500px;
`;

export const LoginLogo = styled.img`
  width: 56px;
  height: 56px;
`

export const LoginTitle = styled.p`
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  text-align: center;
  color: #2E2E2E;
`;

export const LoginTextField = styled(TextField)`
  .MuiInputBase-input {
    color: ${p => p.theme.palette.common.black};
  }
  .MuiFormLabel-root {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  .MuiFormLabel-root.Mui-focused {
    color: black !important;
  }

  .MuiOutlinedInput-root {
    height: 48px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
    border-width: 1px !important;
  }
`;

export const LoginPasswordField = styled(PasswordInput)`
  .MuiFormLabel-root {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  
  .MuiFormLabel-root.Mui-focused {
    color: black !important;
  }

  .MuiOutlinedInput-root {
    height: 48px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
    border-width: 1px !important;
  }
`;

export const SignUpPrompt = styled.p`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.medium};
`;

export const SignUpLink = styled.a`
  display: block;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const ErrorInfo = styled(InfoBox).attrs({
  mode: 'error',
})`
  width: 100%;
  margin-top: 24px;
`;

export const LoginBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LoginForm = styled.form`
  margin: 38px 0 80px 0;
  width: 100%;
  display: grid;
`;

export const LoginInputWrapper = styled.div`
  width: 100%;
  height: 82px;
`;

export const LoginButtonWrapper = styled.div`
  margin-top: 5px;
`;

export const LoginIssuePrompt = styled.p`
  margin-top: 24px;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
`;

export const LoginIssueLink = styled(Link)`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 32px;
  color: #2E2E2E;
  &:before { content: " " }
  transition: color .2s;
  &:hover, &:focus {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const LoginRegisterWrapper = styled.p`
  display: flex;
  flex-direction: column;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  max-width: 250px;
`;

export const LoginRegisterLink = styled.a`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  
  &:before { content: " " }
  color: rgba(46, 46, 46, 1);
  transition: color .2s;
  &:hover, &:focus {
    color: rgba(46, 46, 46, 0.6);
  }
`;
