import styled from 'styled-components';

const Wrapper = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
`;

const Shape = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '-2.5 -2.5 37 37',
})`
  height: 100%;
  width: 100%;
  transform: rotate(-90deg);
`;

const BackgroundRing = styled.circle.attrs({
  cx: 16,
  cy: 16,
  r: 16,
})`
  fill: none;
  stroke: #e2eff0;
  stroke-width: 5;
`;

const ProgressRing = styled.circle.attrs({
  cx: 16,
  cy: 16,
  r: 16,
})<{progress: number}>`
  fill: none;
  stroke: #e2eff0;
  stroke: #78bec7;
  stroke-dasharray: 100 100;
  stroke-dashoffset: ${p => 100 - (p.progress * 100)};
  stroke-width: 5;
  transition: stroke-dashoffset 1s ease-in-out;
`;

const Label = styled.span`
  font-size: 13px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.high};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CircularProgress: React.FC<{
  progress: number;
}> = ({
  progress,
}) => {

  return (
    <Wrapper>
      <Shape>
        <BackgroundRing/>
        <ProgressRing progress={progress}/>
      </Shape>
      <Label>{`${Math.floor(progress * 100)}%`}</Label>
    </Wrapper>
  );
}
