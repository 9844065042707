import styled from 'styled-components';

import { ReactComponent as CheckmarkFilledSvg } from './checkmarkFilled.svg';
import { ReactComponent as CheckmarkOutlineSvg } from './checkmarkOutline.svg';

export const ValidationRules = styled.ul`
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
  border: ${p => p.theme.border};
  border-radius: 4px;
  padding: 16px 8px 8px 8px;
  display: grid;
  grid-row-gap: 16px;
  position: relative;
  z-index: 100;
`;



export const Rule = styled.li`
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 13px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.medium};
`;

export const RuleStatusIcon = styled.div.attrs({
  children: <><CheckmarkFilledSvg /><CheckmarkOutlineSvg /></>
})<{ isOk: boolean }>`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  position: relative;
  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .2s;
  }
  & > svg:nth-child(1) {
    fill: ${p => p.theme.palette.primary.dark};
    opacity: ${p => p.isOk ? 1 : 0};
  }
  & > svg:nth-child(2) {
    fill: ${p => p.theme.palette.text.high};
    opacity: ${p => p.isOk ? 0 : 1};
  }
`;