import { FormEvent, useEffect, useState } from 'react';
import { StripePaymentElementOptions, StripePaymentElementChangeEvent, StripeElementsOptions } from '@stripe/stripe-js';
import {
  PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { Button } from '../../../components';
import { StripeProvider } from '../../../features/StripeCardForm';
import { UsersService } from '../../../services';
import { useUserState } from '../../../contexts/UserContext';
import { SubscriptionProductDto } from '../../../models/SubscriptionProduct';
import { useSignUpContext } from '../context';

export function StripePaymentElement() {
  const [{ currentStep, userDetails }, dispatch] = useSignUpContext();

  const onChange = (e: StripePaymentElementChangeEvent) => {
    dispatch({ type: 'togglePaymentMethodProvided', provided: e.complete });
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'accordion',
    defaultValues: {
      billingDetails: {
        email: userDetails.email,
        name: `${userDetails.firstName} ${userDetails.lastName}`
      }
    }
  };

  if (currentStep !== 'paymentMethod') {
    return <div />;
  }

  return (
    <div>
      <PaymentElement id="payment-element" options={paymentElementOptions} onChange={onChange}/>
    </div>
  );
}

function PaymentElementInternal({ onCancel }: { onCancel?: () => void }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUserState();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const onChange = (e: StripePaymentElementChangeEvent) => {
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/complete`,
        payment_method_data: {
          billing_details: {
            email: user.email,
          },
        },
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message!);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'accordion',
    defaultValues: {
      billingDetails: {
        email: user.email,
        name: user.name
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} onChange={onChange} />
      <Button
        label="Wróć"
        pending={isLoading}
        disabled={isLoading}
        onClick={onCancel}
      />
      <Button
        submit
        label="Zapłać"
        pending={isLoading}
        disabled={isLoading}
      />
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export function PurchaseElement({ product, onCancel }: { product: SubscriptionProductDto, onCancel?: () => void }) {
  const [ clientSecret, setClientSecret ] = useState<string>();

  useEffect(() => {
    UsersService.paymentIntent(product!.id).then(res => setClientSecret(res.clientSecret));
  }, [product]);
  
  const options: StripeElementsOptions = {
    clientSecret
  };

  return clientSecret ? (
    <StripeProvider options={options} key={options.clientSecret}>
      <PaymentElementInternal onCancel={onCancel} />
    </StripeProvider>
  ) : <div>Loading...</div>;
}
