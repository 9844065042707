import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/fp/isEqual';

import {
  useSessionQuery,
  useCoaches,
  useStyles,
  useTypes,
  useTags,
  useDurationRanges,
  useDifficulties,
  useLastDifferentValue,
} from 'src/hooks';
import { removeItem } from 'src/utils/array';
import { FilterTrait, FilterValue, SessionFilters } from 'src/models/SessionFilters';
import { SessionQuery } from 'src/models/SessionQuery';
import { Coach } from 'src/models/Coach';
import { Equipment } from 'src/models/Equipment';
import { Style } from 'src/models/Style';
import { Type } from 'src/models/Type';
import { Tag } from 'src/models/Tag';
import { DurationRange } from 'src/models/DurationRange';
import { Difficulty } from 'src/models/Difficulty';

import { FilterOption } from './types';
import {
  testMatch,
  matchFilterToOption,
} from './utils';
import { useDebouncedValue } from 'src/hooks/useDebounce';

const liveSearchDebounce = 500;

const useSessionSearch = ({
  queryTitle,
  queryUnderline,
  pathBase,
  liveSearch = false,
}: {
  queryTitle: string;
  queryUnderline: string;
  pathBase: string;
  liveSearch?: boolean;
}) => {
  const debounceDelay = liveSearch ? liveSearchDebounce : 0;

  const { location } = useHistory();
  const query = useSessionQuery();
  const [filters, setFilters] = useState<SessionFilters>(query.filters ?? []);
  const [page, setPage] = useState(query.page ?? 0);

  useEffect(() => setFilters(query.filters ?? []), [query]);

  const committedFilters = useDebouncedValue(filters, { debounceDelay, emitFirst: true });
  const prevCommittedFilters = useLastDifferentValue(committedFilters);

  // Reset page if, and only if the filters in effect have actually changed,
  // ie. if the user has changed filter settings.
  useEffect(() => {
    const filtersDidChange = !isEqual(committedFilters ?? [], prevCommittedFilters ?? []);
    if (filtersDidChange) {
      setPage(0);
    }
  }, [committedFilters, prevCommittedFilters])

  const history = useHistory();
  const searchLink = useMemo(() => `/${pathBase}?${SessionQuery.serializeToUrlParams({
    filters,
    ...filters.length ? {
      title: queryTitle,
      underline: queryUnderline,
    } : {},
    ...page ? { page } : {},
  })}`, [filters, queryTitle, queryUnderline, page, pathBase]);
  const liveSearchLink = useDebouncedValue(searchLink, { debounceDelay });

  const [followLink, setFollowLink] = useState(false);
  useEffect(() => {
    if (followLink) {
      history.push(searchLink);
      setFollowLink(false);
    }
  }, [searchLink, followLink, history]);

  useEffect(() => {
    if (liveSearch && liveSearchLink) {
      history.replace(liveSearchLink);
      setFollowLink(false);
    }
  }, [liveSearch, liveSearchLink, history])

  const [advancedMode, setAdvancedMode] = useState(() => query.filters?.some(
    f => [FilterTrait.Coach, FilterTrait.Style, FilterTrait.Type].includes(f.trait),
  ));
  const toggleAdvancedMode = useCallback(
    (forceMode?: boolean) => setAdvancedMode(
      mode => typeof forceMode === 'boolean' ? forceMode : !mode
    ),
    [],
  );

  const [displayFilters, setDisplayFilters] = useState(false);
  const toggleDisplayFilters = () => setDisplayFilters(true)

  const allCoaches = useCoaches();
  const allStyles = useStyles();
  const allTypes = useTypes();
  const allTags = useTags();
  const allDurationRanges = useDurationRanges();
  const allDifficulties = useDifficulties();

  const options = useMemo(
    () => ({
      coaches: allCoaches ?? [],
      styles: allStyles ?? [],
      types: allTypes ?? [],
      tags: allTags ?? [],
      durationRanges: allDurationRanges,
      difficulties: allDifficulties,
    }),
    [allCoaches, allStyles, allTypes, allTags, allDurationRanges, allDifficulties],
  );

  const aggregatedOptions = useMemo(
    () => [...options.tags, ...options.types, ...options.styles, ...options.coaches]
      .sort((a, b) => a.name.localeCompare(b.name)),
    [options],
  );

  const ready = !!allCoaches && !!allStyles && !!allTypes && !!allTags;

  const onFiltersChange = useCallback((trait: FilterTrait, values: FilterValue[]) => {
    setFilters(filters => {
      const changedFilters = values.map(value => ({ trait, value }));
      const filtersToAdd = differenceWith(changedFilters, filters, isEqual);
      const filtersToRemove = filters.filter(f => f.trait === trait && !values.some(isEqual(f.value)));
      return (
        differenceWith(filters, filtersToRemove).concat(filtersToAdd)
      );
    });
  }, []);

  const onAddForeignValue = useCallback((value: Style | Type) => {
    const { trait, id } = value || {};
    const newFilter = { trait, value: id };
    setFilters(filters => filters.concat(newFilter));
    if (newFilter) {
      toggleAdvancedMode(true);
    }
  }, [toggleAdvancedMode]);

  const onPhraseChange = useCallback((phrase: string) => {
    setFilters(filters => {
      const withoutPhrase = filters.filter(f => f.trait !== FilterTrait.Phrase);
      const filterToAdd = !!phrase ? { trait: FilterTrait.Phrase, value: phrase } : [];
      return withoutPhrase.concat(filterToAdd);
    });
  }, []);

  const values = useMemo(() => {
    const allOptions = Object.values(options).flat();
    const selectedOptions = filters
      .map(filter => (
        filter.trait === FilterTrait.Phrase
          ? { name: '', ...filter }
          : allOptions.find(option => matchFilterToOption(filter, option)) as FilterOption
      ))
      .filter((option): option is FilterOption => !!option)
    return {
      all: selectedOptions,
      coaches: selectedOptions.filter(o => o.trait === FilterTrait.Coach) as Coach[],
      styles: selectedOptions.filter(o => o.trait === FilterTrait.Style) as Style[],
      types: selectedOptions.filter(o => o.trait === FilterTrait.Type) as Type[],
      tags: selectedOptions.filter(o => o.trait === FilterTrait.Tag) as Tag[],
      durationRanges: selectedOptions.filter(o => o.trait === FilterTrait.Duration) as DurationRange[],
      difficulties: selectedOptions.filter(o => o.trait === FilterTrait.Difficulty) as Difficulty[],
      phrase: filters.find(f => f.trait === FilterTrait.Phrase)?.value as string ?? '',
    };
  }, [filters, options]);

  const aggregatedValues = useMemo(
    () => [...values.tags, ...values.types, ...values.styles, ...values.coaches],
    [values],
  );

  // Fires off when the phrase/tags field loses focus or receives [Enter]. The idea
  // is to find any tags/styles/types/coaches that match the current phrase
  // but are not yet selected, and add them automatically.
  const onPhraseConfirm = useCallback((phrase: string, { triggerSearch = false } = {}) => {
    setFilters(filters => {
      let filtersDraft = [...filters];
      const alreadyHasPhraseFilter = filters.some(f => f.trait === FilterTrait.Phrase);
      if (alreadyHasPhraseFilter) {
        const withoutPhrase = filters.filter(f => f.trait !== FilterTrait.Phrase);
        const newPhraseFilter = !!phrase ? { trait: FilterTrait.Phrase, value: phrase } : [];
        filtersDraft = withoutPhrase.concat(newPhraseFilter);
      }

      // Types of filters that will be analyzed in search for ones matching the phrase
      // Include e.g. 'FilterTrait.Tag' to automatically add all matching tags
      const relevantTraits = [] as FilterTrait[];
      type RelevantOption = Tag | Type | Style | Coach;

      const selectedOptions = Object.values(values).flat();
      const unselectedOptions = Object.values(options)
        .flat()
        .filter((option): option is RelevantOption => relevantTraits.includes(option.trait))
        .filter((option) => !selectedOptions.some(isEqual(option)));
      const optionsToAdd = unselectedOptions
        .filter((option) => testMatch(phrase, option.name, { exact: option.trait !== FilterTrait.Tag }))

      if (optionsToAdd.length) {
        const extraFilters = optionsToAdd.map(({ trait, id: value }) => ({ trait, value }));
        filtersDraft = filtersDraft.concat(extraFilters);
        toggleAdvancedMode(true);
      }

      return filtersDraft;
    });
    setFollowLink(triggerSearch);
  }, [options, values, toggleAdvancedMode]);

  const onClearAllFilters = useCallback(() => {
    setFilters(() => []);
  }, []);

  const onClearFilter = useCallback((_: FilterOption, index: number) => {
    setFilters(filters => {
      return removeItem(index, filters)
    });
  }, []);

  useEffect(() => {
    setDisplayFilters(false);
  }, [location.pathname]);

  return useMemo(() => ({
    filters,
    committedFilters,
    searchLink,
    advancedMode,
    toggleAdvancedMode,
    displayFilters,
    toggleDisplayFilters,
    ready,
    options,
    values,
    aggregatedOptions,
    aggregatedValues,
    onChange: {
      coaches: (coaches) =>
        onFiltersChange(FilterTrait.Coach, coaches.map(c => c.id)),
      equipment: (equipment) =>
        onFiltersChange(FilterTrait.Equipment, equipment.map(e => e.id)),
      styles: (styles) =>
        onFiltersChange(FilterTrait.Style, styles.map(s => s.id)),
      types: (types) =>
        onFiltersChange(FilterTrait.Type, types.map(t => t.id)),
      tags: (tags) =>
        onFiltersChange(FilterTrait.Tag, tags.map(t => t.id)),
      durationRanges: (ranges) =>
        onFiltersChange(FilterTrait.Duration, ranges.map(r => r.value)),
      difficulties: (difficulties) =>
        onFiltersChange(FilterTrait.Difficulty, difficulties.map(d => d.value)),
      phrase: onPhraseChange,
    },
    onAddForeignValue,
    onPhraseConfirm,
    onClearFilter,
    onClearAllFilters,
  } as SessionSearch), [
    filters,
    committedFilters,
    searchLink,
    advancedMode,
    toggleAdvancedMode,
    ready,
    options,
    values,
    aggregatedOptions,
    aggregatedValues,
    onFiltersChange,
    onPhraseChange,
    onAddForeignValue,
    onPhraseConfirm,
    onClearFilter,
    onClearAllFilters,
    displayFilters
  ]);
};

export default useSessionSearch;

type SessionSearch = {
  filters: SessionFilters;
  committedFilters: SessionFilters;
  searchLink: string;
  advancedMode: boolean;
  toggleAdvancedMode: () => void;
  displayFilters: boolean;
  toggleDisplayFilters: VoidFunction;
  ready: boolean;
  options: {
    coaches: Coach[];
    styles: Style[];
    types: Type[];
    tags: Tag[];
    durationRanges: DurationRange[];
    difficulties: Difficulty[];
  };
  values: {
    all: FilterOption[];
    coaches: Coach[];
    styles: Style[];
    types: Type[];
    tags: Tag[];
    durationRanges: DurationRange[];
    difficulties: Difficulty[];
    phrase: string;
  };
  aggregatedValues: (Tag | Type | Style | Coach)[];
  aggregatedOptions: (Tag | Type | Style | Coach)[];
  onChange: {
    coaches: (coaches: Coach[]) => void;
    equipment: (coaches: Equipment[]) => void;
    styles: (coaches: Style[]) => void;
    types: (coaches: Type[]) => void;
    tags: (coaches: Tag[]) => void;
    durationRanges: (coaches: DurationRange[]) => void;
    difficulties: (difficulties: Difficulty[]) => void;
    phrase: (phrase: string) => void;
  },
  onAddForeignValue: (value: Type | Style | Coach) => void;
  onPhraseConfirm: (phrase: string, options?: { triggerSearch: boolean }) => void;
  onClearFilter: (_: FilterOption, index: number) => void;
  onClearAllFilters: () => void;
};
