import styled from 'styled-components';
import { Collapse } from '@mui/material';

import { Button, InfoBox } from 'src/components';

export const Container = styled.div<{ isActive?: boolean; }>`
  border: ${p => p.theme.border};
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto;
  transition: filter .2s;
  ${p => p.isActive ? p.theme.shadows[3] : ''};
`;

export const ContainerLabel = styled.h3`
  grid-column: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
`;

export const StatusInfo = styled(InfoBox)`
  grid-column: 1 / span 2;
  ${p => p.mode === 'error' && `
    grid-row: 2;
    margin-bottom: 24px;
  `};
  ${p => p.mode === 'success' && `
    grid-row: 4;
    margin-top: 16px;
  `};
`;

export const EditModeToggle = styled.button`
  grid-column: 2;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.15px;
  background: none;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  justify-self: flex-end;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s, background .2s;
  border-bottom: 1px solid transparent;
  padding: 8px;
  transform: translate(8px, -8px);
  border-radius: 4px;
  &:hover {
    background: ${p => p.theme.palette.primary.main};
    color: #ffffff;
  }
`;

export const CurrentCardInfo = styled.div<{lastDigits: string;}>`
  grid-column: 1 / span 2;
  grid-row: 3;
  &:before { content: "•••• •••• •••• ${p =>p.lastDigits}"; }
`;

export const NewCard = styled(Collapse)`
  grid-column: 1 / span 2;
  grid-row: 3;
  background: #ffffff;
`;

export const CardForm = styled.form`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
    &>:nth-child(1) { grid-column: 1 / 3; }
    &>:nth-child(2) { grid-column: 1; }
    &>:nth-child(3) { grid-column: 2; }
  }
`;

export const Submit = styled(Button)`
  justify-self: flex-start;
`;
