import { CarouselItem, ItemType } from '../types';

import { FullImageItem } from './FullImageItem';
import { HalfImageItem } from './HalfImageItem';
import { VideoItem } from './VideoItem';

export const Item: React.FC<CarouselItem> = (props) => {
  switch (props.type) {
    case (ItemType.ImageLeft):
    case (ItemType.ImageRight):
      return <HalfImageItem {...props} />
    case (ItemType.ImageFull):
      return <FullImageItem {...props} />
    case (ItemType.Video):
      return <VideoItem {...props} />
  };
};
