import styled from 'styled-components';

export const Wrapper = styled.h3`
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3px;
  margin-bottom: 24px;
`;

export const Count = styled.span`
  &:before {
    content: " (";
  }
  &:after {
    content: ")"
  }
`;

export const Separator = styled.div`
  border-top: ${p => p.theme.border};
  margin: 24px 0;
`;
