import { DurationRange2 as DurationRange } from 'src/models/DurationRange';
import { FilterTrait } from 'src/models/SessionFilters';

const week = 7 * 24 * 60 * 60;
const ranges: DurationRange[] = [
  {
    name: 'do 7 dni',
    value: `${0},${week}`,
    trait: FilterTrait.Duration,
  },
  {
    name: '1-2 tyg',
    value: `${week},${2 * week}`,
    trait: FilterTrait.Duration,
  },
  {
    name: '2-4 tyg',
    value: `${2 * week},${4 * week}`,
    trait: FilterTrait.Duration,
  },
  {
    name: '>4 tyg',
    value: `${4 * week},`,
    trait: FilterTrait.Duration,
  },
];

export const useChallengeDurationRanges = () => ranges;
