import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useSignUpContext } from '../context';
import { useCouponLookup } from './useCouponLookup';

export const useApplyCouponFromUrl = () => {
  const [shouldSkip, setShouldSkip] = useState(false);
  const [{ couponCode }, dispatch] = useSignUpContext();
  const lookupCoupon = useCouponLookup();
  const { couponCode: urlCouponCode } = useParams<{ couponCode?: string }>();

  useEffect(() => {
    if (urlCouponCode) {
      dispatch({ type: 'setCouponCode', couponCode: urlCouponCode });
    }
  }, [dispatch, urlCouponCode]);

  useEffect(() => {
    if (shouldSkip || !couponCode) {
      return;
    }
    setShouldSkip(true);
    dispatch({ type: 'setCouponCode', couponCode });
    lookupCoupon();
  }, [shouldSkip, couponCode, lookupCoupon, dispatch]);

  useEffect(() => {
    setTimeout(() => setShouldSkip(true));
  }, []);
};
