import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeProvider } from '../../../features/StripeCardForm';
import { UsersService } from '../../../services';
import { useWelcomeViewContext } from '../../../contexts/WelcomeViewContext';
import { useUserContext } from '../../../contexts/UserContext/context';
import { User } from '../../../models/User';
import { sendEcommerceEvent } from '../hooks/useTrackSignUpEvent';
import { GTMEvent } from 'src/models/GoogleTagManager';

function PaymentCompleteInternal() {
  const history = useHistory();
  const stripe = useStripe();
  const [, dispatchUserAction ] = useUserContext();
  const { toggleWelcomeView } = useWelcomeViewContext();
  const [ message, setMessage ] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const params = new URLSearchParams(history.location.search);
    const showWelcome = params.get('showWelcome') != null;
    const clientSecret = params.get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    function handlePaymentSucceeded(user: User) {
      if (showWelcome) {
        dispatchUserAction({ type: 'logInSuccess', payload: { ...user, isEligibleForCoupons: false } });
        toggleWelcomeView(true);
        history.push(`/welcome`);
        const product = user.subscription!.subscriptionProduct!;
        const coupon = user.coupon?.code;
        sendEcommerceEvent(GTMEvent.Purchase, {
          product,
          user,
          discount_info: coupon
        })
      } else {
        dispatchUserAction({ type: 'triggerManualPaymentSuccess', payload: { ...user, isEligibleForCoupons: false } });
        history.push(`/me/payments`);
      }
    }

    async function refreshUser() {
      const user = await UsersService.getMe();
      const status = user.subscription?.status;
      const isActive = status === 'active' || status === 'trialing';
      if (isActive) {
        handlePaymentSucceeded(user);
      } else {
        setMessage('Your payment is processing.');
      }
    }

    async function handlePayment() {
      const { paymentIntent } = await stripe!.retrievePaymentIntent(clientSecret!);
      switch (paymentIntent!.status) {
        case 'succeeded':
          await UsersService.confirmPaymentIntent(paymentIntent!.id);
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    }

    handlePayment().catch(err => console.error(err)).finally(refreshUser);
  }, [stripe, history, dispatchUserAction, toggleWelcomeView]);

  return (
    <div id="payment-message">{message}</div>
  );
}

export function PaymentComplete() {
  return (
    <StripeProvider>
      <PaymentCompleteInternal />
    </StripeProvider>
  );
}
