import styled from 'styled-components';
import { Collapse } from '@mui/material';

import { Button, InfoBox, Loader } from 'src/components';
import { ManualPaymentSuccess } from '../ManualPaymentSuccess';

export const Container = styled.div<{ isActive?: boolean; }>`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  border: ${p => p.theme.border};
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  transition: filter .2s;
  ${p => p.isActive ? p.theme.shadows[3] : p.theme.shadows[1]};
`;

export const MainLoader = styled.div.attrs({
  children: <Loader delay={500} />
})`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerLabel = styled.h3`
  grid-column: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
`;

export const EditModeToggle = styled.button`
  grid-column: 2;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.15px;
  background: none;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  justify-self: flex-end;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s, background .2s;
  border-bottom: 1px solid transparent;
  padding: 8px;
  transform: translate(8px, -8px);
  border-radius: 4px;
  &:hover {
    background: ${p => p.theme.palette.primary.main};
    color: #ffffff;
  }
`;

export const Subscriptions = styled.div`
  grid-column: 1 / 3;
  width: 100%;
  display: grid;
  padding-bottom: 16px;
`;

export const CurrentSubscription = styled.div<{isHidden: boolean}>`
  grid-row: 1;
  grid-column: 1 / 3;
  transition: opacity .2s;
  transition-delay: ${p => p.isHidden ? '0' : '800ms'};
  opacity: ${p => p.isHidden ? 0 : 1};
`;

export const AvailableSubscriptions = styled(Collapse).attrs({
  timeout: 200,
})`
  grid-row: 1;
  grid-column: 1 / 3;
  background: #ffffff;
  position: relative;
  z-index: 1;
`;

export const SubscriptionChangeInfo = styled(InfoBox).attrs({
  mode: 'info',
  noAnimation: true,
})`
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  border-top: ${p => p.theme.border};
  padding-top: 16px;
`;

export const UpcomingSubscriptionInfo = styled(InfoBox).attrs({
  mode: 'warning',
})`
  grid-column: 1 / 3;
  margin-bottom: 16px;
`;

export const PendingCancellationInfo = styled(InfoBox).attrs({
  mode: 'warning',
})`
  grid-column: 1 / 3;
  margin-top: 16px;
  cursor: pointer;
`;

export const TriggerPayment = styled(InfoBox)`
  grid-column: 1 / 3;
  cursor: pointer;
`;

export const PaymentSuccessInfo = styled(ManualPaymentSuccess)`
  grid-column: 1 / 3;
  margin-bottom: 16px;
`;

export const NextEventLabel = styled.div`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
`;

export const NextEventDate = styled.div`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.primary.dark};
  justify-self: flex-end;
`;

export const Submit = styled(Button)`
  margin-top: 32px;
`;
