import styled from 'styled-components';
import { HalfImageCarouselItem, ItemType } from '../types';

import {
  Wrapper,
  Overline,
  Title,
  Text,
  Cta,
} from './styles';

const Image = styled.div<{
  src: string;
  focalPoint: [number, number];
  position: 'left' | 'right';
}>`
  position: absolute;
  top: 0;
  ${p => p.position === 'left' ? 'left' : 'right'}: 0;
  width: 100%;
  height: 100%;
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:
    top ${p => p.focalPoint[0] * 100}%
    left ${p => p.focalPoint[1] * 100}%;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: block;
  }
  @media only screen and (min-width: 1024px) {
    width: 50%;
    &:after {
      display: none;
    }
  }
`;

const Item = styled.section<{ position: 'left' | 'right' }>`
  position: relative;
  padding: 32px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 1023px) {
    color: #ffffff;
  }
  @media only screen and (min-width: 1024px) {
    width: 50%;
    align-items: flex-start;
    transform: translateX(${p => p.position === 'right' ? 100 : 0}%);
    ${Text} {
      text-align: left;
    }
  }
`;

const imagePosition = {
  [ItemType.ImageLeft]: 'left',
  [ItemType.ImageRight]: 'right',
} as const;

const contentPosition = {
  [ItemType.ImageLeft]: 'right',
  [ItemType.ImageRight]: 'left',
} as const;

export const HalfImageItem: React.FC<HalfImageCarouselItem> = ({
  type,
  overline,
  title,
  copy,
  ctaLabel,
  ctaUrl,
  imageUrl,
  focalPoint,
}) => (
  <Wrapper>
    <Image src={imageUrl} focalPoint={focalPoint} position={imagePosition[type]} />
    <Item position={contentPosition[type]}>
      <Overline>{overline}</Overline>
      <Title>{title}</Title>
      <Text dangerouslySetInnerHTML={{ __html: copy ?? '' }} />
      {!!ctaLabel && !!ctaUrl && <Cta href={ctaUrl}>{ctaLabel}</Cta>}
    </Item>
  </Wrapper>
);
