import {EquipmentBadgeIcon, EquipmentIconProps} from './Equipment.types';
import {ReactComponent as Mat} from 'src/assets/icons/equipment/mat.svg';
import {ReactComponent as Chair} from 'src/assets/icons/equipment/chair.svg';
import {ReactComponent as Blocks} from 'src/assets/icons/equipment/blocks.svg';

const EquipmentIcon: React.FC<EquipmentIconProps> = ({
  icon,
}) => {
  switch (icon) {
    case EquipmentBadgeIcon.YOGA_MAT:
      return <Mat/>;
    case EquipmentBadgeIcon.BLOCK:
      return <Blocks/>;
    case EquipmentBadgeIcon.CHAIR:
      return <Chair/>;
    default:
      return null;
  }
}

export default EquipmentIcon;
