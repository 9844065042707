import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ClickAwayListener } from '@mui/material';

import { ToggleArrow } from 'src/components';
import { Lotus } from 'src/icons';
import { useUserInfo } from 'src/hooks';
import { useUserMenuItems } from '../hooks';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const UserBox = styled.span<{open: boolean}>`
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-areas: "avatar username"
                       "avatar current-section";
  grid-template-columns: 48px auto;
  grid-column-gap: 8px;
  padding: 0 32px 0 0;
  width: 100%;
  height: 60px;
  outline: none;
  background: ${p => p.theme.palette.common.white};
  border: ${p => p.theme.border};
  border-radius: 4px;
  border-bottom-left-radius: ${p => p.open ? 0 : 4};
  border-bottom-right-radius: ${p => p.open ? 0 : 4};
  transition: border-radius .2s, background .2s;
  &:hover {
    background: #f3fbfc;
  }
`;

const AvatarContainer = styled.div`
  grid-area: avatar;
  width: 40px;
  height: 40px;
  align-self: center;
  justify-self: flex-end;
  border: ${p => p.theme.border};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;

  ${Lotus} {
    width: 80%;
    color: ${p => p.theme.palette.primary.main};
    & path {
      opacity: 0.5;
    } 
  }
`;

const Username = styled.span`
  grid-area: username;
  display: block;
  justify-self: flex-start;
  align-self: flex-end;
  text-align: left;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CurrentSection = styled.span`
  grid-area: current-section;
  display: block;
  justify-self: flex-start;
  text-align: left;
  font-size: 13px;
  color: ${p => p.theme.palette.primary.dark};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Menu = styled.div<{open: boolean}>`
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%) scaleY(${p => p.open ? 1 : 0});
  transition: transform 150ms;
  transform-origin: top;
  border: ${p => p.theme.border};
  border-radius: 0 0 4px 4px;
  border-top-width: 0;
  background: ${p => p.theme.palette.common.white};
`;

const Link = styled(NavLink)`
  display: block;
  padding: 8px;
  font-size: 16px;
  white-space: nowrap;
  && {
    color: ${p => p.theme.palette.common.black};
    text-decoration: none;
  }
  transition: color 300ms;
  &.active, &:hover {
    color: ${p => p.theme.palette.primary.dark};
  }
`;

const Toggler = styled(ToggleArrow)`
  position: absolute;
  right: 8px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

export const UserMenu: React.FC<{
  onNavigate?: () => void;
}> = ({
  onNavigate,
}) => {
  const userMenuItems = useUserMenuItems();

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const closeIfOpen = useCallback(() => {open && setOpen(false)}, [open])

  const onLinkClick = () => {
    setOpen(false);
    onNavigate?.();
  }

  const { isLoggedIn, user } = useUserInfo();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={closeIfOpen} >

    <Wrapper>
      <UserBox open={open} onClick={toggle}>
        <AvatarContainer>
          <Lotus />
        </AvatarContainer>
        <Username>{user.name}</Username>
        <CurrentSection>Twoja praktyka</CurrentSection>
      </UserBox>
          <Menu open={open}>
            {userMenuItems.map(({ path, label }) => (
              <Link
                to={path}
                key={label}
                exact
                onClick={onLinkClick}
              >
                {label}
              </Link>
            ))}
          </Menu>
      <Toggler open={open} />
    </Wrapper>
    </ClickAwayListener>
  );
};
