import { Coupon } from 'src/models/Coupon';
import { SubscriptionProduct } from 'src/models/SubscriptionProduct';
import { calculateDiscountedPrice } from 'src/models/SubscriptionProductPriceDetails';

export const isPaymentMethodRequired = (product: SubscriptionProduct, coupon: Coupon | null): boolean => {
  const { isRenewable } = product;
  const isFirstPaymentFree = coupon?.appliesTo.includes(product.id) && calculateDiscountedPrice(product, coupon) === 0;

  return !isFirstPaymentFree || isRenewable;
};
