import { useCallback, useRef } from 'react';
import { TimeUpdateEvent } from '@u-wave/react-vimeo';
import {
  Container,
  HorizontalThumbnail,
  VideoFrame,
} from './styles';

const COMPLETED_VIDEO_TIME_POINT = 0.8;
const DEFAULT_QUALITY = '540p';

const VimeoPlayer: React.FC<{
  className?: string;
  viewLink?: string;
  horizontalThumbnail?: string | null;
  isCompleted?: boolean;
  onComplete?: () => void;
}> = ({
  className,
  viewLink,
  horizontalThumbnail,
  isCompleted,
  onComplete
}) => {
  const shouldTriggerOnCompleteFn = useRef(!isCompleted);

  const handleCompleteVideo = useCallback((playerData: TimeUpdateEvent) => {
    if (
      shouldTriggerOnCompleteFn.current &&
      playerData.percent >= COMPLETED_VIDEO_TIME_POINT &&
      onComplete
    ) {
      onComplete();
      shouldTriggerOnCompleteFn.current = false
    }
  }, [onComplete]);

  return (
    <Container className={className}>
      {viewLink && (
        <VideoFrame
          video={viewLink}
          onTimeUpdate={handleCompleteVideo}
          quality={DEFAULT_QUALITY}
        />
      )}
      {!viewLink && horizontalThumbnail && (
        <HorizontalThumbnail src={horizontalThumbnail}/>
      )}
    </Container>
  )
};

export default VimeoPlayer;
