import classnames from 'classnames';
import styles from './DurationIndicator.module.scss';
import {DurationIndicatorProps} from './DurationIndicator.types';
import {ReactComponent as CalendarIcon} from 'src/assets/icons/calendar_icon.svg';
import {ReactComponent as ClockIcon} from 'src/assets/icons/clock_icon.svg';

const toMinutes = (seconds: number) => Math.floor(seconds / 60);
const toDays = (seconds: number) => Math.floor(seconds / 86_400);
const toWeeks = (seconds: number) => Math.floor(seconds / 604_800);
const isWeekBased = (seconds: number) => seconds > 604_800 && seconds % 604_800 === 0;

const formatDuration = (seconds: number) => {
  const weeks = toWeeks(seconds);
  const days = toDays(seconds);
  const minutes = toMinutes(seconds);
  if (weeks && isWeekBased(seconds)) {
    return weeks === 1 ? `1 tydz.` : `${weeks} tyg.`;
  }
  if (days) {
    return days === 1 ? `1 dzień` : `${days} dni`;
  }
  if (minutes) {
    return `${minutes} min`
  }
  return `${seconds} s.`
};

const DurationIndicator: React.FC<DurationIndicatorProps> = ({
  className,
  duration,
  size,
  style,
}) => {
  if (!duration) {
    return null;
  }

  const isDaysLong = toDays(duration);

  return (
    <div className={classnames(styles.Container, styles[size], className)} style={style}>
      {
        isDaysLong ?
          <CalendarIcon />
          :
          <ClockIcon />
      }
      <p className={styles.Text}>{formatDuration(duration)}</p>
    </div>
  )
}

export default DurationIndicator;
