import { composeFilterLink } from 'src/components/Session/helpers';

import { Coach } from 'src/models/Coach';
import { FilterTrait } from 'src/models/SessionFilters';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Link)`
  width: 112px;
  display: flex;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  grid-area: avatar;
  width: 112px;
  height: 112px;
  align-self: center;
  justify-self: flex-end;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  margin-top: ${p => p.theme.spacing(1)}px;
  color: ${p => p.theme.palette.common.black};
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: lightgray;
`;

export const CoachCard: React.FC<
  Coach & {
    className?: string;
    scrollInToView?: boolean;
  }
> = ({
  id,
  name,
  thumbnail,
  className,
  scrollInToView,
}) => (
  <Wrapper
    to={composeFilterLink('session', FilterTrait.Coach, id, scrollInToView)}
    className={className}
  >
    <AvatarContainer>
      <Avatar
        {...thumbnail ? { src: thumbnail } : {}}
        />
    </AvatarContainer>
    <Name>{name}</Name>
  </Wrapper>
);
