import styled, { keyframes } from 'styled-components';

export * from '../styles/multiselectStyles';

export const Counter = styled.div<{visible: boolean;}>`
  background: ${p => p.theme.palette.primary.main};
  padding: 0 8px;
  border-radius: 12px;
  min-width: 24px;
  color: #fff;
  user-select: none;
  transition: transform .2s;
  transform: scale(${p => +p.visible});
  display: flex;
  justify-content: center;
`;

export const valueChange = keyframes`
  0%   { transform: scale(0) }
  100% { transform: scale(1) }
`;

export const CounterContent = styled.span`
  display: block;
  font-size: 14px;
  line-height: 24px;
  white-space: pre;
  animation: ${valueChange} .2s;
`;
