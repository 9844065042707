import styled from 'styled-components';
import { useCoaches } from 'src/hooks';
import { CoachCard, Header, ScrollbarCarousel } from 'src/components';

const Wrapper = styled.div``;

const Carousel = styled(ScrollbarCarousel).attrs(p => ({
  gutter: 55,
  arrowSize: 'small',
}))`
  margin-top: 24px;
`;

const CarouselItem = styled.div`
  width: 112px;
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const CoachesCarousel: React.FC = () => {
  const coaches = useCoaches();

  if (!coaches) return null;

  return (
    <Wrapper>
      <Header
        title="Nauczyciele"
        as="h2"
        link="/teachers"
        linkLabel="Zobacz wszystkich"
      />
      <Carousel>
        {coaches.map(coach => (
          <CarouselItem key={coach.id}>
            <CoachCard {...coach} />
          </CarouselItem>
        ))}
      </Carousel>
    </Wrapper>
  );
};
