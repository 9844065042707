import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import muiTheme from './muiTheme';
import scTheme from './scTheme';
import './global.scss';

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <SCThemeProvider theme={scTheme}>
      <div id="userAppRoot">
        {children}
      </div>
    </SCThemeProvider>
  </MuiThemeProvider>
);

export default ThemeProvider;
