import { DifficultyIndicators } from 'src/components';

import {
  Card,
  Layout,
  Image,
  Overline,
  Title,
  Underline,
  Separator,
  Difficulty,
  Duration,
} from './styles/main';

import LikeButton from './LikeButton';

export const SessionCardletActual: React.FC<{
  link: string;
  title: string;
  underline: string;
  image?: string;
  styleName?: string;
  type?: string;
  coach: string;
  coachImage: string;
  difficulty: number;
  duration?: number;
  isLiked: boolean;
  onToggleLike?: () => void;
}> = ({
  link,
  title,
  underline,
  image = '',
  styleName = '',
  coach,
  coachImage,
  difficulty,
  duration,
  isLiked,
  onToggleLike,
}) => (
  <Card to={link}>
    <Layout>
      <Image>
        <img src={image} alt=""/>
        <LikeButton liked={isLiked} onToggle={onToggleLike} />
      </Image>
      <Overline>{styleName}</Overline>
      <Title>{title}</Title>
      <Underline>{coach}</Underline>
      <Difficulty>
        <DifficultyIndicators difficulty={difficulty}/>
      </Difficulty>
      <Separator />
      <Duration duration={duration} />
    </Layout>
  </Card>
);
