import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { InfoBox } from 'src/components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SignUpPrompt = styled.p`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.medium};
`;

export const SignUpLink = styled.a`
  display: block;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const ErrorInfo = styled(InfoBox).attrs({
  mode: 'error',
})`
  width: 100%;
  margin-top: 24px;
`;

export const Form = styled.form`
  margin-top: 24px;
  width: 100%;
  display: grid;
  row-gap: 24px;
`;

export const LoginIssuePrompt = styled.p`
  margin-top: 24px;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
`;

export const LoginIssueLink = styled(Link)`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  &:before { content: " " }
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const TermsPrompt = styled.p`
  margin-top: 24px;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
`;

export const TermsLink = styled.a`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  &:before { content: " " }
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;
