import styled, { keyframes } from 'styled-components';
import { TextField } from '@mui/material';

import WidgetLayout from 'src/components/WidgetLayout';
import UnstyledLoader from 'src/components/Loader';

import UnstyledAvatar from '../Avatar';

const enter = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const Wrapper = styled(WidgetLayout.ContentWithMargin)`
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-areas: "avatar meta"
                       "avatar content";
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  margin-top: 24px;
  animation: ${enter} 1s;
  position: relative;
`;

export const Overlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.75);
  pointer-events: ${p => p.visible ? 'auto' : 'none'};
  opacity: ${p => p.visible ? 1 : 0};
  transition: opacity .2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled(UnstyledLoader)`
  width: 40px;
  height: 40px;
  color: ${p => p.theme.palette.primary.dark};
`

export const Avatar = styled(UnstyledAvatar)<{ faded?: boolean; }>`
  grid-area: avatar;
  && { // use greater specificity override MUI styling
    width: 40px;
    height: 40px;
  }
  opacity: ${p => p.faded ? 0.15 : 1};
`;

export const Meta = styled.div`
  grid-area: meta;
  display: flex;
`;

export const Username = styled.span<{ faded?: boolean; }>`
  font-size: 13px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.high};
  opacity: ${p => p.faded ? 0.15 : 1};
`;

export const CreatedDate = styled.span<{ faded?: boolean; }>`
  font-size: 13px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.low};
  margin-left: 8px;
  opacity: ${p => p.faded ? 0.15 : 1};
`;

export const Content = styled.p<{ faded?: boolean; }>`
  grid-area: content;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.palette.text.high};
  opacity: ${p => p.faded ? 0.5 : 1};
`;

export const OwnerActions = styled.div`
  display: flex;
  font-size: 13px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.low};
  margin-left: 8px;
`;

export const Action = styled.button`
  display: block;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:not([disabled]) {
    cursor: pointer;
    &:hover {
      color: ${p => p.theme.palette.primary.main};
    }
  }
`;


export const Edit = styled(Action)`
  margin: 0 4px;
`;

export const Remove = styled(Action)`
  margin: 0 4px;
`;

export const ChangeVisibility = styled(Action)`
  margin-left: auto;
`;

export const Input = styled(TextField).attrs({
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  multiline: true,
  rows: 3,
})``;
