import styled from 'styled-components';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as YouTubeIcon } from './youtube.svg';

type SocialSite = 'facebook' | 'instagram' | 'youtube';

const socialSiteIcon = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  youtube: <YouTubeIcon />,
}

const Link = styled.a`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  display: block;
  color: ${p => p.theme.palette.common.black};
`;

const Icon = styled.div.attrs<{type: SocialSite}>(p => ({
  children: socialSiteIcon[p.type],
}))<{type: SocialSite}>`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  /* transform: translateY(1px); */
  & svg {
    width: 100%;
    height: 100%;
    display: block;
    transition: fill .2s;
    fill: ${p => p.theme.palette.primary.dark};
  }
  ${Link}:hover & svg {
    fill: ${p => p.theme.palette.primary.main};
  }
`;

const labels = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  youtube: 'YouTube',
};

export const SocialLink: React.FC<{
  type: SocialSite;
  children: string;
}> = ({
  type,
  children: link,
}) => {
  const label = labels[type];
  return (
    <Link href={link} target="_blank">
      <Icon type={type}/>
      <Label>{label}</Label>
    </Link>
  );
};
