export type EquipmentBadgeProps = {
  className?: string;
  disabled?: boolean;
  icon?: EquipmentBadgeIcon;
  onClick?: VoidFunction;
  text: string;
}

export type EquipmentIconProps = {
  icon: EquipmentBadgeIcon;
}

export enum EquipmentBadgeIcon {
  BARRE = 'barre',
  BLANKET = 'blanket',
  BLOCK = 'block',
  BOLSTER = 'bolster',
  CHAIR = 'chair',
  CUSHION = 'cushion',
  EYE_CUSHION = 'eye-cushion',
  RESISTANCE_BAND = 'resistance-band',
  STOOL = 'stool',
  STRAP = 'strap',
  TENNIS_BALL = 'tennis-ball',
  TOWEL = 'towel',
  WALL = 'wall',
  WEIGHTS = 'weights',
  WHEEL = 'wheel',
  YOGA_MAT = 'yoga-mat',
}
