import { InfiniteData } from 'react-query';
import { CommentDto, CommentType } from './CommentsClient';
import { GetManyResponse } from 'src/types';

export function mutateUpdated(source: CommentDto, updated: CommentDto): CommentDto {
  let comment: CommentDto;
  if (source.thread) {
    comment = {...source.thread};
    comment.replies = comment.replies!.map(c => c.id === updated.id ? updated : c);
    updated.thread = source.thread;
    updated.parent = source.parent;
  } else {
    comment = updated;
    comment.replies = source.replies;
  }
  return comment;
}

export function mutateReplied(replyComment: CommentDto, newReply: CommentDto): CommentDto {
  let comment = { ...(replyComment.thread ?? replyComment) };
  comment.replies = [...(comment.replies ?? []), newReply];
  comment.replyCount = (comment.replyCount ?? 0) + 1;
  newReply.thread = comment;
  newReply.parent = replyComment.thread && replyComment;
  return comment;
}

export function mutateDeleted(comment: CommentDto): CommentDto {
  let output = comment;
  if (comment.thread) {
    output = {...comment.thread};
    output.replies = output.replies!.filter(c => c.id !== comment.id);
    output.replyCount!--;
  }
  return output;
}

export function mutate(data: InfiniteData<GetManyResponse<CommentDto>>, comment: CommentDto, op: 'add' | 'rm' | 'update') {
  const newData = { ...data };
  if (op === 'add') {
    // newData.pages = [...data.pages];
    const page = newData.pages[0]!; // = {...newData.pages[0]!};
    page.count++;
    page.data = [comment, ...page.data];
    return newData;
  }
  let found = false;
  newData.pages = data.pages
    .map(page => {
      if (found) {
        return page;
      }
      const idx = page.data.findIndex(item => item.id === comment.id);
      if (idx < 0) {
        return page;
      }
      found = true;
      if (op === 'rm') {
        const newPage = { ...page, data: page.data.filter((_, i) => i !== idx) };
        page.count--;
        return newPage;
      }
      const newPage = { ...page, data: [...page.data] };
      newPage.data[idx] = comment;
      return newPage;
    });
  return newData;
}

export function buildCommentsGraph(comment: CommentDto, replies: CommentDto[]) {
  const allReplies = comment.replies == null ? replies : [...comment.replies, ...replies];
  const map = new Map(allReplies.map(r => [r.id as string, r]));
  const newComment = { ...comment, replies: allReplies };
  const index = comment.type === CommentType.challengeStep ? 3 : 2;
  for (const item of allReplies) {
    item.thread = newComment;
    const id = item.path![index];
    if (id != null) {
      item.parent = map.get(id);
    }
  }
  return newComment;
}
