import {
  Layout,
  Title,
  Underline,
  Link,
  LinkIcon,
} from './styles';

export const Header: React.FC<{
  title: string;
  underline?: string;
  link?: string;
  linkLabel?: string;
  as: 'h1' | 'h2' | 'h3';
  className?: string;
}> = ({
  title,
  underline,
  link,
  linkLabel,
  as,
  className,
}) => (
  <Layout className={className}>
    <Title as={as}>{title}</Title>
    {underline && (
      <Underline>
        {underline}
      </Underline>
    )}
    {link && linkLabel && (
      <Link to={link}>
        {linkLabel}
        <LinkIcon/>
      </Link>
    )}
  </Layout>
);
