import { Header } from 'src/components';

import { PaymentHistory } from '../features';
import { Spacer } from '../styles';

export const Payments: React.FC = () => {
  return (
    <>
      <Header
        as="h2"
        title="Historia płatności"
        underline="Sprawdź szczegóły historii płatności swojego konta."
      />
      <Spacer />
      <PaymentHistory />
    </>
  );
};
