import { useEffect } from 'react';

export const useAppendTrackingScripts = (scripts?: string[] | null) => {
  useEffect(() => {
    if (!scripts) {
      return;
    }

    const scriptElements = scripts.map(src => {
      const element = document.createElement('script');
      element.src = src;
      return element;
    });

    scriptElements.forEach(element => document.body.appendChild(element));

    return () => {
      scriptElements.forEach(element => document.body.removeChild(element));
    }
  }, [scripts]);
};
