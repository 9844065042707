import { AxiosError } from 'axios';

/** Generic API-related error */
export class ApiError extends Error {
  name = 'ApiError';
  responseStatus?: number = -1;
  id?: string;
  constructor(readonly rawError: Error) {
    super(rawError.message);
    if (isAxiosError(rawError)) {
      this.responseStatus = rawError.response?.status;
      this.id = rawError.response?.data?.id;
    }
  }
}

/** Based on custom error ID returned from BE */
export class UserExistsError extends ApiError {
  name = 'UserExistsError';
  static id = 'AUTH_USER_EXISTS';
}

/** Based on custom error ID returned from BE */
export class InvalidCredentialsError extends ApiError {
  name = 'InvalidCredentialsError';
  static id = 'AUTH_CREDENTIALS';
}

/** Based on custom error ID returned from BE */
export class InvalidTokenError extends ApiError {
  name = 'InvalidTokenError';
  static id = 'AUTH_JWT';
}

/** Generic authorization error */
export class AuthError extends ApiError {
  name = 'AuthError';
  static statusCode = 401;
}

export class StripeError extends ApiError {
  name = 'StripeError';
  static id = 'STRIPE_EXCEPTION';
}

export class SubscriptionIsCanceled extends ApiError {
  name = 'SubscriptionCanceledError';
  static id = 'SUBSCRIPTION_CANCELED';
}

export class InvoiceNonPayable extends ApiError {
  name = 'InvoiceNonPayable';
  static id = 'UNABLE_PAY_INVOICE';
}

export class StripeCardError extends ApiError {
  name = 'StripeCardError';
  code: string | null = null;
  declineCode: string | null = null;
  static id = 'STRIPE_CARD_ERROR';
  constructor(error: any) {
    super(error);
    const { code, declineCode } = error?.response?.data;
    this.code = typeof code === 'string' ? code : null;
    this.declineCode = typeof declineCode === 'string' ? declineCode : null;
  }
}

export class VideoTranscodingError extends ApiError {
  name = 'VideoTranscodingError';
  static id = 'VIDEO_TRANSCODING';
}

export class DuplicateCouponCodeError extends ApiError {
  name = 'DuplicateCouponCodeError';
  static id = 'DUPLICATE_COUPON_CODE';
}

const isAxiosError = <T = any>(error: any): error is AxiosError<T> => {
  return (error as AxiosError).isAxiosError !== undefined;
}

export const refineApiError = (e: Error) => {
  if (isAxiosError<{ id?: string }>(e)) {
    switch (e.response?.data?.id) {
      case UserExistsError.id:
        return new UserExistsError(e);
      case InvalidCredentialsError.id:
        return new InvalidCredentialsError(e);
      case InvalidTokenError.id:
        return new InvalidTokenError(e);
      case StripeError.id:
        return new StripeError(e);
      case SubscriptionIsCanceled.id:
        return new SubscriptionIsCanceled(e);
      case InvoiceNonPayable.id:
        return new InvoiceNonPayable(e);
      case StripeCardError.id:
        return new StripeCardError(e);
      case VideoTranscodingError.id:
        return new VideoTranscodingError(e);
      case DuplicateCouponCodeError.id:
        return new DuplicateCouponCodeError(e);
      default:
        return e.response?.status === AuthError.statusCode
          ? new AuthError(e)
          : new ApiError(e);
    }
  }
  return e;
};
