import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import SubscriptionPaymentsService from 'src/services/SubscriptionPaymentsService';

const itemsPerPage = 12;

const useCurrentPage = () => {
  const page = Number(new URLSearchParams(useLocation().search).get('page'));
  return Number.isInteger(page) && page > 0
    ? page
    : 1;
};

const usePayments = () => {
  const page = useCurrentPage();
  const {
    data: payments,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['paymentHistory', page],
    () => SubscriptionPaymentsService.getMany({ page, limit: itemsPerPage }),
    { keepPreviousData: true, enabled: false },
  );

  useEffect(() => { refetch(); }, [refetch, page]);

  const history = useHistory();
  const onPageChange = useCallback((selectedPage: number) => {
    const params = new URLSearchParams();
    const newPage = selectedPage + 1;
    if (newPage > 1) {
      params.append('page', newPage.toString());
    }
    history.push({ search: params.toString() });
  }, [history]);

  return {
    payments: payments?.data ?? [],
    page,
    pageCount: payments?.pageCount ?? 0,
    onPageChange,
    isLoading,
    isError,
  };
};

export default usePayments;
