import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { CommentDto, CommentStatus } from '../CommentsClient';
import { CommentsService } from '../Comments2';
import { timeAgo } from '../../../utils';
import {
  Avatar,
  Username,
  CreatedDate,
} from './styles';
import { useUserState } from 'src/contexts/UserContext';

/*
<Wrapper>
          <Meta>
            <Username faded={hidden}>{author.name}</Username>
            <CreatedDate faded={hidden}>{timeAgo(new Date(createdDate))}</CreatedDate>
            {isCommentOwner && (
              <OwnerActions>
                (
                <Edit
                  onClick={() => service.edit(comment)}
                  disabled={isFetching}
                >
                  edytuj
                </Edit>
                |
                <Remove
                  onClick={() => service.delete(comment)}
                  disabled={isFetching}
                >
                  usuń
                </Remove>
                )
              </OwnerActions>
            )}
            {canChangeVisibility && (
              <ChangeVisibility
                onClick={() => hidden ? service.unhide(comment) : service.hide(comment)}
                disabled={isFetching}
              >
                {hidden ? 'przywróć' : 'ukryj'}
              </ChangeVisibility>
            )}
          </Meta>
        </Wrapper>
*/

export default function Comment({ comment, service }: { comment: CommentDto, service: CommentsService }) {
  const { user, isRegularUser } = useUserState();
  const { createdDate } = comment;
  const parent = comment.parent?.user.name;
  const content = comment.status === CommentStatus.default ?
    (parent != null ? `@${parent} ` : '') + comment.content :
    'komentarz usunięty';
  const authorName = comment.user.name === 'deleted user' ? 'nieaktywny użytkownik' : comment.user.name;
  const showMenu = comment.user.id === user.id || !isRegularUser;
  const hidden = comment.status !== CommentStatus.default || comment.hidden;
  return (
    <ListItem
      alignItems="flex-start"
      disableGutters
      sx={{paddingRight: '80px'}}
      secondaryAction={
        <>
          <IconButton onClick={() => service.reply(comment)}>
            <ReplyIcon />
          </IconButton>
          {showMenu && <IconButton onClick={(e) => service.menu(comment, e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>}
        </>
      }
    >
      <ListItemAvatar>
        <Avatar name={authorName} faded={hidden} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Username faded={hidden}>{authorName}</Username>
            <CreatedDate faded={hidden}>{timeAgo(new Date(createdDate))}</CreatedDate>
          </>
        }
        secondary={content}
        secondaryTypographyProps={{sx: {
          whiteSpace: 'break-spaces',
          textAlign: 'justify',
          overflowWrap: 'break-word',
          color: 'text.primary'
        }}}
      >
      </ListItemText>
    </ListItem>
  )
}
