import { formatDistanceToNowStrict } from 'date-fns';
import pl from 'date-fns/locale/pl';

const timeAgo = (date: Date) => {
  const differenceInSeconds = (Date.now() - date.getTime()) / 1000;

  if (differenceInSeconds < 60) {
    return 'przed chwilą';
  }

  return formatDistanceToNowStrict(date, {
    locale: pl,
    addSuffix: true,
  });
}

export default timeAgo;
