import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BackNavigationInfo } from 'src/models/BackNavigationInfo';

export const useBackNavigationInfo = (): BackNavigationInfo => {
  const location = useLocation();
  const [query, setQuery] = useState(BackNavigationInfo.deserializeFromUrlParams(location.search));

  useEffect(() => {
    setQuery(BackNavigationInfo.deserializeFromUrlParams(location.search));
  }, [location]);

  return query;
};
