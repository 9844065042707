import { FilterTrait } from 'src/models/SessionFilters';

export type TagId = Branded<string, 'tagId'>;

export interface Tag {
  id: TagId;
  name: string;
  description: string | null;
  trait: FilterTrait.Tag;
};

export type TagDto = Tag;

export class Tag {
  static fromDto = (dto: TagDto): Tag => ({
    id: dto.id,
    name: dto.name,
    description: dto.description,
    trait: FilterTrait.Tag,
  });
}
