import styled from 'styled-components';

import { ReactComponent as Svg } from './check.svg';

export const CheckmarkIcon = styled.div.attrs({
  children: <Svg />,
})<{checked: boolean;}>`
  width: 16px;
  height: 16px;
  border: 1.5px solid ${p => p.checked
    ? p.theme.palette.primary.dark
    : p.theme.palette.gray[400]
  };
  background: ${p => p.checked
    ? p.theme.palette.primary.dark
    : 'transparent'
  };
  border-radius: 50%;
  transition: border-color .2s, background .2s;
  position: relative;
  transition: border .2s, background .2s;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9px;
    height: 9px;
    transition: fill .2s;
    fill: ${p => p.checked
      ? '#ffffff'
      : p.theme.palette.gray[400]
    };
  }
`;
