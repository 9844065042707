import { useRef } from 'react';
import { usePreviousValue } from './usePreviousValue';

type EqualityFn<T> = (a: T, b: T) => boolean;

export function useLastDifferentValue<T>(value: T, isEqual: EqualityFn<T> = (a, b) => a === b) {
  const last = usePreviousValue(value);
  const previousDifferent = useRef<T>();
  if (!last || !isEqual(last, value)) {
    previousDifferent.current = last;
  }
  return previousDifferent.current;
}
