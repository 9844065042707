import { useCallback } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { RegisterDto } from '../../../models/Auth';
import { AuthService, AuthTokenService, UsersService } from '../../../services';
import { useSignUpContext } from '../context';
import { StripeError } from '../../../errors';
import { sendEcommerceEvent } from '../hooks/useTrackSignUpEvent';
import { GTMEvent } from 'src/models/GoogleTagManager';

export function useCreateAccountAndPaymentIntent() {
  const [{ userDetails, product, consents }, dispatchSignUpAction] = useSignUpContext();
  return useCallback(async () => {
    const registerDto: RegisterDto = {
      name: [userDetails.firstName, userDetails.lastName].join(' ').trim(),
      email: userDetails.email,
      password: userDetails.password,
      subscriptionProductId: product!.id,
      termsAndConditionsConsent: consents.termsAndConditions,
      marketingConsent: consents.marketing,
      profilingConsent: consents.profiling,
    };
    try {
      const registerRes = await AuthService.registerV2(registerDto);
      AuthTokenService.set(registerRes.accessToken);
      const { clientSecret } = await UsersService.paymentIntent(product!.id);
      dispatchSignUpAction({ type: 'setIntent', intent: { clientSecret } });
      dispatchSignUpAction({ type: 'setStep', targetStep: 'paymentMethod' });
      sendEcommerceEvent(GTMEvent.Registration, { product });
    } catch (error) {
      dispatchSignUpAction({ type: 'signUpError', error });
    }
  }, [userDetails, product, consents, dispatchSignUpAction]);
}

export function useConfirmIntent() {
  const [{ intent, userDetails }, dispatchSignUpAction] = useSignUpContext();
  const stripe = useStripe();
  const elements = useElements();

  return useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    await elements.submit();
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: intent!.clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/payment/complete?showWelcome=true`,
        payment_method_data: {
          billing_details: {
            email: userDetails.email,
          },
        },
      },
    });

    if (error) {
      dispatchSignUpAction({ type: 'signUpError', error: new StripeError(new Error(error.message)) })
    }

  }, [intent, userDetails, dispatchSignUpAction, stripe, elements]);
}
