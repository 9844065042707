import { UserId } from './User';

export type CommentId = Branded<string, 'commentId'>;

export interface CommentDto {
  id: CommentId;
  content: string;
  hidden: boolean;
  createdDate: string;
  user: {
    id: UserId,
    name: string;
  };
}

export interface Comment {
  id: CommentId;
  content: string;
  hidden: boolean;
  createdDate: Date;
  author: {
    id: UserId;
    name: string;
  }
}

export class Comment {
  static fromDto = (dto: CommentDto): Comment => ({
    id: dto.id,
    content: dto.content,
    hidden: dto.hidden,
    createdDate: new Date(dto.createdDate),
    author: {
      id: dto.user.id,
      name: dto.user.name,
    },
  });
}
