import { useEffect, useRef, useState } from 'react';

import {
  CardCvc,
  CardExpiry,
  CardNumber,
  StripeProvider,
  useConfirmCardSetup,
  useCreatePaymentMethod
} from 'src/features/StripeCardForm';
import { useDebouncedValue } from 'src/hooks/useDebounce';
import { useUserActions, useUserState } from 'src/contexts/UserContext';

import {
  Container,
  ContainerLabel,
  StatusInfo,
  EditModeToggle,
  CurrentCardInfo,
  NewCard,
  CardForm,
  Submit,
} from './styles';
import { useLastDifferentValue } from 'src/hooks';

const CardPicker: React.FC = () => {
  const { cardSuffix } = useUserState().user;
  const { updateCard } = useUserActions();
  const createPaymentMethod = useCreatePaymentMethod();
  const confirmCardSetup = useConfirmCardSetup();
  const status = useUserState().changeCardStatus;
  const containerRef = useRef<HTMLDivElement>(null);
  
  const [isNumberReady, setIsNumberReady] = useState(false);
  const [isExpiryReady, setIsExpiryReady] = useState(false);
  const [isCvcReady, setIsCvcReady] = useState(false);
  const isReadyToSubmit = isNumberReady && isExpiryReady && isCvcReady;

  const [isEditMode, setIsEditMode] = useState(false);
  // The slight delay makes for a better UX and reduces jerkiness,
  // as toggling the edit mode involves mounting some heavy components (stripe elements)
  const showEditMode = useDebouncedValue(isEditMode, { debounceDelay: 200 });

  const [showError, setShowError] = useState(false);
  useEffect(() => setShowError(status === 'error'), [status]);
  // Stop displaying error status if user exists edit mode:
  useEffect(() => setShowError(false), [isEditMode]);

  const [showSuccess, setShowSuccess] = useState(false);
  const previousStatus = useLastDifferentValue(status);
  useEffect(() => {
    if (previousStatus === 'pending' && status === 'idle') {
      setIsEditMode(false);
      setShowSuccess(true);
    } else if (status === 'open') {
      setIsEditMode(true);
      containerRef.current?.scrollIntoView();
    }
  }, [status, previousStatus]);
  useEffect(() => { isEditMode && setShowSuccess(false); }, [isEditMode]);

  return (
    <Container isActive={showEditMode} ref={containerRef}>
      <ContainerLabel>Aktywna karta</ContainerLabel>
      <EditModeToggle onClick={() => setIsEditMode(!isEditMode)}>
        {isEditMode ? 'Anuluj' : 'Edytuj kartę'}
      </EditModeToggle>
      {showError && (
        <StatusInfo
        mode="error"
        message="Karta nie została dodana poprawnie."
        />
      )}
      <CurrentCardInfo lastDigits={cardSuffix || '••••'} />
      <NewCard in={showEditMode}>
        <CardForm key={`${isEditMode}`} onSubmit={e => e.preventDefault()}>
          <CardNumber
            onReadyStateChange={setIsNumberReady}
            disabled={!isEditMode}
          />
          <CardExpiry
            onReadyStateChange={setIsExpiryReady}
            disabled={!isEditMode}
          />
          <CardCvc
            onReadyStateChange={setIsCvcReady}
            disabled={!isEditMode}
          />
          <Submit
            label="Potwierdź zmiany"
            disabled={!isReadyToSubmit}
            pending={status === 'pending'}
            onClick={updateCard(createPaymentMethod, confirmCardSetup)}
          />
        </CardForm>
      </NewCard>
      {showSuccess && (
        <StatusInfo
          mode="success"
          message="Karta została dodana poprawnie."
          delay={1000}
          />
      )}
    </Container>
  );
};

const CardPickerWithStripe = () => (
  <StripeProvider>
    <CardPicker />
  </StripeProvider>
);

export default CardPickerWithStripe;
