import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthService } from 'src/services';

const useToken = () => {
  const token = useParams<{ token?: string; }>()?.token ?? null;
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [validationPending, setValidationPending] = useState(true);

  useEffect(() => {
    if (!token) {
      setValidationPending(false);
      return;
    }
    setValidationPending(true);
    AuthService
      .validatePasswordResetToken(token)
      .then(setIsValid)
      .finally(() => setValidationPending(false));
  }, [token, setIsValid, setValidationPending]);

  return {
    token,
    isValid,
    tokenValidationPending: validationPending,
  };
};

export default useToken;
