import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SearchSvg } from '../icons/search.svg';
import { ReactComponent as SlidersSvg } from '../icons/sliders-h.svg';
import { ReactComponent as ClearSvg } from '../icons/trash-alt.svg';

const size = {
  s: '@media (max-width: 767px)',
  m: '@media (min-width: 768px) and (max-width: 1023px)',
  l: '@media (min-width: 1024px)',
};

export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.04);
  padding: 0 16px;


`;

export const Field = styled.div<{area: string;}>`
  grid-area: ${p => p.area};
`;

export const Separator = styled.div`

`;

export const BasicFields = styled.div`
  border-bottom: ${p => p.theme.border};
  padding: 16px 0;
  display: grid;
  column-gap: 16px;
  row-gap: 16px;

  ${size.l} {
    grid-template-columns: 6fr 3fr 3fr;
    grid-template-areas: "tag duration difficulty";
  }
  ${size.m} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "tag tag"
                         "duration difficulty";
  }
  ${size.s} {
    grid-template-columns: 1fr;
    grid-template-areas: "tag" "duration" "difficulty";
  }
`;

export const AdvancedFields = styled.div`
  border-bottom: ${p => p.theme.border};
  padding: 16px 0;
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  ${size.l} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "coach type style";
  };
  ${size.m} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "coach type"
                         "style .";
  }
  ${size.s} {
    grid-template-columns: 1fr;
    grid-template-areas: "coach" "type" "style";
  }
`;

export const ButtonArea = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

export const SimplifiedSearchArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SimplifiedSearchInput = styled.div`
  width: 190px;
  padding: 10px 0;
`;

export const ModeToggle = styled.button<{ advancedMode: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  user-select: none;
  fill: ${p => p.theme.palette.primary.main};
  &:hover {
    fill: ${p => p.theme.palette.primary.dark};
  }
`;

export const ModeLabel = styled.span`
  font-size: 16px;
  color: ${p => p.theme.palette.common.black};
`;

export const ModeIcon = styled.span.attrs({
  children: <SlidersSvg />,
})`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 8px;

  & svg {
    width: 100%;
    height: 100%;
    transition: fill .2s;
  }
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 16px 0 8px;
  border: 0;
  background: transparent;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  transition: background .2s, color .2s;
  color: ${p => p.theme.palette.text.high};
  fill: ${p => p.theme.palette.text.medium};
  &:hover {
    background: ${p => p.theme.palette.secondary.main};
    color: #fff;
    fill: #fff;
  }
`;

export const ClearLabel = styled.span`
  font-size: 16px;
`;

export const ClearIcon = styled.span.attrs({
  children: <ClearSvg />,
})`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 8px;

  & svg {
    width: 100%;
    height: 100%;
    transition: fill .2s;
  }
`;

export const SearchResultsButton = styled(Link)<{mobile?: boolean}>`
  display: block;
  background: ${p => p.theme.palette.primary.main};
  color: #ffffff;
  height: 40px;
  padding: ${p => p.mobile ? '0 8px' : '0 16px'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-width: ${p => p.mobile ? '140px' : 'unset'};
  max-height: ${p => p.mobile ? '37px' : 'unset'};
  font-size: ${p => p.mobile ? '16px' : 'unset'};
`;

export const SearchResultsExpand = styled('span')<{mobile?: boolean}>`
  display: block;
  background: ${p => p.theme.palette.primary.main};
  color: #ffffff;
  height: 40px;
  padding: ${p => p.mobile ? '0 8px' : '0 16px'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: ${p => p.mobile ? '120px' : 'unset'};
  max-height: ${p => p.mobile ? '37px' : 'unset'};
  font-size: ${p => p.mobile ? '16px' : 'unset'};
`;

export const SearchResultsLabel = styled.span`
  display: block;
  
`;

export const SearchResultsIcon = styled.span.attrs({
  children: <SearchSvg />,
})`
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 8px;

  & svg {
    width: 100%;
    height: 100%;
    fill: #ffffff;
  }
`;
