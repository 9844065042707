import { useCallback, useLayoutEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ChallengeStep, ChallengeStepId, ChallengeSteps } from 'src/models/Challenge';
import { Coach } from 'src/models/Coach';
import { Equipment } from 'src/models/Equipment';
import { FilterTrait } from 'src/models/SessionFilters';
import {
  Avatar,
  BackLink,
  DifficultyIndicators as UnstyledDifficultyIndicators,
  DurationIndicator,
  EquipmentBadge,
} from 'src/components';

import { Group } from './Group';
import { CircularProgress } from './CircularProgress';
import { composeFilterLink } from 'src/components/Session/helpers';

const Wrapper = styled.section`
  user-select: none;
  background: ${p => p.theme.palette.gray[100]};
  padding: 16px 16px 0 16px;
`;

const SectionHeading = styled.h2`
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.3px;
  border-top: ${p => p.theme.border};
  padding-top: 16px;
`;

const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  ${SectionHeading} {
    border: none;
    padding: 0;
  }
`;

const DetailsItem = styled.div`
  &:not(:last-child) {
    border-right: ${p => p.theme.border};
    padding-right: 16px;
    margin-right: 16px;
  }
  display: grid;
  grid-template-rows: 1fr 1fr;
  & > span {
    display: block;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.17px;
    color: ${p => p.theme.palette.text.low};
  }
`;

const Details = styled.div`
  display: flex;
  margin: 16px 0;
`;

const DifficultyIndicators = styled(UnstyledDifficultyIndicators)`
  align-self: center;
`;

const EquipmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const EquipmentItem = styled(EquipmentBadge)`
  margin: 16px 8px 0 0;
`;

const CoachInfo = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px 0;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;

  > span {
    margin-left: 8px;
  }

  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const StepList: React.FC<{
  groups: ChallengeSteps;
  composeStepUrl: (subpath: string) => string;
  progress: number;
  currentStep?: ChallengeStepId;
  nextStep?: ChallengeStep;
  coach: Coach | null;
  equipment: Equipment[];
  difficulty?: number;
  duration?: number;
  showBackLink?: boolean;
  challengeUrl: string;
}> = memo(({
  groups,
  composeStepUrl,
  progress,
  currentStep,
  nextStep,
  coach,
  equipment,
  difficulty,
  duration,
  showBackLink,
  challengeUrl,
}) => {

  const [openStates, setOpenStates] = useState(groups.map(() => false));
  const toggleOpen = useCallback((index: number, targetState?: boolean) => {
    setOpenStates(states => states.map((isOpen, i) =>
      i === index
        ? !isOpen
        : false
    ));
  }, []);

  useLayoutEffect(() => {
    setOpenStates(groups.map(g => false));
  }, [groups]);

  useLayoutEffect(() => {
    const index = groups.findIndex(g => g.steps.some(s => s.id === currentStep));
    const activeIndex = index === -1 ? 0 : index;
    setOpenStates(states => states.map((_, i) => i === activeIndex));
  }, [currentStep, groups]);

  return (
    <Wrapper>
      
      <ProgressInfo>
        <SectionHeading>Twój postęp:</SectionHeading>
        <CircularProgress progress={progress}/>
      </ProgressInfo>

      {showBackLink && (
        <BackLink
          to={challengeUrl}
          label="Wróć do szczegółów wyzwania"
          textSize="regular"
        />
      )}

      {groups.map((group, index) => (
        <Group
          key={index}
          title={group.title}
          steps={group.steps}
          currentStep={currentStep}
          composeStepUrl={composeStepUrl}
          onToggle={() => toggleOpen(index)}
          isOpen={openStates[index] ?? false}
        />
      ))}

      <SectionHeading>Szczegóły:</SectionHeading>
      <Details>      
        {(duration !== undefined) && (
          <DetailsItem>
            <span>Czas trwania</span>
            <DurationIndicator duration={duration} size="big"/>
          </DetailsItem>
        )}
        {difficulty !== undefined && (
          <DetailsItem>
            <span>Poziom</span>
            <DifficultyIndicators difficulty={difficulty}/>
          </DetailsItem>
        )}
      </Details>

      {equipment.length > 0 && (
        <>
          <SectionHeading>Pomoce:</SectionHeading>
          <EquipmentList>
            {equipment.map(({id, name}) => (
              <EquipmentItem key={id} text={name} />
            ))}
          </EquipmentList>
        </>
      )}

      {coach && (
        <>
          <SectionHeading>Nauczyciel:</SectionHeading>
          <CoachInfo
            to={composeFilterLink('challenge', FilterTrait.Coach, coach.id)}
          >
            <Avatar size="large" imageSrc={coach?.thumbnail ?? undefined} />
            <span>{coach?.name}</span>
          </CoachInfo>
        </>
      )}

    </Wrapper>
  );
});
