import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { AuthService } from 'src/services';
import { useDebouncedValue } from 'src/hooks/useDebounce';

import { useSignUpContext } from '../context';

export const useSendPartialSignUpData = () => {
  const [isEmailFieldFocused, setIsEmailFieldFocused] = useState(false);

  const [{
    userDetails,
    consents,
  }] = useSignUpContext();

  const values = useMemo(() => ({
    name: [userDetails.firstName, userDetails.lastName].join(' '),
    email: userDetails.email,
    termsAndConditionsConsent: consents.termsAndConditions,
    marketingConsent: consents.marketing,
    profilingConsent: consents.profiling,
    isEmailFieldFocused,
  }), [
    userDetails,
    consents,
    isEmailFieldFocused,
  ]);

  const debouncedValues = useDebouncedValue(values, { debounceDelay: 1000, emitFirst: false });

  useEffect(() => {
    try {
      if (!debouncedValues?.email) {
        throw new Error('Email is empty');
      }
      yup.string().email().validateSync(debouncedValues?.email);
    } catch (e) {
      return;
    }
    const canSubmit = (
      debouncedValues?.profilingConsent &&
      debouncedValues.marketingConsent &&
      !debouncedValues?.isEmailFieldFocused
    );
    if (!canSubmit) {
      return;
    }
    AuthService.sendUnfinishedSignUpData(debouncedValues!);
  }, [debouncedValues]);

  return { setIsEmailFieldFocused };
};

export default useSendPartialSignUpData;
