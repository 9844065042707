import { Button, Modal } from 'src/components';
import { composeStripeCardErrorMessage } from 'src/utils';

import { useSignUpContext } from '../context';
import { headings, messages} from './messages';

export const ErrorMessage: React.FC = () => {
  const [{
    error,
    cardErrorCode,
    cardErrorDeclineCode,
    errorDismissed,
  }, dispatch] = useSignUpContext();

  const heading = error ? headings[error] : '';
  const message = error ? messages[error] : '';

  const detailedCardErrorMessage = cardErrorCode && composeStripeCardErrorMessage(cardErrorCode, cardErrorDeclineCode);

  return (
    <Modal open={!!error && !errorDismissed}>
      <Modal.Title>{heading}</Modal.Title>
      <Modal.Content>
        <p>{message}</p>
        {detailedCardErrorMessage && <p>{detailedCardErrorMessage}</p>}
      </Modal.Content>
      <Modal.Actions>
        <Button
          label="Wróć"
          onClick={() => dispatch({ type: 'dismissError' })}
        />
      </Modal.Actions>
    </Modal>
  );
};
