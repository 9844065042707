import { CarouselItem, CarouselItemDto, CarouselItemId, CarouselPage, CarouselPageAssignment } from 'src/models/Carousel';

import { HttpService } from 'src/services';

type ConfigResponse = {
  carouselItems?: CarouselItemDto[];
  carouselConfig?: { pageAssignment?: CarouselPageAssignment[] };
}

type CarouselPageConfig = {
  page: CarouselPage;
  carouselItem: CarouselItem;
};

abstract class CarouselService {

  static getConfig = async (): Promise<CarouselPageConfig[]> => {
    const config = await HttpService.get<ConfigResponse>('public/cms/config');
    const items = (config.carouselItems ?? []).map(CarouselItem.fromDto);
    const assignments = config.carouselConfig?.pageAssignment ?? [];
    return assignments
      .map(({ page, carouselItemId }) => ({
        page,
        carouselItem: items.find(i => i.id === carouselItemId),
      }))
      .filter((item): item is CarouselPageConfig => !!item.carouselItem)
  }

  static getSingleCarousel = (id: CarouselItemId): Promise<CarouselItem> => HttpService
    .get<CarouselItemDto>(`cms/config/carousel/${id}`)
    .then(CarouselItem.fromDto);

}

export default CarouselService;
