import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${p => p.theme.palette.common.white};
  max-width: ${p => p.theme.maxContentWidth}px;
  position: relative;
  display: flex;
  min-height: 410px;
  height: 100%; // if 1 item is longer, make all others stretch accordingly
  @media only screen and (min-width: 800px) {
    border: ${p => p.theme.border};
  }
`;

export const Title = styled.h2`
  font-family: Syne;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 0 0 12px 0;
`;

export const Overline = styled.span`
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  color: ${p => p.theme.palette.primary.dark};
`;

export const Text = styled.div`
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.3px;
  text-align: center;
  margin: 12px 0 24px 0;
  & p {
    color: unset;
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
`;

export const Cta = styled.a`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: 4px;
  padding: 9px 22px;
  margin: 12px 0;
  display: inline-block;
  color: ${p => p.theme.palette.common.white};
  background: ${p => p.theme.palette.secondary.main};
  transition: background .2s;
  &:hover, &:focus, &:active {
    background: ${p => p.theme.palette.secondary.dark};
    text-decoration: none;
    color: ${p => p.theme.palette.common.white};
  }
`;
