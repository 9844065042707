import { useMemo } from 'react';

import { Button, WidgetLayout } from 'src/components';
import { SubscriptionProductPriceDetails } from 'src/models/SubscriptionProductPriceDetails';

import { useSignUpContext } from './context';
import { useRedirectIfLoggedIn } from './hooks/useRedirectIfLoggedIn';
import StepManager from './StepManager';
import PaymentMethod from './PaymentMethod';
import Stepper from './Stepper';
import OrderSummary from './OrderSummary';
import { OrderItem } from './OrderItem';
import CouponSection from './CouponSection';
import ErrorMessage from './ErrorMessage';
import { useSignUp } from './hooks/useSignUp';
import useSendPartialSignUpData from './hooks/useSendPartialSignUpData';
import { useApplyCouponFromUrl } from './hooks/useApplyCouponFromUrl';
import { useTrackSignUpEvent } from './hooks/useTrackSignUpEvent';
import { useAppendTrackingScripts } from './hooks/useAppendTrackingScripts';
import { useScrollToTop } from '../../hooks';

const SignUp: React.FC = () => {
  const [{
    status,
    coupon,
    currentStep,
    paymentMethodRequired,
    paymentMethodProvided,
    product,
  }, dispatch] = useSignUpContext();
  useSendPartialSignUpData();
  useApplyCouponFromUrl();
  useAppendTrackingScripts(product?.extraTrackingScripts);
  useScrollToTop([currentStep]);

  useRedirectIfLoggedIn();
  useTrackSignUpEvent();
  const signUp = useSignUp();

  const price = useMemo(() => {
    return SubscriptionProductPriceDetails.compose(product, coupon);
  }, [product, coupon]);

  const buttonLabel = !paymentMethodRequired || paymentMethodProvided || currentStep === 'paymentMethod'
    ? 'Sfinalizuj transakcję'
    : 'Przejdź do płatności';

  const buttonAction = !paymentMethodRequired || paymentMethodProvided || currentStep === 'paymentMethod'
    ? signUp
    : () => dispatch({ type: 'setStep', targetStep: 'paymentMethod' });

  const isButtonActive =  paymentMethodProvided || currentStep === 'userDetailsAndConsents';

  const toBeBilledToday = !!product.trialDays
    ? SubscriptionProductPriceDetails.zeroPrice
    : price.discount?.discountedPrice ?? price.basePrice;

  return (
    <WidgetLayout>
      <StepManager stepper={<Stepper />} paymentElement={<PaymentMethod />} />
      <WidgetLayout.WidgetArea>
        <OrderSummary
          orderItems={[
            <OrderItem
              key={product.id}
              label={product.name}
              secondaryLabel={product.description}
              price={price}
              isRenewable={product.isRenewable}
            />
          ]}
          couponSection={<CouponSection />}
          toBeBilledToday={toBeBilledToday}
          finalizeButton={(
            <Button
              submit
              onClick={buttonAction}
              label={buttonLabel}
              pending={status === 'signUpPending'}
              disabled={!isButtonActive}
            />
          )}
        />
      </WidgetLayout.WidgetArea>
      <ErrorMessage />
    </WidgetLayout>
  );

  /*
  return (
    <Layout.Container>
      <Layout.Stepper>
        <Stepper />
      </Layout.Stepper>
      <Layout.Content>
        <StepManager />
      </Layout.Content>
      <Layout.Summary>
        <OrderSummary
          orderItems={[
            <OrderItem
              key={product.id}
              label={product.name}
              secondaryLabel={product.description}
              price={price}
              isRenewable={product.isRenewable}
            />
          ]}
          couponSection={<CouponSection />}
          toBeBilledToday={price?.discount?.discountedPrice ?? price?.basePrice}
          finalizeButton={(
            <Button
              submit
              onClick={buttonAction}
              label={buttonLabel}
              pending={status === 'signUpPending'}
              disabled={!isButtonActive}
            />
          )}
        />
      </Layout.Summary>
      <ErrorMessage />
    </Layout.Container>
  );
  */
};

export default SignUp;
