import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';
import { Style, StyleDto } from 'src/models/Style';

const StylesService = {

  getAll: (): Promise<Style[]> => HttpService
    .get<GetManyResponse<StyleDto>>('/public/styles')
    .then(response => response.data)
    .then(coaches => coaches.map(Style.fromDto)),

};

export default StylesService;
