import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    padding-top: 56.25%; // 16:9 aspect ratio
  }
`;

export const VideoFrame = styled(Vimeo)`
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const HorizontalThumbnail = styled.img`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
