import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Logo } from 'src/assets/logo.svg';

export const BrandLogo = styled(NavLink).attrs(({
  to: '/home',
  children: <Logo />,
}))`
  display: block;
  border-radius: 50%;
  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  &:hover {
    background-color: ${p => p.theme.palette.gray[100]};
  }
`;
