import axios from 'axios';
import flow from 'lodash/fp/flow';
import { apiUrl } from 'src/config';
import { AuthError, refineApiError } from 'src/errors';
import { AuthTokenService } from '.';

export const axiosInstance = axios.create({ baseURL: apiUrl });

axiosInstance.interceptors.request.use(config => {
  const token = AuthTokenService.get();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

const shouldRedirectOnAuthError = (pathname = '') => {
  const exclusions = [
    '/reset-password',
    '/login',
  ];
  return exclusions.every(excludedPath => !pathname.startsWith(excludedPath));
};

const pluckData = <T>(wrapper: { data: T }) => wrapper.data;
const throwError = (e: Error) => { throw e; };
const redirectIfAuthError = (e: Error) => {
  if (e instanceof AuthError) {
    AuthTokenService.clear();
    if (shouldRedirectOnAuthError(window.location.pathname)) {
      window.location.assign(`${process.env.PUBLIC_URL ?? ''}/login`);
    }
    return e;
  }
  return e;
}
const refineAndThrow = flow(
  refineApiError,
  redirectIfAuthError,
  throwError
);

const HttpService = {
  get: <T = unknown>(path: string) => axiosInstance
    .get<T>(path)
    .then(pluckData)
    .catch(refineAndThrow),

  post: <T = void>(path: string, data?: any) => axiosInstance
    .post<T>(path, data)
    .then(pluckData)
    .catch(refineAndThrow),

  patch: <T = void>(path: string, data?: any) => axiosInstance
    .patch<T>(path, data)
    .then(pluckData)
    .catch(refineAndThrow),

  put: <T = void>(path: string, data?: any) => axiosInstance
    .put<T>(path, data)
    .then(pluckData)
    .catch(refineAndThrow),

  delete: <T = void>(path: string) => axiosInstance
    .delete<T>(path)
    .then(pluckData)
    .catch(refineAndThrow),
};

export default HttpService;
