import { Challenge, ChallengeDto, ChallengeStep, ChallengeStepDto } from './Challenge';
import { SessionDto, transformSessionDto } from './Session';

export enum UserActivityType {
  Completed = 'completed',
  Liked = 'liked',
  Visited = 'visited',
};

export interface UserActivityDto {
  type: UserActivityType;
  status: boolean;
  updatedDate: string;
  session: SessionDto | null;
  challenge: ChallengeDto | null;
  challengeStep: ChallengeStepDto | null;
}

export class UserActivity {
  static fromDto = (dto: UserActivityDto) => ({
    type: dto.type,
    date: new Date(dto.updatedDate),
    session: dto.session ? transformSessionDto(dto.session) : null,
    challenge: dto.challenge ? Challenge.fromDto(dto.challenge) : null,
    challengeStep: dto.challengeStep ? ChallengeStep.fromDto(dto.challengeStep) : null,
  });
}
