import slide_1_image from 'src/assets/images/onboarding-image-first.webp';
import slide_2_image from 'src/assets/images/onboarding-image-second.webp';
import { ReactElement, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Swiper as SwiperClass } from 'swiper/types';
import { OnboardingSlideComponent } from '../../components/OnboardingSlide/OnboardingSlide';
import {
  OnboardingSlideBulletsContainer,
  OnboardingButton,
  OnboardingButtonText,
  OnboardingButtonWrapper,
  OnboardingSlideBulletElement,
  OnboardingContainer,
  OnboardingSwipeSlide,
  OnboardingButtonContainer
} from './Onboarding.styles';

const NUMBER_OF_SLIDES_PER_VIEW = 1;
const FIRST_SLIDE_INDEX = 0;
const SECOND_SLIDE_INDEX = 1;

export const Onboarding = (): ReactElement => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const onSlideChange = (swiper: SwiperClass) => {
    setActiveSlideIndex(swiper.activeIndex);
  }

  const onOnboardingCompleted = () => {
    localStorage.setItem('onboardingCompleted', 'true');
  }

  return <OnboardingContainer>
    <Swiper
      onSlideChange={onSlideChange}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={NUMBER_OF_SLIDES_PER_VIEW}
      navigation={{nextEl: null, prevEl: null, }}
      pagination={{ enabled: false, clickable: false }}
      scrollbar={{ draggable: true, hide: true, el: '<span></span>' }}
    >
      <OnboardingSwipeSlide>
        <OnboardingSlideComponent
          img={slide_1_image}
          title='Największe studio jogi online'
          subtitle='Ponad 600 sesji jogi, 60 jogowych wyzwań, 60 nauczycieli.'
        />
      </OnboardingSwipeSlide>
      <OnboardingSwipeSlide>
        <OnboardingSlideComponent
          img={slide_2_image}
          title='Praktykuj jogę, medytację i pilates'
          subtitle='Ćwicz wszędzie, bez limitu. Anuluj kiedy chcesz.'
        />
      </OnboardingSwipeSlide>
    </Swiper>
    <OnboardingButtonContainer>
      <OnboardingButtonWrapper>
        <OnboardingSlideBulletsContainer>
          <OnboardingSlideBulletElement isActive={activeSlideIndex === FIRST_SLIDE_INDEX} />
          <OnboardingSlideBulletElement isActive={activeSlideIndex === SECOND_SLIDE_INDEX} />
        </OnboardingSlideBulletsContainer>
        <OnboardingButton to='/login' onClick={onOnboardingCompleted} >
          <OnboardingButtonText>
            Zacznij
          </OnboardingButtonText>
        </OnboardingButton>
      </OnboardingButtonWrapper>
    </OnboardingButtonContainer>
  </OnboardingContainer>

}
