import React, { useCallback, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { FilterTrait } from 'src/models/SessionFilters';
import { Tag } from 'src/models/Tag';
import { Type } from 'src/models/Type';
import { Style } from 'src/models/Style';
import { Coach } from 'src/models/Coach';

import { createFilterOptionsHandler } from '../utils';
import { MultiselectOption as Option } from '../MultiselectOption';
import {
  ClearOptions,
  EndAdornment,
  Listbox,
  Paper
} from './styles';

import styles from './multiSelect.module.scss';

const filterOptionsHandler = createFilterOptionsHandler({ minInputLength: 0, maxResults: 50 })

const noOptionsText = 'Brak pasujących wyników';

const clearPhraseOnSelect = true;

/**
 * This component:
 * - collects any arbirtrary user-entered text ("phrase")
 * - suggests existing tags/styles/types/coaches that match the phrase
 *   and have not been selected yet (this happens as the user types along)
 * - emits these items if the user explicitly selects them from the suggestion list
 * - displays currently selected tags
 * - clears all tags and the phrase if requested
 *
 * Note that any non-tag items (styles/types/coaches) are "foreign" in this context,
 * ie. they're included in the suggestions but not displayed as selected
 * (though they will be displayed in their respective fields elsewhere).
 */
const MultiSelect = ({
  id,
  label,
  name,
  disabled,
  aggregatedOptions,
  aggregatedValues,
  tagValues,
  onTagsChange,
  onAddForeignValue,
  phrase,
  onPhraseChange,
  onPhraseConfirm,
}: {
  id: string;
  label: string;
  name?: string;
  disabled?: boolean;
  aggregatedOptions: (Tag | Type | Style | Coach)[];
  aggregatedValues: (Tag | Type | Style | Coach)[];
  tagValues: Tag[];
  onTagsChange: (values: Tag[]) => void;
  onAddForeignValue: (value: Type | Style | Coach) => void;
  phrase: string;
  onPhraseChange: (phrase: string) => void;
  onPhraseConfirm: (phrase: string, options?: { triggerSearch: boolean }) => void;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  // This event fires whenever the user selects a suggested item.
  // We need separate tag items from non-tag items, as they are handled differently.
  const handleChange = useCallback((_, newValues: (string | Tag | Type | Style | Coach)[]) => {
    const last = newValues[newValues.length - 1];
    if (typeof last === 'string') {
      onPhraseConfirm(last, { triggerSearch: true });
      return;
    }
    if (last == null || last.trait === FilterTrait.Tag) {
      onTagsChange(newValues as Tag[]);
    } else {
      onAddForeignValue(last);
    }
    // If a new value was picked from the list, we want to clear the input text
    if (clearPhraseOnSelect) {
      onPhraseChange('');
    }
  }, [onAddForeignValue, onTagsChange, onPhraseChange, onPhraseConfirm]);

  const clear = useCallback((e: React.MouseEvent) => {
    e.stopPropagation(); // Avoid opening the menu on clear btn click
    onTagsChange([]);
    onPhraseChange('');
  }, [onTagsChange, onPhraseChange]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    onPhraseConfirm(phrase);
  }, [phrase, onPhraseConfirm]);

  const endAdornment = (!!tagValues.length || !!phrase) && (
    <EndAdornment>
      <ClearOptions onClick={clear} />
    </EndAdornment>
  );

  return (
    <Autocomplete
      id={id}
      fullWidth
      className={styles.autoSelect}
      size="small"
      multiple
      freeSolo
      disableCloseOnSelect
      clearOnBlur={false}
      options={aggregatedOptions}
      getOptionLabel={option => (option as (typeof aggregatedOptions)[number])?.name}
      value={tagValues}
      filterOptions={(options, state) => {
        // Filter out any options that are already selected;
        // we don't want to show them in the list at all:
        const unusedOptions = options.filter(
          option => !aggregatedValues.some(value => value.trait === option.trait && value.id === option.id)
        );
        return filterOptionsHandler(unusedOptions, state);
      }}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          onPhraseChange(value);
        }
      }}
      inputValue={phrase}
      onFocus={() => setIsFocused(true)}
      onBlur={handleBlur}
      disableClearable
      forcePopupIcon={false}
      openOnFocus
      renderInput={({
        InputProps,
        ...params
      }) => (
        <TextField
          {...params}
          name={name}
          InputProps={{
            ...InputProps,
            endAdornment,
          }}
          InputLabelProps={{
            shrink: isFocused || !!phrase || !!tagValues.length,
          }}
          variant="outlined"
          label={label}
        />
      )}
      ListboxComponent={Listbox}
      PaperComponent={Paper}
      renderOption={(props, option, { inputValue, selected }) =>(
        <Option
          {...props}
          label={option.name}
          inputValue={inputValue}
          selected={selected}
          {...option.trait === FilterTrait.Type ? { annotation: 'Rodzaj jogi'} : {}}
          {...option.trait === FilterTrait.Style ? { annotation: 'Styl jogi'} : {}}
          {...option.trait === FilterTrait.Coach ? { annotation: 'Nauczyciel'} : {}}
        />
      )}
    />
  );
};

export default MultiSelect;
