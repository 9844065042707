import { pinterestPixelTagId } from 'src/config';

declare function pintrk(...args: any): void;

/**
 * Pinterest generated code
 */
function init() {
  const script = document.createElement('script');
  script.text =
    `!function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
    Array.prototype.slice.call(arguments))};var
    n=window.pintrk;n.queue=[],n.version="3.0";var
    t=document.createElement("script");t.async=!0,t.src=e;var
    r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
  pintrk('load', ${pinterestPixelTagId});
  pintrk('page');
`;
  document.head.appendChild(script);
}

function track(event: string, code?: string, data?: any) {
  if (typeof pintrk === 'function') {
    pintrk(event, code, data);
  }
}

const PinterestPixelService = {
  init,
  track
};

export { PinterestPixelService };
