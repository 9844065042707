import { Button, Modal } from 'src/components';

const Confirmation: React.FC<{
  open: boolean;
  newSubscriptionTitle: string;
  onConfirm: () => void;
  onCancel: () => void;
  timing: 'immediately' | 'onBillingPeriodEnd',
  showProductNotActiveWarn: boolean,
}> = ({
  open,
  newSubscriptionTitle,
  onConfirm,
  onCancel,
  timing,
  showProductNotActiveWarn,
}) => (
  <Modal open={open}>
    {timing === 'onBillingPeriodEnd' && (
      <>
        <Modal.Title>Potwierdź zmianę planu</Modal.Title>
        {showProductNotActiveWarn ?
          <Modal.Content>
            <p><strong>UWAGA: Obecnie korzystasz z planu (abonamentu), który nie jest już dostępny w naszej stałej ofercie. Zmiana planu uniemożliwi w przyszłości powrót do obecnej niższej ceny. Dopóki pozostajesz na aktualnym planie, możesz cieszyć się preferencyjną ceną.</strong></p>
            <p>Czy nadal chcesz zatwierdzić zmianę?</p>
            <p>Po potwierdzeniu zmiany Twój plan zostanie zmieniony na <strong>{newSubscriptionTitle}</strong> po wygaśnięciu obecnego okresu rozliczeniowego.</p>
          </Modal.Content>
          : 
        <Modal.Content dangerouslySetInnerHTML={{__html: `Twój plan zostanie zmieniony na: <strong>${newSubscriptionTitle}</strong> po wygaśnięciu obecnego okresu rozliczeniowego.`}}/>
        }
      </>
    )}
    {timing === 'immediately' && (
      <>
        <Modal.Title>Potwierdź zakup planu</Modal.Title>
        <Modal.Content dangerouslySetInnerHTML={{__html: `Wybrany plan: <strong>${newSubscriptionTitle}.</strong>`}}/>
      </>
    )}
    <Modal.Actions>
      <Button
        label="Powrót"
        onClick={onCancel}
        variant="minimal"
        color="primary"
      />
      <Button
        label="Potwierdź zmiany"
        onClick={onConfirm}
      />
    </Modal.Actions>
  </Modal>
);

export default Confirmation;
