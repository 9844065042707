import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.nav`
  position: sticky;
  top: calc(48px + 80px);
  padding: 16px;
  background: ${p => p.theme.palette.gray[100]};
`;

export const UserInfo = styled.div`
  border-bottom: ${p => p.theme.border};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const UserName = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const UserEmail = styled.p`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.low};
`;

export const Link = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: 4px;
  padding: 8px;
  color: ${p => p.theme.palette.text.high};
  transition: color .2s, background .2s;

  &:hover, &:focus {
    background: ${p => p.theme.palette.gray[200]};
  }

  &.active {
    background: ${p => p.theme.palette.primary.main};
    color: #ffffff;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
