import { Header } from 'src/components';

export const ProfileDetails: React.FC = () => {
  return (
    <>
      <Header
        as="h2"
        title="Edytuj profil"
        underline="Dodaj zdjęcie profilowe i edytuj informacje dotyczące swojego profilu."
      />
    </>
  );
};
