import styled from 'styled-components';
import { Loader } from 'src/components';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullPageLoader: React.FC<{
  delay?: number;
}> = ({
  delay = 0,
}) => (
  <Container>
    <Loader delay={delay} />
  </Container>
);
