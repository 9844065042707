import { useCallback, useEffect } from 'react';
import { environment } from 'src/config';

export interface BarecancelParams {
  access_token_id: string;
  customer_oid: string;
  subscription_oids?: string[];
  test_mode?: boolean;
  comment_required?: boolean | string;
  trigger?: string;
  callback_send?: (data: unknown) => void;
  callback_error?: (err: unknown) => void;
}

export interface Barecancel {
  params: BarecancelParams;
  open: () => void;
  close: () => void;
  loaded?: () => void;
  loading: boolean;
  state: 'err' | 'init';
}

declare global {
  interface Window {
    barecancel?: Barecancel;
  }
}

export function useBarecancel(stripeId: string) {
  useEffect(() => {
    if (environment === 'production') {
      return;
    }
    if (window.barecancel?.state != null) {
      window.barecancel.params.customer_oid = stripeId;
      return;
    }
    const barecancelUrl = 'https://baremetrics-barecancel.baremetrics.com/js/application.js';
    const barecancelAccessTokenId = 'db2fdfb3-3d1d-408a-9742-58d3e9f29b03';
    window.barecancel = {
      state: 'init',
      params: {
        access_token_id: barecancelAccessTokenId,
        customer_oid: stripeId,
        test_mode: environment !== 'production',
      },
    } as Barecancel;
    const script = document.createElement('script');
    script.src = barecancelUrl;
    script.async = true;
    document.body.appendChild(script);
  }, [stripeId]);

  return useCallback(() => {
    if (window.barecancel?.loading === false) {
      window.barecancel.open();
    }
  }, []);
}
