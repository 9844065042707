import styled from 'styled-components';
import { Link } from 'react-router-dom';

import InfoBox from 'src/components/InfoBox';

import BrandLogo from './BrandLogo';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 410px;
  padding: 32px;
  border: ${p => p.theme.border};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Brand = styled(BrandLogo)`
  width: 56px;
  height: 56px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${p => p.theme.palette.text.high};
  margin: 16px 0;
`;

export const SignUpPrompt = styled.p`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.medium};
`;

export const SignUpLink = styled.a`
  display: block;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export const ErrorInfo = styled(InfoBox).attrs({
  mode: 'error',
})`
  width: 100%;
  margin-top: 24px;
`;

export const Form = styled.form`
  margin-top: 24px;
  width: 100%;
  display: grid;
  row-gap: 24px;
`;

export const LoginIssuePrompt = styled.p`
  margin-top: 24px;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
`;

export const LoginIssueLink = styled(Link)`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
  &:before { content: " " }
  color: ${p => p.theme.palette.primary.dark};
  transition: color .2s;
  &:hover, &:focus {
    color: ${p => p.theme.palette.primary.main};
  }
`;
