import { DifficultyIndicators as BaseDifficultyIndicators } from 'src/components';
import styled from 'styled-components';

import { ReactComponent as CheckmarkIcon } from '../icons/checkmark.svg';

export const Wrapper = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 11px;
  transition: background .2s;
  &&&&& {
    background-color: unset !important;
  }
  &&&&&.Mui-focused {
    background: rgba(0,0,0,0.03) !important;
  }
`;

export const Checkmark = styled.div.attrs({
  children: <CheckmarkIcon />,
})<{selected: boolean;}>`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  transition: border-color .2s, background .2s;
  border-color: ${p => p.selected
    ? p.theme.palette.primary.main
    : 'rgba(0,0,0,0.2)'};
  background: ${p => p.selected
    ? p.theme.palette.primary.main
    : '#fff'};

  & svg {
    width: 70%;
    height: 70%;
    fill: #fff;
    transition: transform .1s .1s;
    transform: scale(${p => +p.selected});
  }
`;

export const Label = styled.span`
  display: block;
  font-size: 14px;
`;

export const Text = styled.span<{highlight?: boolean}>`
  text-shadow: ${p => p.highlight ? '-0.06ex 0 rgba(0, 0, 0, 0.74), 0.06ex 0 rgba(0, 0, 0, 0.74)' : 'none'};
`;

export const Annotation = styled.span`
  display: inline-block;
  margin-left: 16px;
  color: rgba(0,0,0,0.3);
  text-transform: uppercase;
  font-size: 10px;
`;

export const DifficultyIndicators = styled(BaseDifficultyIndicators)`

`;
