import { HttpService } from 'src/services';
import { GetManyResponse } from 'src/types';
import { Tag, TagDto } from 'src/models/Tag';

let cachedTags: Tag[] | null = null;

const TagsService = {

  getAll: async (): Promise<Tag[]> => {
    if (cachedTags) {
      return cachedTags;
    }

    const tags = await HttpService
      .get<GetManyResponse<TagDto>>('/public/tags')
      .then(response => response.data)
      .then(coaches => coaches.map(Tag.fromDto));
    cachedTags = tags;

    return tags;
  }

};

export default TagsService;
