import { Challenge } from 'src/models/Challenge';
import { Session } from 'src/components';
import { ChallengesService } from 'src/services';

const ChallengeDetails: React.FC<Challenge> = (
  challenge
) => (
  <Session
    contentType={challenge.contentType}
    title={challenge.title}
    underline={challenge.underline}
    difficulty={challenge.difficulty}
    primaryDescription={challenge.primaryDescription}
    secondaryDescription={challenge.secondaryDescription}
    coach={challenge.coach}
    tag={challenge.tag as any}
    equipment={challenge.equipment as any}
    video={challenge.video}
    attachments={challenge.extras}
    horizontalThumbnail={challenge.horizontalThumbnail}
    duration={challenge.duration}
    isLiked={challenge.isLiked}
    toggleLike={liked => ChallengesService.toggleChallengeLiked(challenge.id, liked)}
  />
);

export default ChallengeDetails;
