import { useEffect, useState } from 'react';

export const useDebouncedValue = <T>(value: T, { debounceDelay = 0, emitFirst = true }) => {
  const [
    debouncedValue,
    setDebouncedValue
  ] = useState(emitFirst ? value : undefined);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), debounceDelay);
    return () => clearTimeout(timeout);
  }, [value, debounceDelay]);

  return debouncedValue;
};
