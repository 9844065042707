import { useCallback, useMemo } from 'react';
import { PaymentMethod, SetupIntentResult } from '@stripe/stripe-js';

import {
  AuthService,
  AuthTokenService,
  UsersService,
  ErrorReportingService,
} from 'src/services';

import { useUserContext } from './context';
import { State, StateSelectorHook } from './types';
import { SubscriptionProductId } from 'src/models/SubscriptionProduct';
import { UserRole } from 'src/models/User';

const useUserState: StateSelectorHook = () => {
  const state = useUserContext()[0];
  return useMemo(() => {
    const isRegularUser = state.user.roles.length === 1 && state.user.roles[0] === UserRole.User;
    return {
      ...state,
      isLoggedIn: state.authStatus === 'loggedIn',

      isRegularUser,

      mustActivateSubscription: (
        state.authStatus === 'loggedIn' &&
        isRegularUser &&
        ['canceled', 'past_due'].includes(state.user.subscription?.status!)
      ),

      nextPaymentAt: resolveNextPaymentDate(state),

      isCancellationPending: (
        state.user.subscription?.status !== 'canceled' &&
        !!state.user.subscription?.cancelAt &&
        state.user.subscription!.cancelAt.getTime() > Date.now()
      ),

      isTrialing: (
        state.user.subscription?.trialEnd &&
        state.user.subscription.trialEnd > new Date()
      ) ?? false,
    };
  }, [state]);
};

const resolveNextPaymentDate = (state: State): Date | null => {
  const sub = state.user.subscription;
  if (!sub) return null;

  const hasRenewablePlan = sub.subscriptionProduct?.isRenewable;
  const willHaveRenewablePlan = sub.nextSubscriptionProduct?.isRenewable;
  if (sub.cancelAt || sub.status === 'canceled' || (!hasRenewablePlan && !willHaveRenewablePlan)) {
    return null;
  }

  return sub.currentPeriodEnd || null
};

const useUserActions = () => {
  const [state, dispatch] = useUserContext();

  const logIn = useCallback(async (email: string, password: string) => {
    dispatch({ type: 'logIn' });
    if (await AuthService.isLegacyPlatformUser(email)) {
      dispatch({ type: 'setLegacyPlatformUser' });
      return;
    }
    AuthService.login({ email, password })
      .then(r => r.accessToken)
      .then(AuthTokenService.set)
      .then(() => Promise.all([UsersService.getMe(), UsersService.couponEligibility()]))
      .then(([user, isEligibleForCoupons]) => dispatch({ type: 'logInSuccess', payload: { ...user, isEligibleForCoupons } }))
      .catch(() => {
        AuthTokenService.clear();
        dispatch({ type: 'logInFailure' });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logOut = useCallback(() => {
    AuthTokenService.clear();
    dispatch({ type: 'logOut' });
  }, [dispatch]);

  const checkIfLoggedIn = useCallback(() => {
    if (!AuthTokenService.get()) {
      return;
    }

    dispatch({ type: 'checkAuth' });
    Promise.all([UsersService.getMe(), UsersService.couponEligibility()])
      .then(([user, isEligibleForCoupons]) => dispatch({ type: 'checkAuthSuccess', payload: { ...user, isEligibleForCoupons } }))
      .catch(() => {
        AuthTokenService.clear();
        dispatch({ type: 'checkAuthFailure' });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkIfIsLegacyPlatformUser = useCallback(async (email: string) => {
    const isLegacyPlatformUser = await AuthService.isLegacyPlatformUser(email);
    if (isLegacyPlatformUser) {
      dispatch({ type: 'setLegacyPlatformUser' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleInactiveSubscriptionInfo = useCallback(
    (show: boolean) => dispatch({ type: 'toggleInactiveSubscriptionInfo', payload: show }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateCard = useCallback((
    createPaymentMethod: () => Promise<PaymentMethod>,
    confirmCardSetup: (clientSecret: string) => Promise<SetupIntentResult>,
  ) => async () => {
    dispatch({ type: 'changeCard' });
    const paymentMethod = await createPaymentMethod();
    try {
      const { clientSecret, requires3DS, cardSuffix } = await UsersService.changeCard(paymentMethod.id);
      if (requires3DS) {
        const { setupIntent, error } = await confirmCardSetup(clientSecret);
        if (setupIntent?.status === 'succeeded' && !error) {
          const { cardSuffix } = await UsersService.changeCard(paymentMethod.id)
          dispatch({ type: 'changeCardSuccess', payload: cardSuffix });
        } else {
          throw new Error('Could not complete 3D Secure authorization');
        }
      } else {
        dispatch({ type: 'changeCardSuccess', payload: cardSuffix });
      }
    } catch (error) {
      const payload = error instanceof Error ? error : new Error('Unknown error');
      dispatch({ type: 'changeCardFailure', payload });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeSubscription = useCallback((id: SubscriptionProductId) => {
    dispatch({ type: 'changeSubscription' });
    Promise.all([UsersService.changeSubscription(id), UsersService.couponEligibility()])
      .then(([user, isEligibleForCoupons]) => dispatch({ type: 'changeSubscriptionSuccess', payload: { ...user, isEligibleForCoupons } }))
      .catch(error => {
        const payload = error instanceof Error ? error : new Error('Unknown error');
        dispatch({ type: 'changeSubscriptionFailure', payload });
        ErrorReportingService.report(error);
      })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelSubscription = useCallback(() => {
    dispatch({ type: 'cancelSubscription' });
    Promise.all([UsersService.cancelSubscription(), UsersService.couponEligibility()])
      .then(([user, isEligibleForCoupons]) => dispatch({ type: 'cancelSubscriptionSuccess', payload: { ...user, isEligibleForCoupons } }))
      .catch(error => {
        dispatch({ type: 'cancelSubscriptionFailure' });
        ErrorReportingService.report(error);
      })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const undoCancelSubscription = useCallback(() => {
    dispatch({ type: 'undoCancelSubscription' });
    Promise.all([UsersService.undoCancelSubscription(), UsersService.couponEligibility()])
      .then(([user, isEligibleForCoupons]) => dispatch({ type: 'undoCancelSubscriptionSuccess', payload: { ...user, isEligibleForCoupons } }))
      .catch(error => {
        dispatch({ type: 'undoCancelSubscriptionFailure' });
        ErrorReportingService.report(error);
      })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const triggerManualPayment = useCallback(() => {
    const productId = state.user.subscription?.subscriptionProduct?.id;
    if (!productId) return;
    dispatch({ type: 'triggerManualPayment' });
    Promise.all([UsersService.triggerManualPayment(productId), UsersService.couponEligibility()])
      .then(([user, isEligibleForCoupons]) => dispatch({ type: 'triggerManualPaymentSuccess', payload: { ...user, isEligibleForCoupons } }))
      .catch(error => {
        const payload = error instanceof Error ? error : new Error('Unknown error');
        dispatch({ type: 'triggerManualPaymentFailure', payload });
        ErrorReportingService.report(error);
      })
  }, [state.user.subscription?.subscriptionProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmCouponRedeemed = useCallback(async () => {
    const [user, isEligibleForCoupons] = await Promise.all([UsersService.getMe(), UsersService.couponEligibility()])
    dispatch({ type: 'couponRedeemed', payload: { ...user, isEligibleForCoupons } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dismissCardError = useCallback(() => {
    dispatch({ type: 'dismissCardError' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openCardForm = useCallback(() => dispatch({ type: 'openCardForm' }), []); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => ({
    logIn,
    logOut,
    checkIfLoggedIn,
    checkIfIsLegacyPlatformUser,
    toggleInactiveSubscriptionInfo,
    updateCard,
    changeSubscription,
    cancelSubscription,
    undoCancelSubscription,
    triggerManualPayment,
    confirmCouponRedeemed,
    dismissCardError,
    openCardForm,
  }), [
    logIn,
    logOut,
    checkIfLoggedIn,
    checkIfIsLegacyPlatformUser,
    toggleInactiveSubscriptionInfo,
    updateCard,
    changeSubscription,
    cancelSubscription,
    undoCancelSubscription,
    triggerManualPayment,
    confirmCouponRedeemed,
    dismissCardError,
    openCardForm,
  ]);
};

export {
  useUserState,
  useUserActions,
};
