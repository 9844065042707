import { SignUpError } from '../context/types';

export const headings = {
  [SignUpError.InvalidUserDetails]: 'Nieprawidłowe dane',
  [SignUpError.TermsAndConditionsNotAccepted]: 'Regulamin',
  [SignUpError.EmailExists]: 'Błąd',
  [SignUpError.InvalidPaymentMethod]: 'Nieprawidłowe dane',
  [SignUpError.SpecificPaymentFailure]: 'Błąd',
  [SignUpError.GenericPaymentFailure]: 'Błąd',
  [SignUpError.UnknownError]: 'Błąd',
};

export const messages = {
  [SignUpError.InvalidUserDetails]: 'Podaj prawidłowe dane konta.',
  [SignUpError.TermsAndConditionsNotAccepted]: 'Aby przejść dalej, zaakceptuj regulamin serwisu.',
  [SignUpError.EmailExists]: 'Podany adres email jest już zajęty.',
  [SignUpError.InvalidPaymentMethod]: 'Uzupełnij prawidłowe dane płatności.',
  [SignUpError.SpecificPaymentFailure]: 'Wystąpił błąd przetwarzania płatności.',
  [SignUpError.GenericPaymentFailure]: 'Wystąpił błąd przetwarzania płatności.',
  [SignUpError.UnknownError]: 'Wystąpił błąd.',
};
