import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import UserMenu from '../UserMenu';
import BrandLogo from '../BrandLogo';
import { ContainedWidth } from '../Layout';
import { useMenuItems } from '../hooks';
import { isExternalLink } from './utils';

/** Pushes actual contents down by the height of the navbar. */
const Spacer = styled.div`
  height: 80px;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  border-top: 4px solid ${p => p.theme.palette.primary.main};
  background: ${p => p.theme.palette.common.white};
  ${p => p.theme.shadows[1]}
`;

const Layout = styled.div`
  height: 80px;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 10px minmax(0, 1fr) auto;
  grid-template-areas: "brand . menu profile";
`;

const Logo = styled(BrandLogo)`
  grid-area: brand;
  width: 56px;
  height: 56px;
  align-self: center;
`;

const UserMenuWrapper = styled.div`
  grid-area: profile;
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
`;

const Menu = styled.nav`
  grid-area: menu;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MenuItem = styled(NavLink).attrs({ className: 'nav-item' })`
  display: block;
  font-size: 16px;
  margin-right: 36px;
  padding: 8px 0;
  && {
    color: ${p => p.theme.palette.common.black};
    text-decoration: none;
    border-bottom: 2px solid transparent;
  }
  transition: border-color 300ms;
  &.active, &:hover {
    border-color: ${p => p.theme.palette.primary.main}
  }
`;

export const DesktopNavbar: React.FC<{
  adminMode?: boolean;
}> = ({
  adminMode,
}) => (
  <>
    <Spacer />
    <Wrapper>
      <ContainedWidth>
        <Layout>
          <Logo />
          <UserMenuWrapper>
            <UserMenu />
          </UserMenuWrapper>
          <Menu>
            {useMenuItems().map(({ path, label }) => isExternalLink(path)
              ? (
                  <MenuItem href={path} as="a" key={label} target="_blank">
                    {label}
                  </MenuItem>
                )
              : (
                  <MenuItem to={path} key={label} exact>
                    {label}
                  </MenuItem>
                )
            )}
            {/* To avoid CSS conflicts, it's safer to use a regular <a> instead of router link */}
            {adminMode && <MenuItem href="/admin" as="a">Admin</MenuItem>}
          </Menu>
        </Layout>
      </ContainedWidth>
    </Wrapper>
  </>
);
