import styled, { keyframes } from 'styled-components';

import { SessionCardletSkeleton } from '../SessionCardletSkeleton';

const animation = {
  enter: keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
  `,
  exit: keyframes`
    from { opacity: 1; }
    to   { opacity: 0; }
  `,
};

export const TransitionContainer = styled.div`
  display: grid;
  grid-template-areas: "shared";
`;

export const Skeleton = styled(SessionCardletSkeleton)<{show: boolean}>`
  grid-area: shared;
  transition: opacity 500ms;
  opacity: ${p => p.show ? 1 : 0};
`;

export const TransitionItem = styled.div<{
  mode: 'enter' | 'exit';
  order: number;
}>`
  grid-area: shared;
  top: 0;
  left: 0;
  width: 100%;
  animation: ${p => animation[p.mode]} 500ms;
  animation-delay: ${p => p.order * 100 + (p.mode === 'enter' ? 50 : 0)}ms;
  opacity: ${p => p.mode === 'enter' ? 0 : 1};
  animation-fill-mode: forwards;
  pointer-events: ${p => p.mode === 'enter' ? 'auto' : 'none'};
  transition: transform .2s;
`;
