import { StripeCardError, StripeError, UserExistsError } from 'src/errors';
import { isPaymentMethodRequired } from './helpers';

import { SignUpAction, SignUpError, SignUpState } from './types';

export const initialSignUpState: SignUpState = {
  status: 'retrievingSubscriptionProduct',
  product: null!,
  coupon: null,
  couponCode: '',
  couponLookupStatus: 'idle',
  currentStep: 'userDetailsAndConsents',
  error: null,
  cardErrorCode: null,
  cardErrorDeclineCode: null,
  errorDismissed: false,
  intent: null,
  consents: {
    termsAndConditions: false,
    marketing: false,
    profiling: false,
  },
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  },
  validUserDetailsProvided: false,
  paymentMethodRequired: true,
  paymentMethodProvided: false,
  paymentMethodTouched: false,
};

export function signUpReducer(state: SignUpState, action: SignUpAction): SignUpState {
  switch (action.type) {

    case 'setSubscriptionProduct': {
      const { product } = action;
      const paymentMethodRequired = isPaymentMethodRequired(product, state.coupon);
      return {
        ...state,
        product,
        paymentMethodRequired,
        status: 'idle',
        currentStep: paymentMethodRequired ? state.currentStep : 'userDetailsAndConsents',
      };
    }

    case 'setSubscriptionProductError': {
      return {
        ...state,
        status: 'subscriptionProductNotFound',
      }
    }

    case 'setCouponCode': {
      return {
        ...state,
        couponCode: action.couponCode.toLocaleUpperCase(),
      };
    }

    case 'lookUpCoupon': {
      return {
        ...state,
        coupon: null,
        couponLookupStatus: 'pending',
      };
    }

    case 'lookupCouponSuccess': {
      const { coupon } = action;
      const paymentMethodRequired = !!state.product && isPaymentMethodRequired(state.product, coupon);

      return {
        ...state,
        coupon,
        couponLookupStatus: 'idle',
        paymentMethodRequired,
        currentStep: paymentMethodRequired ? state.currentStep : 'userDetailsAndConsents',
      };
    }

    case 'lookupCouponError': {
      return {
        ...state,
        coupon: null,
        couponLookupStatus: 'invalid',
      };
    }

    case 'clearCoupon': {
      return {
        ...state,
        couponCode: '',
        coupon: null,
        couponLookupStatus: 'idle',
        paymentMethodRequired: !!state.product && isPaymentMethodRequired(state.product, null),
      };
    }

    case 'setStep': {
      if (action.targetStep === 'paymentMethod' && !state.validUserDetailsProvided) {
        return {
          ...state,
          error: SignUpError.InvalidUserDetails,
          errorDismissed: false,
        };
      }
      if (action.targetStep === 'paymentMethod' && !state.consents.termsAndConditions) {
        return {
          ...state,
          error: SignUpError.TermsAndConditionsNotAccepted,
          errorDismissed: false,
        };
      }
      return {
        ...state,
        currentStep: action.targetStep,
      };
    }

    case 'setIntent':
      return { ...state, intent: action.intent };

    case 'setUserDetails': {
      return {
        ...state,
        userDetails: action.userDetails,
      };
    }

    case 'setConsents': {
      return {
        ...state,
        consents: action.consents,
      };
    }

    case 'toggleValidUserDetailsProvided': {
      return {
        ...state,
        validUserDetailsProvided: action.provided,
      };
    }

    case 'togglePaymentMethodProvided': {
      return {
        ...state,
        paymentMethodProvided: action.provided,
      };
    }

    case 'togglePaymentMethodTouched': {
      return {
        ...state,
        paymentMethodTouched: action.touched,
      };
    }

    case 'setError': {
      return {
        ...state,
        errorDismissed: false,
        error: action.error,
        cardErrorCode: null,
        cardErrorDeclineCode: null,
      };
    }

    case 'dismissError': {
      return {
        ...state,
        errorDismissed: true,
      };
    }

    case 'signUpStarted': {
      if (!state.validUserDetailsProvided) {
        return {
          ...state,
          error: SignUpError.InvalidUserDetails,
          errorDismissed: false,
          currentStep: 'userDetailsAndConsents',
        };
      }
      if (!state.consents.termsAndConditions) {
        return {
          ...state,
          error: SignUpError.TermsAndConditionsNotAccepted,
          errorDismissed: false,
          currentStep: 'userDetailsAndConsents',
        };
      }

      return {
        ...state,
        status: 'signUpPending',
      };
    }

    case 'signUpSuccess': {
      return {
        ...state,
        status: 'signUpSuccess',
      };
    }

    case 'signUpError': {
      let error: SignUpError;
      if (action.error instanceof UserExistsError) {
        error = SignUpError.EmailExists;
      } else if (action.error instanceof StripeError) {
        error = SignUpError.GenericPaymentFailure;
      } else if (action.error instanceof StripeCardError) {
        error = SignUpError.SpecificPaymentFailure;
      } else {
        error = SignUpError.UnknownError;
      }

      const currentStep = {
        [SignUpError.InvalidUserDetails]: 'userDetailsAndConsents',
        [SignUpError.TermsAndConditionsNotAccepted]: 'userDetailsAndConsents',
        [SignUpError.EmailExists]: 'userDetailsAndConsents',
        [SignUpError.InvalidPaymentMethod]: 'paymentMethod',
        [SignUpError.SpecificPaymentFailure]: 'paymentMethod',
        [SignUpError.GenericPaymentFailure]: 'paymentMethod',
        [SignUpError.UnknownError]: state.currentStep,
      }[error] as typeof state['currentStep'];

      return {
        ...state,
        currentStep,
        error,
        cardErrorCode: action.error instanceof StripeCardError ? action.error.code : null,
        cardErrorDeclineCode: action.error instanceof StripeCardError ? action.error.declineCode : null,
        errorDismissed: false,
        status: 'signUpError',
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${(action as { type?: string })?.type}`);
    }

  }
}
