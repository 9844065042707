import { useState } from 'react';

import { useUserState } from 'src/contexts/UserContext';
import { Button, Modal } from 'src/components';

import {
  Wrapper,
  Avatar,
  Prompt,
  Input,
  Submit,
} from './styles';

export const NewComment: React.FC<{
  postComment: (content: string) => Promise<unknown>;
  isRefetching: boolean;
}> = ({
  postComment,
  isRefetching,
}) => {
  const { user } = useUserState();
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }

  const handleSubmit = async () => {
    if (content.length < 1) {
      setError(true);
      return;
    }

    setIsSubmitting(true);
    try {
      await postComment(content);
      setContent('');
    }
    catch (error) {}
    finally {
      setIsSubmitting(false);
    }
  };

  const dismissError = () => {
    setError(false)
  };

  return (
    <>
      <Wrapper>
        <Avatar name={user.name} />
        <Prompt>Dodaj swój komentarz</Prompt>
        <Input
          placeholder="Zacznij pisać…"
          inputProps={{ maxLength: 1000 }}
          value={content}
          onInput={handleInput}
          disabled={isSubmitting}
        />
        <Submit
          label="Dodaj komentarz"
          pending={isSubmitting}
          onClick={handleSubmit}
        />
      </Wrapper>

      <Modal open={error}>
        <Modal.Title>Dodawanie komentarza</Modal.Title>
        <Modal.Content>
          <p>Wpisz treść komentarza</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Wróć"
            onClick={dismissError}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
