import { getSessionMiniature } from 'src/utils';
import { Session } from 'src/models/Session';
import { Challenge } from 'src/models/Challenge';
import { ChallengesService, SessionsService } from 'src/services';
import { BackNavigationInfo } from 'src/models/BackNavigationInfo';

export const resolveStyleName = (entity: Session | Challenge) =>
  entity.contentType === 'challenge'
    ? undefined
    : entity.style?.name;

export const resolveTypeName = (entity: Session | Challenge) =>
  entity.contentType === 'challenge'
    ? undefined
    : entity.type?.[0]?.name ?? '';

export const resolveImage = (mode: 'horizontal' | 'vertical', entity: Session | Challenge) => {
  if (mode === 'horizontal') {
    return entity.horizontalThumbnail ||
      getSessionMiniature(entity.video?.thumbnail, 800);
  } else if ('verticalThumbnail' in entity) {
    return entity.verticalThumbnail ?? undefined;
  }
};

export const resolveDuration = (mode: 'horizontal' | 'vertical', entity: Session | Challenge): number | undefined => {
  if ('duration' in entity && entity.contentType === 'challenge') {
    return entity.duration ?? undefined;
  }
  return entity.video?.duration ?? undefined;
};

export const composeLink = (item?: Session | Challenge, backInfo?: BackNavigationInfo, pathBase?: string) => {
  if (!item) {
    return
  }

  let defaultPathBase = item.contentType === 'session' ? 'sessions' : 'challenges';
  const pathname = `/${pathBase ?? defaultPathBase}/${item.id}`;

  const queryParam = backInfo ? BackNavigationInfo.serializeToUrlParams(backInfo) : '';

  return `${pathname}${queryParam ? `?${queryParam}` : ''}`;
};

export const toggleLike = async (item: Session | Challenge, status: boolean) => {
  if (item?.contentType === 'session') {
    await SessionsService.toggleLiked(item.id, status);
    return;
  }
  if (item?.contentType === 'challenge') {
    await ChallengesService.toggleChallengeLiked(item.id, status);
    return;
  }
  throw new Error('Could not toggle like');
};
