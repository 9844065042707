import { useCallback, useEffect, useState } from 'react';

import { useLastDifferentValue } from 'src/hooks';
import { Session } from 'src/models/Session';
import { BackNavigationInfo } from 'src/models/BackNavigationInfo';

import { SessionCardletActual } from './SessionCardletActual';
import {
  TransitionContainer,
  Skeleton,
  TransitionItem,

} from './styles/transition';
import {
  composeLink,
  resolveImage,
  toggleLike,
} from './helpers';

export const SessionCardlet: React.FC<{
  session?: Session;
  order?: number;
  backNavigationInfo?: BackNavigationInfo;
  onLikeToggleSuccess?: () => void;
}> = ({
  session,
  order = 0,
  backNavigationInfo,
  onLikeToggleSuccess,
}) => {
  const prevSession = useLastDifferentValue(session, (a, b) => a?.id === b?.id);

  const [isLiked, setIsLiked] = useState(false);
  useEffect(
    () => setIsLiked(session?.isLiked ?? false),
    [session],
  );

  const handleToggleLike = useCallback(() => {
    if (!session) return;
    toggleLike(session, !isLiked)
      .then(() => {
        setIsLiked(!isLiked);
        onLikeToggleSuccess?.();
      })
      // The error is already reported in the respective service. If it occurs,
      // we simply don't do anything at all UI-wise:
      .catch();
  }, [session, isLiked, onLikeToggleSuccess]);

  const link = composeLink(session, backNavigationInfo);

  return (
    <TransitionContainer key={order}>
      <Skeleton show={!session} />
      {prevSession && (
        <TransitionItem
          order={order}
          mode="exit"
          key={`exit-${session?.id}`} // ensures exit animation will play again
        >
          <SessionCardletActual
            link="#"
            title={prevSession.title}
            underline={prevSession.underline}
            image={resolveImage(prevSession)}
            styleName={prevSession.style?.name}
            coach={prevSession.coach?.name ?? ''}
            coachImage={prevSession.coach?.thumbnail ?? ''}
            difficulty={prevSession.difficulty}
            duration={prevSession.video?.duration || 0}
            isLiked={prevSession.isLiked}
          />
        </TransitionItem>
      )}
      {session && (
        <TransitionItem
          mode="enter"
          order={order}
          key={`enter-${session?.id}`}
        >
          <SessionCardletActual
            link={link ?? ''}
            title={session.title}
            underline={session.underline}
            image={resolveImage(session)}
            styleName={session.style?.name}
            coach={session.coach?.name ?? ''}
            coachImage={session.coach?.thumbnail ?? ''}
            difficulty={session.difficulty}
            duration={session.video?.duration || 0}
            isLiked={isLiked}
            onToggleLike={handleToggleLike}
          />
        </TransitionItem>
      )}
    </TransitionContainer>
  );
};
