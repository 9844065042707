import React, { useContext, useEffect, useState } from 'react';
import { CmsContent } from 'src/models/CmsContent';
import { CmsService } from 'src/services';

const CmsContentContext = React.createContext<CmsContent>({
  homepageSections: [],
  links: {
    practicePlan: null,
  },
});

export const CmsContentProvider: React.FC = ({ children }) => {
  const [content, setContent] = useState<CmsContent>({
    homepageSections: [],
    links: {
      practicePlan: null,
    },
  });
  useEffect(() => {
    CmsService.getContent()
      .then(setContent)
      .catch(() => {});
      // TODO: add error handling
  }, []);

  return (
    <CmsContentContext.Provider value={content}>
      {content ? children : null}
    </CmsContentContext.Provider>
  );
};

export const useCmsContent = () => {
  const content = useContext(CmsContentContext);
  if (!content) {
    throw new Error('useCmsContent must be used within a CmsContentContext');
  }
  return content;
};
