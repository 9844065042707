import {
  Wrapper,
  Link,
  Label,
  Icon,
} from './styles';

export const BackLink: React.FC<{
  label: string;
  to?: string;
  className?: string;
  textSize?: 'small' | 'regular';
}> = ({
  label,
  to = '',
  className,
  textSize = 'small'
}) => (
  <Wrapper className={className}>
    <Link to={to}>
      <Icon/>
      <Label size={textSize}>{label}</Label>
    </Link>
  </Wrapper>
);
