import { SCondition } from '@nestjsx/crud-request';
import uniq from 'lodash/uniq';

import { SessionFilters, FilterTrait, SessionExcludeFilters } from 'src/models/SessionFilters';
import { Tag } from 'src/models/Tag';
import { cleanString } from 'src/utils';

const minWordLength = 5;
const searchForIndividualPhraseWords = false;

/**
 * Builds a complex search query as documented in:
 * https://github.com/nestjsx/crud/wiki/Requests#search
 */
export const composeRequestQueryBuilderSearch = (
  tags: Tag[],
  filters: SessionFilters,
  exclude?: SessionExcludeFilters,
): SCondition | null => {
  const filterTraits = SessionFilters.groupByTrait(filters);

  const conditions: SCondition[] = [];

  conditions.push({ 'published': { '$eq': true } });

  const onlyPhraseFilter = filters.length === 0 || (filters.length === 1 && filters[0]!.trait === FilterTrait.Phrase);
  if (onlyPhraseFilter && exclude != null) {
    if (FilterTrait.Style in exclude) {
      conditions.push({
        'style.id': {
          '$ne': exclude.style,
        },
      });
    }
    if (FilterTrait.Type in exclude) {
      conditions.push({
        'type.id': {
          '$ne': exclude.type,
        },
      });
    }
  }

  if (filterTraits[FilterTrait.Coach].length) {
    conditions.push({
      'coach.id': {
        '$in': filterTraits[FilterTrait.Coach]
      }
    });
  }

  if (filterTraits[FilterTrait.Equipment].length) {
    conditions.push({
      'equipment.id': {
        '$in': filterTraits[FilterTrait.Equipment]
      }
    });
  }

  if (filterTraits[FilterTrait.Style].length) {
    conditions.push({
      'style.id': {
        '$in': filterTraits[FilterTrait.Style]
      }
    });
  }

  if (filterTraits[FilterTrait.Type].length) {
    conditions.push({
      'type.id': {
        '$in': filterTraits[FilterTrait.Type]
      }
    });
  }

  if (filterTraits[FilterTrait.Difficulty].length) {
    conditions.push({
      'difficulty': {
        '$in': filterTraits[FilterTrait.Difficulty]
      }
    });
  }

  if (filterTraits[FilterTrait.Duration].length) {
    const durationRanges = filterTraits[FilterTrait.Duration]
      .map(v => {
        const [min, max] = v.split(',') as [string, string];
        return { 'video.duration': max === '' ? { '$gte': min } : { '$between': [min, max] } }
      });
    conditions.push({ '$or': durationRanges });
  }

  const phrase = cleanString(filterTraits[FilterTrait.Phrase][0] ?? '', { noDashes: true })
  const phraseWords = phrase
    .split(' ')
    .filter(word => word.length >= minWordLength)
    .map(w => cleanString(w, { noDashes: true }))
    .filter(Boolean)
    .filter(() => searchForIndividualPhraseWords);

  const explicitTags = filterTraits[FilterTrait.Tag];
  const implicitTags = phraseWords
    .map(word => tags.filter(tag =>
      cleanString(tag.name).includes(word) ||
      word.includes(cleanString(tag.name)))
    )
    .flat()
    .map(tag => tag.id);
  const combinedTags = uniq([...explicitTags, ...implicitTags]);

  if (combinedTags.length || phraseWords.length || phrase) {
    const tagCondition = combinedTags.length
      ? { 'tag.id': { '$in': combinedTags } }
      : null;
    const phraseConditions = [phrase, ...phraseWords]
      .filter(word => !!word)
      .map(word => (
        { 'normalizedTitle': { '$contL': word } }
      ));
    conditions.push({
      '$or': [
        ...tagCondition ? [tagCondition] : [],
        ...phraseConditions
      ],
    })
  }

  return { '$and': conditions };
}
