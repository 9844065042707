import { FormEvent, useState, Fragment, useCallback } from 'react';
import {
  List,
  ListItemButton,
  TextField,
  Drawer,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { InfiniteData, useInfiniteQuery, useQueryClient } from 'react-query';
import { Waypoint } from 'react-waypoint';

import { CommentsClient, CommentDto, CommentsByIdRequest } from './CommentsClient';
import Header from './Header';
import NewComment from './NewComment';
import Comment from './Comment/Comment2';
import { useUserState } from '../../contexts/UserContext';
import { Action } from './Comment/styles';
import { GetManyResponse } from 'src/types';
import { buildCommentsGraph, mutate, mutateDeleted, mutateReplied, mutateUpdated } from './helpers';

export interface CommentsService {
  reply(item: CommentDto): void;
  load(item: CommentDto): void;
  menu(item: CommentDto, anchor: HTMLElement): void;
}

function CommentsList({ comments, service, level = 0 }: { comments: CommentDto[], service: CommentsService, level?: number }) {
  return (
    <List disablePadding sx={{ pl: level * 4 }}>
      {comments.map(comment =>
        <Fragment key={comment.id}>
          <Comment comment={comment} service={service} />
          {comment.replies != null &&
            <CommentsList comments={comment.replies} service={service} level={level + 1} />
          }
          {(comment.replyCount ?? 0) > (comment.replies?.length ?? 0) &&
            <ListItemButton onClick={() => service.load(comment)}>
              <Action>Pokaż odpowiedzi</Action>
            </ListItemButton>
          }
        </Fragment>
      )}
    </List>
  );
}



type MenuAction = 'edit' | 'remove' | 'hide' | null;

function BottomDialog(props: {
  initialValue: string,
  open: boolean,
  onSubmit: (content: string) => void,
  onClose: () => void,
  children?: React.ReactNode
}) {
  const [inputValue, setInputValue] = useState(props.initialValue);

  const focusOnShow = useCallback((el: HTMLTextAreaElement | null) => {
    if (el != null && props.open === true) {
      const len = inputValue.length;
      el.setSelectionRange(len, len);
      el.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(event: FormEvent) {
    event.preventDefault();
    props.onSubmit(inputValue);
  }

  return (
    <Drawer
      anchor='bottom'
      open={props.open}
      onClose={props.onClose}
    >
      <form autoComplete='off' onSubmit={onSubmit} onReset={props.onClose} style={{
        minWidth: 768,
        margin: 'auto',
        marginBottom: 20,
      }}>
        <h5 style={{padding: '10px 0'}}>
          {props.children}
        </h5>
        <TextField multiline fullWidth value={inputValue} inputRef={focusOnShow}
          onChange={e => setInputValue(e.target.value)} placeholder='Napisz coś...'></TextField>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          columnGap: '1em',
          marginTop: 5,
        }}>
          <Button type='reset'>
            Anuluj
          </Button>
          <Button type='submit' variant="contained" endIcon={<SendIcon />} sx={{
          }}>
            Zapisz
          </Button>
        </div>
      </form>
    </Drawer>
  )
}

export function CommentsRoot({ id }: { id: CommentsByIdRequest }) {
  const [menuComment, setMenuComment] = useState<CommentDto>();
  const [replyComment, setReplyComment] = useState<CommentDto>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAction, setMenuAction] = useState<MenuAction>(null);
  const queryClient = useQueryClient();
  const { user, isRegularUser } = useUserState();
  const isCommentOwner = menuComment != null && user.id === menuComment.user.id;
  const query = useInfiniteQuery(
    [id],
    ({ pageParam = 0 }) => CommentsClient.getComments(id, pageParam * 10, 10, 'DESC'),
    {
      getNextPageParam: ({ page, pageCount }) => page < pageCount ? page : false,
      refetchOnWindowFocus: false,
      structuralSharing: false,
    },
  );

  function fetchNextPage() {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }

  const service: CommentsService = {
    reply(item) {
      setReplyComment(item);
    },
    async load(item) {
      const res = await CommentsClient.getComments({ ...id, path: item.id }, item.replies?.length ?? 0, 10, 'ASC');
      setQueryData(buildCommentsGraph(item, res.data));
    },
    menu(item, anchor) {
      setMenuComment(item);
      setAnchorEl(anchor);
    },
  };

  function setQueryData(comment: CommentDto, op: 'add' | 'rm' | 'update' = 'update') {
    queryClient.setQueryData<InfiniteData<GetManyResponse<CommentDto>>>([id], (data) => mutate(data!, comment, op));
  }

  async function postComment(content: string) {
    const comment = await CommentsClient.postComment(id, content);
    setQueryData(comment, 'add');
  }

  async function remove() {
    let comment = menuComment!;
    await CommentsClient.removeComment(comment.id);
    if (comment.thread) {
      setQueryData(mutateDeleted(comment), 'update');
    } else {
      setQueryData(comment, 'rm');
    }
    setMenuComment(undefined);
    setMenuAction(null);
  }

  async function toggleHidden() {
    const source = menuComment!;
    const comment = await (source.hidden ? CommentsClient.unhideComment(source.id) : CommentsClient.hideComment(source.id));
    setQueryData(mutateUpdated(source, comment));
    setMenuComment(undefined);
  }

  async function onSubmit(inputValue: string) {
    let comment: CommentDto;
    if (menuComment != null) {
      const res = await CommentsClient.editComment(menuComment.id, inputValue);
      comment = mutateUpdated(menuComment, res);
    } else if (replyComment != null) {
      const newReply = await CommentsClient.postComment({ ...id, replyCommentId: replyComment.id }, inputValue);
      comment = mutateReplied(replyComment, newReply);
    } else {
      throw new Error('unsupported operation');
    }
    setQueryData(comment);
    dismiss();
  }

  function dismiss() {
    if (menuAction === 'edit') {
      setMenuAction(null);
    }
    setMenuComment(undefined);
    setReplyComment(undefined);
  }

  function onMenuItemClick(action: MenuAction) {
    setAnchorEl(null);
    if (action === 'hide') {
      toggleHidden();
    } else {
      setMenuAction(action);
    }
  }

  const count = query.data?.pages[0]?.total;
  const comments = query.data?.pages.flatMap(page => page.data) ?? [];

  return (
    <>
      <Header count={count}>Komentarze</Header>
      <NewComment
        postComment={postComment}
        isRefetching={query.isFetching && !query.isLoading}
      />
      <CommentsList comments={comments} service={service} />
      <Waypoint onEnter={fetchNextPage} />

      <Dialog open={menuAction === 'remove'} onClose={() => setMenuAction(null)}>
        <DialogTitle>Usuwanie komentarza</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Na pewno chcesz usunać swój komentarz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMenuAction(null)}>Anuluj</Button>
          <Button onClick={remove}>Tak, usuń komentarz</Button>
        </DialogActions>
      </Dialog>

      <BottomDialog
        open={replyComment != null || menuAction === 'edit'}
        onClose={dismiss}
        onSubmit={onSubmit}
        initialValue={menuAction === 'edit' ? menuComment!.content : ''}
        key={replyComment?.id ?? menuAction}
      >
        {replyComment != null && `Napisz odpowiedź`}
        {menuAction === 'edit' && 'Edytuj komentarz'}
      </BottomDialog>

      <Menu
        onClose={() => setAnchorEl(null)}
        open={anchorEl != null}
        anchorEl={anchorEl}
        disableScrollLock
      >
        {isCommentOwner && <MenuItem onClick={() => onMenuItemClick('edit')}>Edytuj</MenuItem>}
        {isCommentOwner && <MenuItem onClick={() => onMenuItemClick('remove')}>Usuń</MenuItem>}
        {!isRegularUser && <MenuItem onClick={() => onMenuItemClick('hide')}>{menuComment?.hidden ? 'Przywróć' : 'Ukryj'}</MenuItem>}
      </Menu>
    </>
  );
}
