import styled from 'styled-components';
import { TextField } from '@mui/material';

import Button from 'src/components/Button';
import WidgetLayout from 'src/components/WidgetLayout';

import UnstyledAvatar from '../Avatar';

export const Wrapper = styled(WidgetLayout.ContentWithMargin)`
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-areas: "avatar prompt"
                       "avatar input"
                       "avatar submit";
  grid-column-gap: 16px;
  grid-row-gap: 8px;
`;

export const Avatar = styled(UnstyledAvatar)`
  grid-area: avatar;
  && { // use greater specificity override MUI styling
    width: 40px;
    height: 40px;
  }
`;

export const Prompt = styled.span`
  grid-area: prompt;
  font-size: 13px;
  letter-spacing: 0.17px;
  color: ${p => p.theme.palette.text.high};
  margin-bottom: 8px;
`;

export const Input = styled(TextField).attrs({
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  multiline: true,
  rows: 3,
})``;

export const Submit = styled(Button)`
  justify-self: flex-end;
`;
