import { createTheme, ThemeOptions } from '@mui/material';

import { ReactComponent as DeleteIcon } from 'src/assets/icons/times-circle.svg';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#84D5DC',
      light: '#B7FFFF',
      dark: '#51A3AA',
    },
    secondary: {
      main: '#F82551',
      light: '#FF667D',
      dark: '#BE0029'
    }
  },
  typography: {
    fontFamily: '"Work Sans","Helvetica Neue",Arial,sans-serif'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px'
        },
        
      }
    },
    MuiInputLabel: { 
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          background: '#ffffff',
          "& $notchedOutline": {
            borderColor: 'rgba(0,0,0,0.1)',
            transition: 'border-color .2s',
          },
          "&:hover $notchedOutline": {
            borderColor: 'rgba(0,0,0,0.2)',
          },
          "&$focused $notchedOutline": {
            borderColor: '#51A3AA',
            borderWidth: 1,
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: 'rgb(150,150,150)',
          transition: 'color .2s',
          '&$focused': {
              color: 'rgb(50,50,50)',
          },
      },
      }
    },
    MuiChip: {
      styleOverrides: {
        // root: {
        //   backgroundColor: '#84D5DC',
        //   color: '#FFFFFF',
        //   borderRadius: 4,
        // },
        deleteIcon: {
          height: 14,
          transition: 'fill .2s',
          fill: '#FFFFFF',
          '&:hover': {
            fill: '#FF667D',
          },
        },
      },
      defaultProps: {
        deleteIcon: <DeleteIcon />, 
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          backgroundColor: '#FFFFFF'
        },
        tagSizeSmall: {
          marginTop: 1,
          marginBottom: 1,
          marginLeft: 2,
          marginRight: 2,
          height: 22,
        },
        noOptions: {
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'rgba(0,0,0,0.2)',
          display: 'none',
        },
        option: {
          // Define separate sides to overcome specificity
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          paddingRight: 0,
          backgroundColor: 'transparent !important',
        },
        listbox: {
          padding: 0,
        }
      }
    }
  }
};

const theme = createTheme(themeOptions);

export default theme;
