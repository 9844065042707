import { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal: React.FC = ({ children }) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector("#portal");
    setMounted(true);
  }, []);

  if (ref.current !== null && mounted) {
    return createPortal(children, ref.current);
  }
  return null;
};
