import { FilterTrait } from 'src/models/SessionFilters';
import { SessionQuery } from 'src/models/SessionQuery';

export const composeFilterLink = (
  contentType: 'session' | 'challenge' | 'challengeSession',
  trait: FilterTrait,
  itemId: string,
  scrollInToView?: boolean
) => {
  const shouldRedirectToSessionSearchBox = contentType !== 'session' &&
    trait !== FilterTrait.Tag;
  const pathname = `${contentType === 'session' || shouldRedirectToSessionSearchBox
    ? '/sessions'
    : '/challenges'
    }`;
  const query: SessionQuery = {
    filters: [
      {
        trait,
        value: itemId
      }
    ]
  };
  const queryParam = SessionQuery.serializeToUrlParams(query);

  return `${pathname}?${queryParam}&scrollInToView=${!!scrollInToView}`;
};
