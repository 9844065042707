export enum ItemType {
  ImageLeft = 'imageLeft',
  ImageRight = 'imageRight',
  ImageFull =  'imageFull',
  Video = 'video',
}

export type BaseCarouselItem = {
  title: string;
  overline?: string;
  copy?: string;
  ctaLabel: string;
  ctaUrl: string;
};

export type HalfImageCarouselItem = BaseCarouselItem & {
  type: ItemType.ImageLeft | ItemType.ImageRight;
  imageUrl: string;
  focalPoint: [number, number];
};

export type FullImageCarouselItem = BaseCarouselItem & {
  type: ItemType.ImageFull;
  imageUrl: string;
  focalPoint: [number, number];
};

export type VideoCarouselItem = BaseCarouselItem & {
  type: ItemType.Video;
  videoId: string;
};

export type CarouselItem =
  | HalfImageCarouselItem
  | FullImageCarouselItem
  | VideoCarouselItem;
