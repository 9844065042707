import styles from './Welcome.module.scss';
import { Button, VimeoPlayer } from 'src/components';
import {
  welcomeContentSectionOneButtonText,
  welcomeContentSectionOneHeader,
  welcomeContentSectionOneParagraph,
  welcomeContentSectionTwoButtonText,
  welcomeContentSectionTwoHeader,
  welcomeContentSectionTwoParagraph,
  welcomeSecondSectionHeader,
  welcomeSecondSectionOverhead,
  welcomeSubscriptionInfoButton,
  welcomeSubscriptionInfoHeader,
  welcomeSubscriptionInfoParagraph,
  welcomeTopHeaderColored,
  welcomeTopHeaderText,
  welcomeTopSubHeader
} from './Welcome.const';
import {ReactComponent as LightBulbIcon} from 'src/assets/icons/lightbulb_icon.svg';
import {ReactComponent as BinocularsIcon} from 'src/assets/icons/binoculars_icon.svg';
import FacebookGroup from './FacebookGroup/FacebookGroup.component';
import { useRedirectIfVisited } from './useRedirectIfVisited';

const Welcome: React.FC = () => {
  useRedirectIfVisited();
  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
          <div className={styles.SectionHeader}>
            <h2 className={styles.Header}>
            <span className={styles.HeaderColored}>
              {welcomeTopHeaderColored}
            </span>
              {welcomeTopHeaderText}
            </h2>
            <p className={styles.SubHeader}>
              {welcomeTopSubHeader}
            </p>
          </div>
          <VimeoPlayer
            className={styles.SectionContent}
            viewLink='https://player.vimeo.com/video/639000740?h=54f766a4d5'
          />
          <div className={styles.SectionHeader}>
            <p className={styles.Overhead}>
              {welcomeSecondSectionOverhead}
            </p>
            <h3 className={styles.Header}>
              {welcomeSecondSectionHeader}
            </h3>
          </div>
          <div className={styles.SectionContent}>
            <div className={styles.ContentBlock}>
              <LightBulbIcon/>
              <h6 className={styles.Header}>
                {welcomeContentSectionOneHeader}
              </h6>
              <p className={styles.Paragraph}>
                {welcomeContentSectionOneParagraph}
              </p>
              <Button
                className={styles.Button}
                label={welcomeContentSectionOneButtonText}
                href="/challenges/35320966-dbb1-4b0e-9882-04364f81767c"
                openInNewTab
              />
            </div>
            <div className={styles.ContentBlock}>
              <BinocularsIcon/>
              <h6 className={styles.Header}>
                {welcomeContentSectionTwoHeader}
              </h6>
              <p className={styles.Paragraph}>
                {welcomeContentSectionTwoParagraph}
              </p>
              <Button
                className={styles.Button}
                label={welcomeContentSectionTwoButtonText}
                href="/home"
                openInNewTab
              />
            </div>
          </div>
          <FacebookGroup className={styles.SectionContent}/>
          <div className={styles.SectionContent}>
            <div className={styles.SubscriptionInfo}>
              <h5 className={styles.Header}>
                {welcomeSubscriptionInfoHeader}
              </h5>
              <p className={styles.Paragraph}>
                {welcomeSubscriptionInfoParagraph}
              </p>
              <Button
                label={welcomeSubscriptionInfoButton}
                href="/me/subscription"
                openInNewTab
              />
            </div>
          </div>
      </div>
    </div>
  )
};

export default Welcome;
