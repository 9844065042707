import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionQuery } from 'src/models/SessionQuery';

export function serializeToUrlParams<T>(query: T) {
  return SessionQuery.serializeToUrlParams(query as any);
}

export const useChallengeQueryParams = () => {
  const location = useLocation();
  const [query, setQuery] = useState(SessionQuery.deserializeFromUrlParams(location.search, true));

  useEffect(() => {
    setQuery(SessionQuery.deserializeFromUrlParams(location.search, true));
  }, [location]);

  return query;
};
