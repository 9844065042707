import classnames from 'classnames';

import s from './Form.module.scss';

const Layout: React.FC<{ slideFrom: 'left' | 'right'}> = ({ children, slideFrom }) => (
  <div style={{ overflow: 'hidden' }}>
    <div
      className={classnames(
        s.FormLayout,
        slideFrom === 'left' && s.SlideFromLeft,
        slideFrom === 'right' && s.SlideFromRight,
      )}
    >
      {children}
    </div>
  </div>
);

const LeftColumn: React.FC = ({ children }) => (
  <div className={s.Left}>
    {children}
  </div>
);

const RightColumn: React.FC = ({ children }) => (
  <div className={s.Right}>
    {children}
  </div>
);

const FullWidth: React.FC = ({ children }) => (
  <div className={s.Full}>
    {children}
  </div>
);

const Heading: React.FC = ({ children }) => (
  <h5 className={s.Heading}>
    {children}
  </h5>
);

const Note: React.FC = ({ children }) => (
  <p className={s.Note}>
    {children}
  </p>
);

const Separator = () => <div className={s.Separator} />;

const Form = {
  Layout,
  LeftColumn,
  RightColumn,
  FullWidth,
  Heading,
  Note,
  Separator,
};

export default Form;
