import { HttpService } from '.';
import { SubscriptionProduct, SubscriptionProductDto } from 'src/models/SubscriptionProduct';
import { GetManyResponse } from 'src/types';

const getById = (id: string) => {
  if (!id) {
    throw new Error('Missing subscription product ID');
  }
  return HttpService
    .get<SubscriptionProductDto>(`/public/product/${id}`)
    .then(SubscriptionProduct.fromDto);
};

const getAll = () => HttpService
  .get<GetManyResponse<SubscriptionProductDto>>('/public/product')
  .then(r => r.data)
  .then(dtos => dtos.map(SubscriptionProduct.fromDto))
  .then(products => products.filter(p => p.active && !p.hidden));

const ProductService = {
  getById,
  getAll,
};

export default ProductService;
