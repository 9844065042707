import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { isNativePlatform } from '../../../config';
import BrandLogo from '../BrandLogo';

import { ContainedWidth } from '../Layout';
import SocialLink from '../SocialLink';
import { useFooterInfo } from './useFooterInfo';

const layout = {
  mobile: '@media (max-width: 767px)',
  desktop: '@media (min-width: 768px)',
};

const TopArea = styled.div`
  border-top: 2px solid ${p => p.theme.palette.primary.main};
`;

const TopContent = styled.div`
  ${layout.desktop} {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const TopSection = styled.div`
  padding: 16px 0;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.15px;
  & a {
    transition: color .2s;
    color: ${p => p.theme.palette.primary.dark};
  }
  & a:hover {
    color: ${p => p.theme.palette.primary.main};
  }
`;

const SectionHeading = styled.h2`
  font-size: 19px;
  line-height: 19px;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
`;

const BottomArea = styled.div`
  background: rgba(0,0,0,0.04);
  margin-bottom: ${isNativePlatform ? '80px' : '0'};
`;

const BottomContent = styled.div`
  display: grid;
  font-size: 13px;
  ${layout.mobile} {
    grid-template-rows: auto auto;
    grid-template-areas: 'links'
                         'copyright';
    padding: 8px 0;
  }
  ${layout.desktop} {
    grid-template-columns: auto auto;
    grid-template-areas: 'copyright links';
    padding: 24px 0;
  }
`;

const Copyright = styled.p`
  grid-area: copyright;
  ${layout.mobile} {
    padding: 8px 0;
    margin-top: 8px;
  }
`;

const BottomLinks = styled.div`
  grid-area: links;
  display: flex;
  ${layout.desktop} {
    justify-self: flex-end;
  }
  ${layout.mobile} {
    flex-direction: column;
  }
`;

const BottomLink = styled(NavLink)`
  display: block;
  color: ${p => p.theme.palette.common.black};
  ${layout.desktop} {
    margin-left: 48px;
  }
  ${layout.mobile} {
    padding: 8px 0;
  }
`;

const Logo = styled(BrandLogo)`
  width: 56px;
  height: 56px;
`;

export const Footer: React.FC = () => {
  const {
    businessHours,
    copyrightName,
    email,
    facebookLink,
    instagramLink,
    youtubeLink,
  } = useFooterInfo();
  const copyright = `©${new Date().getFullYear()} ${copyrightName}`;

  return (
    <>
      {
        !isNativePlatform &&
          <TopArea>
              <ContainedWidth>
                  <TopContent>
                      <TopSection>
                          <Logo />
                      </TopSection>
                      <TopSection />
                      <TopSection>
                          <SectionHeading>Kontakt</SectionHeading>
                          <div>{businessHours}</div>
                          <div><a href={`mailto:${email}`}>{email}</a></div>
                      </TopSection>
                      <TopSection>
                          <SectionHeading>Media społecznościowe</SectionHeading>
                          <SocialLink type="facebook">{facebookLink}</SocialLink>
                          <SocialLink type="instagram">{instagramLink}</SocialLink>
                          <SocialLink type="youtube">{youtubeLink}</SocialLink>
                      </TopSection>
                  </TopContent>
              </ContainedWidth>
          </TopArea>
      }
      <BottomArea>
        <ContainedWidth>
          <BottomContent>
            <Copyright>{copyright}</Copyright>
            <BottomLinks>
              <BottomLink as="a" target="_blank" href='https://www.portalyogi.pl/blog/faq/'>FAQ</BottomLink>
              <BottomLink as="a" target="_blank" href='https://portalyogi.pl/regulamin/'>Regulamin</BottomLink>
              <BottomLink as="a" target="_blank" href='https://portalyogi.pl/polityka-prywatnosci/'>Polityka prywatności</BottomLink>
            </BottomLinks>
          </BottomContent>
        </ContainedWidth>
      </BottomArea>
    </>
  );
};
