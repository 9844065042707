import { useCallback, useEffect, useState } from 'react';
import { Collapse } from '@mui/material';

import { Coach } from 'src/models/Coach';
import { Equipment } from 'src/models/Equipment';
import { ExtraFile } from 'src/models/ExtraFile';
import { Style } from 'src/models/Style';
import { Tag } from 'src/models/Tag';
import { Type } from 'src/models/Type';
import { Video } from 'src/models/video';
import { Session as SessionInterface } from 'src/models/Session';
import { FilterTrait } from 'src/models/SessionFilters';
import { Challenge, ChallengeSession } from 'src/models/Challenge';
import {
  EquipmentBadge,
  VimeoPlayer,
  DurationIndicator,
  DifficultyIndicators,
  Avatar,
} from 'src/components';
import { isNativePlatform } from '../../config';

import Downloads from './Downloads';
import LikeButton from './LikeButton';
import CompletedButton from './CompletedButton';
import { composeFilterLink } from './helpers';

import {
  Container,
  Separator,
  Title,
  Underline,
  SectionTitle,
  PrimaryDescription,
  SecondaryDescription,
  EquipmentSection,
  EquipmentList,
  EquipmentItem,
  PropertiesAndActions,
  Properties,
  Property,
  PropertyLabel,
  PropertyLink,
  TypeList,
  TypeItem,
  Actions,
  Tags,
  TagList,
  TagToggler,
  TagItem,
  CoachSection,
  CoachCard,
  DownloadsSection,
} from './styles';
import MobileCollapsible from './MobileCollapsible';

export type ContentType =
  SessionInterface["contentType"] |
  Challenge["contentType"] |
  ChallengeSession["contentType"];

/**
 * Used for displaying details of all session-like entities:
 * - sessions
 * - challenges
 * - challenge sessions
 */
const Session: React.FC<{
  contentType: ContentType;
  title: string;
  underline: string;
  difficulty: number;
  primaryDescription: string;
  secondaryDescription: string;
  coach: Coach | null;
  style?: Style | null;
  type?: Type[];
  tag: Tag[];
  equipment: Equipment[];
  video: Video | null;
  attachments: Array<ExtraFile>;
  horizontalThumbnail?: string | null;
  duration?: number | null;
  isLiked?: boolean;
  isCompleted?: boolean;
  toggleLike?: (status: boolean) => void;
  toggleCompleted?: (status: boolean) => void;
}> = ({
  contentType,
  title,
  underline,
  difficulty,
  primaryDescription,
  secondaryDescription,
  coach,
  style,
  type,
  tag,
  equipment,
  video,
  attachments,
  horizontalThumbnail,
  duration,
  isLiked: initialIsLiked,
  isCompleted,
  toggleLike,
  toggleCompleted,
}) => {
  const [tagsOpened, setTagsOpened] = useState(false);

  const [isLiked, setIsLiked] = useState(initialIsLiked);
  useEffect(() => setIsLiked(initialIsLiked), [initialIsLiked]);
  const handleToggleLike = useCallback(() => {
    if (!toggleLike) return;
    toggleLike(!isLiked);
    setIsLiked(!isLiked);
  }, [toggleLike, isLiked]);

  const validAttachments = attachments.filter(extra => extra.url);

  return (
    <Container>
      <Title>{title}</Title>
      <Underline>{underline}</Underline>
      {
        !isNativePlatform &&
          <MobileCollapsible>
              <PrimaryDescription>
                  <div dangerouslySetInnerHTML={{__html: primaryDescription}} />
              </PrimaryDescription>
          </MobileCollapsible>
      }
      {equipment.length > 0 && (
        <>
          <Separator />
          <EquipmentSection>
            <SectionTitle>Przygotuj:</SectionTitle>
            <EquipmentList>
              {equipment.map(item =>(
                <EquipmentItem key={item.id}>
                  <EquipmentBadge text={item.name} />
                </EquipmentItem>
              ))}
            </EquipmentList>
          </EquipmentSection>
        </>
      )}
      {(video || horizontalThumbnail) && (
        <VimeoPlayer
          viewLink={video?.viewLink}
          horizontalThumbnail={horizontalThumbnail}
          isCompleted={isCompleted}
          onComplete={() => toggleCompleted?.(true)}
        />
      )}
      <PropertiesAndActions>
        <Properties>
          {!!duration && (
            <Property>
              <PropertyLabel>Czas trwania</PropertyLabel>
              <DurationIndicator duration={duration ?? 0} size="big"/>
            </Property>
          )}
          <Property>
            <PropertyLabel>Poziom</PropertyLabel>
            <DifficultyIndicators
              difficulty={difficulty || 0}
              maxDifficulty={5}
            />
          </Property>
          {style && (
            <Property>
              <PropertyLabel>Styl Jogi</PropertyLabel>
              <PropertyLink to={composeFilterLink(contentType, FilterTrait.Style, style.id)}>
                {style.name}
              </PropertyLink>
            </Property>
          )}
          {(type?.length ?? 0) > 0 && (
            <Property>
              <PropertyLabel>Rodzaj Jogi</PropertyLabel>
              <TypeList>
                {type?.map(t => (
                  <TypeItem key={t.id}>
                    <PropertyLink to={composeFilterLink(contentType, FilterTrait.Type, t.id)}>
                      {t.name}
                    </PropertyLink>
                  </TypeItem>
                ))}
              </TypeList>
            </Property>
          )}
        </Properties>
        <Actions>
          {isCompleted !== undefined && (
            <CompletedButton
              completed={isCompleted}
              onToggle={() => toggleCompleted?.(!isCompleted)}
            />
          )}
          {isLiked !== undefined && (
            <LikeButton
              liked={isLiked}
              onToggle={handleToggleLike}
            />
          )}
        </Actions>
      </PropertiesAndActions>
      {
        !isNativePlatform &&
          <Tags>
              <Collapse in={tagsOpened}>
                  <TagList>
                    {tag.map(t => (
                      <TagItem
                        key={t.id}
                        to={composeFilterLink(contentType, FilterTrait.Tag, t.id)}
                      >
                        {t.name}
                      </TagItem>
                    ))}
                  </TagList>
              </Collapse>
              <TagToggler onClick={() => setTagsOpened(current => !current)}>
                  <span>{tagsOpened ? 'Schowaj tagi' : 'Pokaż tagi'}</span>
              </TagToggler>
          </Tags>
      }
      {validAttachments?.length > 0 && (
        <DownloadsSection>
          <SectionTitle>Do pobrania:</SectionTitle>
          <Downloads files={validAttachments}/>
        </DownloadsSection>
      )}
      {
        !isNativePlatform &&
          <MobileCollapsible>
              <SecondaryDescription>
                  <div dangerouslySetInnerHTML={{__html: secondaryDescription}} />
              </SecondaryDescription>
          </MobileCollapsible>
      }
      <Separator />
      <CoachSection>
        <SectionTitle>Nauczyciel:</SectionTitle>
        {coach && (
          <CoachCard
            to={composeFilterLink(contentType, FilterTrait.Coach, coach.id)}
          >
            <Avatar size="large" imageSrc={coach.thumbnail ?? ''}/>
            <p>{coach.name}</p>
          </CoachCard>
        )}
      </CoachSection>
    </Container>
  );
};

export default Session;
