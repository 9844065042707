import styles from './FacebookGroup.module.scss';
import {FacebookGroupProps} from './FacebookGroup.types';
import classnames from 'classnames';
import {
  facebookGroupButton,
  facebookGroupHeader,
  facebookGroupOverhead,
  facebookGroupParagraph
} from './FacebookGroup.const';
import {Button} from 'src/components';

const FacebookGroup: React.FC<FacebookGroupProps> = ({
  className
                                 }) =>
  <div className={classnames(styles.Container, className)}>
    <p className={styles.Overhead}>{facebookGroupOverhead}</p>
    <h3 className={styles.Header}>{facebookGroupHeader}</h3>
    <p className={styles.Paragraph}>{facebookGroupParagraph}</p>
    <Button
      href="https://www.facebook.com/groups/grupa.portalyogi/"
      label={facebookGroupButton}
    />
  </div>


export default FacebookGroup;
