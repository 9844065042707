import { User } from 'src/models/User';

type UserCouponStatus = {
  isEligibleForCoupons: boolean;
};

export type Action =
  { type: 'logIn'; } |
  { type: 'logInSuccess'; payload: User & UserCouponStatus; } |
  { type: 'logInFailure'; } |
  { type: 'logOut'; } |

  { type: 'setLegacyPlatformUser'; } |

  { type: 'toggleInactiveSubscriptionInfo', payload: boolean; } |

  { type: 'checkAuth'; } |
  { type: 'checkAuthSuccess'; payload: User & UserCouponStatus; } |
  { type: 'checkAuthFailure'; } |

  { type: 'openCardForm' } |
  { type: 'changeCard' } |
  { type: 'changeCardSuccess'; payload: User['cardSuffix']; } |
  { type: 'changeCardFailure'; payload: Error } |

  { type: 'changeSubscription'; } |
  { type: 'changeSubscriptionSuccess'; payload: User & UserCouponStatus; } |
  { type: 'changeSubscriptionFailure'; payload: Error } |

  { type: 'dismissCardError' } |

  { type: 'cancelSubscription'; } |
  { type: 'cancelSubscriptionSuccess'; payload: User & UserCouponStatus; } |
  { type: 'cancelSubscriptionFailure'; } |

  { type: 'undoCancelSubscription'; } |
  { type: 'undoCancelSubscriptionSuccess'; payload: User & UserCouponStatus; } |
  { type: 'undoCancelSubscriptionFailure'; } |

  { type: 'triggerManualPayment'; } |
  { type: 'triggerManualPaymentSuccess'; payload: User & UserCouponStatus; } |
  { type: 'triggerManualPaymentFailure'; payload: Error } |

  { type: 'couponRedeemed'; payload: User & UserCouponStatus; };

export enum CardError {
  SpecificPaymentFailure = 'SpecificPaymentFailure',
  GenericPaymentFailure = 'GenericPaymentFailure',
  UnknownError = 'UnknownError',
};

export type State = {
  authStatus: 'idle' | 'pending' | 'loggedIn' | 'error' | 'legacyPlatformUser';
  changeCardStatus: 'idle' | 'open' | 'pending' | 'error';
  changeSubscriptionStatus: 'idle' | 'pending' | 'error';
  cancelSubscriptionStatus: 'idle' | 'pending' | 'error';
  undoCancelSubscriptionStatus: 'idle' | 'pending' | 'error';
  manualPaymentStatus: 'idle' | 'pending' | 'error';
  cardError: CardError | null;
  cardErrorCode: string | null;
  cardErrorDeclineCode: string | null;
  cardErrorDismissed: boolean;
  showInactiveSubscriptionInfo: boolean;
  user: User & UserCouponStatus;
};

export type ComputedState = State & {
  isLoggedIn: boolean;
  isRegularUser: boolean;
  mustActivateSubscription: boolean;
  nextPaymentAt: Date | null;
  isCancellationPending: boolean;
  isTrialing: boolean;
};

export type Dispatch = (action: Action) => void;
export type Reducer = (state: State, action: Action) => State;
export type StateSelectorHook = () => ComputedState;
