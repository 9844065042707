import { useState } from 'react';
import {TextField} from '@mui/material';
import * as yup from 'yup';

import { AuthBox, Button } from 'src/components';
import { AuthService } from 'src/services';

import { Form, InfoBox } from '../styles';

export const RequestEmail: React.FC<{
  tokenWasInvalid?: boolean;
}> = ({
  tokenWasInvalid,
}) => {

  const [email, setEmail] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      yup.string().email().validateSync(email);
    } catch(e) {
      setError('Nieprawidłowy adres email');
      return;
    }

    setIsPending(true);
    setError('');

    try {
      const isSuccess = await AuthService.requestPasswordReset(email);
      if (isSuccess) {
        setIsSuccess(true);
      } else {
        setError('Nie znaleziono podanego adresu email.')
      }
    } catch(e) {
      setError('Wystąpił błąd.');
    } finally {
      setIsPending(false);
    }
  };

  return (
    <AuthBox title="Odzyskaj hasło">
      {tokenWasInvalid && !error && !isSuccess && (
        <InfoBox
          mode="warning"
          message="Link do zmiany hasła jest nieprawidłowy, wygasł albo został już wykorzystany."
        />
      )}
      {error && (
        <InfoBox
          mode="error"
          message={error}
        />
      )}
      {isSuccess && (
        <InfoBox
          mode="success"
          message="Na podany adres email wysłaliśmy wiadomość z instrukcją odzyskania hasła."
        />
      )}
      <Form onSubmit={onSubmit}>
        <TextField
          size='small'
          variant='outlined'
          value={email}
          label='Adres email'
          name='email'
          id='email'
          onChange={event => {setEmail(event.target.value)}}
          data-testid='email-input'
          disabled={isPending || isSuccess}
        />
        <Button
          onClick={onSubmit}
          pending={isPending}
          disabled={!email || isSuccess}
          label={tokenWasInvalid ? 'Wyślij ponownie' : 'Wyślij'}
          submit
        />
      </Form>
    </AuthBox>
  );
};
