export interface CustomEnvironmentParams {
  gtm_auth: string;
  gtm_preview: string;
}

export interface SnippetParams {
  id: string;
  environment?: CustomEnvironmentParams;
}

export interface GtmPurchaseEvent {
  event: GTMEvent;
  ecommerce: {
    items: [{product_id: string, product_name: string}];
    transactionId?: string;
    value: number;
    currency: 'PLN';
    discount_info?: string;
    md5?: string;
  };
}

export enum GTMEvent {
  Registration = 'registration',
  AddCardInfo = 'add_card_info',
  Purchase = 'purchase',
}
