import React, { useContext, useMemo, useState } from 'react';

type WelcomeViewContextValue = {
  showWelcomeView: boolean;
  toggleWelcomeView: (show: boolean) => void;
};

const WelcomeViewContext = React.createContext<WelcomeViewContextValue>({
  showWelcomeView: false,
  toggleWelcomeView: () => {},
});

export const WelcomeViewContextProvider: React.FC = ({ children }) => {
  const [showWelcomeView, toggleWelcomeView] = useState(false);

  const context = useMemo(() => ({
    showWelcomeView,
    toggleWelcomeView,
  }), [showWelcomeView, toggleWelcomeView]);

  return (
    <WelcomeViewContext.Provider value={context}>
      {children}
    </WelcomeViewContext.Provider>
  );
};

export const useWelcomeViewContext = () => {
  const context = useContext(WelcomeViewContext);
  if (!context) {
    throw new Error('useWelcomeViewContext must be used within a WelcomeViewContext');
  }
  return context;
};
