import styled from 'styled-components';

import { Header } from 'src/components';

import {
  Divider,
  Spacer,
  InSectionHeading,
  ActionButtons,
} from '../styles';
import {
  CardPicker,
  SubscriptionPicker,
  CancelSubscription,
  RedeemCode,
  InactiveSubscriptionInfo,
  AdminModeNotification,
  CardErrorInfo,
} from '../features';

const StyledAdminModeNotification = styled(AdminModeNotification)`
  margin-top: 24px;
`;

export const Subscription: React.FC = () => {
  return (
    <>
      <Header
        as="h2"
        title="Zarządzaj subskrypcją"
        underline="Edytuj wybrany plan i sprawdź datę kolejnego rozliczenia."
      />
      <StyledAdminModeNotification />
      <Spacer />
      <SubscriptionPicker />
      <Spacer />
      <Divider />
      <Spacer />
      <InSectionHeading>Zmień kartę</InSectionHeading>
      <CardPicker />
      <Spacer />
      <Divider />
      <Spacer/>
      <InSectionHeading>Dostępne akcje</InSectionHeading>
      <ActionButtons>
        <CancelSubscription />
        <RedeemCode />
      </ActionButtons>
      <InactiveSubscriptionInfo />
      <CardErrorInfo />
    </>
  );
};
