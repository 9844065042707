import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormGroup, TextField } from '@mui/material';

import { PasswordInput, WidgetLayout } from 'src/components';

import { useSignUpContext } from '../context';
import Form from '../Form';
import Checkbox from './Checkbox';
import { SignUpError } from '../context/types';

type Values = {
  userDetails: {
    name: string;
    lastName: string;
    email: string;
    emailRepeat: string;
    password: string;
  };
  consents: {
    termsAndConditions: boolean;
    marketing: boolean;
    profiling: boolean;
  };
};

const validationSchema = yup.object().shape({
  userDetails: yup.object().shape({
    name: yup
    .string()
    .required('Pole wymagane'),
    email: yup
      .string()
      .email('Nieprawidłowy adres email')
      .required('Pole wymagane'),
    emailRepeat: yup
      .string()
      .oneOf([yup.ref('email'), null], 'Powtórzony email się nie zgadza')
      .required('Pole wymagane'),
  }),
});

export const UserDetailsAndConsents: React.FC = () => {
  const [{
    status,
    error,
    userDetails,
    consents
  }, dispatch] = useSignUpContext();
  const signUpPending = status === 'signUpPending';

  const [, setIsEmailFieldFocused] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    initialErrors,
    isValid,
    validateForm,
  ...formik
  } = useFormik<Values>({
    initialValues: {
      userDetails: {
        name: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        emailRepeat: userDetails.email,
        password: userDetails.password,
      },
      consents: {
        termsAndConditions: consents.termsAndConditions,
        marketing: consents.marketing,
        profiling: consents.profiling,
      },
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const showErrorIfEmpty = error === SignUpError.InvalidUserDetails;
  // Triggering validation is necessary to force display "field required" annotations
  // next to empty fields that have not been touched yet:
  useEffect(
    () => { validateForm(); },
    [showErrorIfEmpty, validateForm ],
  );

  useEffect(() => {
    dispatch({
      type: 'setUserDetails',
      userDetails: {
        firstName: values.userDetails.name,
        lastName: values.userDetails.lastName,
        email: values.userDetails.email,
        password: values.userDetails.password,
      },
    });
    dispatch({
      type: 'setConsents',
      consents: values.consents,
    });
  }, [values, dispatch]);

  useEffect(() => {
    const isFormValid = !Object.values(errors).length;
    dispatch({
      type: 'toggleValidUserDetailsProvided',
      provided: isFormValid && isPasswordValid,
    });
  }, [errors, isPasswordValid, dispatch]);

  const allConsentsGranted = Object.values(values.consents).every(Boolean);
  const toggleAllConsents = () => {
    const consentStatus = !allConsentsGranted;
    formik.setFieldValue('consents.termsAndConditions', consentStatus);
    formik.setFieldValue('consents.marketing', consentStatus);
    formik.setFieldValue('consents.profiling', consentStatus);
  };

  return (
    <div>
      <WidgetLayout.ContentWithMargin>
        <Form.Layout slideFrom="left">
          <Form.FullWidth>
            <Form.Heading>Informacje o koncie</Form.Heading>
            <Form.Note>Dane, które wykorzystamy do stworzenia Twojego konta</Form.Note>
          </Form.FullWidth>

          <Form.LeftColumn>
            <TextField
              label='Imię'
              name='userDetails.name'
              value={values.userDetails.name}
              error={(showErrorIfEmpty || touched.userDetails?.name) && !!errors.userDetails?.name}
              helperText={(showErrorIfEmpty || touched.userDetails?.name) && errors.userDetails?.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={signUpPending}
              fullWidth
              size='small'
              variant='outlined'
            />
          </Form.LeftColumn>

          <Form.RightColumn>
            <TextField
              label='Nazwisko'
              name='userDetails.lastName'
              value={values.userDetails.lastName}
              error={(showErrorIfEmpty || touched.userDetails?.lastName) && !!errors.userDetails?.lastName}
              helperText={(showErrorIfEmpty || touched.userDetails?.lastName) && errors.userDetails?.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={signUpPending}
              fullWidth
              size='small'
              variant='outlined'
            />
          </Form.RightColumn>

          <Form.FullWidth>
            <TextField
              label='Adres Email'
              name='userDetails.email'
              value={values.userDetails.email}
              error={(showErrorIfEmpty || touched.userDetails?.email) && !!errors.userDetails?.email}
              helperText={(showErrorIfEmpty || touched.userDetails?.email) && errors.userDetails?.email}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setIsEmailFieldFocused(false);
              }}
              onFocus={() => setIsEmailFieldFocused(true)}
              disabled={signUpPending}
              fullWidth
              size='small'
              variant='outlined'
              type="email"
            />
          </Form.FullWidth>

          <Form.FullWidth>
            <TextField
              label='Powtórz Adres Email'
              name='userDetails.emailRepeat'
              value={values.userDetails.emailRepeat}
              error={(showErrorIfEmpty || touched.userDetails?.emailRepeat) && !!errors.userDetails?.emailRepeat}
              helperText={(showErrorIfEmpty || touched.userDetails?.emailRepeat) && errors.userDetails?.emailRepeat}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={signUpPending}
              onPaste={e => e.preventDefault()}
              fullWidth
              size='small'
              variant='outlined'
            />
          </Form.FullWidth>

          <Form.FullWidth>
            <PasswordInput
              label='Hasło'
              name='userDetails.password'
              errorIfEmpty={showErrorIfEmpty}
              value={values.userDetails.password}
              onChange={handleChange}
              disabled={signUpPending}
              onReadyStateChange={setIsPasswordValid}
              size='small'
              showValidationChecks
            />
          </Form.FullWidth>
        </Form.Layout>
      </WidgetLayout.ContentWithMargin>

      <WidgetLayout.ContentWithoutMargin>
        <Form.Separator />
      </WidgetLayout.ContentWithoutMargin>

      <WidgetLayout.ContentWithMargin>
        <Form.Layout slideFrom="left">
        <Form.Heading>Zgody</Form.Heading>
        <Form.FullWidth>
          <FormGroup>
            <Checkbox
              label={<p><strong>Zaznacz wszystkie</strong></p>}
              checked={allConsentsGranted}
              onChange={toggleAllConsents}
              disabled={signUpPending}
            />
            <Checkbox
              name="consents.termsAndConditions"
              label={<p>Akceptuję <a href="https://www.portalyogi.pl/regulamin/" target="_blank" rel="noreferrer">regulamin</a> serwisu oraz chcę natychmiastowego świadczenia usług i rozumiem, że nie będę mógł od niej odstąpić w terminie 14 dni <strong>(wymagane)</strong></p>}
              checked={values.consents.termsAndConditions}
              onChange={handleChange}
              disabled={signUpPending}
            />
            <Checkbox
              name="consents.marketing"
              label={<p>Wyrażam zgodę PortalYogi PSA na kontakt ze mną w celu marketingu bezpośredniego, w tym przesyłanie mi informacji handlowych i marketingowych dotyczących działalności, treści cyfrowych i usług oferowanych przez PortalYogi PSA z siedzibą w Warszawie za pomocą środków komunikacji elektronicznej na podany adres e-mail. Oświadczam, że zapoznałem się z <a href='https://www.portalyogi.pl/polityka-prywatnosci-portalyogi/' target='_blank' rel='noopener noreferrer'>Polityką Prywatności</a> w tym z informacją o dobrowolnym charakterze wyrażenia zgody, prawie do wycofania zgody w każdym czasie oraz prawie do rezygnacji z usług Newslettera w dowolnym momencie.</p>}
              checked={values.consents.marketing}
              onChange={handleChange}
              disabled={signUpPending}
            />
            <Checkbox
              name="consents.profiling"
              label={<p>Wyrażam zgodę na profilowanie i podejmowanie wobec mnie w oparciu o wykonane profilowanie decyzji w sposób zautomatyzowany przez PortalYogi PSA Profilowanie polega na analizie aktywności zakupowej Klienta w sposób umożliwiający indywidualne kształtowanie oferty oraz naliczanie dodatkowych rabatów dla Klientów.</p>}
              checked={values.consents.profiling}
              onChange={handleChange}
              disabled={signUpPending}
            />
          </FormGroup>
        </Form.FullWidth>
        </Form.Layout>
      </WidgetLayout.ContentWithMargin>
    </div>
  );
};
