import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useChallengeQueryParams,
  useCoaches,
  useChallengeDurationRanges,
  useTags,
  useDifficulties
} from 'src/hooks';
import { useDebouncedValue } from 'src/hooks/useDebounce';
import { FilterTrait } from 'src/models/SessionFilters';
import { Tag } from 'src/models/Tag';
import { DurationRange2 as DurationRange } from 'src/models/DurationRange';
import { ChallengeFilters } from 'src/models/Challenge';
import { Difficulty } from 'src/models/Difficulty';
import { Coach } from 'src/models/Coach';
import { FilterOption } from 'src/features/SessionSearchbox/types';
import { Type } from 'src/models/Type';
import { Style } from 'src/models/Style';
import { SessionQuery } from 'src/models/SessionQuery';

const debounceDelay = 500;

interface ChallengeSearch {
  advancedMode: boolean;
  toggleAdvancedMode: () => void;
  displayFilters: boolean;
  toggleDisplayFilters: VoidFunction;
  searchLink: string;
  values: {
    all: FilterOption[];
    aggregated: (Tag | Coach)[];
    tag: Tag[];
    duration: DurationRange[];
    difficulty: Difficulty[];
    coach: Coach[];
    phrase: string;
  };
  options: {
    tag: Tag[];
    duration: DurationRange[];
    difficulty: Difficulty[];
    coach: Coach[];
  };
  aggregatedOptions: (Tag | Coach)[];
  onChange: {
    tag: (coaches: Tag[]) => void;
    duration: (coaches: DurationRange[]) => void;
    difficulty: (coaches: Difficulty[]) => void;
    coach: (coaches: Coach[]) => void;
    phrase: (phrase: string) => void;
  };
  onAddForeignValue: (value: Type | Style | Coach) => void;
  onPhraseConfirm: (phrase: string, options?: { triggerSearch: boolean }) => void;
  onClearFilter: (item: FilterOption, index: number) => void;
  onClearAllFilters: () => void;
}

function adjustOptions<T extends { [K: string]: any }>(values: string[] | undefined, options: T[], key: keyof T) {
  if (values == null || options.length === 0) {
    return [];
  }
  const map = new Map<string, T>(options.map(v => ([v[key], v])));
  return values.map(v => map.get(v)!);
}

const useChallengeSearch = () => {
  const history = useHistory();
  const query = useChallengeQueryParams();
  const [page] = useState(query.page);
  const [filters, setFilters] = useState<ChallengeFilters>(query.filters ?? {});
  // useEffect(() => setFilters(query.filters ?? {}), [query]);

  const [displayFilters, setDisplayFilters] = useState(false);
  const toggleDisplayFilters = () => setDisplayFilters(true)

  const searchLink = useMemo(() => {
    const search = SessionQuery.serializeToUrlParams({
      filters,
      ...(page ? { page } : {}),
    });
    return `/challenges?${search}`;
  }, [filters, page]);
  const liveSearchLink = useDebouncedValue(searchLink, { debounceDelay });
  useEffect(() => {
    if (liveSearchLink) {
      history.replace(liveSearchLink);
    }
  }, [liveSearchLink, history]);

  const [advancedMode, setAdvancedMode] = useState(false);
  const toggleAdvancedMode = useCallback(
    (forceMode?: boolean) => setAdvancedMode(
      mode => typeof forceMode === 'boolean' ? forceMode : !mode
    ),
    [],
  );

  const allCoaches = useCoaches({ withHidden: true });
  const allTags = useTags();
  const allDurationRanges = useChallengeDurationRanges();
  const allDifficulties = useDifficulties();

  const options = useMemo(
    () => ({
      coach: allCoaches ?? [],
      tag: allTags ?? [],
      duration: allDurationRanges,
      difficulty: allDifficulties,
    }),
    [allCoaches, allTags, allDurationRanges, allDifficulties],
  );

  const aggregatedOptions = useMemo(
    () => [...options.tag, ...options.coach]
      .sort((a, b) => a.name.localeCompare(b.name)),
    [options],
  );

  const onFiltersChange = useCallback((trait: keyof ChallengeFilters, values: string[]) => {
    setFilters(filters => {
      filters[trait] = values.length > 0 ? values : undefined;
      return { ...filters };
    });
  }, []);

  const onAddForeignValue = useCallback(({ trait, id }: Coach) => {
    setFilters(filters => {
      if (Array.isArray(filters[trait])) {
        filters[trait]!.push(id);
      } else {
        filters[trait] = [id];
      }
      return { ...filters };
    });
  }, []);

  const onPhraseConfirm = useCallback((phrase: string, { triggerSearch = false } = {}) => {
    onFiltersChange(FilterTrait.Phrase, phrase === '' ? [] : [phrase]);
  }, [onFiltersChange]);

  const onClearAllFilters = useCallback(() => {
    setFilters(() => ({}));
  }, []);

  const values = useMemo(() => {
    const out = {
      tag: adjustOptions(filters.tag, options.tag, 'id'),
      duration: adjustOptions(filters.duration, options.duration, 'value'),
      difficulty: adjustOptions(filters.difficulty, options.difficulty, 'value'),
      coach: adjustOptions(filters.coach, options.coach, 'id')
    };
    const all = Object.values(out).flat();
    const phrase = filters.phrase ? filters.phrase[0] : '';
    const aggregated = [...out.tag, ...out.coach];
    return { all, aggregated, ...out, phrase } as ChallengeSearch['values'];
  }, [filters, options]);

  const onClearFilter = useCallback((item: FilterOption, _: number) => {
    const key = item.trait as keyof ChallengeFilters;
    if (Array.isArray(values[key])) {
      const items = (values[key] as any[]).filter(v => v !== item).map(v => v.id);
      onFiltersChange(key, items);
    }
  }, [values, onFiltersChange]);

  useEffect(() => {
    setDisplayFilters(false);
  }, [history.location.pathname]);

  return useMemo<ChallengeSearch>(() => ({
    options,
    searchLink,
    aggregatedOptions,
    displayFilters,
    toggleDisplayFilters,
    values,
    onChange: {
      tag: (tags) =>
        onFiltersChange(FilterTrait.Tag, tags.map(v => v.id)),
      duration: (ranges) =>
        onFiltersChange(FilterTrait.Duration, ranges.map(v => v.value)),
      difficulty: (difficulties) =>
        onFiltersChange(FilterTrait.Difficulty, difficulties.map(v => String(v.value))),
      coach: (coaches) =>
        onFiltersChange(FilterTrait.Coach, coaches.map(v => v.id)),
      phrase: (phrase) =>
        onFiltersChange(FilterTrait.Phrase, phrase === '' ? [] : [phrase]),
    },
    onAddForeignValue,
    onPhraseConfirm,
    onClearAllFilters,
    onClearFilter,
    advancedMode,
    toggleAdvancedMode,
  } as ChallengeSearch), [
    options,
    searchLink,
    aggregatedOptions,
    values,
    onFiltersChange,
    onAddForeignValue,
    onPhraseConfirm,
    onClearAllFilters,
    onClearFilter,
    advancedMode,
    toggleAdvancedMode,
    displayFilters
  ]);
};

export default useChallengeSearch;
