import {
  Layout,
  Card,
  Image,
  Overline,
  Title,
  Underline,
  Difficulty,
  Duration,
} from './styles/skeleton';

export const SessionCardletSkeleton: React.FC<{
  className?: string;
}> = ({
  className,
}) => (
  <Card className={className}>
    <Layout>
      <Image />
      <Overline/>
      <Title/>
      <Underline/>
      <Difficulty/>
      <Duration/>
    </Layout>
  </Card>
);
