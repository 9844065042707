export type CarouselItemId = Branded<string, 'carouselItemId'>;

export interface CarouselItem {
  id: CarouselItemId;
  image: string | null;
  name: string;
  title: string;
  overline: string;
  copy: string;
  ctaLabel: string;
  ctaLink: string;
}

export interface CarouselItemDto {
  id: CarouselItemId;
  itemConfig: {
    name?: string;
    title?: string;
    overline?: string;
    copy?: string;
    ctaLabel?: string;
    ctaLink?: string;
  };
  image: {
    url: string;
  } | null;
}

export abstract class CarouselItem {
  static fromDto = (dto: CarouselItemDto): CarouselItem => ({
    id: dto.id,
    image: dto.image?.url ?? null,
    name: dto.itemConfig?.name ?? '<no name>',
    title: dto.itemConfig?.title ?? '',
    overline: dto.itemConfig?.overline ?? '',
    copy: dto.itemConfig?.copy ?? '',
    ctaLabel: dto.itemConfig?.ctaLabel ?? '',
    ctaLink: dto.itemConfig?.ctaLink ?? '',
  });
}

export enum CarouselPage {
  Homepage = 'Homepage',
  Sessions = 'Sessions',
  Challenges = 'Challenges',
  Meditations = 'Meditations',
  Pilates = 'Pilates',
  Teachers = 'Teachers',
};

export type CarouselPageAssignment = {
  page: CarouselPage;
  carouselItemId: CarouselItemId | null;
};
