import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useUserActions, useUserState } from 'src/contexts/UserContext';
import { AuthCheck } from 'src/views';
import {isNativePlatform} from '../config';

const ProtectedRoute: React.FC<
  RouteProps & {
    requiresSubscription?: boolean;
  }
> = ({
  requiresSubscription: routeRequiresSubscription = false,
  children,
  component,
  render,
  ...props
}) => {
  const {
    authStatus,
    mustActivateSubscription: userMustActivateSubscription,
  } = useUserState();
  const { toggleInactiveSubscriptionInfo } = useUserActions();

  switch (authStatus) {
    case 'idle':
    case 'error':
    case 'legacyPlatformUser':
      return (
        <Route
          {...props}
          render={({ location }: any) => {
            const isOnboardingCompleted = localStorage.getItem('onboardingCompleted');
            const redirect = isNativePlatform && isOnboardingCompleted !== 'true' ? '/onboarding' : '/login';
            // Avoid infinite loop, where the user enters protected route /logout, is taken
            // to /login, logs in, and is taken back to /login (the referrer path)
            const referrer = location.pathname === '/logout' ? undefined : location;
            return <Redirect to={{ pathname: redirect, state: { from: referrer }}} />;
          }}
        />
      );
    case 'pending':
      return <Route {...props} component={AuthCheck} />
    case 'loggedIn': {
      if (routeRequiresSubscription && userMustActivateSubscription) {
        toggleInactiveSubscriptionInfo(true);
        return <Redirect to={{ pathname: '/me/subscription' }} />;
      }
      return <Route {...props} children={children} component={component} render={render} />;
    }
  }
};

export default ProtectedRoute;
