import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { Button, Modal } from 'src/components';

import { ActionButton } from '../../styles';
import { useMutation } from 'react-query';
import { UsersService } from 'src/services';
import { CouponCode } from 'src/models/Coupon';
import { useUserActions, useUserState } from 'src/contexts/UserContext';

const CodeInput = styled(TextField).attrs({
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
})``;

export const RedeemCode: React.FC = () => {
  const { confirmCouponRedeemed } = useUserActions();
  const { isEligibleForCoupons } = useUserState().user
  const [showDialog, setShowDialog] = useState(false);
  const [code, setCode] = useState('');
  useEffect(() => setCode(''), [showDialog]);

  const couponRedemption = useMutation(UsersService.redeemCoupon, {
    onError: () => setError(true),
    onSuccess: () => {
      confirmCouponRedeemed();
      setSuccess(true);
    },
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {!success && isEligibleForCoupons && (
        <ActionButton onClick={() => setShowDialog(true)}>
          Zrealizuj voucher / kod rabatowy
        </ActionButton>
      )}
      <Modal open={showDialog}>
        <Modal.Title>Zrealizuj voucher / kod rabatowy</Modal.Title>
        <Modal.Content>
          <p>Aby rozpocząć, wprowadź swój kod poniżej:</p>
          <CodeInput
            label="Twój kod"
            value={code}
            onChange={e => setCode(e.target.value.toLocaleUpperCase())}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Anuluj"
            onClick={() => setShowDialog(false)}
            variant="minimal"
            color="primary"
            disabled={couponRedemption.isLoading}
          />
          <Button
            label="Zrealizuj kod"
            onClick={() => couponRedemption.mutate(code as CouponCode)}
            disabled={!code || success}
            pending={couponRedemption.isLoading}
          />
        </Modal.Actions>
      </Modal>

      <Modal open={showDialog && success}>
        <Modal.Title>Kod zrealizowany</Modal.Title>
        <Modal.Content>
          <p>Kod {code} został zrealizowany!</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="OK"
            onClick={() => setShowDialog(false)}
          />
        </Modal.Actions>
      </Modal>

      <Modal open={showDialog && error}>
        <Modal.Title>Błąd</Modal.Title>
        <Modal.Content>
          <p>Podany kod jest nieprawidłowy.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Wróć"
            onClick={() => {
              setError(false);
              setCode('');
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
