import React, { useCallback, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import {
  MultiselectOption as Option,
  MultiselectDifficultyOption as DifficultyOption,
} from '../MultiselectOption';
import { createFilterOptionsHandler } from '../utils';
import {
  ClearOptions,
  Counter,
  CounterContent,
  DropdownIndicator,
  EndAdornment,
  Listbox,
  Paper
} from './styles';
import { Tag } from 'src/models/Tag';
import { Type } from 'src/models/Type';
import { Style } from 'src/models/Style';
import { Coach } from 'src/models/Coach';
import { DurationRange, DurationRange2 } from 'src/models/DurationRange';
import { Difficulty } from 'src/models/Difficulty';
import { FilterTrait } from 'src/models/SessionFilters';

const noOptionsText = 'Brak pasujących wyników';

const filterOptionsHandler = createFilterOptionsHandler({})

type MultiselectOption = Tag | Type | Style | Coach | DurationRange | DurationRange2 | Difficulty;

// TODO: wrap in memo?
// FIXME: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
const MultiSelectWithCounter = <T extends MultiselectOption>({
  id,
  name,
  label,
  disabled,
  options,
  values,
  onChange,
  noTextInput,
}: {
  id: string;
  name?: string;
  label: string;
  disabled?: boolean;
  options: T[];
  values: T[];
  onChange?: (values: T[]) => void;
  onClear?: () => void;
  noTextInput?: boolean;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const selectNone = useCallback((e: React.MouseEvent) => {
    e.stopPropagation(); // Avoid opening the menu on clear btn click
    onChange?.([]);
  }, [onChange]);


  const endAdornment = (
    <>
      <EndAdornment>
        <Counter visible={!!values.length}>
          {/* Change key to trigger entry animation each time the value changes */}
          <CounterContent key={values.length || '1'}>
            {values.length || '1'}
          </CounterContent>
        </Counter>
      </EndAdornment>
      {!!values.length && (
        <EndAdornment>
          <ClearOptions onClick={selectNone} />
        </EndAdornment>
      )}
      {!values.length && (
        <EndAdornment>
          <DropdownIndicator
            isOpen={isFocused}            
            onClick={isFocused ? event => {
              // Close on clicking the end adornment, if open.
              // Would be great if this adornment could just not intercept clicks/taps:
              // this way clicking/tapping on it would work just like clicking anywhere else in the
              // text field (=close the dropdown menu). Unfortunately, setting pointer events to `none`
              // doesn't do the trick.
              setTimeout(() => inputRef.current?.blur());
            } : undefined}
          />
        </EndAdornment>
      )}
    </>
  );

  return (
    <Autocomplete
      id={id}
      fullWidth
      size="small"
      multiple
      options={options}
      getOptionLabel={option => option.name}
      value={values}
      filterOptions={filterOptionsHandler}
      noOptionsText={noOptionsText}
      onChange={(_, newValues) => {
        onChange?.(newValues)
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      disableClearable
      forcePopupIcon={false}
      disableCloseOnSelect
      openOnFocus
      renderInput={({
        // Ignore the startAdornment as it includes `Chip`s representing
        // selected options and we don't need them.
        InputProps: { startAdornment, ...InputProps },
        ...params
      }) => (
        <TextField
          inputRef={inputRef}
          name={name}
          {...params}
          InputProps={{
            ...InputProps,
            endAdornment,
          }}
          InputLabelProps={{
            shrink: isFocused && !noTextInput,
          }}
          variant="outlined"
          // inputProps={{ style: {cursor: 'pointer !important'} }}
          label={label}
          {... noTextInput ? {
            onKeyDown: e => e.preventDefault(),
            style: { caretColor: 'transparent' }
          } : {}}
        />
      )}
      ListboxComponent={Listbox}
      PaperComponent={Paper}
      renderOption={(props, option, { inputValue, selected }) =>
        option.trait === FilterTrait.Difficulty ? (
          <DifficultyOption
            {...props}
            difficulty={(option as Difficulty).value}
            selected={selected}
            key={option.name}
          />
        ) : (
          <Option
            {...props}
            label={option.name}
            inputValue={inputValue}
            selected={selected}
            checkbox
            key={option.name}
          />
        )
      }
    />
  )
};

export default MultiSelectWithCounter;
