
import {
  Dialog,
  Wrapper,
  Title,
  Content,
  Actions,
} from './styles';

const compositeComponents = {
  Title,
  Content,
  Actions,
};

type CompositeComponents = typeof compositeComponents;

const Modal: React.FC<{
  open: boolean;
}> & CompositeComponents = ({
  open,
  children,
}) => (
  <Dialog open={open} maxWidth="sm" fullWidth>
    <Wrapper>
      {children}
    </Wrapper>
  </Dialog>
);

Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

export { Modal }
