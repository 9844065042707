import styled from 'styled-components';

import { ReactComponent as DownloadIconSvg } from './download.svg';

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  color: ${p => p.theme.palette.text.high};
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;

  transition: background .2s, color .2s;
  &:hover {
    background: ${p => p.theme.palette.primary.main};
    color: #ffffff;
  }
`;

const DownloadIcon = styled.div.attrs({
  children: <DownloadIconSvg />
})`

  width: 16px;
  height: 16px;
  margin-left: 4px;
  transition: fill .2s;
  fill: ${p => p.theme.palette.primary.dark};
  ${Link}:hover & {
    fill: #ffffff;
  }
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const InvoiceButton: React.FC<{
  url: string;
}> = ({
  url,
}) => (
  <Link href={url}>
    Faktura
    <DownloadIcon />
  </Link>
);

export default InvoiceButton;
