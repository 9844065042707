import styled from 'styled-components';

import Loader from 'src/components/Loader';

import { ReactComponent as ExclamationTriangleIcon } from './icons/exclamation-triangle.svg';
import { ReactComponent as ExclamationCircleIcon } from './icons/info-circle.svg';
import { ReactComponent as CheckCircleIcon } from './icons/check-circle.svg';

const colors = {
  border: {
    info: '#84D5DC',
    warning: '#f2c94c',
    success: '#00B067',
    error: '#b00020',
  },
  icon: {
    info: '#51A3AA',
    warning: '#f2c94c',
    success: '#00B067',
    error: '#b00020',
  },
  background: {
    info: '#f8fdfd',
    warning: '#fffcf5',
    success: '#f2fff4',
    error: '#fbf0f2',
  },
};

export const ExclamationTriangle = styled.i.attrs({
  children: <ExclamationTriangleIcon />
})`
  display: flex;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const ExclamationCircle = styled.i.attrs({
  children: <ExclamationCircleIcon />
})`
  display: flex;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;


export const CheckCircle = styled.i.attrs({
  children: <CheckCircleIcon />
})`
  display: flex;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const Container = styled.div<{
  mode: 'info' | 'warning' | 'success' | 'error';
}>`
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  & > ${ExclamationCircle},
  & > ${ExclamationTriangle},
  & > ${CheckCircle} {
    margin-right: 8px;
  }
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: ${p => colors.background[p.mode]};
  border-color: ${p => colors.border[p.mode]};
  fill: ${p => colors.icon[p.mode]};
`;

export const Message = styled.p`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.17px;
`;

export const PendingLoader = styled(Loader)`
  width: 32px;
  height: 32px;
`;

export const PendingOverlay = styled.div<{
  mode: 'info' | 'warning' | 'success' | 'error';
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${p => colors.background[p.mode]};
  ${PendingLoader} {
    color: ${p => colors.icon[p.mode]};
  }
`;
