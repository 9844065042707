import { useQuery } from 'react-query';

import { CarouselPage } from 'src/models/Carousel';
import CarouselService from 'src/services/CarouselService';

export const useCarousel = (page: CarouselPage) => {
  const config = useQuery(
    'carouselConfig',
    CarouselService.getConfig,
    {
      staleTime: Infinity
    }
  );

  if (!config.data) {
    return null;
  }

  return config.data.find(item => item.page === page)?.carouselItem ?? null;
};

export { CarouselPage };
