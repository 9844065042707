import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  CheckmarkIcon,
  SessionIcon,
} from './icons';

const Wrapper = styled(NavLink)`
  font-size: 16px;
  line-height: 24px;
  padding: 13px 0;
  display: grid;
  grid-template-columns: 18px 18px minmax(0, 1fr);
  gap: 8px;
  align-items: center;
  &:last-child {
    margin-bottom: 13px;
  }
`;

const Title = styled.span<{active: boolean}>`
  color: ${p => p.active ? p.theme.palette.primary.dark : p.theme.palette.text.high};
  transition: color .2s;
`;

const Duration = styled.span`
  color: ${p => p.theme.palette.text.low};
`;

export const Step: React.FC<{
  title: string;
  link: string;
  active: boolean;
  completed: boolean;
  duration?: number | null
}> = ({
  title,
  link,
  duration,
  active,
  completed,
}) => (
  <Wrapper to={link}>
    <CheckmarkIcon checked={completed} />
    <SessionIcon />
    <Title active={active}>
      {title}
      {duration && <Duration> ({formatDuration(duration)})</Duration>}
    </Title>
  </Wrapper>
);

const formatDuration = (seconds: number) =>
  new Date(1000 * seconds).toISOString().substr(11, 8).replace(/^00:/, '');
