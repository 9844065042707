import { FullPageLoader, InfoBox, Pagination } from 'src/components';

import usePayments from './usePayments';
import PaymentList from './PaymentList';

export const PaymentHistory: React.FC = () => {
  const {
    payments,
    page,
    pageCount,
    onPageChange,
    isLoading,
    isError,
  } = usePayments();

  if (isLoading) {
    return <FullPageLoader delay={200} />;
  }

  if (isError) {
    return <InfoBox
      mode="error"
      message="Wystąpił błąd podczas pobierania listy płatności."
    />;
  }

  if (payments.length === 0) {
    return <InfoBox
      mode="info"
      message="Brak płatności w historii."
    />;
  }

  return (
    <>
      <PaymentList payments={payments} />
      <Pagination
        page={page}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
    </>
  );
};
