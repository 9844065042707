import styled from 'styled-components';

import { ReactComponent as PoweredByStripeIcon } from './PoweredByStripe.svg';
import { ReactComponent as VisaIcon } from './Visa.svg';
import { ReactComponent as MasterCardIcon } from './MasterCard.svg';
import { ReactComponent as AmericanExpressIcon } from './AmericanExpress.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(0,0,0,0.04);
  padding: 8px;
  & svg {
    display: block;
    height: 40px;
    width: auto;
    
    @media (max-width: 374px) {
      height: 26px;
    }
    
    @media (max-width: 767px) {
      height: 30px;
    }
  }
  & svg:not(:first-child) {
    margin-left: 8px;
  }

`;

export const Branding: React.FC = () => (
  <Container>
    <PoweredByStripeIcon />
    <VisaIcon />
    <MasterCardIcon />
    <AmericanExpressIcon />
  </Container>
);
