import { useQuery, useQueryClient } from 'react-query';

import { ChallengesService } from 'src/services';
import { arrayOfNumbers } from 'src/utils/array';
import { Header, SessionCard } from 'src/components';

import {
  Wrapper,
  Carousel,
  Item,
} from './styles';

const maxItems = 9;

export const ChallengesCarousel: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: challenges = [], isSuccess } = useQuery(
    ['ChallengesCarousel'],
    () => ChallengesService.getMany({
      limit: maxItems,
    }).then(result => result.data),
    { staleTime: Infinity }
  );

  const actualCount = isSuccess ? challenges.length : null;
  const displayCount = actualCount ?? maxItems;

  return (
    <Wrapper>
      <Header
        title="Wyzwania"
        link="/challenges"
        linkLabel="Wszystkie wyzwania"
        as="h2"
      />
      <Carousel>
        {arrayOfNumbers(displayCount).map(index => (
          <Item
            isFirst={index === 0}
            isLast={index === challenges.length - 1}
            key={index}
          >
            <SessionCard
              mode="vertical"
              session={challenges[index]}
              onLikeToggleSuccess={() => {
                queryClient.invalidateQueries(['ChallengesCarousel']);
                queryClient.invalidateQueries(['visited', 'forHomepage']);
              }}
            />
          </Item>
        ))}
      </Carousel>
    </Wrapper>
  );
};
